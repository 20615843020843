import React, { useContext, useEffect, useState } from "react";

import { AdminContext } from "../../context/AdminContext/AdminContext";

import { getRelatedServiceLines } from "../../services/servicesLine";

import SelectOption from "../SelectOption";

import styles from "./ChangeServiceLineForm.module.scss";

export const ChangeServiceLineForm = () => {
  const { userInfo } = useContext(AdminContext);

  const [serviceLinesOptions, setServiceLinesOptions] = useState([]);

  const getServiceLines = async (id) => {
    try {
      const response = await getRelatedServiceLines(id);
      const serviceLines = response.data.filter(serviceLine => serviceLine.id !== userInfo.service_line.id);
      if (!serviceLines.length) setServiceLinesOptions([userInfo.service_line])
      else setServiceLinesOptions(serviceLines);
    } catch (error) {
      console.error(error);
      setServiceLinesOptions([userInfo.service_line]);
    }
  };

  useEffect(() => {
    getServiceLines(
      userInfo.service_line.parent_id ||
      userInfo.service_line.id
    );
  }, []);

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Cambiar línea de servicio</h2>
      <SelectOption
        name="service_line"
        className={styles.selectInput}
      >
        <option value="" disabled>
          Selecciona la nueva línea de servicio asociada al evento
        </option>
        {serviceLinesOptions.map(({ id, name, parent_id }) => (
          <option key={id} value={`${id}--${name}--${parent_id}`}>
            {name}
          </option>
        ))}
      </SelectOption>
    </section>
  );
};
