import * as xlsx from 'json-as-xlsx';

export async function jsonToExcel({
  filename = "Archivo.xlsx",
  sheetName = "Hoja 1",
  data,
  columns = [] 
}) {
  if (!Array.isArray(data) || !data.length) return false;
  const keys = Object.keys(data[0]);
  const cols = columns.length 
    ? columns.filter(col => Object.hasOwnProperty.call(data[0], col.key))
    : keys.map(key => ({ key, column: key }));
  const dataXLS = [
    {
      sheet: sheetName,
      columns: cols.map(col => ({
        label: col.column,
        value: col.key
      })),
      content: data,
    }
  ];
  xlsx(dataXLS, {
    fileName: filename,
  });
  return true;
}