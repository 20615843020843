import React, { useState } from "react"

import { restorePlanById } from "../../services/plans";

import { ResponseResult } from "../../atoms/ResponseResult";
import { ModalStep } from "../../atoms/ModalStep";
import { ModalStepper } from "../ModalStepper";
import Spinner from "../../atoms/Spinner";

import confirmation from "../../assets/imgs/icons/InfoIcon.svg";
import successIcon from "../../assets/imgs/icons/rol_deleteConfirm.svg";

import styles from "./RestorePlanStepper.module.scss";

export const RestorePlanStepper = ({ planId, updateScreen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const restorePlan = async (planId) => {
    setLoading(true);
    try {
      const { statusCode } = await restorePlanById(planId);
      await updateScreen();
      if (statusCode !== 200) setResult("error");
      else setResult("success");      
    } catch (error) {
      console.error(error);
      setResult("error");
    } finally {
      setLoading(false);
    }
  };

  const resultMessages = {
    error: {
      title: "Inténtalo de nuevo",
      subtitle: "la restauración del plan falló",
      img: confirmation,
    },
    success: {
      title: "Evento restaurado",
      img: successIcon,
    }
  };

  if (loading) return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center"
      }}
    >
      <Spinner />
    </div>
  );

  if (result) return <ResponseResult type={result} {...resultMessages[result]} />;

  return (
    <ModalStepper
      initialValues={{ planId }}
      onSubmit={async () => await restorePlan(planId)}
      onClose={onClose}
      showCancelButton
    >
      <ModalStep textButtonCancel="No" textButtonNext="Si">
        <section className={styles.confirmationContainer}>
          <img src={confirmation} alt="Confirmation image" />
          <h2 className={styles.title}>Estás a punto de</h2>
          <h2 className={styles.title}>restaurar un evento</h2>
          <span className={styles.subtitle}>¿Deseas continuar?</span>
        </section>
      </ModalStep>

    </ModalStepper>
  )
};
