import styles from "./_.module.scss";

export default function NavIcon({ isSelected, iconName, onClick = () => {} }) {
  return (
    <img
      onClick={onClick}
      src={
        isSelected
          ? `/icons/${iconName}Icon_blue.svg`
          : `/icons/${iconName}Icon_white.svg`
      }
      className={styles.icon}
      alt="icono eventos"
    ></img>
  );
}
