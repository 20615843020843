import styles from "./AlertInfo.module.scss";
import NextButton from "../../atoms/NextButton";
import CancelButton from "../../atoms/CancelButton";

const AlertInfo = ({
  icon,
  title,
  message,
  handleConfirm,
  handleClose,
  handleDeleteTracing,
  subtitle,
}) => {
  return (
    <div className={styles.alertContainer}>
      {title && (
        <div className={styles.alertTitle}>
          <img src={icon} alt={title} />
          <h2>{title}</h2>
        </div>
      )}
      {subtitle && (
        <div className={styles.alertSubtitle}>
          <img src={icon} alt={title} />
          <h2>{subtitle}</h2>
        </div>
      )}
      {message && (
        <div className={styles.alertDescription}>
          <p>{message}</p>
        </div>
      )}
      <div className={styles.alertButtons}>
        {handleClose && (
          <CancelButton
            text="Cancelar"
            type="button"
            className={styles.confirmButton}
            onClick={handleClose}
          />
        )}

        {handleConfirm && (
          <NextButton
            text="Aceptar"
            type="button"
            className={styles.cancelButton}
            onClick={handleConfirm || handleDeleteTracing}
          />
        )}
      </div>
    </div>
  );
};

export default AlertInfo;
