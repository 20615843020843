import CancelButton from '../../atoms/CancelButton';
import CenterMessage from '../../atoms/CenterMessage';
import NextButton from '../../atoms/NextButton';
import FooterDescription from '../../molecules/FooterDescription';
import ProfileHeader from '../../molecules/ProfileHeader';
import icon from '../../assets/imgs/icon-check.png'
import styles from './FinalProcessView.module.scss';
import BannerEspecial from '../../atoms/BannerEspecial';

export default function FinalProcessView() {
  
  return (
    <>
      <ProfileHeader />
      <BannerEspecial />
      <section className={styles.central}>
        <img src={icon} alt="icono-ckeck" />
        <CenterMessage
          title2='Excelente trabajo,'
          text2='la salud de tus pacientes te lo agradecerá' />
        <div className={styles.buttons}>
          <CancelButton
          text='diligenciar otra escala' />
          <NextButton
          text='Salir' />
        </div>
      </section>
      <FooterDescription />
    </>
  );
}