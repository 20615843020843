import React, { useContext } from "react";

import { AdminContext } from "../../context/AdminContext/AdminContext";

import stethoscope from '../../assets/imgs/green_stethoscope.svg';

import styles from "./ChangeServiceLineWarning.module.scss";

export const ChangeServiceLineWarning = () => {
  const { userInfo } = useContext(AdminContext);

  return (
    <section className={styles.container}>
      <img src={stethoscope} alt="Stethoscope image" />
      <h2 className={styles.title}>¿Necesitas cambiar de línea de servicio</h2>
      <span className={styles.subtitle}>al paciente "{userInfo.patient.name}"?</span>
    </section>
  );
};
