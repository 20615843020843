import { useContext, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Input from "../../atoms/Input";
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import { Button } from "../../atoms/Button";
import ModalContent from "../../atoms/ModalContent";
import SelectOption from "../../atoms/SelectOption";
import { AppContext } from "../../context/AppContext/AppContext";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import {
  updateAlert,
  startTracking,
} from "../../services/Contigo/patient";
import { getTypeParameter } from "../../services/common/admin";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import styles from "./alertForm.module.scss";

export const AlertForm = ({ edit, data, setEdit, updateData }) => {
  const { reasonEdit } = useContext(ContigoContext);
  const { user } = useContext(AppContext);
  const [behaviorOptions, setBehaviorOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [initialValues] = useState({
    symptoms: data?.trace?.symptoms,
    intervention: data?.trace?.intervention,
    remarks: data?.trace?.remarks,
    behavior: data?.trace?.behavior,
  });

  const validationSchema = Yup.object().shape({
    symptoms: Yup.string().required("Campo obligatorio"),
    intervention: Yup.string().required("Campo obligatorio"),
    behavior: Yup.string().required("Campo obligatorio"),
    remarks: Yup.string().required("Campo obligatorio"),
  });


  

  useEffect(() => {
    const getReasons = async () => {
      const res = await getTypeParameter(9);
      setBehaviorOptions(res.data);
    };
    getReasons();
  }, []);

  const behaviorSelected = behaviorOptions.find(item => item.id ===data.trace.behavior)

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setOpenConfirm(true);
          setLoading(true);
          const payload = {
            questionId: data.answerQuestions.idQuestion,
            symptoms: values.symptoms,
            intervention: values.intervention,
            remarks: values.remarks,
            behavior: parseInt(values.behavior),
            email: user.email,
          };

          if (data.trace.status) {
            const response = await updateAlert({
              ...payload,
              remarkId: reasonEdit,
              trackId: data.answerQuestions.id_track,
            });
            if (response.status === 200) {
              setMessage("Seguimiento guardado");
              setLoading(false);
              await updateData();
              setEdit(false);
            }
          } else {
            const response = await startTracking({
              ...payload,
              trackId: data.answerQuestions.id_track,
              planId: data.answerQuestions.id_plan,
            });
            if (response.status === 201) {
              setMessage("Seguimiento registrado");
              setLoading(false);
              await updateData();
              setEdit(false);
            }
          }
        }}
      >
        <Form>
          <section className={styles.detailContainer}>
            <div className={styles.detailOptions}>
              <p className={styles.boldTitle}>Síntomas</p>
              {edit ? (
                <Input name="symptoms" />
              ) : (
                <p>{data?.trace?.symptoms}</p>
              )}
            </div>

            <div className={styles.detailOptions}>
              <p className={styles.boldTitle}>Intervención</p>
              {edit ? (
                <Input name="intervention" />
              ) : (
                <p>{data?.trace?.intervention}</p>
              )}
            </div>
            <div className={styles.detailOptions}>
              <p className={styles.boldTitle}>Conducta</p>
              {edit ? (
                <SelectOption name="behavior">
                  <option value="" selected>
                    Seleccionar
                  </option>
                  {behaviorOptions.map(({ id, value }) => (
                    <option value={id} key={value}>
                      {value}
                    </option>
                  ))}
                </SelectOption>
              ) : (
                <p>{behaviorSelected ? behaviorSelected?.value : null}</p>
              )}
            </div>
            <div className={styles.detailOptions}>
              <p className={styles.boldTitle}>Observaciones</p>
              {edit ? <Input name="remarks" /> : <p>{data?.trace?.remarks}</p>}
            </div>
          </section>
          {edit ? (
            <section className={styles.buttonsContainer}>
              <Button
                variant="outlined"
                color="secondary"
                disabled={false}
                onClick={() => setEdit(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                type="submit"
              >
                Guardar
              </Button>
            </section>
          ) : null}
        </Form>
      </Formik>

      <Modal
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        variant="large"
      >
        <>
          {loading ? (
            <Spinner className_container="alertDel" />
          ) : (
            <ModalContent
              close={() => setOpenConfirm(false)}
              text={message}
              icon={personGreen}
              btns={false}
              alt="Icono confirmación"
            />
          )}
        </>
      </Modal>
    </>
  );
};
