import React from "react";
import { useField } from "formik";
import { useLocation } from "react-router-dom";
import styles from "./SelectOption.module.scss";
import ErrorMessage from "../ErrorMessage";

const SelectOption = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const location = useLocation();

  return (
    <div
      className={`${styles.selectoption} ${
        props.className ? props.className : null
      }`}
    >
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className={`
          ${styles.label} 
          ${styles[props.margin]} 
          ${props.classNameL && props.classNameL}
          `}
        >
          {label}
        </label>
      ) : null}
      <select
        {...field}
        {...props}
        defaultValue={props.defaultValue}
        className={`${
          location.pathname === "/lineas"
            ? styles.selectContainer_Lines
            : styles.selectContainer
        } ${props.classInputSelect && props.classInputSelect}`}
        disabled={props.disabled}
      />
      {props.touchedConditional
        ? meta && (
            <ErrorMessage
              error={meta.error}
              meta={meta}
              className={`${styles.error} ${
                props.errorMessageStyle && props.errorMessageStyle
              }`}
              containerCustom={`${
                props.containerCustom && props.containerCustom
              }`}
            />
          )
        : meta.touched && (
            <ErrorMessage
              error={meta.error}
              meta={meta}
              className={`${styles.error} ${
                props.errorMessageStyle && props.errorMessageStyle
              }`}
            />
          )}
    </div>
  );
};

export default SelectOption;
