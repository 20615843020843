import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Spinner from "../../atoms/Spinner";
import PatientDataTable from "../../atoms/PatientDataTable";
import { AlertCard } from "../../molecules/AlertCard";
import { getAlertByPatientId } from "../../services/Contigo/patient";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import styles from "./PatientAlert.module.scss";

import withAuth  from '../../HOC/withAuth';

const PatientAlert = () => {
  const { setDiligenceInfo } = useContext(DesenlacesContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    const response = await getAlertByPatientId(params.idAlert);
    if (!response.message){
      setData(response.data.data.response[0]);
      setDiligenceInfo(response.data.data.response[0].patient);
      setLoading(false);
    }
    else {
      navigate('/error/404');
    }
   
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Spinner className_container="rolesForm" />
        </div>
      ) : (
        <section className={styles.alertContainer}>
          <PatientDataTable styleType="patientsDetail" updateInfo={getData} />
          <div className={styles.cards}>
            <h2 className={styles.title}> {data.alerts[0].form}</h2>
            <h2 className={styles.subtitle}>{data.alerts[0].service_line}</h2>
            <>
              {data
                ? data.alerts?.map((alert, idx) => (
                    <AlertCard
                      data={alert}
                      key={`alert-${idx}`}
                      updateData={getData}
                    />
                  ))
                : null}
            </>
          </div>
        </section>
      )}
    </>
  );
};

export default withAuth(PatientAlert, '/contigo/pacientes')
