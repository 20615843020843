import styles from "./CancelButton.module.scss";

export default function CancelButton(props) {

  return(
			<button
				type={props.type || "button"}
				{...props.type ==="button" && {onClick: props.onClick}}
				className={`
					${styles.cancelButton} 
					${props.className} 
					${styles[props.tracing]} 
					${styles[props.patientBtn]}
					${styles[props.className]}
					${props.cancelButtonCustom && props.cancelButtonCustom}
				`}
				onClick={props.onClick}
			>
				{props.text}
			</button>
		);
}
