import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";

import Input from "../../atoms/Input";
import ErrorMessage from "../../atoms/ErrorMessage";
import { Button } from "../../atoms/Button/";
import { sendForm } from "../../services/common/admin";
import Doctor from "../../assets/imgs/doctor_help.png";

import styles from "./ContactForm.module.scss";
import Spinner from "../../atoms/Spinner";

export const ContactForm = () => {
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


  return (
    <section className={styles.formContainer}>
      <button
        className={styles.backButton}
        type="button"
        onClick={() => navigate(-1)}
      >
        {`< Atrás`}
      </button>
      <Formik
        initialValues={{}}
        onSubmit={async (values) => {
          setError("");
          setCharging(true);
          setLoading(true);
          const payload = {
            name: values.name,
            email: values.email,
            phone: values.phone,
          };

          const result = await sendForm(payload);
          setLoading(false);
          if (result.statusCode === 201) {
            navigate("/helNotification");
          } else {
            setError("Ha ocurrido un error, intente de nuevo");
          }
          setCharging(false);
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Olvidaste diligenciar este campo"),
          email: Yup.string()
            .required("Este campo es requerido")
            .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, "Correo no válido"),
          phone: Yup.string(),
        })}
      >
        {() => (
          <Form>
            <p className={styles.textInfo}>
            Déjanos tus datos para ayudarte con el <span>acceso a nuestra plataforma,</span> nosotros nos encargamos del resto.
            </p>
            <div className={styles.inputContainer}>
              <Input label="Nombre" name="name" type="text" variant="white" />
              <Input label="Correo" name="email" type="email" variant="white" />
              <Input label="Celular" name="phone" type="text" variant="white" />
              {error ? <ErrorMessage error={error} /> : null}
            </div>
            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={charging}
                className={styles.buttonSend}
              >
                Enviar
              </Button>
            </div>
            <img
              src={Doctor}
              alt="imagen doctor seccion ayuda"
              className={styles.doctor}
            />
            {loading ? (
              <div className={styles.spinnerContainer}>
                <Spinner
                  className_img="logPatientImg"
                  className_container="logPatientCont"
                />
              </div>
            ) : null}
          </Form>
        )}
      </Formik>
    </section>
  );
};
