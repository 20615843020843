import * as React from 'react';

import Modal from "../../atoms/Modal";
import UpdateDataForm from "../UpdateDataForm";
import updateData from "../../assets/imgs/icons/updateData.svg"
import styles from "./UpdateData.module.scss"

export default function UpdateData(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button className={styles.btnUser} onClick={handleClickOpen}>
        <img src={updateData} alt="Actualizar datos" />
        Actualizar
      </button>
      <Modal
        isOpen={open}
        onClose={handleClose}
        variant={"large"}
      >
        <UpdateDataForm
          data={props}
          onClose={handleClose}
          updateInfo={props.updateInfo}
        />
      </Modal>
    </>
  );
}
