import TotalTable from "../../atoms/TotalTable";
import { TitleModules } from "../../atoms/TitleModules";
import totalIcon_blue from "../../assets/imgs/icons/totalIcon_blue.svg";
import styles from "./TotalAdmin.module.scss";

import withAuth  from '../../HOC/withAuth';
function TotalAdmin() {
  return (
    <>
      <TitleModules icon={totalIcon_blue} alt="Icono Reporte" title="Reporte" />
      <section className={styles.tableSection}>
        <TotalTable />
      </section>
    </>
  );
}


export default withAuth(TotalAdmin, '/desenlaces/reporte')