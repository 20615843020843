import React from "react";
import { useField } from "formik";
import ErrorMessage from "../ErrorMessage";
import styles from "./NpsQuestion.module.scss";

const NpsQuestion = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };
  return (
    <div className={styles.npsContainer}>
      <section role="group" className={styles.npsGroup}>
        {props?.options?.map((value, index) => (
          <label
            key={`${index}-nps`}
            className={`${
              index <= 6
                ? styles.redColor
                : index <= 8
                ? styles.yellowColor
                : styles.greenColor
            } ${styles.npsOption}`}
          >
            <input
              type="radio"
              name={field.name}
              value={value.value}
              style={
                index <= 6
                  ? { borderColor: "#F24E68" }
                  : index <= 8
                  ? { borderColor: "#FFD772" }
                  : { borderColor: "#69CA63" }
              }
              onChange={handleChange}
              checked={field.value === value.value}
              disabled={props.disabled}
            />
            <p className={styles.npsValue}>{value.label}</p>
          </label>
        ))}
      </section>

      {props?.ranges ? (
        <section className={styles.rangesSection}>
          <p>{props?.ranges[0]?.detail}</p>
          <p>{props?.ranges[1]?.detail}</p>
        </section>
      ) : null}
      <ErrorMessage error={meta.error} meta={meta} />
    </div>
  );
};

export default NpsQuestion;
