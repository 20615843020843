import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { ProtectedRoute } from "../atoms/ProtectedRoute/ProtectedRoute";
import withAuth from "../HOC/withAuth";
import NewSection from "../organisms/NewSection";
import RolesAdmin from "../organisms/RolesAdmin";
import ServiceLinesAdmin from "../organisms/ServiceLinesAdmin";
import UsersAdmin from "../organisms/UsersAdmin";
import { Protected } from "../atoms/ProtectedRoute/Protected";
import AuthSuccess from "../pages/AuthSuccess";
import ErrorDiligence from "../pages/ErrorDiligence";
import ErrorPages from "../pages/ErrorPage";
import FinalDiligence from "../pages/FinalDiligence";
import FinalProcessView from "../pages/FinalProcessView";
import HelpView from "../pages/HelpView";
import LoginPatients from "../pages/LoginPatients/";
import ManagerLogin from "../pages/ManagerLogin";
import ModulesPage from "../pages/ModulesPage";
import OnBoarding from "../pages/OnBoarding";
import OnBoardingWelcome from "../pages/OnboardingWelcome";
import OtpPatients from "../pages/OtpPatients/";
import PatientScale from "../pages/PatientScale";
import PatientsNoLogin from "../pages/PatientsNoLogin";
import PosasScale from "../pages/PosasScale";
import Splash from "../pages/Splash/";
import WelcomeView from "../pages/WelcomeView";
import Patients from "../organisms/Patients";
import PatientDetail from "../organisms/PatientDetail";
import Diligence from "../organisms/Diligence";
import PatientAlert from "../organisms/PatientAlert";
import NewMonitoring from "../organisms/NewMonitoring";
import ContigoReport from "../organisms/ContigoReport";
import CreateScale from "../organisms/CreateScaleAdmin";
import ScaleAdmin from "../organisms/ScaleAdmin";
import ScalePreview from "../organisms/ScalePreview";
import TotalAdmin from "../organisms/TotalAdmin";
import Tracing from "../organisms/Tracing";
import DownloadSummary from "../organisms/DownloadSummary";
import ForToday from "../organisms/ForToday";
import Historical from "../organisms/Historical";
import PatientDesenlacesDetail from "../organisms/PatientDesenlacesDetail";
import PatientsDesenlaces from "../organisms/PatientsDesenlaces";
import { LoginAuthOption } from "../pages/LoginAuthOption";
import { HelpNotification } from "../pages/HelpNotification";
import Dashboard from "../organisms/Dashboard";

const DiligenceWithAuth = withAuth(Diligence, "/contigo/pacientes");
const ForTodayDiligenceWithAuth = withAuth(Diligence, "/desenlaces/parahoy");
const HistoricalDiligenceWithAuth = withAuth(
  Diligence,
  "/desenlaces/historico"
);
const PatientDiligenceWithAuth = withAuth(Diligence, "/desenlaces/pacientes");

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/:plan" element={<Splash />} />
        <Route path="/login" element={<LoginPatients />} />
        <Route path="/loginAuth" element={<LoginAuthOption />} />
        <Route path="/otp" element={<OtpPatients />} />
        <Route
          path="/Welcome/"
          element={
            <ProtectedRoute>
              <WelcomeView />
            </ProtectedRoute>
          }
        />

        <Route path="/onboarding" element={<OnBoarding />} />
        <Route path="/help" element={<HelpView />} />
        <Route path="/helNotification" element={<HelpNotification />} />
        <Route path="/errordiligence" element={<ErrorDiligence />} />
        <Route path="/finalprocess" element={<FinalProcessView />} />
        <Route path="/finaldiligence" element={<FinalDiligence />} />
        <Route
          path="/scale"
          element={
            <ProtectedRoute>
              <PatientScale />
            </ProtectedRoute>
          }
        />
        <Route path="/newSection" element={<NewSection />} />
        <Route path="/posas" element={<PosasScale />} />
        <Route path="/patienthelp" element={<PatientsNoLogin />} />
        {}
        <Route path="/managerlogin" element={<ManagerLogin />} />
        <Route path="/authsuccess" element={<AuthSuccess />} />

        {/* Admin Routes */}

        <Route path="/welcomeAdmin" element={<OnBoardingWelcome />} />

        <Route path="/dashboard" 
        element={
          <Protected>
            <ModulesPage content={<Dashboard />} />
          </Protected>
        }
        
        />

        <Route
          exact
          path="/usuarios"
          element={
            <Protected>
              <ModulesPage content={<UsersAdmin />} />
            </Protected>
          }
        />
        <Route
          path="/roles"
          element={
            <Protected>
              <ModulesPage content={<RolesAdmin />} />
            </Protected>
          }
        />
        <Route
          path="/lineas"
          element={
            <Protected>
              <ModulesPage content={<ServiceLinesAdmin />} />
            </Protected>
          }
        />

        {/* Formularios */}

        <Route
          exact
          path="/formularios/"
          element={
            <Protected>
              <ModulesPage content={<ScaleAdmin />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/formularios/:idScale"
          element={
            <Protected>
              <ModulesPage content={<CreateScale />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/formularios/nuevoFormulario"
          element={
            <Protected>
              <ModulesPage content={<CreateScale />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/formularios/preview/:idScale"
          element={
            <Protected>
              <ModulesPage content={<ScalePreview />} />
            </Protected>
          }
        />

        {/* DesenlacesRoutes */}

        <Route
          exact
          path="/desenlaces/eventos"
          element={
            <Protected>
              <ModulesPage content={<Tracing />} />
            </Protected>
          }
        />
        <Route
          exact
          path="/desenlaces/parahoy"
          element={
            <Protected>
              <ModulesPage content={<ForToday />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/historico"
          element={
            <Protected>
              <ModulesPage content={<Historical />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/historico/diligenciamiento/:idPlan"
          element={
            <Protected>
              <ModulesPage content={<HistoricalDiligenceWithAuth />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/parahoy/diligenciamiento/:idPlan"
          element={
            <Protected>
              <ModulesPage content={<ForTodayDiligenceWithAuth />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/pacientes"
          element={
            <Protected>
              <ModulesPage content={<PatientsDesenlaces />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/pacientes/diligenciamiento/:idPatient/:idPlan"
          element={
            <Protected>
              <ModulesPage content={<PatientDiligenceWithAuth />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/pacientes/detalle/:idPatient"
          element={
            <Protected>
              <ModulesPage content={<PatientDesenlacesDetail />} />
            </Protected>
          }
        />
        <Route
          exact
          path="/desenlaces/resumen"
          element={
            <Protected>
              <ModulesPage content={<DownloadSummary />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/desenlaces/reporte"
          element={
            <Protected>
              <ModulesPage content={<TotalAdmin />} />
            </Protected>
          }
        />

        {/* Contigo */}
        <Route
          exact
          path="/contigo/pacientes"
          element={
            <Protected>
              <ModulesPage content={<Patients />} />
            </Protected>
          }
        />
        <Route
          exact
          path="/contigo/pacientes/nuevoMonitoreo"
          element={
            <Protected>
              <ModulesPage content={<NewMonitoring />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/contigo/pacientes/detalle/:idPatient"
          element={
            <Protected>
              <ModulesPage content={<PatientDetail />} />
            </Protected>
          }
        />
        <Route
          exact
          path="/contigo/pacientes/detalle/diligenciamiento/:idPatient/:idPlan"
          element={
            <Protected>
              <ModulesPage content={<DiligenceWithAuth />} />
            </Protected>
          }
        />
        <Route
          exact
          path="/contigo/pacientes/detalle/alerta/:idPatient/:idAlert"
          element={
            <Protected>
              <ModulesPage content={<PatientAlert />} />
            </Protected>
          }
        />

        <Route
          exact
          path="/contigo/reporte"
          element={
            <Protected>
              <ModulesPage content={<ContigoReport />} />
            </Protected>
          }
        />
        <Route path="/*" element={<Navigate to="/error/404" replace />} />
        <Route path="/error/*" element={<ErrorPages />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
