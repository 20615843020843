import HeaderLogowhite from "../../atoms/HeaderLogoWhite"
import styles from "./ProfileHeaderWhite.module.scss"

export default function ProfileHeaderWhite() {
  return (
    <>
      <div className={styles.container}>
        <HeaderLogowhite />
        <h1 className={styles.mainTitle}>Tu recuperación</h1>
      </div>
    </>
    )
}