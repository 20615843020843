import styles from "./FooterSocialNetwork.module.scss";
import facebook from "../../assets/imgs/facebook.svg";
import instagram from "../../assets/imgs/instagram.svg";
import whatsapp from "../../assets/imgs/whatsapp.svg";
import linkedin from "../../assets/imgs/linkedin.svg";
import twitter from "../../assets/imgs/twitter.svg";
import youtube from "../../assets/imgs/youtube.svg";

export default function FooterSocialNetwork() {
  return (
    <section className={styles.network}>
      <p>Siguenos:</p>
      <div className={styles.facebook}>
        <a
          href="https://es-la.facebook.com/fsfb.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebookLogo" />
        </a>
      </div>
      <div className={styles.instagram}>
        <a
          href="https://www.instagram.com/fsfb_salud/?hl=es"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagramLogo" />
        </a>
      </div>
      <div className={styles.whatsapp}>
        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
          <img src={whatsapp} alt="whatsappLogo" />
        </a>
      </div>
      <div className={styles.linkedin}>
        <a
          href="https://co.linkedin.com/company/fundacion-santa-fe-de-bogota"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="linkedinLogo" />
        </a>
      </div>
      <div className={styles.twitter}>
        <a
          href="https://twitter.com/FSFB_Salud?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="twitterLogo" />
        </a>
      </div>
      <div className={styles.youtube}>
        <a
          href="https://www.youtube.com/user/fsfbsalud/videos"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtubeLogo" />
        </a>
      </div>
    </section>
  );
}
