import { useEffect, useRef, useState } from "react";

import ArrowIcon from "../../assets/imgs/icons/arrowSelect.svg";
import styles from "./_.module.scss";

export default function PillInput({
  selected = [],
  options = [],
  onClosePill = () => {},
  onSelectOption = () => {},
  onCustomElementCreation = () => {},
  disabled = false
}) {
  const [opened, setOpened] = useState(false);
  const [inputContent, setInputContent] = useState("");
  const [filteredOptions, setfilteredOptions] = useState([]);
  const inputRef = useRef(null);
  const parentRef = useRef(null);

  const pillGenerator = (elements) =>
    elements.map((elm) => (
      <div className={styles.pill} key={elm?.value}>
        <span>{elm?.value}</span>
        <span
          className={styles.times}
          onClick={(ev) => {
            if (!disabled) {
              ev.stopPropagation();
              onClosePill(elm?.key);
            }
          }}
        >
          X
        </span>
      </div>
    ));

  const optionGenerator = (elements) =>
    elements.map((elm) => (
      <div
        key={elm?.value}
        className={styles.option}
        onClick={() => onSelectOption(elm)}
      >
        {elm?.value}
      </div>
    ));
  const handleInputChange = () => {
    setInputContent(inputRef?.current.value || "");
  };
  const handleInputKeyboard = (ev) => {
    if (ev.keyCode === 13) ev.preventDefault();
    if (ev.keyCode === 13 && inputRef?.current) {
      onCustomElementCreation({
        key: Math.ceil(Math.random() * 10000),
        value: inputRef.current.value,
      });
      inputRef.current.value = "";
      setInputContent("");
    }
    return false;
  };

  useEffect(() => {
    if (opened) inputRef?.current.focus();

    const handleClickOutside = (event) => {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target) &&
        opened
      )
        setOpened(false);
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [opened]);

  useEffect(() => {
    const selectedKeys = selected.map((sel) => sel?.key);
    setfilteredOptions(
      options.filter(
        (opt) =>
          !selectedKeys.includes(opt.key) &&
          (!inputContent ||
            opt.value.toLowerCase().indexOf(inputContent.toLowerCase()) >= 0)
      )
    );
  }, [options, inputContent, selected]);

  return (
    <div className={styles.parent} ref={parentRef}>
      <div
        className={`${styles.container} ${opened && styles.selected}`}
        onClick={() => !disabled && setOpened(!opened)}
      >
        {selected?.length
          ? pillGenerator(selected)
          : !opened && (
              <span className={styles.noinfo}>Seleccione una opción</span>
            )}
        <input
          ref={inputRef}
          className={`${styles.inputField} ${opened && styles.visible}`}
          type="text"
          onChange={handleInputChange}
          onKeyDown={handleInputKeyboard}
          onClick={(ev) => ev.stopPropagation()}
        />
        <div className={styles.arrowIcon}>
          <img src={ArrowIcon} />
        </div>
      </div>
      <div className={`${styles.expanded} ${opened && styles.visible}`}>
        {filteredOptions?.length ? (
          optionGenerator(filteredOptions)
        ) : (
          <span className={styles.option}>No hay opciones.</span>
        )}
      </div>
    </div>
  );
}
