import { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import Breadcrumb from "../../atoms/Breadcumbs";
import { RoutesContext } from "../../context/RoutesContext/RoutesContext";
import { useCrumb } from "../../hooks/useCrumb";

export const BreadCrumbsContainer = () => {
  const { setParams } = useContext(RoutesContext);
  const params = useParams();
  const location = useLocation();
  const [list] = useCrumb({ location });

  useEffect(() => {
    setParams(params);
  }, [params]);

  return <Breadcrumb crumbs={list} />;
};
