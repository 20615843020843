import React, { useState } from "react";

import { Formik, Form } from "formik";
import styles from "./ModalFormStepper.module.scss";
import { Button } from "../../atoms/Button";

export const ModalFormStepper = ({ onClose, isLoading, children, ...props }) => {
  const childrenArray = React.Children.toArray(children);
  const totalSteps = childrenArray.length - 1;
  const [step, setStep] = useState(0);

  const currentChild = childrenArray[step];

  const isLastStep = () => {
    return step === totalSteps;
  };

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit({ values, helpers });
        } else {
          setStep((s) => s + 1);
        }
      }}
      validationSchema={currentChild?.props?.validationSchema}
    >
      {() => (
        <Form autoComplete="off" className={styles.formContainer}>
          {currentChild}
          <section className={styles.stepperContainer}>
            <div className={isLoading ? styles.loading : styles.pointsStep}>
              {childrenArray.map((child, i) => (
                <span
                  className={`${
                    i === step
                      ? styles.circleContainerBig
                      : styles.circleContainerSmall
                  } `}
                ></span>
              ))}
            </div>
            <div className={isLoading ? styles.loading : styles.buttonOption}>
              {step !== 0 ?
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={false}
                  onClick={() => setStep((s) => s - 1)}
                >
                {step === totalSteps && "Atrás"}
                </Button>
                : null
              }
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={false}
              >
                {step === totalSteps ? "Guardar" : "Siguiente"}
              </Button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
};
