import * as moment from "moment-timezone";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Pagination } from "../../atoms/Pagination";
import { Switch } from "../../atoms/SwitchFSFB";
import TableHeader from "../../atoms/TableHeader";
import { RolesContext } from "../../context/RolesContext/RolesContext";
import { offsetUser } from "../../custom/offset";
import { useSort } from "../../hooks/useSort";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Table from "../../molecules/Table";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import styles from "./RolesAdminTable.module.scss";

function randomizer() {
  return Math.floor(Math.random() * 100000);
}

export default function RolesAdminTable({
  newRolRender,
  isLoading,
  setIsLoading,
  renderControl,
  handleEditStatus,
  childData,
  handleSelected,
}) {
  const {
    rolesList,
    setRolesList,
    updateBy_roles, 
    setUpdateBy_roles,
    module_roles, 
    setModule_roles,
    name_roles, 
    setName_roles,
    sort_roles,
    setSort_roles,
    actualPage,
    setActualPage,
    pagination,
    setCustomePagination,
    setDiligenceInfo,
  } = useContext(RolesContext);

  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();

  const handleGetRolesList = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort_roles,
      name: name_roles,
      module: module_roles,
      updated_by: updateBy_roles
    }
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/rols?${route}`,});
      const lastPage = res.pagination.last_page;
      const actualPage_1 = res.pagination.page;
      setRolesList(res.data);
      setCustomePagination({ page: actualPage_1, paginationTotal: lastPage });
      setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetRolesList();
  }, [actualPage, sort_roles, control, newRolRender, renderControl]);

  // custom hook sort
  useSort(sort_roles, setSort_roles);

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Estado" column={column} />;
      },
      accessor: "switch",
      Cell: ({ row }) => (
        <>
          <div className={styles.switchContainer}>
            <Switch
              toggled={row.original.active}
              onChange={() => {

                handleEditStatus(!row.original.active, row.original);
              }}
              name={row.original.id}
            />
          </div>
        </>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setName_roles}
            filterValue={name_roles}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleSelected(row.original)}
          >
            {row.original.name}
          </p>
        );
      },
      accessor: "name",
      width: 25,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Módulo"
            column={column}
            handleGetFilter={setModule_roles}
            filterValue={module_roles}
          />
        );
      },
      accessor: "modules",
      Cell: ({ row }) => (
        <>
          {row.original.modules.length &&
            row.original?.modules
              ?.map((module) => module.label)
              .toString()
              .replaceAll(",", ", ")}
        </>
      ),
      width: 20,
      disableSortBy: true,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Modificado por"
            column={column}
            handleGetFilter={setUpdateBy_roles}
            filterValue={updateBy_roles}
          />
        );
      },
      accessor: "updated_by",
      width: 20,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha modificado" column={column} />;
      },
      Cell: ({ row }) => (
        <p>
          {moment
            .tz(row.values.updated_at, "America/Bogota")
            .format("YYYY-MM-DD HH:mm")}
        </p>
      ),
      accessor: "updated_at",
      width: 15,
      disableFilters: true,
      tooltipType: "date"
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Usuarios"
            column={column}
          />
        );
      },
      accessor: "users",
      width: 10,
      textAlign: "right",
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [module_roles, name_roles, updateBy_roles]);

  useEffect(() => {
    if (
      module_roles.length ||
      name_roles.length ||
      updateBy_roles?.length > 0
    ) {
      setActualPage(1);
    }
  }, [module_roles, name_roles, updateBy_roles]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={rolesList}
        offsetUser={offsetUser}
        tooltipText="Habilita y asigna roles a las personas que gestionarán las funciones de la App."
        childData={childData}
        setValues={setDiligenceInfo}
      />
      <Pagination
        actualPage={actualPage}
        setPage={setActualPage}
        infoPage={pagination}
      />
    </>
  );
}