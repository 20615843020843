import * as xlsx from "json-as-xlsx";
import * as moment from "moment-timezone";

export async function handleDownload(
  setIsLoading,
  setIsDownLoad,
  fetchData,
  optionsInformant,
  optionsLossInformation,
  optionsInterview
) {
  const data = fetchData || [];

  const linesGroup = data?.reduce((lines, item) => {
    const line = lines[item.service_line] || [];
    line.push([item]);
    lines[item.service_line] = line;
    return lines;
  }, {});

  const reportData = [];
  for (let line in linesGroup) {
    reportData.push({
      line: line,
      tracks: linesGroup[line].flat(1),
    });
  }

  for (let line in reportData) {
    const tracks = reportData[line].tracks;
    let columnsArray = [
      { label: "FECHA", value: "date" },
      { label: "NOMBRE", value: "name" },
      { label: "DOCUMENTO", value: "doc_number" },
      { label: "LINEA", value: "service_line" },
      { label: "OBSERVACION", value: "remark" },
    ];

    let contentArray = [];
    for (let track of tracks) {
      let scales = track.scales;
      const dataBase = {
        date: moment
          .tz(track?.date, "America/Bogota")
          .format("YYYY-MM-DD HH:mm:ss"),
        name: track?.name,
        doc_number: track?.doc_number,
        service_line: track?.service_line,
        remark: track?.remark,
      };

      if (!scales.length) {
        contentArray.push({ ...dataBase });
      } else {
        for (let scale in scales || []) {
          const answers = scales[scale].answers;
          columnsArray.push(
            { label: "FORMULARIO", value: "form" },
            { label: "FECHA_SEGUIMIENTO", value: "tracing_date" },
            { label: "ID_PACIENTE", value: "id_patient" },
            { label: "ACEPTA_MEDICION", value: "accept_scale" },
            { label: "INFORMANTE", value: "informant" },
            { label: "PERDIDA_DE_INFORMACION", value: "loss_information" },
            { label: "TIPO_ENTREVISTA", value: "intervie_type" },
            { label: "FRECUENCIA", value: "frecuency" },
            { label: "PUNTAJE", value: "score" }
          );
         
          for (let answer of answers) {
            let questions = answer?.questions;
            const baseData = {
              form: scales[scale].name,
              date: moment
                .tz(track?.date, "America/Bogota")
                .format("YYYY-MM-DD HH:mm:ss"),
              name: track?.name,
              doc_number: track?.doc_number,
              service_line: track?.service_line,
              remark: track?.remark,
              tracing_date: moment
                .tz(answer?.tracing_date, "America/Bogota")
                .format("YYYY-MM-DD HH:mm:ss"),
              id_patient: answer?.id_patient,
              accept_scale:
                answer?.accept_scale === true
                  ? "Si"
                  : false
                  ? "No"
                  : "No Aplica",
              informant: answer.informant
                ? optionsInformant.find(
                    (option) => option.id === answer.informant
                  ).label
                : "No aplica",
              loss_information: answer.loss_information
                ? optionsLossInformation.find(
                    (option) => option.id === answer.loss_information
                  ).label
                : "No aplica",
              intervie_type: answer.intervie_type
                ? optionsInterview.find(
                    (option) => option.id === answer.intervie_type
                  ).label
                : "No aplica",
              frecuency: answer?.frequency,
              score: answer?.score,
            };

            if (!questions.length ) {
              contentArray.push({ ...baseData });
            } else {

              const clearQuestions = questions.filter(item => item !== null)
              const groupBySection = clearQuestions.reduce((group, current) => {
                const { section } = current;

                group[section] = group[section] ?? [];
                group[section].push(current);

                return group;
              }, {});

              let arraySection = [];
              for (let s in groupBySection) {
                arraySection.push({
                  form: scales[scale].name,
                  section: s,
                  questions: groupBySection[s].sort(
                    (a, b) => a.order - b.order
                  ),
                });
              }

              for (let section in arraySection) {
                const sectionNumber = parseInt(section) + 1;
                const questionsBySection = arraySection[section].questions;
                const form = arraySection[section].form;

                columnsArray.push({
                  form: form,
                  label: `SECCION_${sectionNumber}`,
                  value: `s_${arraySection[section].section}`,
                });

                for (let q in questionsBySection) {
                  const questionLabel = `${sectionNumber}_${questionsBySection[q].label}`;
                  const questionValue =
                    `${sectionNumber}_${questionsBySection[q].label}`
                      .replace(/\s+/g, "")
                      .replace(/\./g, "");
                  const scoreLabel = `Puntaje_${sectionNumber}_${questionsBySection[q].label}`;
                  const scoreValue =
                    `score_${sectionNumber}_${questionsBySection[q].label}`
                      .replace(/\s+/g, "")
                      .replace(/\./g, "");

                  const updatedLabel = `${sectionNumber}_${questionsBySection[q].label}`;
                  questionsBySection[q].label = updatedLabel;

                  columnsArray.push(
                    {
                      form: form,
                      label: questionLabel.toUpperCase(),
                      value: questionValue,
                    },
                    {
                      form: form,
                      label: scoreLabel,
                      value: scoreValue,
                    }
                  );
                }
              }


              const listQuestions = arraySection
                .flatMap((q) => q.questions)
                .sort((a, b) => a.order - b.order);

              contentArray.push({
                ...baseData,
                ...listQuestions.reduce(
                  (prev, curr, index) => ({
                    ...prev,
                    [`${curr.label.replace(/\s+/g, "").replace(/\./g, "")}`]:
                      curr.answer,
                    [`score_${curr.label
                      .replace(/\s+/g, "")
                      .replace(/\./g, "")}`]: curr.score,
                  }),
                  {}
                ),
                ...arraySection.reduce(
                  (prev, curr, index) => ({
                    ...prev,
                    [`s_${curr.section}`]: curr.section,
                  }),
                  {}
                ),
              });
            
            }
          }
        }
      }
    }

    function groupByForm(arr) {
      const groupedData = {};
      arr.forEach((obj) => {
        const formName = obj.form;
        if (!groupedData[formName] && formName !== undefined) {
          groupedData[formName] = [];
        }
        if (obj.form) {
          groupedData[formName].push(obj);
        }
      });
      return Object.values(groupedData);
    }
    

    const groupedData = groupByForm(contentArray);
    function groupByColumnForm(arr) {
      const groupedColumnData = {};

      arr.forEach((obj) => {
        const formName = obj.form;
        if (!groupedColumnData[formName]) {
          groupedColumnData[formName] = [];
        }
        groupedColumnData[formName].push(obj);
      });

      const undefinedObjects = groupedColumnData["undefined"];
      for (const key in groupedColumnData) {
        if (key !== "undefined") {
          groupedColumnData[key] = undefinedObjects.concat(
            groupedColumnData[key]
          );
        }
      }
      delete groupedColumnData["undefined"];
      return Object.values(groupedColumnData);
    }
    const groupedColumnData = groupByColumnForm(columnsArray);

    const nameLine = reportData[line].line;

    const sheets = [];
   
    for (let form in groupedData) {
      sheets.push({
        sheet: groupedData[form][0].form.substring(0, 30),
        columns: groupedColumnData[form],
        content: groupedData[form],
      });
    }

    xlsx(sheets, {
      fileName: `${nameLine}_Contigo`,
    });
  }
  setIsLoading(false);
  setIsDownLoad(true);
  // setIsDownLoad(false);
}
