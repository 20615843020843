import { LoginWelcome } from "../../templates/LoginWelcome";
import { WelcomeMessage } from "../../molecules/WelcomeMessage";

export default function OnBoardingWelcome() {
  return (
    <LoginWelcome>
      <WelcomeMessage />
    </LoginWelcome>
  );
}
