import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Field } from "formik";

import ModalContent from "../../atoms/ModalContent";
import Spinner from "../../atoms/Spinner";
import { ModalStepper } from "../../molecules/ModalStepper";
import { ModalStep } from "../../atoms/ModalStep";
import {  deleteAlert } from "../../services/Contigo/patient";
import { getTypeParameter } from "../../services/common/admin";
import { AppContext } from "../../context/AppContext/AppContext";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";
import styles from "./DeleteAlert.module.scss";

const validateSchema = Yup.object({
  reason: Yup.string()
    .min(1, "Debe seleccionar una opción")
    .required("este campo es requerido"),
});

export const DeleteAlert = ({ onClose, data, updateData }) => {
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reasons, setReasons] = useState();
  const [message, setMessage] = useState("");
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);

  useEffect(() => {
    const getReasons = async () => {
      const res = await getTypeParameter(10);
      setReasons(res.data);
    };
    getReasons();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner className_container="alertDel" />
      ) : showConfirmMessage ? (
        <ModalContent
          text={message}
          icon={personRed}
          btns={false}
          alt="Icono confirmación"
        />
      ) : (
        <ModalStepper
          onClose={onClose}
          isLoading={isLoading}
          initialValues={{
            reason: "",
          }}
          onSubmit={async (values) => {
            setIsLoading(true);
            const response = await deleteAlert(
              data.idQuestion,
              user.email,
              values.values.reason
            );

            if (response.status === 200) {
              setIsLoading(false);
              setMessage("Seguimiento eliminado");
              setShowConfirmMessage(true);
              updateData();
            } else {
               setIsLoading(false);
              setMessage("Seguimiento eliminado");
              setShowConfirmMessage(true);
            }
          }}
        >
          <ModalStep>
            <ModalContent
              close={onClose}
              text="Estás a punto de eliminar un seguimiento"
              helpText="¿Deseas continuar?"
              icon={personGreen}
              btns={false}
              alt="Icono editar seguimiento"
            />
          </ModalStep>
          <ModalStep validationSchema={validateSchema}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <h2 className={styles.updateTitle}>Observaciones</h2>
                <div className={styles.inputContainer}>
                  <p htmlFor="reason" className={styles.patientName}>
                    Indica el motivo por el cual eliminas el evento
                  </p>
                  <div className={styles.inputAndError}>
                    <Field
                      className={styles.loginInputs}
                      as="select"
                      id="reason"
                      name="reason"
                    >
                      <option value="" className={styles.documentOptions}>
                        Motivo de eliminación
                      </option>
                      {reasons?.map((option) => (
                        <option
                          value={option.id}
                          className={styles.documentOptions}
                        >
                          {option.value}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              </>
            )}
          </ModalStep>
        </ModalStepper>
      )}
    </>
  );
};
