import { useState } from "react";

import { FiltersContext } from "./FiltersContext";

export const FiltersProvider = ({ children }) => {
  const [startDateEventos, setStartDateEventos] = useState(null);
  const [endDateEventos, setEndDateEventos] = useState(null);
  const [genereEventos, setGenereEventos] = useState("");
  const [ageEventos, setAgeEventos] = useState("");

  const [startDateParaHoy, setStartDateParaHoy] = useState(null);
  const [endDateParaHoy, setEndDateParaHoy] = useState(null);
  const [genereParaHoy, setGenereParaHoy] = useState("");
  const [ageParaHoy, setAgeParaHoy] = useState("");
  const [idOptionsParaHoy, setIdOptionsParaHoy] = useState([]);

  const [generePacientes, setGenerePacientes] = useState("");
  const [agePacientes, setAgePacientes] = useState("");
  const [updatePacientes, setUpdatePacientes] = useState("");

  const [startDatePacientesContigo, setStartDatePacientesContigo] = useState(null);
  const [endDatePacientesContigo, setEndDatePacientesContigo] = useState(null);
  const [generePacientesContigo, setGenerePacientesContigo] = useState("");
  const [agePacientesContigo, setAgePacientesContigo] = useState("");
  const [updatePacientesContigo, setUpdatePacientesContigo] = useState("");
  const [alertPacientesContigo, setAlertPacientesContigo] = useState("");

  const [startDateReporteContigo, setStartDateReporteContigo] = useState(null);
  const [endDateReporteContigo, setEndDateReporteContigo] = useState(null);
  const [genereReporteContigo, setGenereReporteContigo] = useState("");
  const [ageReporteContigo, setAgeReporteContigo] = useState("");
  const [observationReporteContigo, setObservationReporteContigo] = useState([]);
  const [monitoringReporteContigo, setMonitoringReporteContigo] = useState([]);

  return (
    <FiltersContext.Provider
      value={{
        startDateEventos, setStartDateEventos,
        endDateEventos, setEndDateEventos,
        genereEventos, setGenereEventos,
        ageEventos, setAgeEventos,

        startDateParaHoy, setStartDateParaHoy,
        endDateParaHoy, setEndDateParaHoy,
        genereParaHoy, setGenereParaHoy,
        ageParaHoy, setAgeParaHoy,
        idOptionsParaHoy, setIdOptionsParaHoy,

        generePacientes, setGenerePacientes,
        agePacientes, setAgePacientes,
        updatePacientes, setUpdatePacientes,

        startDatePacientesContigo, setStartDatePacientesContigo,
        endDatePacientesContigo, setEndDatePacientesContigo,
        generePacientesContigo, setGenerePacientesContigo,
        agePacientesContigo, setAgePacientesContigo,
        updatePacientesContigo, setUpdatePacientesContigo,
        alertPacientesContigo, setAlertPacientesContigo,

        startDateReporteContigo, setStartDateReporteContigo,
        endDateReporteContigo, setEndDateReporteContigo,
        genereReporteContigo, setGenereReporteContigo,
        ageReporteContigo, setAgeReporteContigo,
        observationReporteContigo, setObservationReporteContigo,
        monitoringReporteContigo, setMonitoringReporteContigo
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};