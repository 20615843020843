import React from "react";

import checkIcon from "../../assets/imgs/icons/checkWhite.svg";

import styles from "./QuestionList.module.scss";

export const QuestionList = ({ values, scale }) => {
  let questionList = [];

  for (let q of scale?.schema) {
    if (q.question) {
      questionList.push(q.question);
    } else if (q.section) {
      for (let question of q.section.questions) {
        questionList.push(question);
      }
    }
  }
  const handleQuestionClick = (id) => {
    const questionElement = document.getElementById(id);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className={styles.questionGroup}>
      <spam
        className={`
            ${styles.normalQuestion}
            ${
              values.agree_medition === "true" ||
              values.agree_medition === true ||
              values.agree_medition === "false" ||
              values.agree_medition === false
                ? styles.completeQuestion
                : values.agree_medition === true
                ? styles.requiredQuestion
                : null
            }`}
        onClick={() => handleQuestionClick("agree_medition")}
      >
        {values.agree_medition === "true" ||
        values.agree_medition === true ||
        values.agree_medition === "false" ||
        values.agree_medition === false ? (
          <img src={checkIcon} alt="check icon" />
        ) : null}
      </spam>

      <spam
        className={`
            ${styles.normalQuestion}
            ${
              values.loss_information
                ? styles.completeQuestion
                : values.agree_medition === "true"
                ? styles.requiredQuestion
                : null
            }`}
        onClick={() => handleQuestionClick("loss_information")}
      >
        {values.loss_information ? (
          <img src={checkIcon} alt="check icon" />
        ) : null}
      </spam>

      <spam
        className={`
            ${styles.normalQuestion}
            ${
              values.informant
                ? styles.completeQuestion
                : values.agree_medition === "true"
                ? styles.requiredQuestion
                : null
            }`}
        onClick={() => handleQuestionClick("informant")}
      >
        {values.informant ? <img src={checkIcon} alt="check icon" /> : null}
      </spam>

      <spam
        className={`
            ${styles.normalQuestion}
            ${
              values.interview_type
                ? styles.completeQuestion
                : values.agree_medition === "true"
                ? styles.requiredQuestion
                : null
            }`}
        onClick={() => handleQuestionClick("interview_type")}
      >
        {values.interview_type ? (
          <img src={checkIcon} alt="check icon" />
        ) : null}
      </spam>

      <spam
        className={`
            ${styles.normalQuestion}
            ${
              values.answer_by.length
                ? styles.completeQuestion
                : values.agree_medition === "true"
                ? styles.requiredQuestion
                : null
            }`}
        onClick={() => handleQuestionClick("answer_by")}
      >
        {values.answer_by ? <img src={checkIcon} alt="check icon" /> : null}
      </spam>
      {console.log("Aca estamos",questionList)}
      {console.log("Estos son los values",values)}
      {questionList.map((pregunta, index) => (
        
        <spam
          key={index}
          className={`
            ${styles.normalQuestion}
            ${typeof values[pregunta.id]!='undefined' || typeof values[pregunta.id] === 'number'
                ? styles.completeQuestion
                : !values[pregunta.id] &&
                  pregunta.is_required &&
                  values.agree_medition === "true"
                ? styles.requiredQuestion
                : null
            }`}
          onClick={() => handleQuestionClick(pregunta.id)}
        >
          {values[pregunta.id] ? (
            <img src={checkIcon} alt="check icon" />
          ) : null}
        </spam>
      ))}
    </div>
  );
};
