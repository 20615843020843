import { useState, useEffect, useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import { getTypeParameter } from "../../services/common/admin";
import { searchPatient } from "../../services/Contigo/patient";

import SelectOption from "../../atoms/SelectOption";
import TextInput from "../../atoms/TextInput";

import searchIcon from "../../assets/imgs/icons/search.svg";

import styles from "./SearchPatient.module.scss";

export const SearchPatientForm = () => {
  const {
    setUserInfo,
    setLoading,
    setIsError,
    setUserExists,
    setPayloadSearch,
    setShowInfo,
  } = useContext(ContigoContext);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const getListDocument = async () => {
      const response = await getTypeParameter(1);
      setDocuments(
        response.data.map((type) => ({ value: type.id, label: type.value }))
      );
    };
    getListDocument();
  }, []);

  return (
    <Formik
      initialValues={{ "": "" }}
      validationSchema={Yup.object({
        documentNumber: Yup.string().required(
          "Debe ingresar el número de documento"
        ),
        documentType: Yup.string()
          .min(1, "Debes seleccionar un tipo de documento")
          .required("Debes seleccionar un tipo de documento"),
      })}
      onSubmit={async (values) => {
        setUserInfo({});
        setIsError(false);
        setUserExists(false);
        setLoading(true);
        const payload = {
          doc: values.documentNumber,
          tdoc: values.documentType,
        };
        setPayloadSearch(payload);
        const response = await searchPatient(payload);
        if (response.response.length > 0) {
          setUserInfo(response.response[0]);
          setShowInfo(true);
        } else {
          setIsError(true);
        }
        setLoading(false);
      }}
    >
      {() => (
        <Form className={styles.form}>
          <div className={styles.selectOption}>
            <SelectOption label="Tipo" name="documentType">
              <option value="" disabled selected>
                Seleccionar
              </option>
              {documents.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </SelectOption>
          </div>
          <div className={styles.input}>
            <TextInput
              placeholder="Digita tu número de documento"
              label="Número de documento"
              name="documentNumber"
              type="text"
              className={styles.loginLabel}
            />
          </div>
          <button type="submit" className={styles.buttonSearch}>
            Buscar
            <img src={searchIcon} alt="Icono de búsqueda" />
          </button>
        </Form>
      )}
    </Formik>
  );
};
