import React, { useState } from "react";
import { ClickAwayListener } from "@mui/material";

import arrowDown from "../../assets/imgs/icons/arrowDownSort.svg";
import checked from "../../assets/imgs/icons/greenCheck.svg";

import styles from "./Select.module.scss";

export const Select = ({ icon, placeholder, content, options, multiple = false, onSelect, ...props }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const onClickOption = (val) => {
    !multiple && setOptionsOpen(false);
    onSelect(val);
  };

  return (
    <ClickAwayListener onClickAway={() => setOptionsOpen(false)}>
      <div style={{ position: "relative" }}>
        <div
          style={{ width: props.width }}
          className={styles.selectContainer}
          onClick={() => setOptionsOpen(prev => !prev)}
        >
          {icon && <img src={icon} className={styles.icon} alt="Icon Image" />}
          <span className={styles.placeholder}>
            {placeholder}:
            <span className={styles.content}> {content}</span>
          </span>
          <img src={arrowDown} className={styles.arrowDown} alt="Arrow Down" />
        </div>
        <section
          style={{ width: props.width, display: optionsOpen ? "block" : "none" }}
          className={styles.optionsContainer}
        >
          {options.map(opt => (
            <div
              key={opt.label}
              className={styles.option}
              onClick={() => onClickOption(opt.label)}
            >
              {opt.label}
              {content.includes(opt.label) && <img src={checked} alt="Checked Icon" />}
            </div>
          ))}
        </section>
      </div >
    </ClickAwayListener>
  );
};
