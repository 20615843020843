import React from "react";

import whiteLogo from "../../assets/imgs/fsfb_white.png"
import Logo from "../../assets/imgs/logoFSFB.svg";
import styles from "./LogoHeader.module.scss";

export const LogoHeader = ({variant}) => {
  return (
    <div className={styles.logoContainer}>
      <img src={variant==="light"? whiteLogo : Logo} alt="Logo Fundación SantaFe" className={styles.logo} />
      <h2
        className={`${
          variant === "light" ? styles.headerLight : styles.headerPrincipal
        } ${styles.headerTitle}`}
      >
        Tu recuperación
      </h2>
    </div>
  );
};
