import React, { useMemo, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import * as moment from "moment-timezone";

import useSelect from "../../hooks/useSelect";
import {useSort} from '../../hooks/useSort';
import { Switch } from "../../atoms/SwitchFSFB";
import { Pagination } from "../../atoms/Pagination";
import TableHeader from "../../atoms/TableHeader";
import Modal from "../../atoms/Modal";
import { ConfirmContent } from "../../atoms/ConfirmContent";
import Spinner from "../../atoms/Spinner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Table from "../../molecules/Table";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";

import { offsetUser } from "../../custom/offset";
import { updateStatusScale } from "../../services/scalesAdmin";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { AppContext } from "../../context/AppContext/AppContext";
import confirmationIcon from "../../assets/imgs/icons/infoGreenIcon.svg";

import styles from "./ScaleAdminTable.module.scss";

export default function ScaleAdminTable({ newUserRender, setNewUserRender }) {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const {
    setIsEditScale,
    scalesList,
    setScalesList,
    line_scale,
    setLine_scale,
    scale_scale,
    setScale_scale,
    module_scale, 
    setModule_scale,
    sort,
    setSort,
    actualPage,
    setActualPage,
    pagination,
    setCustomePagination,
    setOptionsSelect,
    setSelectGroup,
  } = useContext(ScalesContext);
  const { height, width } = useWindowDimensions();

  const [childDataState, setChildDataState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoadConfirmation, setIsLoadConfirmation] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const propertyNameMap = {
    active: "active",
    id: "id",
    name: "name",
  };

  const selectOptions = useSelect(childDataState, propertyNameMap);

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  function childData(e) {
    setChildDataState(e);
  }

  const getListScales = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort,
      scale: scale_scale,
      service_line: line_scale,
      modules: module_scale,
    }
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );

    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/forms?${route}`
    })

    setScalesList(response.data);
    setCustomePagination({
      page: response.pagination.page,
      paginationTotal: response.pagination.last_page,
    });
    setNewUserRender(false);
    setIsLoading(false);
  };

  const handleEditStatus = async (form) => {
   setLoading(true);
    setOpen(true);
    const status = !form.active;
    const payload = {
      scale_ids: [form.id],
    };
    form.active = !form.active;
    const data = await updateStatusScale(payload, status,user.email);

    if (data.status === 200) {
      setLoading(false);
      setIsLoadConfirmation(true);
    } else {
      setLoading(false);
      setIsError(true);
      setIsLoadConfirmation(true);
    }
  };

  const handleRedirect = async (id) => {
    navigate(`/formularios/${id}`);
  };

  const handleOpenEditScale = async (scale) => {
    setIsEditScale(true);
    await handleRedirect(scale.id);
  };

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Estado" column={column} />;
      },
      accessor: "switch",
      Cell: ({ row }) => (
        <div className={styles.switchContainer}>
          {row.original.active}
          <Switch
            toggled={row.original.active}
            onChange={() => {
              handleEditStatus(row.original);
            }}
            name={row.original.id}
          />
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setScale_scale}
            filterValue={scale_scale}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleOpenEditScale(row.original)}
          >
            {row.original.name}
          </p>
        );
      },
      accessor: "name",
      width: 35,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Líneas"
            column={column}
            handleGetFilter={setLine_scale}
            filterValue={line_scale}
          />
        );
      },
      Cell: ({ row }) => (
        <>
          {row.original.service_lines.length &&
            row.original?.service_lines
              ?.map((lines) => lines.name)
              .toString()
              .replaceAll(",", ", ")}
        </>
      ),
      accessor: "service_lines",
      width: 20,
      disableSortBy: true,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Módulo"
            column={column}
            handleGetFilter={setModule_scale}
            filterValue={module_scale}
          />
        );
      },
      Cell: ({ row }) => (
        <>
          {row.original.modules.length &&
            row.original?.modules
              ?.map((module) => module.label)
              .toString()
              .replaceAll(",", ", ")}
        </>
      ),
      accessor: "modules",
      width: 15,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha modificado" column={column} />;
      },
      Cell: ({ row }) => (
        <p>
          {moment
            .tz(row.values.updated_at, "America/Bogota")
            .format("YYYY-MM-DD hh:mm")}
        </p>
      ),
      accessor: "updated_at",
      width: 20,
      textAlign: "right",
      disableFilters: true,
      tooltipType: "date"
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [line_scale, scale_scale, module_scale]);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    getListScales();
  }, [actualPage, sort, control, newUserRender, isLoadConfirmation]);

    // custom hook sort
    useSort(sort, setSort)

  useEffect(() => {
    if (line_scale.length || scale_scale.length || module_scale.length > 0) {
      setActualPage(1);
    }
  }, [line_scale, scale_scale, module_scale]);

  useEffect(() => {
    setSelectGroup(selectOptions);
  }, [selectOptions]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={scalesList}
        offsetUser={offsetUser}
        tooltipText="Gestiona los formularios."
        childData={childData}
        setValues={setOptionsSelect}
      />
      <Pagination
        actualPage={actualPage}
        setPage={setActualPage}
        infoPage={pagination}
        className={styles.pagination}
      />

      <Modal isOpen={open} onClose={() => setOpen(false)} variant="large">
        {loading ? (
          <Spinner className_container="rolesForm" />
        ) : (
          isLoadConfirmation && (
            <ConfirmContent
              text={isError ? "No se pudo completar\nla acción, inténtalo de nuevo" : "Formulario actualizado"}
              icon={confirmationIcon}
              alt="Icono creación o edición de formulario exitoso"
            />
          )
        )}
      </Modal>
    </>
  );
}
