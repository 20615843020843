import { useContext, useEffect, useState } from "react";
import * as moment from "moment-timezone";


import withAuth  from '../../HOC/withAuth';
import ButtonOption from "../../atoms/ButtonOption";
import ToolboxTable from "../../atoms/ToolboxTable";
import TracingTable from "../../atoms/TracingTable";
import Spinner from "../../atoms/Spinner";
import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import DeleteEvents from "../../molecules/DeleteEvents";
import Filters from "../Filters";
import ModuleTemplate from "../../templates/ModuleTemplate";
import { offsetUser } from "../../custom/offset";
import { AppContext } from "../../context/AppContext/AppContext";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import { TracingContext } from "../../context/TracingContext/TracingContext";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import { useSort } from "../../hooks/useSort";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import alertGreen from "../../assets/imgs/icons/alertGreen.svg";
import SendIcon from "../../assets/imgs/icons/sendIcon.svg";
import errorUpdate from "../../assets/imgs/icons/errorUpdate.svg";
import styles from "./Tracing.module.scss";


function randomizer() {
  return Math.floor(Math.random() * 100000);
}


function Tracing() {
  const {
    startDateEventos, setStartDateEventos,
    endDateEventos, setEndDateEventos,
    genereEventos, setGenereEventos,
    ageEventos, setAgeEventos
  } = useContext(FiltersContext);

  const {
    selectTracingGroup,
    isLoading,
    setIsLoading,
    setSelectTracingGroup,
    actualPage,
    setActualPage,
    setPatientsList,
    name_tracing,
    document_tracing,
    setCustomePagination,
    sort_tracing,
    setSort_tracing,
    scale_tracing,
  } = useContext(TracingContext);

  const { user } = useContext(AppContext);
  const { height, width } = useWindowDimensions();
  const [openDeleteEvents, setOpenDeleteEvents] = useState(false);
  const [isLoadingAlert, setIsLoadingAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSucess, setIsSucess] = useState(false);
  const [openStart, setOpenStart] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);

  // const [openCreate, setOpenCreate] = useState(false);
  useSort(sort_tracing, setSort_tracing);

  const getTracingList = async () => {
    setIsLoading(true);
    let params = {
      doc_number: document_tracing,
      service_line: scale_tracing,
      name: name_tracing,
      sort: sort_tracing,
      offset: offsetUser(height, width),
      page: actualPage,
      end_date: endDateEventos
        ? moment.tz(endDateEventos, "America/Bogota").format("YYYY-MM-DD")
        : "",
      start_date: startDateEventos
        ? moment.tz(startDateEventos, "America/Bogota").format("YYYY-MM-DD")
        : "",
      genre: genereEventos,
      legal_age: ageEventos,
    };

    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const response = await await apiInterceptor({
      method: "GET",
      endpoint: `/tr/desenlaces/events?${route}`,
    });
    setPatientsList(response.data);
    setCustomePagination({
      page: response.pagination.page,
      paginationTotal: response.pagination.last_page,
    });
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setOpenStart(false);
    setIsSucess(false);
    setIsError(false);
    setSelectTracingGroup([]);
  };

  const handleConfirm = async () => {
    // return
    setIsLoadingAlert(true);
    const data = await selectTracingGroup.map((datos) => datos?.id);
    const response = await apiInterceptor({
      method: 'POST',
      endpoint: '/tr/desenlaces/start',
      data
     })

    if (response.statusCode === 200) {
      await getTracingList();
      setIsLoadingAlert(false);
      setIsSucess(true);
    } else {
      setIsLoadingAlert(false);
      setIsError(true);
      getTracingList();
    }
  };

  // Filters
  const handleApplyFilters = () => {
    if (isLoading) return;
    getTracingList();
  };

  useEffect(() => {
    if (selectTracingGroup?.length !== 0) {
      setSelectedAmount(selectTracingGroup?.length);
    }
  }, [selectTracingGroup]);


  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    getTracingList();
  }, [actualPage, sort_tracing, control, genereEventos, ageEventos, startDateEventos, endDateEventos]);


  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [name_tracing, document_tracing, scale_tracing]);

  useEffect(() => {
    if (
      name_tracing.length ||
      document_tracing.length ||
      scale_tracing.length > 0
    ) {
      setActualPage(1);
    }
  }, [name_tracing, document_tracing, scale_tracing]);

  return (
    <>
      <ModuleTemplate>
        {selectTracingGroup?.length ? (
          <ToolboxTable name="Tollbox">
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={() => setOpenDeleteEvents(true)}
            />
            <ButtonOption
              icon={SendIcon}
              label="Iniciar"
              onClick={() => setOpenStart(true)}
            />
          </ToolboxTable>
        ) : null}
        <Filters
          name="filters"
          module="eventos"
          startDate={startDateEventos}
          setStartDate={setStartDateEventos}
          endDate={endDateEventos}
          setEndDate={setEndDateEventos}
          genere={genereEventos}
          setGenere={setGenereEventos}
          ageFilter={ageEventos}
          setAgeFilter={setAgeEventos}
        />
        <TracingTable
           name="Table"
           apply={handleApplyFilters}
        />
      </ModuleTemplate>

      <Modal
        isOpen={openDeleteEvents}
        onClose={() => setOpenDeleteEvents(false)}
        variant="large"
      >
        <DeleteEvents
          data={selectTracingGroup?.length && selectTracingGroup}
          onClose={() => setOpenDeleteEvents(false)}
          updateInfo={getTracingList}
          valuesDelete={selectTracingGroup?.length}
          selectOptions={selectTracingGroup}
          user={user}
        />
      </Modal>

      <Modal
        isOpen={openStart}
        onClose={() => handleCloseModal()}
        variant="large"
      >
        {isLoadingAlert ? (
          <Spinner className_container="rolesForm" />
        ) : isSucess ? (
          <ModalContent
            close={() => handleCloseModal()}
            btns={false}
            text={
              selectedAmount === 1
                ? "Evento iniciado"
                : `Has iniciado ${selectedAmount} eventos`
            }
            icon={alertGreen}
            alt="Icono inicio de proceso exitoso"
            updateTitleCustom={styles.updateTitleCustom}
          />
        ) : isError ? (
          <ModalContent
            close={() => handleCloseModal()}
            btns={false}
            text={"¡Ups!, hay un inconveniente\npor favor intenta de nuevo"}
            icon={errorUpdate}
            alt="Icono inicio de proceso fallido"
            btnsContainerCustom={styles.btnsContainerCustom}
          />
        ) : (
          <ModalContent
            close={() => handleCloseModal()}
            confirm={handleConfirm}
            text={"Atención"}
            helpText={`Estás a punto de dar inicio al proceso de ${
              selectTracingGroup?.length
            } ${selectTracingGroup?.length > 1 ? "pacientes" : "paciente"}`}
            icon={alertGreen}
            alt="Icono inicio de proceso"
            updateTitleCustom={styles.updateTitleCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            helpTextCustom={styles.helpTextCustom}
            textCancelCustom="Cancelar"
            textConfirmCustom="Aceptar"
          />
        )}
      </Modal>

      {/* <Modal
        isOpen={openCreate}
        onClose={() => setOpenCreate(false)}
        variant="large"
      >
        <CreateEventForm apply={handleApplyFilters}  onClose={() => setOpenCreate(false)}/>
      </Modal> */}
    </>
  );
}

export default withAuth(Tracing, '/desenlaces/eventos')