import logo from "../../assets/imgs/logoFsfb.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./LoginDescription.module.scss";

export default function LoginDescription(props) {
  const img = props.img;
  const { width } = useWindowDimensions();

  return (
    <>
      <div
        className={
          img === "patient"
            ? styles.patientContainer
            : img === "manager"
            ? styles.managerContainer
            : img === "otp"
            ? styles.otpContainer
            : img === "admin"
            ? styles.adminContainer
            : img === "onboarding"
            ? width <= 480
              ? styles.onboardingRespContainer
              : styles.onboardingContainer
            : null
        }
      >
        <img src={logo} alt="Logo FSFB" className={styles.logoFsfb}></img>
        <div
          className={img === "onboarding" ? styles.onboardingText : styles.text}
        >
          <p className={styles.text1}>{props.text1}</p>
          <p className={styles.text2}>{props.text2}</p>
          <p className={styles.text3}>{props.text3}</p>
          <p className={styles.text4}>{props.text4}</p>
        </div>
      </div>
    </>
  );
}
