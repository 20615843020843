import React from "react";
import { useField } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";


import ErrorMessage from "../ErrorMessage";
import styles from "./Likert.module.scss";

const LikertFSFB = ({ options, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <>
      <FormControl>
        <RadioGroup
          aria-labelledby="radio-buttons-likert"
          name={props.name}
          className={`${styles.optionsContainer}`}
          {...field}
          {...props}
          sx={{}}
        >
          {options?.map((option) => (
            <FormControlLabel
              value={option.id}
              label={option.detail}
              key={option.id}
              control={
                <Radio
                  style={{
                    color: "#009DAC",
                    padding: "1.12vh",
                  }}
                />
              }
              className={`${styles.radioContainer} `}
              labelPlacement="start"
            />
          ))}
        </RadioGroup>
      </FormControl>
      <ErrorMessage error={meta.error} meta={meta} />
    </>
  );
};

export default LikertFSFB;
