import axios from "axios";
import { apiInterceptor } from "./interceptors/jwt.interceptor";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const updateData = async(data) => {
  const response = await apiInterceptor({
    method: 'PUT',
    endpoint:'/tr/patients/update',
    data
  })

  return response;

}


export const deleteTracing = async (data) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/tracing/batchDelete`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getPlanStatus = async (plan) => {
  return (
    await axios.get(`${API_URL}/recovery/plans/status`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
      params: {
        plan,
      },
    })
  )?.data?.data;
};

export const getStats = async (user) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/tracing/stats?user=${user}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

