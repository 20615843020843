import ErrorTemplate from "../../molecules/ErrorTemplate";


export default function Error404() {
  return (
    <ErrorTemplate
      titleLabel="¡Ups! Error 404"
      subtitleLabel="Página no encontrada"
      text={[
        "No encontramos la página que solicitaste.",
        "¡Busquemos un mejor lugar para ti!"
      ]}
    />
  );
}
