import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const getScales = async (route) => {
  try {
    const response = await axios.get(
      `${API_URL}/recovery/admin/scale/getAll?${route}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getScalesById = async (id) => {
  try {
    const response = await axios.get(
      `${API_URL}/recovery/admin/scale/getById/${id}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateScale = async (data, id) => {
  try {
    const response = await axios.put(
      `${API_URL}/recovery/admin/scale/updateById/${id}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateStatusScale = async (data, status, email) => {
  try {
    const response = await axios.put(
      `${API_URL}/recovery/admin/scale/activate?activate=${status}&email=${email}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};



export const deleteScale = async (data) => {
  try {
    const response = await axios.delete(
      `${API_URL}/recovery/admin/scale/deleteById`,
      {
        data,

        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const createScale = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/recovery/admin/scale`, data, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
