import { useState, useContext, useRef } from "react";
import ManagerName from "../../atoms/ManagerName";
import { AppContext } from "../../context/AppContext/AppContext";
import arrowIcon from "../../assets/imgs/icons/ArrowRight.svg";
import styles from "./ManagerData.module.scss";
import Logout from "../../atoms/Logout";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export default function ManagerData() {
  const { user } = useContext(AppContext);
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);


  const handleClickOutside = () => setIsOpen(false);
  useOnClickOutside(modalRef, handleClickOutside);
  return (
    <div className={styles.header}>
      {Object.keys(user).length ? (
        <>
          <div
            className={styles.userContainer}
            onClick={() => setIsOpen(!isOpen)}
          >
            <ManagerName name={user.name} />
            <img
              className={styles.iconArrow}
              src={arrowIcon}
              alt="icono para desplegar modal perfil"
            />
          </div>
          {isOpen && (
            <div className={styles.modalUser} ref={modalRef}>
              <section className={styles.userOption}>
                <ManagerName name={user.name} />
                <div className={styles.userData}>
                  <p className={styles.userName}>{user.name}</p>
                  <p className={styles.userRol}>{user.rol}</p>
                </div>
              </section>

              <section className={styles.logoutButton}>
                <Logout />
              </section>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
}
