import { useFormikContext } from "formik";

import FrequencyInput from "../../organisms/FrequencyInput";
import { LineSelect } from "../LineSelect";

export const LineFrecuenctySelect = () => {
  const { values } = useFormikContext();

  return (
    <>
      <LineSelect name="service_lines" />
      {values?.service_lines?.length ? (
        <FrequencyInput name="service_lines" />
      ) : null}
    </>
  );
};
