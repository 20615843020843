import kuid from "kuid";
import { useEffect, useState } from "react";
import { logout } from "../../services/auth/auth.service";
import { getItem } from "../../services/localStorage";
import { getUser, whoamiService } from "../../services/whoiam";
import { AppContext } from "./AppContext";

export const AppProvider = ({ children }) => {
  const [step, setStep] = useState(0);
  const [otpChallenge, setOtpChallenge] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [interv, setInterv] = useState(null);
  const [sessionActive, setSessionActive] = useState(false);
  const [keys, setKeys] = useState(null);
  const [user, setUser] = useState({});
  const [modules, setModules] = useState([]);
  const [selectPoints, setSelectPoints] = useState([]);
  const [userPlan, setUserPlan] = useState(getItem("plan") || "");
  const [patientDocument, setPatientDocument] = useState(
    getItem("document") || ""
  );
  const [destination, setDestination] = useState();
  const [plan, setPlan] = useState({});
  const [contEq5d, setContEq5d] = useState(0);
  const [contEq5dTherm, setContEq5dTherm] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [idPatient, setIdPatient] = useState("");
  const [sort, setSort] = useState("");
  const [moduleSelect, setModulesSelect] = useState("");
  const [reasonEdit, setReasonEdit ] = useState();
  const [trackIdSelected, setTrackIdSelected] = useState(null);
  const options = [
    {id:"4", value: "CC", label: "Cédula de ciudadanía", littleTitle: "C.C." },
    { id:"5",value: "CE", label: "Cédula de Extranjería", littleTitle: "C.E." },
    {id:"P", value: "PA", label: "Pasaporte", littleTitle: "Pasaporte" },
    {id:"D", value: "CD", label: "Carné de Diplomático", littleTitle: "C.D." },
    {id:"S", value: "SC", label: "Salvoconducto", littleTitle: "Salvocond." },
    {id:"6", value: "NI", label: "NIT", littleTitle: "NIT" },
    {id:"2", value: "RC", label: "Registro Civil", littleTitle: "R.C." },
    {id:"N", value: "NU", label: "NUIP", littleTitle: "NUIP" },
    {id:"3", value: "TI", label: "Tarjeta de Identidad", littleTitle: "T.I." },
    {id:"M", value: "MS", label: "Menor sin ID", littleTitle: "Men. no ID" },
    {id:"A", value: "AS", label: "Adulto sin ID", littleTitle: "Adul. no ID" },
  ];
  async function reloaderWhoiam(e) {
    if (loaded) {
      try {
        if (e.detail) {
          let whoiam = await whoamiService();
          if (whoiam.statusCode === 200 && whoiam.infoUser) {
            setUser(whoiam.infoUser);
            return;
          }
        }
        throw new Error("Invalid whoiam info");
      } catch (error) {
        console.error("Error when update token but not have whoiam.", error);
        try {
          setKeys(null);
          await logout();
        } catch (error) {}
        window.location.reload();
      }
    }
  }

  function pathEvaluator(origin, current) {
    if (typeof origin !== "string" || typeof current !== "string") return false;
    const validation = origin.includes(current)
    return validation
  }

 function modulePropsAdditions(mod, locationPath, level = 0) {
    let isSelected = pathEvaluator(locationPath, mod.path);
    if (!mod.kuid) mod.kuid = kuid();
    mod.level = level;
    if (mod.submodules?.length)
      mod.submodules = mod.submodules.map((nestedMod) => {
        const results = modulePropsAdditions(
          nestedMod,
          locationPath,
          level + 1
        );
        isSelected = isSelected || results.isSelected;
        return results.mod;
      });
    mod.isSelected = isSelected;
    return { mod, isSelected };
  }

  function anidatedExpandedModuleEvaluation(id, modules) {
    let hasChanges = false;
    for (let i = 0; i < modules.length; i++) {
      const mod = modules[i];
      if (mod.kuid === id) {
        mod.isExpanded = !mod.isExpanded;
        hasChanges = true;
      }
      let results;
      if (mod.submodules?.length) {
        results = anidatedExpandedModuleEvaluation(id, mod.submodules);
        hasChanges = hasChanges || results.hasChanges;
        mod.submodules = results.modules;
      }
    }
    return { hasChanges, modules };
  }

  function toggleExpandableMenu(id) {
    const { hasChanges, modules: newmodules } =
      anidatedExpandedModuleEvaluation(id, JSON.parse(JSON.stringify(modules)));
    if (hasChanges) setModules(newmodules);
  }

   
   const modulesMixer =async (moduleList, locationPathname) => {
    setModules( moduleList.map((mod) => modulePropsAdditions(mod, locationPathname).mod) );
  }

  function sessionTimer() {
    if (Number(process.env?.REACT_APP_SESSION_DURATION_HOURS) && !interv) {
      const newInterv = setInterval(() => {
        const sessionTimeValid =
          keys?.session_date &&
          keys.session_date +
            Number(process.env?.REACT_APP_SESSION_DURATION_HOURS) *
              60 *
              60 *
              1000 -
            Date.now() >
            0;
        setSessionActive(sessionTimeValid);
      }, 1000);
      setInterv(newInterv);
    }
  }

  useEffect(() => {
    sessionTimer();
    return () => {
      if (interv) {
        setInterv(null);
        clearInterval(interv);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interv, setInterv, keys]);

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    const user = getUser();
    if (user) setUser(user); // For load in ram
    window.addEventListener("tokenRefreshed", reloaderWhoiam);
    return () => window.removeEventListener("tokenRefreshed", reloaderWhoiam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, setUser]);

  const getModulesOptions = () => {
    setTimeout(() => {
      modulesMixer(user?.modules || [], window.location.pathname)
   },100)
  }

  useEffect(() =>{
    getModulesOptions()
  },[user]
  );

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        keys,
        setKeys,
        patientDocument,
        setPatientDocument,
        step,
        setStep,
        selectPoints,
        setSelectPoints,
        otpChallenge,
        setOtpChallenge,
        userPlan,
        setUserPlan,
        plan,
        setPlan,
        contEq5d,
        setContEq5d,
        contEq5dTherm,
        setContEq5dTherm,
        sessionActive,
        modules,
        toggleExpandableMenu,
        modulesMixer,
        idPatient,
        setIdPatient,
        sort,
        setSort,
        moduleSelect, setModulesSelect,
        reasonEdit, setReasonEdit,
        trackIdSelected, setTrackIdSelected,
        userInfo, setUserInfo,
        options,
        destination, setDestination
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
