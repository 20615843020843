import React, {useContext} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "../../atoms/Button";
import woman from "../../assets/imgs/welcomeWoman.png";
import { useUserModules } from "../../molecules/MenuLinks/_.hooks";
import { AppContext } from "../../context/AppContext/AppContext";
import styles from "./WelcomeMessage.module.scss";

export const WelcomeMessage = () => {
  const {  user } = useContext(AppContext);
  const { modules } = useUserModules(user);

  const location = useLocation();
  const navigate = useNavigate();

  // const route = modules[0]?.path
  // ? modules[0]?.path
  // : modules[0]?.submodules[0]?.path
  // ? modules[0]?.submodules[0]?.path
  // : "/scale";
  const route = modules[0]?.path
  ? '/dashboard'
  : modules[0]?.submodules[0]?.path
  ? '/dashboard'
  : "/scale";


  const handleNext = () => {
    if (location.pathname === "/welcomeAdmin") {
      navigate(route);
    } 
  };

  return (
    <div className={styles.container}>
      <section className={styles.welcomeText}>
        <h1 className={styles.title}>Nos alegra tenerte por aquí</h1>
        <p className={styles.messageText}>
          Detrás de <span className={styles.lightBlue}>las sonrisas</span> de
          nuestros pacientes está el
          <span className={styles.boldText}> trabajo que haces</span> todos los
          días.
        </p>

        <div className={styles.buttonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={false}
            onClick={handleNext}
          >
            No volver a mostrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={false}
            onClick={ handleNext}
          >
            Continuar
          </Button>
        </div>
      </section>
      <img src={woman} alt="Welcome" className={styles.woman} />
    </div>
  );
};
