import React, { useEffect, useState, useRef } from "react";
import { useField } from "formik";
import { Stage, Layer, Line } from "react-konva";
import CreateIcon from "@mui/icons-material/Create";

import ErrorMessage from "../ErrorMessage";
import EraserIcon from "../../assets/imgs/eraser_icon.png";
import posasBg from "../../assets/imgs/posas.png";
import style from "./Canva.module.scss";


const Canvas = ({ answersLines, ...props }) => {
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);
  const [penEnabled, setPenEnabled] = useState(true);

  const handleMouseDown = (e) => {
    if (!penEnabled) return;
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => { 
    setValue(lines);
  }, [lines]);


  useEffect(() => {
    if (answersLines !== undefined) {
      setLines(answersLines)
    }
    if (props.disabled) {
      setPenEnabled(false);
    }
  },[])


  return (
    <div className={style.canvaContainer}>
      <h2 className={style.imgLabel}>Traza sobre la imagen las áreas afectadas</h2>
      <div className={style.canvaButtons}>
        <button
          type="button"
          onClick={() => setTool("pen")}
        >
          <CreateIcon />
        </button>
        <button
          type="button"
          onClick={() => setTool("eraser")}
        >
          <img src={EraserIcon} width="22" height="22" alt="icono de borrador"/>
        </button>
      </div>

      <Stage
        width={475}
        height={320}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMousemove={handleMouseMove}
        onTouchMove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchEnd={handleMouseUp}
        style={{
          backgroundImage: `url(${posasBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          mouseZoomFactor: 1,
        }}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={
                line.points
              }
              stroke="rgb(225, 110, 95)"
              strokeWidth={line.tool === "eraser" ? 10 : 3}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          ))}
        </Layer>
      </Stage>

      <ErrorMessage error={meta.error} meta={meta} />
    </div>
  );
};

export default Canvas;
