import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";

import { ModulesSelect } from "../ModulesSelect";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { getLineByModules } from "../../services/servicesLine";

export const ModulesCreateScale = () => {
  const { setIsDesenlacesSelect, setIsContigoSelect, editScale } =
    useContext(ScalesContext);
  const { setServiceLine } = useContext(AdminContext);
  const { values, setFieldValue } = useFormikContext();

  const getListLines = async (ids) => {
    const response = await getLineByModules(ids);
    const list = response.response;
    const newList = [];
    for (let opt of list) {
      newList.push(opt.serviceLines);
    }
    const options = newList.flat(1);
    const lineOptions = options.map((option) => ({
      key: option.id,
      value: option.name,
    }));
    const linesSelect = values.service_lines;
    const newLines = [];

    for (let line of linesSelect) {
      const optLine = lineOptions.find((item) => item.key === line.line.key);
      if (optLine) {
        newLines.push(line);
      } else {
        if (editScale.id) {
          newLines.push({ ...line, to_delete: true, frequency_days: [0] });
        }
      }
    }
    setServiceLine(lineOptions);
    setFieldValue("service_lines", newLines);
  };

  useEffect(() => {
    const selectedModules = values.modules;
    if (selectedModules.length) {
      const newValues = [];
      for (let module of selectedModules) {
        newValues.push(module.key);
      }
      getListLines(newValues.toString());
    } else {
      setServiceLine([]);
    }
  }, [values.modules]);

  useEffect(() => {
    if (values.modules.find((item) => item.key === 100) !== undefined) {
      setIsDesenlacesSelect(true);
      setFieldValue("isDesenlacesSelect", true);
    } else {
      setIsDesenlacesSelect(false);
      setFieldValue("isDesenlacesSelect", false);
    }

    if (values.modules.find((item) => item.key === 101) !== undefined) {
      setIsContigoSelect(true);
      setFieldValue("isContigoSelect", true);
    } else {
      setIsContigoSelect(false);
      setFieldValue("isContigoSelect", false);
    }
  }, [values.modules]);

  const defaultValuesModules = values.modules ? values.modules : [];

  return (
    <ModulesSelect
      name="modules"
      defaultValue={defaultValuesModules}
      disabled={editScale?.active}
    />
  );
};
