import React, { useState, useContext } from "react";
import * as Yup from "yup";

import { AdminContext } from "../../context/AdminContext/AdminContext";
import { TracingContext } from "../../context/TracingContext/TracingContext";

import { updateEvent } from "../../services/events";

import Spinner from "../../atoms/Spinner";
import { ModalStepper } from "../ModalStepper"
import { ModalStep } from "../../atoms/ModalStep";
import { ResponseResult } from "../../atoms/ResponseResult";
import { ChangeServiceLineForm } from "../../atoms/ChangeServiceLineForm";
import { ChangeServiceLineWarning } from "../../atoms/ChangeServiceLineWarning";

export const ChangeServiceLineStepper = ({ onClose }) => {
  const { userInfo } = useContext(AdminContext);
  const { patientsList, setPatientsList } = useContext(TracingContext);

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");

  const updateServiceLineEvent = async (id, name, parentId) => {
    setLoading(true);
    try {
      const body = { id_service_line: id };
      const response = await updateEvent(userInfo.id, body);
      if (!response.data.length) setResult("error");
      else {
        setResult("success");
        const indexOfUserToUpdate = patientsList.findIndex(patientEvent => patientEvent.id === userInfo.id);
        const newPatients = [...patientsList];
        newPatients[indexOfUserToUpdate] = {
          ...patientsList[indexOfUserToUpdate],
          service_line: {
            id: Number(id),
            name,
            parent_id: Number(parentId) || null,
            changed: true,
          }
        };
        setPatientsList([...newPatients]);
        newPatients[indexOfUserToUpdate] = {
          ...newPatients[indexOfUserToUpdate],
          service_line: {
            ...newPatients[indexOfUserToUpdate].service_line,
            changed: false,
          }
        };
        setTimeout(() => setPatientsList([...newPatients]), 1800);
      }
    } catch (error) {
      console.error(error);
      setResult("error");
    } finally {
      setLoading(false);
    }
  };

  const resultMessages = {
    error: {
      title: "Inténtalo de nuevo",
      subtitle: "el cambio de línea no pudo completarse",
    },
    success: {
      title: "Cambio de línea de servicio exitoso",
    }
  };

  const validateSchema = Yup.object({
    service_line: Yup.string().required("Campo obligatorio"),
  });

  if (loading) return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center"
      }}
    >
      <Spinner />
    </div>
  );

  if (result) return <ResponseResult type={result} {...resultMessages[result]} />;

  return (
    <ModalStepper
      initialValues={{
        service_line: "",
      }}
      onSubmit={async ({ values: { service_line } }) => {
        const [id, name, parentId] = service_line.split("--");
        await updateServiceLineEvent(Number(id), name, parentId);
      }}
      onClose={onClose}
      showCancelButton
    >
      <ModalStep>
        <ChangeServiceLineWarning />
      </ModalStep>
      <ModalStep textButtonNext="Aceptar" validationSchema={validateSchema}>
        <ChangeServiceLineForm />
      </ModalStep>
    </ModalStepper>
  )
};
