import React from "react";
import { useField } from "formik";

import styles from "./DatePicker.module.scss";

const DatePicker = ({ ...props }) => {
  const [field, meta] = useField({ ...props });

  return (
    <div className={styles.dateContainer}>
      <input
        type="date"
        name={field.name[0]}
        {...field} {...props}
        className={styles.datePickerInput}
      /> 
    </div>
  );
};

export default DatePicker;
