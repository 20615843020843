import { useState, useContext, useEffect } from "react";

import ButtonOption from "../../atoms/ButtonOption";
import RadioIcon from "../../assets/imgs/icons/radbutton_selected_active.svg";
import SelectIcon from "../../assets/imgs/icons/select.svg";
import TextIcon from "../../assets/imgs/icons/Text.svg";
import DateIcon from "../../assets/imgs/icons/calendar.svg";
import SectionIcon from "../../assets/imgs/icons/Sorting.svg";
import Numbers from "../../assets/imgs/icons/Numbers.svg";
import Likert from "../../assets/imgs/icons/TodoList.svg";
import AddIcon from "../../assets/imgs/icons/AddButton.svg";
import Speed from "../../assets/imgs/icons/Speed.svg";
import styles from "./SelectOptionList.module.scss";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import { useFormikContext } from "formik";

const options = [
  {
    id: 0,
    icon: SectionIcon,
    label: "Sección",
    value: "newSection",
    className: `${styles.sectionButton}`,
  },
  {
    id: 1,
    icon: RadioIcon,
    label: "Opción múltiple",
    value: 1,
    className: `${styles.optionButton}`,
  },
  {
    id: 2,
    icon: SelectIcon,
    label: "Única opción",
    value: 3,
    className: `${styles.optionButton}`,
  },
  {
    id: 3,
    icon: TextIcon,
    label: "Texto abierto",
    value: 8,
    className: `${styles.optionButton}`,
  },
  {
    id: 4,
    icon: DateIcon,
    label: "Fecha",
    value: 4,
    className: `${styles.optionButton}`,
  },
  {
    id: 5,
    icon: Numbers,
    label: "Numérica",
    value: 5,
    className: `${styles.optionButton}`,
  },
  {
    id: 6,
    icon: Likert,
    label: "Likert",
    value: 6,
    className: `${styles.optionButton}`,
  },
  {
    id: 7,
    icon: Speed,
    label: "NPS",
    value: 12,
    options: [
      { detail: "0", score: "0", order: 1 },
      { detail: "1", score: "1", order: 2 },
      { detail: "2", score: "2", order: 3 },
      { detail: "3", score: "3", order: 4 },
      { detail: "4", score: "4", order: 5 },
      { detail: "5", score: "5", order: 6 },
      { detail: "6", score: "6", order: 7 },
      { detail: "7", score: "7", order: 8 },
      { detail: "8", score: "8", order: 9 },
      { detail: "9", score: "9", order: 10 },
      { detail: "10", score: "10", order: 11 },
    ],
    className: `${styles.optionButton}`,
  },
];

export const SelectOptionsList = ({ pushOption }) => {
  const [showButtons, setShowButtons] = useState(true);
  const { values } = useFormikContext();

  const { indexSection, setIndexSection, orderQuestions, setOrderQuestions } =
    useContext(ScalesContext);

  const handleSelectOption = (optionSelect) => {
    if (optionSelect === "newSection") {
      const newSection = indexSection !== undefined ? indexSection + 1 : 0;
      // const schema = [...values.schema];
      setIndexSection(newSection);
      pushOption({
        section: {
          id: newSection,
          name: "",
          questions: [],
        },
      });
    } else if (
      optionSelect === 2 ||
      optionSelect === 6 ||
      optionSelect === 3 ||
      optionSelect === 1
    ) {
      const order = orderQuestions;
      pushOption({
        question: {
          detail: "",
          type: optionSelect,
          options: [],
          is_required: false,
          order: order,
        },
        order: order,

      });
      setOrderQuestions(order + 1);
    } else if (optionSelect === 4 || optionSelect === 5 || optionSelect === 8) {
      const order = orderQuestions;

      pushOption({
        question: {
          detail: "",
          type: optionSelect,
          is_required: false,
          order: order,
        },
        order: order,
      });
      setOrderQuestions(order + 1);
    } else if (optionSelect === 12) {
      const order = orderQuestions;
      pushOption({
        question: {
          detail: "",
          type: optionSelect,
          options: options[7].options,
          ranges: [],
          is_required: false,
          order: order,
        },
      });
      setOrderQuestions(order + 1);
    }
  };

  useEffect(() => {
    if (!!values.schema.length) {
      const allSections = values.schema.filter(sch => sch.hasOwnProperty("section"));
      const sectionIndexMaxOrder = allSections.map(section => Number(section.order)).sort((a, b) => b - a)[0];
      setIndexSection(sectionIndexMaxOrder);
      const allQuestionWithoutSection = values.schema.filter(sch => !sch.hasOwnProperty("section"));
      let allQuestions = [];
      allSections.forEach(sct => sct.section.questions.forEach(qst => allQuestions.push(qst)));
      allQuestionWithoutSection.forEach(qst => allQuestions.push(qst));
      const questionIndexMaxOrder = allQuestions.map(qst => Number(qst.order)).sort((a, b) => b - a)[0];
      setOrderQuestions(questionIndexMaxOrder + 1);
    }
  }, []);

  return (
    <div className={styles.listOptionsButtons}>
      <button
        type="button"
        className={`${styles.addButton} ${showButtons && styles.withoutText}`}
        onClick={() => setShowButtons(!showButtons)}
      >
        <img src={AddIcon} alt="Añadir pregunta" className={styles.addImage} />
        {!showButtons && "Agregar nuevo"}
      </button>

      <>
        {showButtons ? (
          <>
            {options.map((item, idx) => (
              <ButtonOption
                icon={item.icon}
                label={item.label}
                key={idx}
                onClick={() => {
                  handleSelectOption(item.value);
                  setShowButtons(!showButtons);
                }}
                className={`${item.className} ${
                  showButtons ? styles.visible : ""
                }`}
                style={{ transitionDelay: `${showButtons ? 0 : 300}ms` }}
              />
            ))}
          </>
        ) : null}
      </>
    </div>
  );
};
