import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import UserScale from "../../templates/UserScale";
import MainProfileHeader from "../../molecules/MainProfileHeader";
import { getplan } from "../../services/patientPlan";
import { getPlanStatus } from "../../services/getTracing";
import { AppContext } from "../../context/AppContext/AppContext";

export default function PatientScale() {
  const { userPlan, plan, setPlan } = useContext(AppContext);
  const navigate = useNavigate();
  const getData = async () => {
    const response = await getplan(userPlan);
    if(response.statusCode===200){
      setPlan(response.data[0]);
    }
  };

  const getStatus = async () => {
    const response = await getPlanStatus(userPlan);
    if (response.response[0].isFinished) {
      navigate("/finaldiligence");
    } else {
      getData();
    }
  };


  useEffect(() => {
    getStatus();
  }, []);


  return <UserScale header={<MainProfileHeader />} scale={plan} />;
}
