import React, { useState } from "react";

import CardFSFB from "../../atoms/Card";
import ActionButton from "../../atoms/ActionButton";
import QuestionOptionCard from "../../molecules/QuestionOptionCard";
import Arrow from "../../assets/imgs/icons/arrowUpCircleWhite.svg";
import styles from "./AccordeonPreview.module.scss";

export const AccordeonPreview = ({ data }) => {
  const [isAccordeonOpen, setIsAccordeonOpen] = useState(true);

  return (
    <CardFSFB className={styles.card}>
      <div className={styles.newSectionContainer}>
        <p className={styles.title}>
          {data.section.name}
        </p>
        <ActionButton
          icon={Arrow}
          onClick={() => setIsAccordeonOpen(!isAccordeonOpen)}
        />
      </div>
      {isAccordeonOpen ? 
      <CardFSFB className={styles.accordeon}>
        {data.section.questions.map((q, index) => 
        <QuestionOptionCard question={q}  key={ `s-q${index}`  }/>
        ) }
      </CardFSFB> : null}
    </CardFSFB>
  );
};
