import React, { useContext } from "react";

import { CardOtp } from "../../molecules/CardOtp";
import { LogoHeader } from "../../atoms/LogoHeader";
import OtpPatientsForm from "../../molecules/OtpPatientsForm";
import { LoginWelcome } from "../../templates/LoginWelcome";
import { AppContext } from "../../context/AppContext/AppContext";
import styles from "./OtpPatients.module.scss";

export default function LoginPatients(props) {
  const { otpChallenge } = useContext(AppContext);

  return (
    <LoginWelcome>
      <section className={styles.container}>
        <LogoHeader />
        <h2 className={styles.infoText}>
          Ingresa el código que hemos enviado al{" "}
          {otpChallenge.data?.phone ? "número" : "correo"}:
        </h2>
        <p className={styles.title}>
          {otpChallenge.data?.phone
            ? otpChallenge.data?.phone
            : otpChallenge.data?.email}
        </p>
        <CardOtp >
          <OtpPatientsForm />
        </CardOtp>
      </section>
      {otpChallenge.data?.code && (
        <div style={{ color: "blue" }}>Código: {otpChallenge.data?.code}</div>
      )}
    </LoginWelcome>
  );
}
