// import * as Yup from "yup";

export const mockScale = [
  {
    type: "image",
    name: "PosasImage",
    question: "Traza sobre la imagen las áreas afectadas",
    value: "",
    image: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt1",
    question: "¿Te ha dolido la cicatriz en las últimas semanas?",
    label1: "No, para nada",
    label2: "Si, mucho",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt2",
    question: "¿Te ha estado picando la cicatriz en las últimas semanas?",
    label1: "No, para nada",
    label2: "Si, mucho",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt3",
    question:
      " ¿Tienes la cicatriz un color diferente al de su piel normal en este momento?",
    label1: "No, como la piel normal",
    label2: "Sí, muy diferente",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt4",
    question:
      "¿Tienes la cicatriz una rigidez diferente a la du su piel normal en este momento (es decir, no es igual de rígida)?",
    label1: "No, como la piel normal",
    label2: "Sí, muy diferente",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt5",
    question:
      "¿Tienes la cicatriz un grosor diferente al de su piel normal en este momento es decir, no es igual de gruesa)?",
    label1: "No, como la piel normal",
    label2: "Sí, muy diferente",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt6",
    question:
      "¿Es la cicatriz más irregular que tu piel normal en este momento?",
    label1: "No, como la piel normal",
    label2: "Sí, muy diferente",
    value: "",
    isRequired: true,
  },
  {
    type: "radioButton",
    name: "opt7",
    question:
      "¿Cuál es tu opinión general sobre la cicatriz comparada con la piel normal?",
    label1: "Como la piel normal",
    label2: "Muy diferente",
    value: "",
    isRequired: true,
  },
];

// "optionList": [
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "2",
//     value: 2,
//   },
//   {
//     label: "3",
//     value: 3,
//   },
//   {
//     label: "4",
//     value: 4,
//   },
//   {
//     label: "",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },
//   {
//     label: "1",
//     value: 1,
//   },

// ],
