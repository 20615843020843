import { useField } from "formik";
import { Switch } from "../../atoms/SwitchFSFB";

import styles from "./Switch.module.scss";


const SwitchFSFB = (props) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const handleChange = (value) => {
    value === 2 ? setValue(1) : setValue(2);
  };

  return (
    <div className={styles.multipleSwitchOptions}>
      <p>Múltiple</p>
        <Switch
          toggled={field.value === 2 ? true : false}
          onChange={() => {
            handleChange(field.value === 1 ? 1 : 2)
          }}
          name={field.name}
          disabled={props.disabled}
        />
      <p>Única</p>
    </div>
  );
};
export default SwitchFSFB;
