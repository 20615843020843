import ManagerLoginForm from "../../molecules/ManagerLoginForm";
import { LoginWelcome  } from "../../templates/LoginWelcome";

export default function ManagerLogin() {
  return (
    <LoginWelcome>
      <ManagerLoginForm />
    </LoginWelcome>
  );
}
