import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import * as moment from "moment-timezone";

import Table from "../../molecules/Table";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { offsetUser } from "../../custom/offset";
import TableHeader from "../../atoms/TableHeader";
import { useSort } from "../../hooks/useSort";
import statusChecked from "../../assets/imgs/icons/statusChecked.svg";
import statusUnchecked from "../../assets/imgs/icons/statusUnchecked.svg";
import yellowAlert from "../../assets/imgs/icons/yellowAlert.svg";
import styles from "./PatientsTable.module.scss";
import { Pagination } from "../../atoms/Pagination";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";

export default function PatientsTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();
  let timerService;

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  const {
    startDatePacientesContigo,
    endDatePacientesContigo,
    generePacientesContigo,
    agePacientesContigo,
    updatePacientesContigo,
    alertPacientesContigo,
  } = useContext(FiltersContext);

  const {
    setCustomePagination_pat,
    actualPage_pat,
    setActualPage_pat,
    modulePatientsList,
    setModulePatientsList,
    name_patient,
    setName_patient,
    document_patient,
    setDocument_patient,
    sort_patients,
    setSort_patients,
    pagination_pat,
  } = useContext(ContigoContext);

  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha" column={column} />;
      },
      Cell: ({ row }) => (
        <p>
          {" "}
          {moment.tz(row.values.date, "America/Bogota").format("YYYY-MM-DD")}
        </p>
      ),
      accessor: "date",
      width: 15,
      disableFilters: true,
      tooltipType: "date",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setName_patient}
            filterValue={name_patient}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleSelected(row.original.id_patient)}
          >
            {row.original.patient_name}
          </p>
        );
      },
      accessor: "patient_name",
      width: 45,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Documento"
            column={column}
            handleGetFilter={setDocument_patient}
            filterValue={document_patient}
          />
        );
      },
      accessor: "doc_number",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Al día" column={column} />;
      },
      accessor: "status",
      Cell: ({ row }) => (
        <img
          src={row.original.status === true ? statusChecked : statusUnchecked}
          alt="check"
        />
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Alerta" column={column} />;
      },
      accessor: "alert",
      Cell: ({ row }) => ({
        ...(row.original.alert === true ? (
          <img src={yellowAlert} alt="check" className={styles.alertIcon} />
        ) : (
          <></>
        )),
      }),
      disableFilters: true,
      disableSortBy: true,
      width: 10,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Estado"
            column={column}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
          >
            {row.original.status_description}
          </p>
        );
      },
      accessor: "status_description",
      disableFilters: true,
      disableSortBy: true,
      width: 15,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const handleSelected = async (idPatient) => {
    navigate(`/contigo/pacientes/detalle/${idPatient}`);
  };

  const handleGetPatientsList = async () => {
    let params = {
      page: actualPage_pat,
      offset: offsetUser(height, width),
      patient_name: name_patient,
      doc_number: document_patient,
      sort: sort_patients,
      start_date: startDatePacientesContigo
        ? format(startDatePacientesContigo, "yyyy-MM-dd")
        : "",
      end_date: endDatePacientesContigo
        ? format(endDatePacientesContigo, "yyyy-MM-dd")
        : "",
      genre: generePacientesContigo,
      legal_age: agePacientesContigo,
      updated: updatePacientesContigo,
      alert: alertPacientesContigo,
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/contigo/patients?${route}`,
    });
    const lastPage = response.pagination.last_page;
    const actualPage_1 = response.pagination.page;
    setModulePatientsList(response.data);
    setCustomePagination_pat({ page: actualPage_1, paginationTotal: lastPage });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetPatientsList();
  }, [
    actualPage_pat,
    sort_patients,
    control,
    startDatePacientesContigo,
    endDatePacientesContigo,
    generePacientesContigo,
    agePacientesContigo,
    updatePacientesContigo,
    alertPacientesContigo,
  ]);

  // custom hook sort
  useSort(sort_patients, setSort_patients);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [name_patient, document_patient]);

  useEffect(() => {
    if (name_patient.length || document_patient.length > 0) {
      setActualPage_pat(1);
    }
  }, [name_patient, document_patient]);

  const reloadInfoTable = () => {
    timerService =
      !timerService &&
      setInterval(() => {
        handleGetPatientsList();
      }, 900000);
  };

  useEffect(() => {
    reloadInfoTable();
    return () => clearInterval(timerService);
  }, []);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={modulePatientsList}
        getData={handleGetPatientsList}
        offsetUser={offsetUser}
        actualPage={actualPage_pat}
      />

      <Pagination
        actualPage={actualPage_pat}
        setPage={setActualPage_pat}
        infoPage={pagination_pat}
        className={styles.pagination}
      />
    </>
  );
}
