import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import ErrorMessage from "../../atoms/ErrorMessage";
import Spinner from "../../atoms/Spinner";
import Input from "../../atoms/Input";
import SelectOption from "../../atoms/SelectOption";
import { Button } from "../../atoms/Button/";
import { AppContext } from "../../context/AppContext/AppContext";
import { getInfoPatient } from "../../services/common/admin";
import { removeItem } from "../../services/localStorage";
import styles from "./LoginPatientsForm.module.scss";

export default function LoginPatientsForm() {
  const navigate = useNavigate();
  const { patientDocument, setUserInfo, options } = useContext(AppContext);
  const [charging, setCharging] = useState(false);
  const [customError, setCustomError] = useState("");
  const devEnv =
    window.location.origin.indexOf("dev") > -1 ||
    window.location.origin.indexOf("localhost") > -1;
  const [isDev] = useState(devEnv);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setCustomError(""), 4000);
    return () => clearTimeout(timer);
  }, [customError]);

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{
          documentType: "CC",
          documentNumber: "",
        }}
        validationSchema={Yup.object({
          documentNumber: Yup.number()
            .required("Por favor digita un número de documento")
            .typeError(
              "Este campo debe ser diligenciado con números únicamente"
            ),
        })}
        onSubmit={async (values) => {
          if (patientDocument && patientDocument !== values.documentNumber) {
            setCustomError(
              "El documento no pertenece al paciente de la escala a diligenciar"
            );
            return;
          }
          if (patientDocument) removeItem("document");
          setIsLoading(true);
          if (charging) return;
          setCharging(true);

          const payload = {
            doc: values.documentNumber,
            tdoc: options.find((option) => option.value === values.documentType)
              .id,
          };

          const response = await getInfoPatient(payload);

          if (response.data.data.response.length) {
            setIsLoading(false);
            setUserInfo(response.data.data.response[0]);
            navigate("/loginAuth");
          } else {
            setCustomError(
              "Los datos ingresados no están registrados, inténtalo nuevamente"
            );
            setIsLoading(false);
          }

          setCharging(false);
        }}
      >
        {() => (
          <Form>
            <div className={styles.loginForm}>
              <SelectOption
                label="Tipo de documento"
                name="documentType"
                id="documentType"
                classNameL={styles.loginLabel}
              >
                <option value="" disabled selected>
                  Seleccionar
                </option>
                {options.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectOption>
              <Input
                label="Número de documento"
                name="documentNumber"
                type="tel"
                classNameL={styles.loginLabel}
              />
              <div className={styles.anchorContainer}>
                <a
                  href="https://www.fsfb.org.co/wps/portal/fsfb/inicio/acercadefsfb/fsfb-web/sectionItem/terminos-y-condiciones/!ut/p/z1/tZJbb4IwGIZ_yy68bFoO5XCJM9ERUaMypTemYMEaKYqEHX79yg7JlkWYceWiTcv7Pd_bvoUEriERtOYZrXgh6EGuI2JtZtORqRkmCoYTbCDPXzgWHlsOGltw9S5APz4P9ed630BoONUhub7-O-lv9S0C0o5_hASSY8K3MNLS2MEUmcAyKQImc00Q2zoGmLm24-hxQl3WqBNRHasdjNJzGgMueMKLHvqaacLKhG5Z87OHPsakEBUTVQ9VrMy5KM7gBci9bVMh2Bn6XYeUKehlcB9k0iqtdrJpWsD1BZoU8_3pRDzptGn8XMH1v1ldNRfQYnZk_RJM-wPkLeezmRYaw8DVPgVtT6YrtEiGbl_0MDDhqubsCYaiKHP5iBdXZjzq7GDd2KEDj9XibaX4pakWr_bul4ZSvKGrxWtK8b7aaP1boz3mYRjmjoEP9ThdPOxxlm8G_QmI_Pr18pTdvQG_TRiL/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                  target="__blank"
                  className={styles.anchor}
                >
                  Términos y condiciones
                </a>
                <a
                  href="https://www.fsfb.org.co/wps/portal/fsfb/inicio/acercadefsfb/fsfb-web/sectionItem/habeas-data/!ut/p/z1/lZFBb4JAEIV_Sw8cZQdWN9AbtAliQAgtEfdiVl2BBFmyrJL467ttetBosJ3bJN97M_MGUVQg2rJzXTJVi5Y1ul9TskmT-dTCU4iD5QyDt_hwyCwiDkQErX4AuCkP_Mz2MUCQ2Ij-X3_t9Df9CEDH7RfPBugEbBm_xSWiHVPVpG4PAhUV23LWT_ZMsW_Ca7fY0YTkBy65NE9SR1cp1fWvBhgwDINZClE23NyJowGPJJXoFSpuSX0dHd1vTu6AxH8H7zNLUyvHQexav8DYh55l1B3zvLhEfOUoN6zDly-VsErF/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                  className={styles.anchor}
                  target="__blank"
                >
                  Política de tratamiento de datos
                </a>
              </div>
              <ErrorMessage error={customError} />
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={charging}
                  className={styles.buttonSend}
                >
                  Continuar
                </Button>
              </div>
              {isLoading ? (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    className_img="logPatientImg"
                    className_container="logPatientCont"
                  />
                </div>
              ) : null}
            </div>

            {/* {isDev && (
                  <div styles={{ color: "gray", fontSize: "smaller" }}>
                    Documento válido: {patientDocument}
                  </div>
                )} */}
          </Form>
        )}
      </Formik>
    </div>
  );
}
