import styles from "./NextButton.module.scss";

export default function NextButton(props) {
  return (
    <button
      type={props.type || "button"}
      form={props?.form}
      {...(props?.type === "button" && { onClick: props.onClick })}
      {...props.type ==="submit" && {onClick: props.onClick}}
      className={`
        ${styles.NextButton} 
        ${props.className && props.className } 
        ${styles[props.tracing]} 
        ${styles[props.height]} 
        ${styles[props.patientBtn]}
        ${styles[props.btnFiltersModal]}
        ${styles[props.className]}
        ${props.nextButtonCustom && props.nextButtonCustom}
      `}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </button>
  );
}
