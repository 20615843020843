import styles from "./LoginButton.module.scss";

export default function LoginButton(props) {

  return (
    <button
			type={props.type || "submit"}
			className={`
        ${styles.loginButton}
        ${props.variant === "loginPatient" && styles.loginPatient}
        ${props.disabled && styles.disabledButton}
        ${styles[props.btn]}
      `}
			{...props.type === "button" && {onClick: props.onClick}}
      disabled={props.disabled}
      >
      {props.icon}
      {props.text}
    </button>
  );
}
