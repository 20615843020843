import UserIcon from "../../assets/imgs/icons/userUpdate.svg";
import DeleteIcon from "../../assets/imgs/icons/DeleteIcon.svg";
import styles from "./DeleteEventsConfirmation.module.scss";

const DeleteEventsConfirmation = ({ status, trackDelete, deleteRegister }) => {
  let icon, title;

  switch (status) {
    case 200:
      if (trackDelete) {
        icon = UserIcon;
        title = "Monitoreo detenido";
      } else if (deleteRegister) {
        icon = UserIcon;
        title = "Registro(s) eliminado(s)";
      } else {
        icon = UserIcon;
        title = "Evento(s) eliminado(s)";
      }
      break;

    default:
      icon = DeleteIcon;
      title = "No se pudo completar la acción, \ninténtalo de nuevo";
      break;
  }

  return (
    <div className={styles.updateConfirmationContainer}>
      <img src={icon} alt="icono de confirmación actualización" />
      <h2 className={styles.updateTitleBlue}>{title}</h2>
    </div>
  );
};

export default DeleteEventsConfirmation;
