
import MainHeaderLogo from "../../atoms/MainHeaderLogo";
import ManagerData from "../../molecules/ManagerData";
import { BreadCrumbsContainer } from "../../molecules/BreadCrumbsContainer";
import Banner from "../../atoms/Banner";
import styles from "./MainProfileHeader.module.scss";

export default function MainProfileHeader() {

  return (
    <>
      <section className={styles.logo}>
        <MainHeaderLogo />
      </section>
      <section className={styles.content}>
        <div className={styles.mainTitle}>
          <BreadCrumbsContainer/>
          <ManagerData />
        </div>
        <div className={styles.banner}>
          <Banner />
        </div>
      </section>
    </>
  );
}
