import React from 'react'


import CardFSFB from "../../atoms/Card";
import Doctor from "../../assets/imgs/doctor_saludo.png";
import styles from "./CardOtp.module.scss"

export const CardOtp = ({children}) => {
  return (
    <CardFSFB className={styles.cardForm}>
      {children}
      <img src={Doctor} alt="Doctor saludando" className={styles.doctor} />
      </CardFSFB>
  )
}
