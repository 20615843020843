import { Field } from "formik";
import Input from "../../atoms/Input";
import SwitchFSFB from "../../atoms/Switch";
import ActionButtonList from "../../molecules/ActionButtonList";
import OptionCheckboxAdmin from "../../molecules/OptionCheckboxAdmin";
import SelectOptionAdmin from "../SelectOptionAdmin";
import NpsAdmin from "../NpsAdmin";
import { optionList } from "./OptionList";
import styles from "./cardSelectOption.module.scss";

const CardSelectOption = ({ ...props }) => {
  const { type, name, id, deleteOption, handleCopy, order, idQuestion } =
    props;
  const nameOpt = optionList.find((opt) => opt.value === type)?.label;

  return (
    <>
      <section className={styles.actionButtonsOptions}>
        <Input
          name={`${name}.detail`}
          type="text"
          disabled={idQuestion}
          placeholder="Nueva pregunta"
        />

        <ActionButtonList
          name={`${name}.is_required`}
          deleteOption={deleteOption}
          id={id}
          handleCopy={handleCopy}
          order={order}
          disabled={idQuestion}
        />
      </section>

      <div
        className={
          type === 1 || type === 2 || type === 3 || type === 6
            ? styles.selectedOption
            : styles.selectOptionFlex
        }
      >
        {type === 1 && (
          <OptionCheckboxAdmin
            name={`${name}.options`}
            nameType={`${name}.type`}
            type={type}
            // disabled={!isEditable }
          />
        )}
        {type === 2 && (
          <OptionCheckboxAdmin
            name={`${name}.options`}
            nameType={`${name}.type`}
            type={2}
            // disabled={!isEditable }
          />
        )}
        {type === 3 && (
          <SelectOptionAdmin
            name={`${name}.options`}
            type={3}
            disabled={idQuestion}
          />
        )}
        {type === 6 && (
          <OptionCheckboxAdmin
            name={`${name}.options`}
            type={6}
            disabled={idQuestion}
          />
        )}
        {type === 12 && (
          <NpsAdmin
            name={`${name}.ranges`}
            type={12}
            disabled={idQuestion}
          />
        )}

        <section
          className={
            (type === 1) | (type === 2)
              ? styles.optionsQuestion
              : styles.options
          }
        >
          {(type === 1) | (type === 2) ? (
            <div className={styles.switch}>
              <p className={styles.titleOption}>Tipo respuesta:</p>
              <SwitchFSFB
                name={`${name}.type`}
                disabled={props.disabled && props.disabled}
              />
            </div>
          ) : null}
          <div className={styles.typeQuestion}>
            <p className={styles.titleOption}>{nameOpt} | </p>

            <label className={styles.checkboxOption}>
              Obligatoria
              <Field
                type="checkbox"
                name={`${name}.is_required`}
                className="checkBox"
                disabled={idQuestion}
              />
            </label>
          </div>
        </section>
      </div>
    </>
  );
};

export default CardSelectOption;
