import { useState, useContext } from "react";

import { DashboardContext } from "../../context/DashboardContext/DashboardContext";

import { BarChart } from "../../molecules/BarChart/BarChart";
import ActionButton from "../../atoms/ActionButton";
import InfoCard from "../../atoms/InfoCard";

import Arrow from "../../assets/imgs/icons/arrowAccordionLightBlue.svg";

import styles from "./DetailDashboard.module.scss";

export const DetailDashboard = ({ title }) => {
  const { desenlacesDetailCards, contigoDetailCards } = useContext(DashboardContext);

  const [titleChart, setTitleChart] = useState("");
  const [activeCard, setActiveCard] = useState("");
  const [path, setPath] = useState("");
  const [type, setType] = useState("");
  const [timeFilterType, setTimeFilterType] = useState("");
  const [isAccordeonOpen, setIsAccordeonOpen] = useState(true);

  const getData = (name, path, type, time = "past") => {
    if (name === activeCard) {
      setTitleChart("");
      return setActiveCard("");
    }
    setActiveCard(name)
    setTitleChart(name);
    setType(type);
    setPath(path);
    setTimeFilterType(time)
  };

  const cardsToRender = {
    Desenlaces: desenlacesDetailCards,
    Contigo: contigoDetailCards,
  };

  const module = {
    Desenlaces: 100,
    Contigo: 101,
  };

  return (
    <section>
      <div className={styles.resumeTitle}>
        <p className={styles.resumeText}>{title}</p>
        <div className={styles.resumeLine} />
        <div className={`${isAccordeonOpen && styles.accordionUp}`}>
          <ActionButton
            icon={Arrow}
            onClick={() => setIsAccordeonOpen(!isAccordeonOpen)}
          />
        </div>
      </div>
      <section
        style={{
          display: `${isAccordeonOpen ? "" : "none"}`
        }}
      >
        <section className={styles.listCards}>
          {cardsToRender[title].map((card) => (
            <InfoCard
              key={card.detail}
              variant="medium"
              color={card.color}
              detail={card.detail}
              icon={card.icon}
              active={activeCard === card.detail}
              opacity={!activeCard ? false : activeCard !== card.detail}
              onClick={() => getData(card.detail, card.path, card.type, card.timePeriod)}
              {...card}
            />
          ))}
        </section>
        {activeCard && <BarChart titleChart={titleChart} path={path} type={type} module={module[title]} timeFilterType={timeFilterType}/>}
      </section>
    </section>
  );
};
