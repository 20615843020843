import { useField } from "formik";
import React, { useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import { AppContext } from "../../context/AppContext/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ErrorMessage from "../ErrorMessage";
import styles from "./RadioButton.module.scss";

const RadioButtonFSFB = ({ number, ...props }) => {
  const [field, meta] = useField({ ...props });
  const { plan } = useContext(AppContext);
  const { width } = useWindowDimensions();

  const firstLabelPosas = [
    "84de587b-78ba-416b-af93-3b1dba9c923a",
    "6391f97d-c89d-4b70-9631-2210dbafebe5",
    "8ed715d6-41ba-4f87-a795-e44827731208",
    "b14e3b9e-f5f9-4b65-b472-46207bebc506",
  ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>No, para nada</p>
  ) : [
      "dd614683-c198-4a52-bc8e-73605890444f",
      "2f78429d-f0c4-4b29-8dd8-919adb83f4ad",
      "18fb4274-6d60-44b3-9baa-b21bc98ae655",
      "0424bb20-4d66-41fa-a62f-e5ea74ac2418",
      "37936f25-9308-4a36-a353-b39227e9a6df",
      "219c6188-43c9-40bd-8a5d-10c86218903f",
      "57f55d87-5938-4a97-959c-31303bdeafa4",
      "b6f9323d-dccb-4044-b940-985a66324c17",
    ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>No, como la piel normal</p>
  ) : [
      "858a835b-cae2-4faf-a430-27db29051858",
      "aa272df7-5196-4f9e-a2ad-6995b0e4297b",
    ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>Como la piel normal</p>
  ) : null;

  const firstLabelPosasPat = [
    "84de587b-78ba-416b-af93-3b1dba9c923a",
    "6391f97d-c89d-4b70-9631-2210dbafebe5",
    "8ed715d6-41ba-4f87-a795-e44827731208",
    "b14e3b9e-f5f9-4b65-b472-46207bebc506",
  ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>No, para nada</p>
  ) : [
      "dd614683-c198-4a52-bc8e-73605890444f",
      "2f78429d-f0c4-4b29-8dd8-919adb83f4ad",
      "18fb4274-6d60-44b3-9baa-b21bc98ae655",
      "0424bb20-4d66-41fa-a62f-e5ea74ac2418",
      "37936f25-9308-4a36-a353-b39227e9a6df",
      "219c6188-43c9-40bd-8a5d-10c86218903f",
      "57f55d87-5938-4a97-959c-31303bdeafa4",
      "b6f9323d-dccb-4044-b940-985a66324c17",
    ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>No, como la piel normal</p>
  ) : [
      "858a835b-cae2-4faf-a430-27db29051858",
      "aa272df7-5196-4f9e-a2ad-6995b0e4297b",
    ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>Como la piel normal</p>
  ) : null;

  const firstLabelPosasManag = (
    <p className={styles.labelPosasManager}>Piel normal</p>
  );

  const lastLabelPosas = [
    "84de587b-78ba-416b-af93-3b1dba9c923a",
    "6391f97d-c89d-4b70-9631-2210dbafebe5",
    "8ed715d6-41ba-4f87-a795-e44827731208",
    "b14e3b9e-f5f9-4b65-b472-46207bebc506",
  ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>Si, mucho</p>
  ) : [
      "dd614683-c198-4a52-bc8e-73605890444f",
      "2f78429d-f0c4-4b29-8dd8-919adb83f4ad",
      "18fb4274-6d60-44b3-9baa-b21bc98ae655",
      "0424bb20-4d66-41fa-a62f-e5ea74ac2418",
      "37936f25-9308-4a36-a353-b39227e9a6df",
      "219c6188-43c9-40bd-8a5d-10c86218903f",
      "57f55d87-5938-4a97-959c-31303bdeafa4",
      "b6f9323d-dccb-4044-b940-985a66324c17",
    ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>Sí, muy diferente</p>
  ) : [
      "858a835b-cae2-4faf-a430-27db29051858",
      "aa272df7-5196-4f9e-a2ad-6995b0e4297b",
    ].find((e) => e === props.idQuestion) ? (
    <p className={styles.labelPosas}>Muy diferente</p>
  ) : null;

  const lastLabelPosasPat = [
    "84de587b-78ba-416b-af93-3b1dba9c923a",
    "6391f97d-c89d-4b70-9631-2210dbafebe5",
    "8ed715d6-41ba-4f87-a795-e44827731208",
    "b14e3b9e-f5f9-4b65-b472-46207bebc506",
  ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>Si, mucho</p>
  ) : [
      "dd614683-c198-4a52-bc8e-73605890444f",
      "2f78429d-f0c4-4b29-8dd8-919adb83f4ad",
      "18fb4274-6d60-44b3-9baa-b21bc98ae655",
      "0424bb20-4d66-41fa-a62f-e5ea74ac2418",
      "37936f25-9308-4a36-a353-b39227e9a6df",
      "219c6188-43c9-40bd-8a5d-10c86218903f",
      "57f55d87-5938-4a97-959c-31303bdeafa4",
      "b6f9323d-dccb-4044-b940-985a66324c17",
    ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>Sí, muy diferente</p>
  ) : [
      "858a835b-cae2-4faf-a430-27db29051858",
      "aa272df7-5196-4f9e-a2ad-6995b0e4297b",
    ].find((e) => e === props.name) ? (
    <p className={styles.labelPosas}>Muy diferente</p>
  ) : null;

  const lastLabelPosasManag = (
    <p className={styles.labelPosasManager}>La peor cicatriz imaginable</p>
  );

  return (
    <div className={`${plan.id === 80 && styles.containerWidth}`}>
      <div
        className={`${styles.optionsRadioContainer} ${props.containerStyle} ${
          props.idQuestion === 79 && styles.optionsRadioContainerPosas
        }  ${props.idScale === 80 && styles.optionsRadioContainerPosas}`}
      >
        {props.leftLabel && <p>props.leftLabel</p>}
        {width > 480 && firstLabelPosas}
        {width > 960 && props.idScale === 80 && firstLabelPosasPat}
        {width > 960 && props.idQuestion === 79 && firstLabelPosasManag}
        <RadioGroup
          row
          name={props.name}
          className={props.className}
          {...field}
        >
          {props.options?.map((option) => (
            <FormControlLabel
              value={option.value}
              label={
                plan.id === 23 && width <= 960 ? (
                  <Typography sx={{ fontSize: 12 }}>{option.label}</Typography>
                ) : (
                  <p className={styles.labelText}>{option.label}</p>
                )
              }
              labelPlacement={
                props.labelPlacement ? props.labelPlacement : "top"
              }
              sx={plan.id === 23 && width <= 960 && { height: "2rem" }}
              disabled={props.disabled}
              control={
                <Radio
                  style={{
                    color: "#009DAC",
                  }}
                  disabled={props.disabled}
                />
              }
              key={option.value}
              className={
                plan.id === 80
                  ? styles.posasRadioContainer
                  : props.idScale === 23 || props.idScale === 176
                  ? styles.eq5dRadioContainer
                  : styles.radioContainer
              }
            />
          ))}
        </RadioGroup>
        {width > 480 && lastLabelPosas}
        {width > 960 && props.idQuestion === 79 && lastLabelPosasManag}
        {width > 960 && props.idScale === 80 && lastLabelPosasPat}
        {width <= 480 && (
          <div className={styles.labelMobile}>
            {firstLabelPosas}
            {lastLabelPosas}
          </div>
        )}
        {width <= 960 && props.idQuestion === 79 ? (
          <div className={styles.labelTab}>
            {firstLabelPosasManag}
            {lastLabelPosasManag}
          </div>
        ) : null}
        {width <= 960 && props.idScale === 80 ? (
          <div className={styles.labelTab}>
            {firstLabelPosasPat}
            {lastLabelPosasPat}
          </div>
        ) : null}
        {props.rightLabel && <p>props.rightLabel</p>}
      </div>

      <ErrorMessage error={meta.error} meta={meta} />
    </div>
  );
};

export default RadioButtonFSFB;
