import CreateIcon from "@mui/icons-material/Create";
import { useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Layer, Line, Stage } from "react-konva";
import EraserIcon from "../../assets/imgs/eraser_icon.png";
import posasBg from "../../assets/imgs/posas.png";
import posasBgMobile from "../../assets/imgs/posasResp.png";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ErrorMessage from "../ErrorMessage";
import style from "./CanvaPatient.module.scss";


const CanvaPatient = ({ answersLines, scale, ...props }) => {
  const { width } = useWindowDimensions();
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const [tool, setTool] = useState("pen");
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => { 
    setValue(lines);
  }, [lines]);

  useEffect(() => {
    if (answersLines !== undefined) {
      setLines(answersLines)
    }
  })


  return (
    <div className={style.canvaContainer}>
      {}
      <div className={style.canvaButtons}>
        <button
          type="button"
          onClick={() => setTool("pen")}
        >
          {
            width <= 960 ?
            <CreateIcon 
              sx={{
                width: "1rem",
                height: "1rem"
              }}
            /> :
            <CreateIcon/>
          }
        </button>
        <button
          type="button"
          onClick={() => setTool("eraser")}
        >

          {
            width <= 960 ?
            <img src={EraserIcon} width="16" height="16" alt="icono borrador"/>
            :
            <img src={EraserIcon} width="22" height="22" alt="icono borrador"/>
          }
        </button>
      </div>

      {
        width > 480 ?
        <>
          <Stage
            width={475}
            height={width <= 960 ? 220 : 320}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            onMousemove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onMouseup={handleMouseUp}
            onTouchEnd={handleMouseUp}
            style={{
              backgroundImage: `url(${posasBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              mouseZoomFactor: 1,
            }}
          >
            <Layer>
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={
                    line.points
                  }
                  stroke="rgb(225, 110, 95)"
                  strokeWidth={line.tool === "eraser" ? 10 : 3}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={
                    line.tool === "eraser" ? "destination-out" : "source-over"
                  }
                />
              ))}
            </Layer>
          </Stage>
          <ErrorMessage 
            error={meta.error} 
            meta={meta}
            errorResponsive="errorResponsive"
            errorMessResp="errorMessResp"
          />
        </>
        :
        <Stage
        width={325}
        height={350}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onMousemove={handleMouseMove}
        onTouchMove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchEnd={handleMouseUp}
        style={{
          backgroundImage: `url(${posasBgMobile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          mouseZoomFactor: 1,
        }}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={
                line.points
              }
              stroke="rgb(225, 110, 95)"
              strokeWidth={line.tool === "eraser" ? 10 : 3}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === "eraser" ? "destination-out" : "source-over"
              }
            />
          ))}
        </Layer>
      </Stage>
      }
    </div>
  );
};

export default CanvaPatient;
