import ErrorTemplate from "../../molecules/ErrorTemplate";

export default function ErrorInternet() {
  return (
    <ErrorTemplate
      titleLabel="Valida tu acceso a internet"
      subtitleLabel="Sin internet"
      text={[
        "Verifica tu conexión e inténtalo de nuevo.",
      ]}
    />
  );
}
