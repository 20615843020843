import React from "react";
import { useLocation } from "react-router-dom";
import { useField } from "formik";

import styles from "./textInput.module.scss";
import ErrorMessage from "../ErrorMessage";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const location = useLocation();
  
  return (
    <div>
      <label
        htmlFor={props.id || props.name}
        className={`${props.className && props.className} ${styles.inputLabel}`}
      >
        {label}
      </label>
      <div className={`${props.customInputContainer && props.customInputContainer} ${styles.inputContainer}`}>
        <input
          type={props.type || "text"}
          className={`${styles.inputs} ${props.classInput && props.classInput}`}
          {...field}
          placeholder={props.placeholder && props.placeholder}
        />
        {props.isEmail && (
          <span className={styles.labelEmail}>@fsfb.org.co</span>
        )}
      </div>
      {location.pathname === "/roles" || "/lineas"  ? 
        meta.touched && <ErrorMessage error={meta.error} meta={meta} containerCustom={styles.roles} />
      :  <ErrorMessage error={meta.error} meta={meta} className={styles.error} />}
    </div>
  );
};

export default TextInput;
