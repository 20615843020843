import { useState } from 'react';
import { AdminContext } from "./AdminContext";

export const AdminProvider = ({ children }) => {
  // dropdown menu
  const [dropdown, setDropdown] = useState(true);
  // text
  const [textConfirmation, setTextConfirmation] = useState('')

  // Table data
  const [sacleName, setScaleName] = useState()
  // Pagination
  const [pagination, setCustomePagination] = useState({
    page: 1, numberOfPages: 1
  });
  const [actualPage, setActualPage] = useState(1)

  const [selectedOption, setSelectedOption] = useState(null);
  const [downloadList, setDownloadList] = useState([]);
  const [editScale, setEditScale] = useState({});
  const [serviceLine, setServiceLine] = useState([]);
  const [previewScale, setPreviewScale] = useState({});
  const [isEditEscale,setIsEditScale]= useState(false);
  const [userInfo, setUserInfo] = useState(null);


  return (
    <AdminContext.Provider
      value={{
        dropdown, setDropdown,
        selectedOption, setSelectedOption,
        sacleName, setScaleName,
        pagination, setCustomePagination,
        actualPage, setActualPage,
        downloadList, setDownloadList,
        editScale, setEditScale,
        previewScale, setPreviewScale,
        isEditEscale,setIsEditScale,
        textConfirmation, setTextConfirmation,
        serviceLine, setServiceLine,
        userInfo, setUserInfo
      }}
    >
      {children}
    </AdminContext.Provider>
  )
};

