import React  from "react";
import { useField } from "formik";

import styles from "./Input.module.scss";
import ErrorMessage from "../ErrorMessage";

const Input = ({ label, ...props }) => {

  const [field, meta] = useField(props);


  return (
    <div className={styles.inputConainer}>
      {label && 
      <label
        htmlFor={props.id || props.name}
        className={`${props.classNameL && props.classNameL}
          ${props.variant === "white" ? styles.labelWhite : styles.inputLabel}
       `}
      >
        {label}
      </label>
      }
      <div className={styles.input}>
        <input

          type={props.type || "text"}
          className={`
          ${
            props.variant === "white" ? styles.inputWhiteOption : styles.inputs
          } ${props.className && props.className}`}
          placeholder={props.placeholder && props.placeholder}
          {...field}
          disabled={props.disabled && props.disabled}
          autoComplete="off"
        />
        {props.subLabel && <p className={styles.subLabel}>{props.subLabel} </p>}
      </div>
      {meta.error !== undefined && <ErrorMessage error={meta.error} /> }
    </div>
  );
};

export default Input;
