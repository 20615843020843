import { useState, useContext } from "react";


import withAuth  from '../../HOC/withAuth';
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import ButtonOption from "../../atoms/ButtonOption";
import ModalContent from "../../atoms/ModalContent";
import ServicesLinesTable from "../../atoms/ServiceLinesAdminTable";
import ToolboxTable from "../../atoms/ToolboxTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import { getItem } from "../../services/localStorage";
import { deleteLine, statusLine, updateLine } from "../../services/servicesLine";
import { AppContext } from "../../context/AppContext/AppContext";
import { LinesContext } from "../../context/LinesContext/LinesContext";
import useSelect from "../../hooks/useSelect";
import { AddNewLine } from "../../molecules/AddNewLine";
import { EditLine } from "../../molecules/EditLine";
import addIcon from "../../assets/imgs/icons/addIcon.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import check from "../../assets/imgs/icons/check.svg";
import xIcon from "../../assets/imgs/icons/xIcon.svg";
import styles from "./ServiceLinesAdmin.module.scss";

 function ServiceLinesAdmin() {
  const [openCreateLine, setOpenCreateLine] = useState(false);
  const [openCreateNotification, setOpenCreateNotification] = useState(false);
  const [newLineRender, setNewLineRender] = useState(false);
  const [error400, setError400] = useState();
  const [openEditLine, setOpenEditLine] = useState(false);
  const [editLine, setEditLine] = useState({});
  const [status, setStatus] = useState();
  const [renderControl, setRenderControl] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTrueModal, setOpenTrueModal] = useState(false);
  const [openFalseModal, setOpenFalseModal] = useState(false);
  const [statusDelete, setStatusDelete] = useState(null);
  const [statusActive, setStatusActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState();

  const { user } = useContext(AppContext);
  const { actualPage, createStatus, editStatus } = useContext(LinesContext);

  const actualPage_1 = actualPage;
    // *--- Select ---*
    const [childDataState, setChildDataState] = useState();
    function childData(e) {
      setChildDataState(e)
    }
    const propertyNameMap = {
      id_line: 'id',
      name: 'name',
      status: 'active',
  };
  const selectOptions = useSelect(childDataState, propertyNameMap);
    // *--- Select ---*

  const handleDelete = async (user) => {
    setIsLoading(true);
    setAmount(selectOptions.length);
    const schemaCreate = {
      serviceLines_ids: selectOptions
      .filter((datos) => datos?.id_line)
      .map((datos) => datos?.id_line || ""),
    };
    const data = await deleteLine(schemaCreate, user);
    setIsLoading(false);
    setStatusDelete(data.status);;
    if (data.status === 200) {
      setStatus(data.status);
      setNewLineRender(!newLineRender);
    } else if (data.status === 400) {
      setStatus(data.status);
      setError400(data.data.data.response[0].message);
    } else {
      alert("Error al eliminar la línea");
    }
    
  };

  const handleEditStatus = async (user) => {
    const id = user.id;
    const newStatus = {
      active: !user.active,
      updated_by: getItem("user").email,
    };
    user.active = !user.active;
    const data = await updateLine(newStatus, id);
    if (data.status === 200) {
      setStatus(data.status);
      setOpenCreateNotification(true);
    } else if (data.status === 400) {
      setStatus(data.status);
      setError400(data.data.data.response[0].message);
      setOpenCreateNotification(true);
    } else {
      alert("Error al actualizar el estado de la línea");
    }
  };

  const handleStatusTrue = async (status) => {
    setIsLoading(true);
    setAmount(selectOptions.length);
    const activate = status
    const newStatus = {
      serviceLines_ids: selectOptions
      .filter((datos) => datos?.id_line)
      .map((datos) => datos?.id_line || ""),
    };
    const data = await statusLine(newStatus, getItem("user").email, activate);
    setIsLoading(false);
    setStatusActive(data.status);
    setNewLineRender(!newLineRender);
  };

  const handleOpenDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setStatusDelete(null);
    setOpenDeleteModal(false);
    setAmount(null)
  };

  const handleOpenTrue = () => {
    setOpenTrueModal(true);
  };

  const handleCloseTrue = () => {
    setStatusActive(null);
    setOpenTrueModal(false);
    setAmount(null)
  };

  const handleOpenFalse = () => {
    setOpenFalseModal(true);
  };

  const handleCloseFalse = () => {
    setStatusActive(null);
    setOpenFalseModal(false);
    setAmount(null)
  };

  const handleOpenCreateLine = () => {
    setOpenCreateLine(true)
  };

  const handleCloseCreateLine = () => {
    setOpenCreateLine(false);
  };

  const handleOpenEditLine = (user) => {
    setEditLine(user);
    setOpenEditLine(true);
  };

  const handleCloseEditLine = () => {
    setOpenEditLine(false);
  };

  const handleCloseCreateLineNotification = () => {
    setOpenCreateNotification(false);
    setError400(null);
    status === 400 && setRenderControl(!renderControl);
    setStatus(null);
  };

  return (
    <>
      <ModuleTemplate>
        <div name="options">
          <ButtonOption
            icon={addIcon}
            label="Nuevo"
            onClick={handleOpenCreateLine}
          />
        </div>
        {selectOptions?.length > 0 &&
          <ToolboxTable name="Tollbox" >
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={handleOpenDelete}
            />
            <ButtonOption
              icon={check}
              label="Activar"
              onClick={handleOpenTrue}
            />
            <ButtonOption
              icon={xIcon}
              label="Desactivar"
              onClick={handleOpenFalse}
            />
          </ToolboxTable>
        }
        <ServicesLinesTable
          name="Table"
          newLineRender={newLineRender}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          renderControl={renderControl}
          handleEditStatus={handleEditStatus}
          childData={childData}
          handleSelected={handleOpenEditLine}
        />
      </ModuleTemplate>

      <Modal
        isOpen={openCreateNotification}
        onClose={handleCloseCreateLineNotification}
        variant="large"
      >
        <ModalContent
          close={handleCloseCreateLineNotification}
          text={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? "Línea guardada\ncon éxito"
              : createStatus === 400
              ? error400
              : status === 400
              ? error400
              : "No se pudo completar la acción,\ninténtalo de nuevo"
          }
          icon={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? personGreen
              : error400
              ? redFlag
              : personRed
          }
          btns={false}
          alt={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? "Icono línea guardado"
              : "Icono línea no guardado"
          }
        />
      </Modal>


      <Modal
        isOpen={
          openDeleteModal ||
          openTrueModal ||
          openFalseModal
        }
        onClose={
          openDeleteModal ? handleCloseDelete :
          openTrueModal ? handleCloseTrue :
          openFalseModal ? handleCloseFalse
          : null
        }
        variant="large"
      >
        {isLoading ?
          <Spinner className_container="rolesForm" />
          :
            openDeleteModal ?
            <ModalContent
              close={handleCloseDelete}
              confirm={() => handleDelete(user.email)}
              text={
                statusDelete === 200
                  ? `${amount > 1 ? 'Líneas borradas' : 'Línea borrada'}`
                  : statusDelete === null
                  ? `Estás a punto de borrar\n${selectOptions.length} ${selectOptions.length > 1 ? 'líneas' : 'línea'}`
                  : error400
              }
              updateTitleCustom={styles.updateTitleCustom}
              helpText={statusDelete === null && "¿Deseas continuar?"}
              helpTextCustom={styles.helpTextCustom}
              btns={statusDelete === null && true}
              btnsContainerCustom={styles.btnsContainerCustom}
              cancelButtonCustom={styles.cancelButtonCustom}
              nextButtonCustom={styles.nextButtonCustom}
              icon={
                statusDelete === 200 || statusDelete === null
                  ? personGreen
                  : redFlag
              }
              alt="Icono de líneas"
            />
            :
            openTrueModal ?
            <ModalContent
            close={handleCloseTrue}
            confirm={() => handleStatusTrue(true)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Líneas activadas' : 'Línea activada'}`
                : statusActive === null
                ? `Estás a punto de activar\n${selectOptions.length} ${selectOptions.length > 1 ? 'líneas' : 'línea'}`
                : "No es posible activar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de línea"
          />
          :
            openFalseModal ?
            <ModalContent
            close={handleCloseFalse}
            confirm={() => handleStatusTrue(false)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Líneas desactivadas' : 'Línea desactivada'}`
                : statusActive === null
                ? `Estás a punto de desactivar\n${selectOptions.length} ${selectOptions.length > 1 ? 'líneas' : 'línea'}`
                : "No es posible desactivar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de línea"
          />
          : null
        }
      </Modal>

      <Modal
        isOpen={
          openCreateLine ||
          openEditLine
        }
        onClose={
          openCreateLine ? handleCloseCreateLine :
          openEditLine ? handleCloseEditLine :
          null
        }
        variant="large"
      >
        {isLoading ?
          <Spinner className_container="rolesForm" />
          :
          openCreateLine ?
        <AddNewLine
        edit={false}
        onClose={handleCloseCreateLine}
        setStatus={setStatus}
        setIsLoading={setIsLoading}
        setOpenCreateNotification={setOpenCreateNotification}
        setError400={setError400}
        setNewLineRender={setNewLineRender}
        newLineRender={newLineRender}
        />
        : openEditLine ?
        <EditLine
          edit={true}
          lineData={editLine}
          onClose={handleCloseEditLine}
          setStatus={setStatus}
          setIsLoading={setIsLoading}
          setOpenCreateNotification={setOpenCreateNotification}
          setError400={setError400}
          setNewLineRender={setNewLineRender}
          newLineRender={newLineRender}
        />
        : null
        }
      </Modal>
    </>
  );
}

export default withAuth(ServiceLinesAdmin, '/lineas')