import PropTypes from 'prop-types';
import TextInputTable from "../../atoms/TextInputTable";

export default function FilterInput(props) {
  const { column, handleGetFilter, filterValue } = props;

  const handleFilterChange = (e) => {
    column.setFilter(e.target.value || undefined);
    handleGetFilter(e.target.value)
  };

  return (
    <TextInputTable
      callback={handleFilterChange}
      placeholder={""}
      value={filterValue}
    />
  );
}

FilterInput.propTypes = {
  column: PropTypes.object.isRequired,
};