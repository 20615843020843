import { useContext, useState } from "react";

import Spinner from "../../atoms/Spinner";
import { ModalStepper } from "../ModalStepper";
import { ModalStep } from "../../atoms/ModalStep";
import ImportDataWarning from "../../atoms/UpdateDataWarning";
import UserUpdateConfirmation from "../../atoms/UserUpdateConfirmation";
import { UserBasicInfo } from "../../molecules/UserBasicInfo";
import { UserFormUpdate } from "../../molecules/UserFormUpdate";
import { updateData } from "../../services/getTracing";
import { AdminContext } from "../../context/AdminContext/AdminContext";

export const UserUpdateStepper = ({ onClose, updateInfo }) => {
  const { userInfo } = useContext(AdminContext);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [update, setUpdate] = useState(true);

  return (
    <>
      {update ? <UserBasicInfo setUpdate={setUpdate} />  :
      loading ? (
        <Spinner className_container="rolesForm" />
      ) : status !== null ? (
        <UserUpdateConfirmation status={status} />
      ) : (
        <ModalStepper
          initialValues={{
            email: userInfo?.patient?.email ? userInfo?.patient?.email : "",
            mobile: userInfo?.patient?.mobile ? userInfo?.patient?.mobile : "",
            phone: userInfo?.patient?.phone ? userInfo?.patient?.phone : "",
          }}
          onSubmit={async (values) => {
            setLoading(true);
            const schemaCreate = {
              id_patient: userInfo.patient.id_patient,
              email: values.values.email,
              mobile: values.values.mobile,
              phone: values.values.phone,

            };
            const data_1 = await updateData(schemaCreate);
            setStatus(data_1.statusCode);
            setLoading(false);
            updateInfo();
            return data_1;
          }}
          onClose={onClose}
        >
          <ModalStep
            textButtonCancel="Atrás"
            textButtonNext="Siguiente"
          >
            <UserFormUpdate />
          </ModalStep>
          <ModalStep
          textButtonNext="Guardar"
          >
            <ImportDataWarning />
          </ModalStep>
        </ModalStepper>
      )}
    </>
  );
};
