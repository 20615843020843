import React, { useState, useEffect } from 'react';

export default function PillInputNew ({
  options,
  submodule,
  setIdSubmodule,
  index,
  changeSelected,
  setSubmodule,
  setValue,
  typeValue,
}) {
  const [selectedOptions, setSelectedOptions] = useState(submodule ? submodule : []);
  const [availableOptions, setAvailableOptions] = useState(options);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleSelect = (option) => {
    const selectedOption = availableOptions.find((item) => item.value === (typeValue === "string" ? option : parseInt(option)) );
    if (selectedOption) {
      const updatedSelectedOptions = [...selectedOptions, selectedOption];
      setSelectedOptions(updatedSelectedOptions);
      if (setSubmodule) {
        setSubmodule(updatedSelectedOptions)
      }
      if (setValue) {
        setValue(updatedSelectedOptions)
      }
      if(changeSelected) {
        changeSelected(index, updatedSelectedOptions)
      }


      const updatedAvailableOptions = availableOptions.filter(
        (item) => item.value !== (typeValue === "string" ? option : parseInt(option))
      );
      setAvailableOptions(updatedAvailableOptions);

      const updatedSelectedKeys = [...selectedKeys, selectedOption.value];
      setSelectedKeys(updatedSelectedKeys);
    }
  };

  const handleRemove = (option) => {
    const updatedSelectedOptions = selectedOptions.filter(
      (item) => item.value !== option.value
    );
    setSelectedOptions(updatedSelectedOptions);
    if (setSubmodule) {
      setSubmodule(updatedSelectedOptions)
    }
    if (setValue) {
      setValue(updatedSelectedOptions)
    }
    if(changeSelected) {
      changeSelected(index, updatedSelectedOptions)
    }


    setAvailableOptions([...availableOptions, option]);

    const updatedSelectedKeys = selectedKeys.filter(
      (key) => key !== option.value
    );
    setSelectedKeys(updatedSelectedKeys);

  };

  useEffect(() => {
    if (setIdSubmodule) {
      setIdSubmodule(selectedKeys);
    }
  }, [selectedKeys]);


  return (
    <div
      style={{
        position: "relative",
        height: "auto",
        display: "flex",
        color: "#788591",
        fontFamily: "Gilroy-Regular",
        fontSize: "14px",
        flexDirection: "row",
        flexWrap: "wrap",
        minHeight: "30px",
        maxHeight: "60px",
        border: "1px solid #5AC3F0",
        borderRadius: "11px",
        overflow: "auto",
      }}
    >
      <select
        value=""
        onChange={(e) => handleSelect(e.target.value)}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "white",
          position: "absolute",
          zIndex: "1",
          color: "#65717B",
          paddingLeft: "5px"
        }}
      >
        <option value="" disabled selected hidden>
          {selectedOptions.length === 0 ? "Seleccione" : ""}
        </option>
        {availableOptions?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {selectedOptions?.map((option) => (
        <span
          key={option.value}
          style={{
            background: "#F5F5F5",
            borderRadius: "4px",
            margin: "5px 10px",
            zIndex: "2",
            height: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 5px",
          }}
        >
          {option.label}
          <button
            type="button"
            onClick={() => handleRemove(option)}
            style={{
              marginLeft: '5px',
              border: "none",
              backgroundColor: "transparent",
              color: "rgb(90, 195, 240)",
              cursor: "pointer",
            }}
          >
            X
          </button>
        </span>
      ))}
    </div>
  );
};

