import React, { useState, useContext } from "react";

import withAuth  from '../../HOC/withAuth';
import ModuleTemplate from "../../templates/ModuleTemplate";
import Filters from "../Filters";
import TodayTable from "../../atoms/TodayTable";
import useSelect from "../../hooks/useSelect";
import ButtonOption from "../../atoms/ButtonOption";
import ToolboxTable from "../../atoms/ToolboxTable";
import Modal from "../../atoms/Modal";
import DeleteToday from "../../molecules/DeleteToday";
import { AppContext } from "../../context/AppContext/AppContext";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import styles from "./ForToday.module.scss";

function ForToday() {
  const {
    startDateParaHoy, setStartDateParaHoy,
    endDateParaHoy, setEndDateParaHoy,
    genereParaHoy, setGenereParaHoy,
    ageParaHoy, setAgeParaHoy,
    idOptionsParaHoy, setIdOptionsParaHoy
  } = useContext(FiltersContext);
  const [openDeleteToday, setOpenDeleteToday] = useState(false);
  const [tableRender, setTableRender] = useState(false);

  const { user } = useContext(AppContext);

  // *--- Select ---*
  const [childDataState, setChildDataState] = useState();
  function childData(e) {
    setChildDataState(e)
  }
  const propertyNameMap = {
    id_plan: 'id',
    name: 'patient',
    id_patient: 'id_patient',
  };
  const selectOptions = useSelect(childDataState, propertyNameMap);
  // *--- Select ---*

  const handleOpenDelete = () => {
    setOpenDeleteToday(true);
  };

  const handleCloseDeleteToday = () => {
    setOpenDeleteToday(false);
  };

  return (
    <>
      <ModuleTemplate>
        {selectOptions?.length > 0 &&
          <ToolboxTable name="Tollbox" >
            {[
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={handleOpenDelete}
            />
            ]}
          </ToolboxTable>
        }
        <Filters
          name="filters"
          module="paraHoy"
          startDate={startDateParaHoy}
          setStartDate={setStartDateParaHoy}
          endDate={endDateParaHoy}
          setEndDate={setEndDateParaHoy}
          genere={genereParaHoy}
          setGenere={setGenereParaHoy}
          ageFilter={ageParaHoy}
          setAgeFilter={setAgeParaHoy}
          idOptions={idOptionsParaHoy}
          setIdOptions={setIdOptionsParaHoy}
        />
        <TodayTable
          name="Table"
          childData={childData}
          tableRender={tableRender}
        />
      </ModuleTemplate>

      <Modal
          isOpen={openDeleteToday}
          onClose={handleCloseDeleteToday}
          variant="large"
          containerStyle={styles.containerStyle}
        >
          <DeleteToday
            onClose={handleCloseDeleteToday}
            tableRender={tableRender}
            setTableRender={setTableRender}
            deleteRegister={selectOptions?.length}
            selectOptions={selectOptions}
            user={user}
          />
        </Modal>
    </>
  );
}
export default withAuth(ForToday, '/desenlaces/parahoy')