import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import withAuth  from '../../HOC/withAuth';
import PatientDataTable from "../../atoms/PatientDataTable";
import { PatientDetailTable } from "../../molecules/PatientDetailTable";
import { AppContext } from "../../context/AppContext/AppContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { getPatientDetails } from "../../services/Contigo/patient";
import styles from './PatientDetail.module.scss';

 function PatientDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { setDiligenceInfo } = useContext(DesenlacesContext);
  const { modules, modulesMixer } = useContext(AppContext);

  const handlePatientDetail = async () => {
    const response = await getPatientDetails(params.idPatient);
    
    if(response.response[0]){
      setDiligenceInfo(response.response[0]);
      setLoading(false);
    } else {
      navigate('/error/404');
    }
  };

  useEffect(() => {
    handlePatientDetail();
    return () => setDiligenceInfo(null)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const changeRoute = async () => {
        await modulesMixer(modules, "/contigo/pacientes");
      };
      changeRoute();
    }, 100);
  }, []);

  
  return (
    <div className={styles.container}>
      <PatientDataTable styleType="patientsDetail"  updateInfo={handlePatientDetail}  />
      {!loading ? (
        <PatientDetailTable />
      ) : (
        null
      )}
    </div>
  );
}

export default withAuth(PatientDetail, '/contigo/pacientes')