import { useState, useEffect } from "react";
import { useField } from "formik";

import PillInputNew from "../../molecules/PillInputNew";
import { getSubModules } from "../../services/roles";
import { SubmoduleSelect } from "../SubmoduleSelect";
import ErrorMessage from "../../atoms/ErrorMessage";
import styles from "./SubmoduleList.module.scss";

export const SubmoduleList = ({ edit, key, item, index, changeSelected, listSubmodule, setIdSubmodule, setIdDelete, listKey, ...props }) => {
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const [submodule, setSubmodule] = useState();
  const [listSubmodules, setListSubmodules] = useState();

  const fetchSubmodules = async () => {
    const response = await getSubModules(item.value);
    setListSubmodules(
      response[0].map((module) => ({ key: module.id, value: module.label }))
    );
  };

  useEffect(() => {
    if(edit) {
    fetchSubmodules();
    }
  }, []);

  const modifiedArray = listSubmodules?.map(obj => {
    const { key, value, ...rest } = obj;
    return { id: key, label: value, ...rest };
  });

  const modifiedItemSubmodules = item.submodules?.map(obj => {
    const { id, ...rest } = obj;
    return { value: id, ...rest };
  });

  useEffect(() => {
    if(edit) {
      setValue(modifiedItemSubmodules)
    }
  }, []);

  const list = listSubmodule.map((element) => ({
    value: element.id,
    label: element.label,
  }));

  const list2 = modifiedArray?.map((element) => ({
    value: element.id,
    label: element.label,
  }));

  const missingSubmodules = list?.filter((element) => !field.value.some((sub) => sub.value === element.value));
  
  const missingSubmodules2 = list2?.filter((element) => !item.submodules.some((sub) => sub.id === element.value));

  return (
    <>
    { edit ?
      listSubmodule.length ? (
        <div className={styles.listContainer}>
          <label className={styles.title} htmlFor={item.label}> {item.label} </label>
          <div className={styles.pillinputContainer}>
            {modifiedArray !== undefined ?
          <PillInputNew
            options={missingSubmodules2}
            setSubmodule={setSubmodule}
            setValue={setValue}
            setIdSubmodule={setIdSubmodule}
            setIdDelete={setIdDelete}
            listKey={listKey}
            index={index}
            changeSelected={changeSelected}
            submodule={modifiedItemSubmodules}
          /> : null
            }

          {meta.touched && <ErrorMessage error={meta.error} meta={meta} containerCustom={styles.ErrorMessage}  />}
          </div>
        </div>
      ) : null
      :
      listSubmodule.length ? (
        <div className={styles.listContainer}>
          <p className={styles.title}> {item.label} </p>
          <div className={styles.pillinputContainer}>
          <PillInputNew
            options={missingSubmodules}
            setSubmodule={setSubmodule}
            setValue={setValue}
            setIdSubmodule={setIdSubmodule}
            setIdDelete={setIdDelete}
            listKey={listKey}
            index={index}
            changeSelected={changeSelected}
            submodule={field.value}
          />
          {meta.touched && <ErrorMessage error={meta.error} meta={meta} containerCustom={styles.ErrorMessage}  />}
          </div>
        </div>
      ) : null
    }
    </>
  );
};
