import WarningIcon from "../../assets/imgs/icons/warningIcon.svg";

import styles from "./UpdateDataWarning.module.scss";

const ImportDataWarning = () => {
  return (
    <section className={styles.warningContainer}>
    <img src={WarningIcon} alt="Icono de alerta" />
    <p className={styles.warningSubtitleBlue}>
      Atención,
    </p>
    <p className={styles.textContinue}>
      Estás a punto de actualizar los datos del paciente
      <br/>¿Deseas continuar?
    </p>
    </section>
  );
};

export default ImportDataWarning;
