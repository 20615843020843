import Banner from "../../atoms/Banner";
import FooterSocialNetwork from "../../atoms/FooterSocialNetwork";
import FooterText from "../../atoms/FooterText";
import styles from "./FooterDescription.module.scss";

export default function FooterDescription() {
  return (
    <>
      <footer className={styles.footerDescription}>
        <FooterText
          sub1="Todos los derechos reservados © Fundación Santa Fe de Bogotá 2021"
          sub2="Teléfono Bogotá: 6030303 el Resto del país 018000 8976 98"
        />
        <FooterSocialNetwork />
        <Banner />
      </footer>
    </>
  );
}
