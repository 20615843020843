import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import Modal from "../../atoms/Modal";

import SuccessIcon from "../../assets/imgs/icons/infoGreenIcon.svg";
import ErrorIcon from "../../assets/imgs/icons/DeleteIcon.svg";

import styles from "./ConfirmationMonitoring.module.scss";

export const ConfirmationMonitoring = () => {
  const { confirmResponse, setConfirmResponse, setIsConfirmation } = useContext(ContigoContext);
  const navigate = useNavigate();
  const response = confirmResponse?.tracks_object
    ? confirmResponse?.tracks_object
    : [];

  const correctInitialState = [];
  const errorPatients = [];

  for (const patient in response) {
    const planArray = response[patient].planArray;
    if (planArray.length) {
      for (const plan in planArray) {
        const status = planArray[plan].started;
        if (status) {
          correctInitialState.push({
            name: response[patient].patient_name,
            doc_number: response[patient].doc_number,
            message: planArray[plan].message,
          });
        } else {
          errorPatients.push({
            name: response[patient].patient_name,
            doc_number: response[patient].doc_number,
            message: planArray[plan].message,
          });
        }
      }
    } else {
      errorPatients.push({
        name: response[patient].patient_name,
        doc_number: response[patient].doc_number,
        message: "No existen planes para este paciente",
      });
    }
  }

  const responseMap = correctInitialState.map((item) => {
    return [item.name, item]
  });
  let dataMapArr = new Map(responseMap);
  let correctWithOutDuplicates = [...dataMapArr.values()]

  const handleRedirect = () => {
    setIsConfirmation(false);
    setConfirmResponse({});
    navigate(`/contigo/pacientes`);
  };

  useEffect(() => {
    return () => {
      setIsConfirmation(false);
      setConfirmResponse({});
    }
  }, [])

  return (

    <Modal
      isOpen={!!correctInitialState.length || !!errorPatients.length}
      onClose={handleRedirect}
      containerStyle={styles.modalContainer}
      variant="large"
    >
      <>
        {correctInitialState.length && (
          <section className={styles.successContainer}>
            <div className={styles.successHeader}>
              <img
                className={styles.icon}
                src={SuccessIcon}
                alt="Ícono de notificación"
              />
              <h5 className={styles.titleMessage}>¡Excelente!</h5>
            </div>
            <div className={styles.sentSuccesfully}>
              <span>
                Has iniciado un nuevo monitoreo para{" "}
                <strong>
                  {correctWithOutDuplicates.length} pacientes
                </strong>
                <br />
                Recuerda estar al tanto de su avance en la sección Pacientes y verificar
                <br />
                que hayan recibido su mensaje de bienvenida
              </span>
            </div>
          </section>
        )}
        {errorPatients.length && (
          <section className={styles.errorContainer}>
            <div className={styles.errorHeader}>
              <img
                className={styles.icon}
                src={ErrorIcon}
                alt="Ícono de notificación"
              />
              <h5 className={styles.titleMessage}>Inténtalo de nuevo</h5>
            </div>
            <div className={styles.sentError}>
              <span>
                No se pudo iniciar monitoreo para{" "}
                <strong>
                  {errorPatients.length} pacientes
                </strong>
              </span>
              <ul className={styles.listUsers}>
                {errorPatients.map((patient) => (
                  <li key={patient.doc_number}>
                    {patient?.name} - {patient?.doc_number} : {patient?.message}
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}
        <section className={styles.buttonContainer}>
          <button
            type="button"
            onClick={handleRedirect}
            className={styles.buttonRedirect}
          >
            Aceptar
          </button>
        </section>
      </>
    </Modal>
  );
};
