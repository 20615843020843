import React from "react";

import greenStethoscope from "../../assets/imgs/green_stethoscope.svg";
import redStethoscope from "../../assets/imgs/red_stethoscope.svg";

import styles from "./ResponseResult.module.scss";

export const ResponseResult = ({ type, title, subtitle, img }) => {
  const imgSrc = {
    error: redStethoscope,
    success: greenStethoscope,
  };

  const getImage = () => img ? img : imgSrc[type];

  return (
    <section className={styles.container}>
      <img src={getImage()} alt="Response result image" />
      <h2 className={styles.title}>{title}</h2>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </section>
  );
};
