import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./HelpLink.module.scss";

export const HelpLink = () => {
  const navigate = useNavigate();
  return (
    <button className={styles.helpText} onClick={() => navigate("/help")}>
      Necesito ayuda
    </button>
  );
};
