import React, { useState } from "react";
import { ManagerContext } from "./ManagerContext";

export const ManagerProvider = ({ children }) => {

  const [stats, setStats] = useState([]);
  const [location, setLocation] = useState("");
  // Pagination
  const [amountData, setAmountData] = useState(9);
  const [dateInfo, setDateInfo] = useState(null);
  const [dateInfoUntil, setDateInfoUntil] = useState(null);

  const [header, setHeader] = useState("");

  return (
    <ManagerContext.Provider
      value={{
        amountData, setAmountData,
        location, setLocation,
        header, setHeader,
        stats, setStats,
        dateInfo, setDateInfo,
        dateInfoUntil, setDateInfoUntil,
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
};
