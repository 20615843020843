import React, { useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import {useSort} from '../../hooks/useSort';
import { Pagination } from "../../atoms/Pagination";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import { AppContext } from "../../context/AppContext/AppContext";
import statusChecked from "../../assets/imgs/icons/statusChecked.svg";
import statusUnchecked from "../../assets/imgs/icons/statusUnchecked.svg";
import TableHeader from "../../atoms/TableHeader";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./PatientsDesenlacesTable.module.scss";
import { offsetUser } from "../../custom/offset";
import Table from "../../molecules/Table";

export default function PatientsDesenlacesTable() {
  const {
    generePacientes, setGenerePacientes,
    agePacientes, setAgePacientes,
    updatePacientes, setUpdatePacientes
  } = useContext(FiltersContext);
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();
  let timerService;

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  const {
    patientList,
    setPatientList,
    name_patient,
    setName_patient,
    document_patient,
    setDocument_patient,
    email_patient,
    setEmail_patient,
    sort_patient,
    setSort_patient,
    actualPage_patient,
    setActualPage_patient,
    pagination_patient,
    setCustomePagination_patient,
    setIdPatientDetail
  } = useContext(DesenlacesContext);
  const { setModulesSelect } = useContext(AppContext);

  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setName_patient}
            filterValue={name_patient}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p className={styles.rowContainer} onClick={() => handleSelected(row.original)}>{row.original.name}</p>
        )
      },
      accessor: "name",
      width: 50,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Documento"
            column={column}
            handleGetFilter={setDocument_patient}
            filterValue={document_patient}
          />
        );
      },
      accessor: "doc_number",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Correo"
            column={column}
            handleGetFilter={setEmail_patient}
            filterValue={email_patient}
          />
        );
      },
      accessor: "email",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Teléfonos"
            column={column}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <div className={styles.phoneRow}>
            {
              row.original.mobile && row.original.phone === null ?
              <p className={styles.mobile}>{row.original.mobile} </p> :
              row.original.mobile === null && row.original.phone ?
              <p className={styles.mobile}>{row.original.phone} </p> :
              row.original.mobile && row.original.phone ?
              <p className={styles.mobile}>{row.original.mobile}{", "}{row.original.phone}</p> :
              <p className={styles.mobile}></p>
            }
          </div>
        );
      },
      accessor: "mobile" && "phone",
      width: 15,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Al día"
            column={column}
          />
        );
      },
      Cell: ({ row }) => (
        <img
          src={row.original.status === true ? statusChecked : statusUnchecked}
          alt="check"
        />
      ),
      accessor: "status",
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: 'center',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const handleSelected = async (selected) => {
    setModulesSelect('desenlaces')
    await setIdPatientDetail(selected.id_patient);
    navigate(`/desenlaces/pacientes/detalle/${selected.id_patient}`);
  };

  const handleGetPatientsList = async () => {
    let params = {
      page: actualPage_patient,
      offset: offsetUser(height, width),
      name: name_patient,
      doc_number: document_patient,
      genre: generePacientes,
      legal_age: agePacientes,
      updated: updatePacientes,
      email: email_patient,
      sort: sort_patient,
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/desenlaces/patients?${route}`,});
    const lastPage = res.pagination.last_page;
    const actualPage_1 = res.pagination.page;
    setPatientList(res.data);
    setCustomePagination_patient({ page: actualPage_1, paginationTotal: lastPage });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetPatientsList();
  }, [actualPage_patient, sort_patient, control, generePacientes, agePacientes, updatePacientes]);

  // custom hook sort
  useSort(sort_patient, setSort_patient)

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [email_patient, document_patient, name_patient]);

  useEffect(() => {
    if (email_patient.length || document_patient.length || name_patient.length > 0) {
      setActualPage_patient(1);
    }
  }, [email_patient, document_patient, name_patient]);

  const reloadInfoTable = () => {
    timerService = !timerService && setInterval(() => {
      handleGetPatientsList();
    },900000)
  }

  useEffect (()=> {
    reloadInfoTable();
    return () =>   clearInterval(timerService)
  },[]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={patientList}
        offsetUser={offsetUser}
        tooltipText="Haz click en la fila correspondiente para ver el detalle del paciente."
      />
      <Pagination
        actualPage={actualPage_patient}
        setPage={setActualPage_patient}
        infoPage={pagination_patient}
        className={styles.pagination}
      />
    </>
  );
}
