import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const getHistoricalList = async (
  offset,
  page,
  isFinished,
  filters = ""
) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/plans/history?offset=${offset}&page=${page}&is_finished=${isFinished}${filters}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
