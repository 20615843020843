import { useState } from 'react';

import { TracingContext } from './TracingContext';

export const TracingProvider =({ children }) => {

  const [selectTracingGroup, setSelectTracingGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [patientsList, setPatientsList] = useState([]);
  const [name_tracing, setName_tracing] = useState("");
  const [document_tracing, setDocument_tracing] = useState("");
  const [pagination, setCustomePagination] = useState({
    page: 1,
    numberOfPages: 1,
  });

  const [sort_tracing, setSort_tracing] = useState("");
  const [diligenceInfo, setDiligenceInfo] = useState(null);
  const [scale_tracing, setScale_tracing] = useState("");
  const [event_tracing, setEvent_tracing] = useState("");

  return(
    <TracingContext.Provider
      value={{
        selectTracingGroup, setSelectTracingGroup,
        isLoading, setIsLoading,
        actualPage, setActualPage,
        patientsList, setPatientsList,
        name_tracing, setName_tracing,
        document_tracing, setDocument_tracing,
        pagination, setCustomePagination,
        sort_tracing, setSort_tracing,
        diligenceInfo, setDiligenceInfo,
        scale_tracing, setScale_tracing,
        event_tracing, setEvent_tracing,
      }}
    >
      {children}
    </TracingContext.Provider>
  )
}