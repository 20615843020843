import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage} from "formik";
import CircleIcon from '@mui/icons-material/Circle';

import Spinner from "../../atoms/Spinner";
import NextButton from "../../atoms/NextButton";
import DeleteWarning from "../../atoms/DeleteWarning";
import CancelButton from "../../atoms/CancelButton";
import DeleteEventsConfirmation from "../../atoms/DeleteEventsConfirmation";
import { getTypeParameter } from "../../services/common/admin";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

import icon from "../../assets/imgs/signo-de-exclamacion.png";
import styles from "./DeleteToday.module.scss";


const validateSchema = Yup.object({
  reason: Yup.string().min(1, "Debe seleccionar una opción").required("Este campo es requerido")
});

const DeleteToday = ({ onClose, deleteRegister, selectOptions, user, setTableRender, tableRender }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState()
  const [reasons, setReasons] = useState()

  useEffect(() => {
    const getReasons = async () => {
      const response = await getTypeParameter(7);
      setReasons(response.data);
    };
    getReasons()
  },[])

  return (
    <FormikStepper
      onClose={onClose}
      isLoading={isLoading}
      initialValues={{
        reason: "",
      }}
      onSubmit={async(values) => {
        setIsLoading(true)
        const schemaCreate = {
          plans_ids: selectOptions
          .filter((datos) => datos?.id_plan)
          .map((datos) => datos?.id_plan || ""),
          delete_remark_id: parseInt(values.reason)
        };
        const data = await apiInterceptor({
          method: "DELETE",
          endpoint: `/tr/desenlaces/deletePlans`,
          data: schemaCreate,
        });
        if(data.statusCode === 200) {
          setTableRender(!tableRender);
        }
        setStatus(data.statusCode);
      }}
    >
      <FormikStep>
        <DeleteWarning deleteRegister={deleteRegister}/>
      </FormikStep>
      <FormikStep validationSchema={validateSchema} >
      {isLoading ? <Spinner/> : 
        <>
          <h2 className={styles.updateTitle}>Observaciones</h2>
          <div className={styles.inputContainer}>

            <div className={styles.inputAndError}>
            <Field
              className={styles.loginInputs}
              as="select"
              id="reason"
              name="reason"
            >
              <option value="" className={styles.documentOptions}>
              Elige el motivo por el cual eliminarás el registro
              </option>
              {reasons?.map((option) => (
                <option
                  value={option.id}
                  className={styles.documentOptions}
                >
                  {option.value}
                </option>
              ))}
            </Field>
            </div>
          </div>
        </>
      }
      </FormikStep>
      <FormikStep>
        <DeleteEventsConfirmation 
          status={status}
          deleteRegister={true}
        />
      </FormikStep>
    </FormikStepper>
  );
};

export default DeleteToday;

function FormikStep({ children }) {
  return <> {children}</>;
}

function FormikStepper({ onClose, isLoading, children, ...props }) {
  const location = useLocation();
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);

  const currentChild = childrenArray[step];

  const isLastStep = () => {
    return step === 1;
  };

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep((s) => s + 1);
        } else {
          setStep((s) => s + 1);
        }
      }}
      validationSchema={
        currentChild?.props?.validationSchema
      }
    >
    {({ errors }) => (
      <Form autoComplete="off" className={styles.formContainer}>
        <section>
        {currentChild}
        {step === 1 &&
          <ErrorMessage
            name="reason"
            component={() => (
              <div className={styles.errorMessage}>
                <img
                  src={icon}
                  alt="Icono de exclamación"
                  className={styles.exclamationIcon}
                />
                {errors?.reason}
              </div>
            )}
          />
        }
        </section>
        <section className={styles.stepperContainer}>
          <div className={isLoading ? styles.loading : styles.pointsStep}>
            {step === 1 ?
              <>
                <span className={styles.circleContainer}>
                  <CircleIcon sx={{fontSize: "6px"}}/>
                </span>
                <span className={styles.circleContainer}>
                  <CircleIcon sx={{fontSize: "12px"}}/>
                </span>
              </>
              : step === 0 ?
              <>
                <span className={styles.circleContainer}>
                  <CircleIcon sx={{fontSize: "12px"}}/>
                </span>
                <span className={styles.circleContainer}>
                  <CircleIcon sx={{fontSize: "6px"}}/>
                </span>
              </>
              : null
            }
          </div>
          <div className={isLoading ? styles.loading : styles.buttonOption}>
            {step < 2 && (
              <>
                <CancelButton
                  text={step === 1 ? "Cancelar" : "No"}
                  onClick={step === 1 ? () => step > 0 && setStep((s) => s - 1) : () => onClose()}
                  type="button"
                  tracing={location.pathname === "/desenlaces/eventos" ? "tracing" : null}
                  className={styles.buttonBase}
                />

                <NextButton
                  text={step === 1 ? "Continuar" : "Si"}
                  type="submit"
                  tracing={location.pathname === "/desenlaces/eventos" ? "tracing" : null}
                  className={styles.buttonBase}
                />
              </>
            )}
          </div>          
        </section>

      </Form>
    )}
    </Formik>
  );
}
