import React, { useContext } from "react";

import Input from "../../atoms/Input";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import styles from "./UserFormUpdate.module.scss";

export const UserFormUpdate = () => {
  const { userInfo } = useContext(AdminContext);

  return (
    <div className={styles.formContainer}>
       <h2 className={styles.patientName}>{userInfo.patient.name}</h2>
      <p className={styles.updateTitle}>Actualización de datos</p>

      <div className={styles.inputContainer}>
       <div className={styles.inputWidthLarge}>
          <Input label="Correo electrónico" name="email" type="text" />
        </div>
        <div className={styles.inputWidthSmall}>
          <Input label="Fijo" name="phone" type="text" />
        </div>
        <div className={styles.inputWidthSmall}>
          <Input label="Celular" name="mobile" type="text" />
        </div>
      </div>
    </div>
  );
};
