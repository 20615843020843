import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

import withAuth from "../../HOC/withAuth";
import CardFSFB from "../../atoms/Card";
import Spinner from "../../atoms/Spinner";
import NextButton from "../../atoms/NextButton";
import QuestionOptionCard from "../../molecules/QuestionOptionCard";
import { AccordeonPreview } from "../../molecules/AccordeonPreview";
import { getLineByModules } from "../../services/servicesLine";
import { AppContext } from "../../context/AppContext/AppContext";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import startIcon from "../../assets/imgs/icons/startCircleIcon.svg";
import bibliography from "../../assets/imgs/icons/bibliography.svg";
import TimeIcon from "../../assets/imgs/icons/time.svg";
import EyeIcon from "../../assets/imgs/icons/closeEye.svg";
import styles from "./ScalePreview.module.scss";

const ScalePreview = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [lines, setLines] = useState([]);
  const { modules } = useContext(AppContext);
  const { serviceLine, setServiceLine } = useContext(AdminContext);
  const { previewScale, setPreviewScale, setEditScale, frecuencyOptions } =
    useContext(ScalesContext);
  const image = previewScale?.image ? previewScale?.image : null;

  const questionSort = (a, b) => {
    return a.order - b.order;
  };

  const getInitialData = async (id) => {
    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/forms/${id}`,
    });

    if (response?.data?.[0]) {
      const data = await response?.data?.[0];

      await setPreviewScale({
        ...data,
      });
    } else if (response?.status === 400) {
      navigate("/error/404");
    }
  };

  const getListLines = async (ids) => {
    const response = await getLineByModules(ids);
    const list = response.response;
    const newList = [];
    for (let opt of list) {
      newList.push(opt.serviceLines);
    }
    const options = newList.flat(1);
    const lineOptions = options.map((option) => ({
      key: option.id,
      value: option.name,
    }));

    setServiceLine(lineOptions);
  };

  const fetchData = async () => {
    const initialModules = [];

    const modulesSelect = previewScale?.modules;

    for (let opt of modulesSelect) {
      const optionModule = modules?.find((mod) => mod.id === opt);
      initialModules.push({
        key: optionModule?.id,
        value: optionModule?.label,
      });
    }

    const initialLineServices = [];

    const linesSelect = previewScale?.service_lines;
    for (let service of linesSelect) {
      const optionLine = serviceLine.find((s) => s.key === service.id);

      const frecuency = service?.frequency_days?.split(",") || [];
      const arrayFrecuency = [];

      for (let opt in frecuency) {
        const findOpt = frecuencyOptions.find(
          (item) => item.key === frecuency[opt]
        );
        if (findOpt) {
          arrayFrecuency.push(findOpt);
        } else {
          arrayFrecuency.push({
            value: `${frecuency[opt]} días`,
            key: frecuency[opt],
          });
        }
      }
      await initialLineServices.push({
        line: optionLine,
        frequency_days: arrayFrecuency,
      });
    }

    await previewScale.schema.sort(questionSort);
    await setPreviewScale({
      name: previewScale?.name ? previewScale.name : "",
      description: previewScale?.description ? previewScale?.description : "",
      time_complete: previewScale?.time_complete
        ? previewScale?.time_complete
        : "",
      age_range: previewScale?.age_range ? previewScale?.age_range : "",
      bibliografy: previewScale?.bibliografy ? previewScale?.bibliografy : "",
      service_lines: initialLineServices ? initialLineServices : [],
      image: image,
      modules: initialModules,
      send_to_patient: previewScale?.send_to_patient,
      threshold: previewScale?.threshold ? previewScale?.threshold : "",
      isDesenlacesSelect: false,
      isContigoSelect: false,
      schema: previewScale?.schema ? previewScale?.schema : [],
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const getEditData = async () => {
    const copyModules = previewScale.modules;
    let modules = [];

    for (let m of copyModules) {
      modules.push(m.key);
    }

    const copyServiceLines = previewScale?.service_lines;
    const serviceLines = [];
    for (let opt of copyServiceLines) {
      serviceLines.push({
        id: opt?.line?.key,
        frequency_days: opt?.frequency_days?.map((day) => day.key).toString(),
      });
    }

    const data = {
      ...previewScale,
      modules: modules,
      service_lines: serviceLines,
    };

    await setEditScale(data);
    navigate(`/formularios/${params.idScale}`);
  };

  useEffect(() => {
    if (Object.keys(previewScale).length) {
      setLoading(false);
    } else {
      getInitialData(params.idScale);
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      await getListLines("100, 101");
    };
    getData();

    return () => {
      setPreviewScale({});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(previewScale).length) {
      fetchData();
    }
    const listLines = previewScale?.service_lines || [];

    const linesPreview = [];
    for (let opt of listLines) {
      linesPreview.push({
        id: opt?.line?.key,
        name: opt?.line?.value,
      });
    }
    setLines(linesPreview);
  }, [previewScale]);

  return (
    <>
      {loading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <>
          <div className={styles.scalePreviewContainer}>
            <button
              className={styles.viewScale}
              type="button"
              onClick={async () => {
                await getEditData();
              }}
            >
              <img src={EyeIcon} alt="Icono de visualizar" />
              Cerrar
            </button>
            {previewScale.image ? (
              <CardFSFB>
                <div className={styles.optionContainer}>
                  <div className={styles.imageScale}>
                    <img src={previewScale.image} alt="Imagen o Logo" />
                  </div>
                </div>
              </CardFSFB>
            ) : null}

            <section className={styles.titleContainer}>
              {previewScale?.name && (
                <div className={styles.optionContainer}>
                  <h3 className={styles.titleScale}> {previewScale?.name} </h3>
                </div>
              )}

              <div className={styles.timingOption}>
                <img src={TimeIcon} alt="Icono de titulo" />
                <p> {previewScale?.time_complete} minutos </p>
              </div>
            </section>

            <section>
              <ul className={styles.serviceLinesOpt}>
                {lines?.map((item, idx) => (
                  <li>
                    {item.name} <span>{idx < lines.length - 1 ? "-" : ""}</span>
                  </li>
                ))}
              </ul>
            </section>

            <section className={styles.containerDescription}>
              <img
                src={startIcon}
                alt="icono de descripción"
                style={{ width: "35px" }}
              ></img>
              <p className={styles.descriptionText}>
                {previewScale?.description}
              </p>
            </section>
            {previewScale?.bibliografy ? (
              <section className={styles.containerDescription}>
                <img
                  src={bibliography}
                  alt="icono bibliografía"
                  style={{ width: "35px" }}
                ></img>
                <p className={styles.descriptionText}>
                  {previewScale?.bibliografy}
                </p>
              </section>
            ) : null}

            <section className={styles.dinamicQuestions}>
              {previewScale?.schema.map((question, index) => (
                <>
                  {question.section ? (
                    <>
                      {!question?.section?.to_delete ? (
                        <AccordeonPreview data={question} key={`s-${index}`} />
                      ) : null}
                    </>
                  ) : (
                    <>
                      {!question?.question?.to_delete ? (
                        <QuestionOptionCard question={question.question} />
                      ) : null}
                    </>
                  )}
                </>
              ))}
            </section>

            <div className={styles.buttonOption}>
              <NextButton
                text="Atras"
                type="button"
                onClick={async () => {
                  await getEditData();
                }}
                className={styles.buttonSend}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withAuth(ScalePreview, "/formularios");
