import { useState, useEffect } from "react";

import { DesenlacesContext } from "./DesenlacesContext";

export const DesenlacesProvider = ({ children }) => {
  const [scale_tracing, setScale_tracing] = useState("");
  const [email_tracing, setEmail_tracing] = useState("");

  const [started, setStarted] = useState();

  const [nameSort_tracing, setNameSort_tracing] = useState("name:asc");
  const [lineSort_tracing, setLineSort_tracing] = useState("scale:asc");
  const [eventSort_tracing, setEventSort_tracing] =
    useState("event_number:asc");
  const [emailSort_tracing, setEmailSort_tracing] = useState("email:asc");

  const [editedFinishScale, setEditedFinishScale] = useState(() => {
    const storedCount = localStorage.getItem("editedFinishScale");
    return storedCount ? JSON.parse(storedCount) : false;
  });

  const [name_today, setName_today] = useState("");
  const [scale_today, setScale_today] = useState("");
  const [line_today, setLine_today] = useState("");
  const [document_today, setDocument_today] = useState("");
  const [frequency_today, setFrequency_today] = useState("");
  const [sort_today, setSort_today] = useState("");
  const [nameSort_today, setNameSort_today] = useState("name:asc");
  const [eventSort_today, setEventSort_today] = useState("event_number:asc");
  const [lineSort_today, setLineSort_today] = useState("service_line:asc");
  const [scaleSort_today, setScaleSort_today] = useState("scale:asc");
  const [todayList, setTodayList] = useState([]);
  const [actualPage_today, setActualPage_today] = useState(1);
  const [pagination_today, setCustomePagination_today] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [id, setId] = useState();

  const [scale, setScale] = useState("");
  const [name, setName] = useState("");
  const [line, setLine] = useState("");
  const [document, setDocument] = useState("");
  const [frequency, setFrequency] = useState("");
  const [sort_historical, setSort_historical] = useState("");
  const [nameSort_historical, setNameSort_historical] = useState("name:asc");
  const [eventSort_historical, setEventSort_historical] =
    useState("event_number:asc");
  const [lineSort_historical, setLineSort_historical] =
    useState("service_line:asc");
  const [scaleSort_historical, setScaleSort_historical] = useState("scale:asc");
  const [historicalList, setHistoricalList] = useState([]);
  // Pagination Historical Table (draft)
  const [actualPage_his, setActualPage_his] = useState(1);
  const [pagination_his, setCustomePagination_his] = useState({
    page: 1,
    numberOfPages: 1,
  });
  // Pagination Historical Table (finalized)
  const [actualPage_hisFin, setActualPage_hisFin] = useState(1);
  const [pagination_hisFin, setCustomePagination_hisFin] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [isFinished, setIsFinished] = useState(true);

  const [line_total, setLine_total] = useState("");
  const [sort_total, setSort_total] = useState("");
  const [lineSort_total, setLineSort_total] = useState("service_line:asc");
  const [scaleSort_total, setScaleSort_total] = useState("scales_amount:asc");
  const [totalList, setTotalList] = useState([]);
  const [actualPage_Report, setActualPage_report] = useState(1);
  const [pagination_report, setCustomePagination_report] = useState({
    page: 1,
    numberOfPages: 1,
  });

  const [patientList, setPatientList] = useState([]);
  const [name_patient, setName_patient] = useState("");
  const [document_patient, setDocument_patient] = useState("");
  const [email_patient, setEmail_patient] = useState("");
  const [sort_patient, setSort_patient] = useState("");
  const [nameSort_patient, setNameSort_patient] = useState("patient_name:asc");
  const [documentSort_patient, setDocumentSort_patient] =
    useState("doc_number:asc");
  const [actualPage_patient, setActualPage_patient] = useState(1);
  const [pagination_patient, setCustomePagination_patient] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [idPatientDetail, setIdPatientDetail] = useState(() => {
    const storedCount = localStorage.getItem("idPatientDetail");
    return storedCount ? JSON.parse(storedCount) : null;
  });
  const [patientsInfo, setPatientsInfo] = useState(null);
  const [diligenceInfo, setDiligenceInfo] = useState(null);
  const [idScaleSelected, setIdScaleSelected] = useState(null);


  useEffect(() => {
    localStorage.setItem(
      "editedFinishScale",
      JSON.stringify(editedFinishScale)
    );
  }, [editedFinishScale]);
  useEffect(() => {
    localStorage.setItem("idPatientDetail", JSON.stringify(idPatientDetail));
  }, [idPatientDetail]);

  return (
    <DesenlacesContext.Provider
      value={{
        scale_tracing,
        setScale_tracing,
        email_tracing,
        setEmail_tracing,
        started,
        setStarted,
        nameSort_tracing,
        setNameSort_tracing,
        lineSort_tracing,
        setLineSort_tracing,
        eventSort_tracing,
        setEventSort_tracing,
        emailSort_tracing,
        setEmailSort_tracing,
        name_today,
        setName_today,
        scale_today,
        setScale_today,
        line_today,
        setLine_today,
        document_today,
        setDocument_today,
        frequency_today,
        setFrequency_today,
        sort_today,
        setSort_today,
        nameSort_today,
        setNameSort_today,
        eventSort_today,
        setEventSort_today,
        lineSort_today,
        setLineSort_today,
        scaleSort_today,
        setScaleSort_today,
        todayList,
        setTodayList,
        actualPage_today,
        setActualPage_today,
        pagination_today,
        setCustomePagination_today,
        scale,
        setScale,
        name,
        setName,
        document,
        setDocument,
        line,
        setLine,
        frequency,
        setFrequency,
        sort_historical,
        setSort_historical,
        nameSort_historical,
        setNameSort_historical,
        eventSort_historical,
        setEventSort_historical,
        lineSort_historical,
        setLineSort_historical,
        scaleSort_historical,
        setScaleSort_historical,
        actualPage_his,
        setActualPage_his,
        pagination_his,
        setCustomePagination_his,
        actualPage_hisFin,
        setActualPage_hisFin,
        pagination_hisFin,
        setCustomePagination_hisFin,
        historicalList,
        setHistoricalList,
        line_total,
        setLine_total,
        sort_total,
        setSort_total,
        lineSort_total,
        setLineSort_total,
        scaleSort_total,
        setScaleSort_total,
        totalList,
        setTotalList,
        actualPage_Report,
        setActualPage_report,
        pagination_report,
        setCustomePagination_report,
        id,
        setId,
        patientList,
        setPatientList,
        name_patient,
        setName_patient,
        document_patient,
        setDocument_patient,
        email_patient,
        setEmail_patient,
        sort_patient,
        setSort_patient,
        nameSort_patient,
        setNameSort_patient,
        documentSort_patient,
        setDocumentSort_patient,
        actualPage_patient,
        setActualPage_patient,
        pagination_patient,
        setCustomePagination_patient,
        idPatientDetail,
        setIdPatientDetail,
        patientsInfo,
        setPatientsInfo,
        editedFinishScale,
        setEditedFinishScale,
        isFinished,
        setIsFinished,
        diligenceInfo,
        setDiligenceInfo,
        idScaleSelected, setIdScaleSelected,
      }}
    >
      {children}
    </DesenlacesContext.Provider>
  );
};
