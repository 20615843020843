import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PatientsTable from "../../atoms/PatientsTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import ButtonOption from "../../atoms/ButtonOption";
import Filters from "../Filters";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import addIcon from "../../assets/imgs/icons/addIcon.svg";

import withAuth  from '../../HOC/withAuth';
 function Patients() {
  const {
    startDatePacientesContigo, setStartDatePacientesContigo,
    endDatePacientesContigo, setEndDatePacientesContigo,
    generePacientesContigo, setGenerePacientesContigo,
    agePacientesContigo, setAgePacientesContigo,
    updatePacientesContigo, setUpdatePacientesContigo,
    alertPacientesContigo, setAlertPacientesContigo
  } = useContext(FiltersContext);

  const navigate = useNavigate();


  return (
    <>
      <ModuleTemplate>
        <div
          name="options"
          style={{display: 'flex'}}
        >
          <ButtonOption
            icon={addIcon}
            label="Nuevo monitoreo"
            onClick={() => {
              navigate(`/contigo/pacientes/nuevoMonitoreo`);
            }}
          />
        </div>
        <Filters
          name="filters"
          module="pacientesContigo"
          startDate={startDatePacientesContigo}
          setStartDate={setStartDatePacientesContigo}
          endDate={endDatePacientesContigo}
          setEndDate={setEndDatePacientesContigo}
          genere={generePacientesContigo}
          setGenere={setGenerePacientesContigo}
          ageFilter={agePacientesContigo}
          setAgeFilter={setAgePacientesContigo}
          updateFilter={updatePacientesContigo}
          setUpdateFilter={setUpdatePacientesContigo}
          alertFilter={alertPacientesContigo}
          setAlertFilter={setAlertPacientesContigo}
        />
        <PatientsTable
          name="Table"
        />
      </ModuleTemplate>
    </>
  );
}

export default withAuth(Patients, '/contigo/pacientes')