import { useState } from 'react';

import { UsersContext } from './UsersContext';

export const UsersProvider =({ children }) => {

  const [actualPage, setActualPage] = useState(1);
  const [pagination, setCustomePagination] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [userList,setUserList] = useState([]);
  const [email_users, setEmail_users] = useState("")
  const [updateBy_users, setUpdateBy_users] = useState("")
  const [rol_users, setRol_users] = useState("")
  const [name_users, setName_users] = useState("")
  const [sort_users, setSort_users] = useState("")
  const [createStatus, setCreateStatus] = useState()
  const [editStatus, setEditStatus] = useState()
  const [nameSort_users, setNameSort_users] = useState("name:asc")
  const [emailSort_users, setEmailSort_users] = useState("email:asc")
  const [rolSort_users, setRolSort_users] = useState("rol:asc")
  const [diligenceInfo, setDiligenceInfo] = useState(null)

  return(
    <UsersContext.Provider
      value={{
        actualPage, setActualPage,
        pagination, setCustomePagination,
        userList,setUserList,
        email_users, setEmail_users,
        updateBy_users, setUpdateBy_users,
        rol_users, setRol_users,
        name_users, setName_users,
        sort_users, setSort_users,
        createStatus, setCreateStatus,
        editStatus, setEditStatus,
        nameSort_users, setNameSort_users,
        emailSort_users, setEmailSort_users,
        rolSort_users, setRolSort_users,
        diligenceInfo, setDiligenceInfo
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}