import React from 'react'
import agreeHabeasData from "../../assets/imgs/icons/agreeHabeasData.svg";
import disagreeHabeasData from "../../assets/imgs/icons/disagreeHabeasData.svg";
import styles from './HabeasData.module.scss'

export default function HabeasData({policyAcceptance}) {
  return (
    <div className={styles.dataProcessing}>
    Habeas data
    {
      policyAcceptance ? 
      <img src={agreeHabeasData} className={styles.habeasDataIcon} alt="Acepta Habeas Data" /> :
      <img src={disagreeHabeasData} className={styles.habeasDataIcon} alt="No Acepta Habeas Data" />
    }
  </div>
  )
}
