import React, { useState } from "react";
import * as Yup from "yup";

import Input from "../../atoms/Input";
import Spinner from "../../atoms/Spinner";
import ImportDataWarning from "../../atoms/UpdateDataWarning";
import UserUpdateConfirmation from "../../atoms/UserUpdateConfirmation";
import { ModalStepper } from "../ModalStepper";
import { ModalStep } from "../../atoms/ModalStep";
import styles from "./UpdateDataForm.module.scss";
import { updateData } from "../../services/getTracing";

const validateSchema = Yup.object({
  phone: Yup.string(),
  mobile: Yup.string(),
  email: Yup.string()
    .required("Este campo es requerido")
    .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, "Correo no válido"),
});

const UpdateDataForm = ({ data, updateInfo, onClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState();
  const [isNotification, setIsNotification] = useState(false);

  return (
    <>
      {isLoading ? (
        <Spinner className_container="rolesForm" />
      ) : isNotification ? (
        <UserUpdateConfirmation status={status} />
      ) : (
        <ModalStepper
          isLoading={isLoading}
          initialValues={{
            phone: data?.phone ? data?.phone : "",
            mobile: data?.mobile ? data?.mobile : "",
            email: data?.email ? data.email : "",
          }}
          onSubmit={async ({ values }) => {
            setIsLoading(true);
            const schemaCreate = {
              id_patient: data.id,
              email: values.email,
              mobile: values.mobile,
              phone: values.phone,
            };
            const data_1 = await updateData(schemaCreate);
            updateInfo && await updateInfo();
            setStatus(data_1.statusCode);
            setIsLoading(false);
            setIsNotification(true);
            return data_1;
          }}
          onClose={onClose}
        >
          <ModalStep validationSchema={validateSchema}   textButtonNext="Siguiente" onClose={onClose}>
            <div className={styles.formContainer}>
              <h2 className={styles.patientName}>
                {data?.name
                  ? data?.name
                  : data?.diligenceInfo?.patient_name
                  ? data?.diligenceInfo?.patient_name
                  : ""}
              </h2>
              <p className={styles.updateTitle}>Actualización de datos</p>

              <div className={styles.inputContainer}>
                <div className={styles.inputWidthSmall}>
                  <Input label="Fijo" name="phone" type="text" />
                </div>
                <div className={styles.inputWidthSmall}>
                  <Input label="Celular" name="mobile" type="text" />
                </div>
                <div className={styles.inputWidthLarge}>
                  <Input label="Correo electrónico" name="email" type="text" />
                </div>
              </div>
            </div>
          </ModalStep>
          <ModalStep textButtonNext="Guardar">
            <ImportDataWarning />
          </ModalStep>
        </ModalStepper>
      )}
    </>
  );
};

export default UpdateDataForm;
