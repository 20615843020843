import styles from "./TitleModules.module.scss";
import { Tooltip } from "../../atoms/Tooltip";
import tooltipIcon from "../../assets/imgs/icons/tooltip_blue.svg";

export const TitleModules = ({ icon, alt, title, tooltip }) => {
  return (
    <div className={styles.container}>
      <img src={icon} className={styles.icon} alt={alt} />
      <h2 className={styles.title}>{title} </h2>
      {tooltip ? (
        <Tooltip text={tooltip} img={tooltipIcon} />
      ) : null}
    </div>
  );
};
