import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import withAuth from "../../HOC/withAuth";

import ScaleAdminTable from "../../atoms/ScaleAdminTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import ButtonOption from "../../atoms/ButtonOption";
import ToolboxTable from "../../atoms/ToolboxTable";
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import ModalContent from "../../atoms/ModalContent";
import { ConfirmContent } from "../../atoms/ConfirmContent";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import { scaleDuplicator } from "../../services/scaleDuplicator";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { offsetUser } from "../../custom/offset";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import addIcon from "../../assets/imgs/icons/addIcon.svg";
import xIcon from "../../assets/imgs/icons/xIcon.svg";
import check from "../../assets/imgs/icons/check.svg";
import doubleIcon from "../../assets/imgs/icons/doubleIcon.svg";
import doubleIconGrey from "../../assets/imgs/icons/doubleIcon_grey.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";
import AlertIcon from "../../assets/imgs/icons/infoGreenIcon.svg";
import confirmationIcon from "../../assets/imgs/icons/infoGreenIcon.svg";

import styles from "./ScaleAdmin.module.scss";

function ScaleAdmin() {
  const navigate = useNavigate();
  const {
    setEditScale,
    setIsEditScale,
    selectGroup,
    setScalesList,
    line_scale,
    scale_scale,
    module_scale,
    sort,
    actualPage,
    setCustomePagination,
  } = useContext(ScalesContext);
  const { height, width } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [newUserRender, setNewUserRender] = useState(false);
  const [error400, setError400] = useState();
  const [isErrorDelete, setIsErrorDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDuplication, setOpenDuplication] = useState(null);
  const [isLoadConfirmation, setLoadConfirmation] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState();
  const [openActiveModal, setActiveModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [inactiveStatus, setInactiveStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [numForms, setNumForms] = useState(null);

  const handleNewScale = () => {
    setEditScale({});
    setIsEditScale(false);
    navigate("/formularios/nuevoFormulario");
  };

  // Eliminar formularios

  const getListScales = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort,
      scale: scale_scale,
      service_line: line_scale,
      modules: module_scale,
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );

    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/forms?${route}`,
    });

    setScalesList(response.data);
    setCustomePagination({
      page: response.pagination.page,
      paginationTotal: response.pagination.last_page,
    });
    setNewUserRender(false);
    setIsLoading(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    const data = await apiInterceptor({
      method: "DELETE",
      endpoint: "/tr/admin/forms",
      data: selectGroup.map((item) => item.id),
    });

    if (data.statusCode === 200) {
      setNewUserRender(true);
      setIsLoading(false);
      setIsDelete(true);
    } else if (data.statusCode === 400) {
      setIsLoading(false);
      setError400(data.data.data.response[0].message);
    } else {
      setIsLoading(false);
      setIsErrorDelete(true);
    }
  };

  const handleCloseDelete = () => {
    setIsDelete(false);
    setIsErrorDelete(false);
    setError400(null);
    setOpenDeleteModal(false);
  };

  // DuplicarFormulario

  const handleOpenDuplication = () => {
    setOpenDuplication(true);
  };

  const duplicationConfirmation = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const id = selectGroup[0].id;

    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/forms/${id}`,
    });

    if (response.statusCode === 200) {
      const payload = await scaleDuplicator(response.data[0]);

      const data = await apiInterceptor({
        method: "POST",
        endpoint: "/tr/admin/forms",
        data: payload,
      });

      if (data.statusCode === 201) {
        await getListScales();
        setIsLoading(false);
        setLoadConfirmation(true);
      } else {
        setError400(data.error);
      }
    } else if (response?.statusCode === 400) {
      setError400('error');
    }
  };

  const handleCloseDuplication = () => {
    setOpenDuplication(null);
    setLoadConfirmation(false);
    setNewUserRender(false);
  };

  // Editar Estatus

  const handleOpenTrue = async () => {
    await setActiveStatus(true);
    setInactiveStatus(false);
    setActiveModal(true);
  };

  const handleOpenFalse = async () => {
    await setInactiveStatus(true);
    setActiveStatus(false);
    setActiveModal(true);
  };

  const handleEditStatus = async () => {
    const status = activeStatus ? true : inactiveStatus ? false : false;
    setIsLoading(true);
    const data = await apiInterceptor({
      method: "PATCH",
      endpoint: `/tr/admin/forms/status?activate=${status}`,
      data: selectGroup.map((item) => item.id),
    });
    setStatus(status);
    setNumForms(selectGroup.length);

    if (data.statusCode === 200) {
      setIsLoading(false);
      setStatusUpdate(true);
      setActiveStatus(false);
      setInactiveStatus(false);
      setNewUserRender(true);
    } else {
      setIsLoading(false);
      setActiveStatus(false);
      setInactiveStatus(false);
      setNewUserRender(true);
      setError400(data.data.data.response[0].message);
      setIsErrorDelete(true);
    }
  };

  const handleClose = async () => {
    setActiveModal(false);
    setStatus(null);
    setNumForms(null);
  };

  return (
    <>
      <ModuleTemplate>
        <div className={styles.options} name="options">
          <ButtonOption icon={addIcon} label="Nuevo" onClick={handleNewScale} />
        </div>

        {selectGroup?.length && (
          <ToolboxTable name="Tollbox">
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={handleOpenDeleteModal}
            />
            <ButtonOption
              icon={selectGroup.length > 1 ? doubleIconGrey : doubleIcon}
              label="Duplicar"
              onClick={handleOpenDuplication}
              disabled={selectGroup.length > 1}
            />
            <ButtonOption
              icon={check}
              label="Activar"
              onClick={handleOpenTrue}
            />
            <ButtonOption
              icon={xIcon}
              label="Desactivar"
              onClick={handleOpenFalse}
            />
          </ToolboxTable>
        )}

        <ScaleAdminTable
          name="Table"
          newUserRender={newUserRender}
          setNewUserRender={setNewUserRender}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </ModuleTemplate>

      {/* delete_forms */}
      <Modal
        isOpen={openDeleteModal}
        onClose={handleCloseDelete}
        variant="large"
      >
        {isLoading ? (
          <Spinner className_container="rolesForm" />
        ) : (
          <ModalContent
            close={handleCloseDelete}
            confirm={() => handleDelete()}
            text={
              error400
                ? "No es posible borrar"
                : isDelete
                ? `${
                    selectGroup?.length > 1
                      ? "Formularios borrados"
                      : "Formulario borrado"
                  }`
                : isErrorDelete
                ? "No se pudo completar\nla acción, inténtalo de nuevo"
                : `Estás a punto de\neliminar ${selectGroup?.length}  ${
                    selectGroup?.length > 1 ? "formularios" : "formulario"
                  }`
            }
            helpText={
              error400
                ? error400
                : isDelete || isErrorDelete
                ? null
                : "¿Deseas continuar?"
            }
            btns={isDelete || isErrorDelete ? false : true}
            textCancelCustom={error400 ? "Aceptar" : null}
            cancelButtonCustom={error400 ? styles.cancelButtonCustom : null}
            nextButtonCustom={error400 ? styles.nextButtonCustom : null}
            icon={error400 ? redFlag : isDelete ? AlertIcon : personRed}
            alt="Icono eliminación de formulario"
          />
        )}
      </Modal>

      {/* Duplicar Formularios */}

      <Modal
        isOpen={openDuplication}
        onClose={handleCloseDuplication}
        variant="large"
      >
        {isLoading ? (
          <Spinner className_container="rolesForm" />
        ) : isLoadConfirmation ? (
          <ConfirmContent
            text={"Formulario duplicado"}
            icon={confirmationIcon}
            alt="Icono creación o edición de formulario exitoso"
          />
        ) : (
          <ModalContent
            close={handleCloseDuplication}
            btns={true}
            text={"¿Deseas duplicar\nel formulario?"}
            icon={AlertIcon}
            alt="confirmación duplicado"
            confirm={() => duplicationConfirmation()}
          />
        )}
      </Modal>

      {/* Activar - desactivar Formularios */}
      <Modal isOpen={openActiveModal} onClose={handleClose} variant="large">
        {isLoading ? (
          <Spinner className_container="rolesForm" />
        ) : (
          <ModalContent
            close={handleClose}
            text={
              activeStatus || inactiveStatus
                ? `Estás a punto de ${
                    activeStatus ? "activar" : "desactivar"
                  } ${selectGroup?.length} ${
                    selectGroup?.length > 1 ? "formularios" : "formulario"
                  }`
                : statusUpdate
                ? `Se  ${status ? "activaron" : "desactivaron"} ${numForms} ${
                    numForms > 1 ? "formularios" : "formulario"
                  } `
                : error400 &&
                  "No se pudo completar\nla acción, inténtalo de nuevo"
            }
            icon={error400 ? redFlag : isDelete ? AlertIcon : personRed}
            btns={activeStatus || inactiveStatus ? true : false}
            alt="Icono actualización de estado de formulario"
            helpText={
              error400 ? error400 : statusUpdate ? null : "¿Deseas continuar?"
            }
            confirm={handleEditStatus}
          />
        )}
      </Modal>
    </>
  );
}

export default withAuth(ScaleAdmin, "/formularios");
