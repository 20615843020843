import ageIconFilter from "../../assets/imgs/icons/filters/age.svg";
import dateIconFilter from "../../assets/imgs/icons/filters/date.svg";
import genreIconFilter from "../../assets/imgs/icons/filters/genre.svg";
import ageBlueIconFilter from "../../assets/imgs/icons/filters/ageBlue.svg";
import dateBlueIconFilter from "../../assets/imgs/icons/filters/dateBlue.svg";
import genreBlueIconFilter from "../../assets/imgs/icons/filters/genreBlue.svg";
import categoryIconFilter from "../../assets/imgs/icons/filters/category.svg";
import categoryBlueIconFilter from "../../assets/imgs/icons/filters/categoryBlue.svg";
import observation from "../../assets/imgs/icons/filters/observation.svg";
import observationBlue from "../../assets/imgs/icons/filters/observationBlue.svg";
import monitoring from "../../assets/imgs/icons/filters/monitoring.svg";
import monitoringBlue from "../../assets/imgs/icons/filters/monitoringBlue.svg";

export const allFilters = [
  {
    module: ['eventos', 'paraHoy', 'pacientesContigo', 'reporteContigo'],
    name: 'Fecha Desde',
    img: dateIconFilter,
    imgBlue: dateBlueIconFilter
  },
  {
    module: ['eventos', 'paraHoy', 'pacientesContigo', 'reporteContigo'],
    name: 'Fecha Hasta',
    img: dateIconFilter,
    imgBlue: dateBlueIconFilter
  },
  {
    module: ['eventos', 'paraHoy', 'pacientesDesenlaces', 'pacientesContigo', 'reporteContigo'],
    name: 'Género',
    img: genreIconFilter,
    imgBlue: genreBlueIconFilter
  },
  {
    module: ['eventos', 'paraHoy', 'pacientesDesenlaces', 'pacientesContigo', 'reporteContigo'],
    name: 'Edad',
    img: ageIconFilter,
    imgBlue: ageBlueIconFilter
  },
  {
    module: ['paraHoy'],
    name: 'Líneas de servicio',
    img: categoryIconFilter,
    imgBlue: categoryBlueIconFilter
  },
  {
    module: ['pacientesDesenlaces', 'pacientesContigo'],
    name: 'Al día',
    img: ageIconFilter,
    imgBlue: ageBlueIconFilter
  },
  {
    module: ['pacientesContigo'],
    name: 'Alerta',
    img: ageIconFilter,
    imgBlue: ageBlueIconFilter
  },
  {
    module: ['reporteContigo'],
    name: 'Observación',
    img: observation,
    imgBlue: observationBlue
  },
  {
    module: ['reporteContigo'],
    name: 'Tipo de monitoreo',
    img: monitoring,
    imgBlue: monitoringBlue
  }
];

export const genreOptions = [
  {
    value: "M",
    name: "Hombre",
  },
  {
    value: "F",
    name: "Mujer",
  },
];
export const ageOptions = [
  {
    value: "true",
    name: "Mayor de 18",
  },
  {
    value: "false",
    name: "Menor de 18",
  },
];
export const updateOptions = [
  {
    value: "true",
    name: "Si",
  },
  {
    value: "false",
    name: "No",
  },
];
export const alertOptions = [
  {
    value: "true",
    name: "Si",
  },
  {
    value: "false",
    name: "No",
  },
];
export const observationOptions = [
  {
    value: "true",
    name: "Si",
  },
  {
    value: "false",
    name: "No",
  },
];