export const PAST_PERIOD_OPTIONS = [
  { label: "Última semana", value: "lastWeek" },
  { label: "Últimos 15 días", value: "last2Weeks" },
  { label: "Último mes", value: "lastMonth" },
  { label: "Últimos tres meses", value: "last3Months" },
  { label: "Últimos seis meses", value: "last6Months" },
  { label: "Último año", value: "lastYear" },
];

export const FUTURE_PERIOD_OPTIONS = [
  { label: "Próxima semana", value: "nextWeek" },
  { label: "Próximos 15 días", value: "next2Weeks" },
  { label: "Próximo mes", value: "nextMonth" },
  { label: "Próximos tres meses", value: "next3Months" },
  { label: "Próximos seis meses", value: "next6Months" },
  { label: "Próximo año", value: "nextYear" },
];
