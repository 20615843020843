import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import ModalContent from "../../atoms/ModalContent";
import ScaleAdminStepper from "../../organisms/ScaleAdminStepper";
import ScaleAdminStep from "../../organisms/ScaleAdminStep";
import { BasicInfoStep } from "../../molecules/BasicInfoStep";
import { QuestionsStep } from "../../molecules/QuestionsStep";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { basicInfoSchema, questionsStepSchema } from "./CreateScaleSchema";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import infoGreenIcon from "../../assets/imgs/icons/infoGreenIcon.svg";
import infoRedIcon from "../../assets/imgs/icons/InfoIcon.svg";

export const AdminStepper = ({ initialValues }) => {
  const navigate = useNavigate();
  const { editScale, setIsEditScale, setScaleName } = useContext(ScalesContext);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [statusEdit, setStatusEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState(false);

  const handleClosePopup = () => {
    setOpenPopup(false);
    navigate("/formularios");
  };

  return (
    <>
      <ScaleAdminStepper
        initialValues={initialValues}
        onSubmit={async (values, { setErrors }) => {
          let validate = false;
          let questionErrors = [];

          let questionList = [];
          let copyQuestions = values.schema;

          for (let q of copyQuestions) {
            if (q.question) {
              if (q?.question?.to_delete && q?.question?.id) {
                questionList.push(q);
              } else if (!q?.question?.to_delete) {
                questionList.push(q);
              }
            } else if (q.section) {
              if (q.section.to_delete && q.section.idSection) {
                questionList.push(q);
              } else if (!q.section.to_delete) {
                questionList.push(q);
              }
            }
          }

          if (!questionList.length) {
            setErrors({ schema: "Agregue una pregunta" });
          } else {
            let createQuestion = [];
            for (let q of questionList) {
              if (q.question) {
                !q.question.hasOwnProperty("id") && createQuestion.push(q);
              } else if (q.section) {
                !q.section.hasOwnProperty("idSection") &&
                  createQuestion.push(q);
              }
            }

            if (!createQuestion.length) {
              setErrors({ schema: "Agregue una pregunta" });
            } else {
              for (let option of questionList) {
                let error = {
                  question: {},
                };

                if (option.question) {
                  if (!option.question.to_delete) {
                    !option.question.detail.length &&
                      (error.question.detail =
                        "Olvidaste diligenciar este campo");

                    if (
                      option.question.type === 2 ||
                      option.question.type === 6 ||
                      option.question.type === 1 ||
                      option.question.type === 3
                    ) {
                      let questionsOptions = option?.question?.options;
                      !questionsOptions.length &&
                        (error.question.options = "Debes Añadir 1 opción");

                      let questionFilter = questionsOptions?.filter(
                        (question) => !question?.detail.length
                      );

                      questionFilter?.length &&
                        (error.question.options =
                          "Olvidaste diligenciar alguna de las opciones");
                    }

                    if (option.question.type === 12) {
                      let ranges = option.question.ranges;
                      ranges.filter((r) => r.detail.length).length < 2 &&
                        (error.question.ranges = "Debes añadir los dos rangos");
                    }
                  }

                  Object.keys(error).length
                    ? questionErrors.push(error)
                    : questionErrors.push(undefined);
                } else if (option.section) {
                  let error = { section: {} };

                  !option.section.name.length &&
                    (error.section.name = "Olvidaste diligenciar este campo");

                  if (!option.section.to_delete) {
                    let questionsOptions = option.section.questions;

                    if (questionsOptions?.length) {
                      const errorQ = [];

                      for (let q of questionsOptions) {
                        let questions = {};
                        !q.detail.length &&
                          (questions.detail =
                            "Olvidaste diligenciar este campo");

                        if (
                          q.type === 2 ||
                          q.type === 6 ||
                          q.type === 1 ||
                          q.type === 3
                        ) {
                          let questionsOptions = q.options;
                          !questionsOptions.length &&
                            (questions.options = "Debes Añadir 1 opción");

                          let questionFilter = questionsOptions?.filter(
                            (question) => !question?.detail.length
                          );

                          questionFilter?.length &&
                            (questions.options =
                              "Olvidaste diligenciar alguna de las opciones");
                        }

                        if (q.type === 12) {
                          let ranges = q.ranges;
                          ranges.filter((r) => r.detail.length).length < 2 &&
                            (questions.ranges = "Debes añadir los dos rangos");
                        }

                        Object.keys(questions).length
                          ? errorQ.push(questions)
                          : errorQ.push(undefined);
                      }
                      error.section.questions = errorQ;
                    } else {
                      error.section.questions = "Debes Añadir 1 opción";
                    }
                  }

                  Object.keys(error).length
                    ? questionErrors.push(error)
                    : questionErrors.push(undefined);
                }
              }
              setErrors({ schema: questionErrors });
            }
          }

          const submitValidation = [];

          for (let e of questionErrors) {
            if (e.question) {
              if (Object.keys(e.question).length) {
                submitValidation.push(e);
              }
            }
            if (e.section) {
              if (Object.keys(e.section).length) {
                const listQ = e.section.questions;
                const submitSection = listQ.filter((q) => q !== undefined);
                submitSection.length && submitValidation.push(submitSection);
              }
            }
          }

          if (submitValidation.length === 0) {
            validate = true;
          }

          if (validate) {
            const lines = [];

            const listLines = values.service_lines.length
              ? values.service_lines
              : [];

            for (let opt of listLines) {
              opt.to_delete
                ? lines.push({
                    id: opt.line.key,
                    frequency_days: {
                      ingress_date: opt?.frequency_days?.income_frecuency
                        ? opt?.frequency_days?.income_frecuency?.map((day) =>
                            parseInt(day.key)
                          )
                        : [],
                      egress_date: opt?.frequency_days?.egress_frecuency
                        ? opt?.frequency_days?.egress_frecuency?.map((day) =>
                            parseInt(day.key)
                          )
                        : [],
                    },
                    to_delete: opt.to_delete,
                  })
                : lines.push({
                    id: opt.line.key,
                    frequency_days: {
                      ingress_date: opt?.frequency_days?.income_frecuency
                        ? opt?.frequency_days?.income_frecuency?.map((day) =>
                            parseInt(day.key)
                          )
                        : [],
                      egress_date: opt?.frequency_days?.egress_frecuency
                        ? opt?.frequency_days?.egress_frecuency?.map((day) =>
                            parseInt(day.key)
                          )
                        : [],
                    },
                  });
            }

            const sendPatient =
              values.modules.map((module) => module.key).length === 1 &&
              values.modules.find((module) => module.key === 101)
                ? true
                : values.send_to_patient === "true"
                ? true
                : false;

            const payloadScale = {
              name: values.name,
              service_lines: lines,
              description: values.description,
              age_range: values.age_range,
              time_complete: parseInt(values.time_complete),
              bibliografy: values.bibliografy,
              image: values.image ? values.image : "",
              modules: values.modules.map((module) => module.key),
              threshold: values?.threshold ? parseInt(values?.threshold) : null,
              send_to_patient: sendPatient,
              schema: questionList,
            };

            const newScale = {
              ...payloadScale,
            };

            const id = editScale.id ? editScale.id : null;
            if (id === "nuevaEscala" || id === null) {
              setScaleName(values.name);
              setLoadingUpdate(true);
              setOpenPopup(true);

              const data = await apiInterceptor({
                method: "POST",
                endpoint: "/tr/admin/forms",
                data: newScale,
              });

              if (data.data === undefined) {
                setIsEditScale(false);
                setLoadingUpdate(false);
                setStatus(data.response[0].id ? true : false);
              }
              if (data.statusCode === 201) {
                setIsEditScale(false);
                setLoadingUpdate(false);
                setStatus(data.data[0].message ? true : false);
              } else if (data.statusCode === 500 || data.statusCode === 400) {
                setLoadingUpdate(false);
                setStatus(data.response[0].id ? true : false);
              }
            } else {
              setLoadingUpdate(true);
              setScaleName(values.name);
              const id = editScale.id;
              setOpenPopup(true);
              const data = await apiInterceptor({
                method: "PUT",
                endpoint: `/tr/admin/forms/${id}`,
                data: payloadScale,
              });
              if (data.statusCode === 200) {
                setLoadingUpdate(false);
                setIsEditScale(false);
                setStatusEdit(data.statusCode);
              } else if (data.statusCode === 400) {
                setLoadingUpdate(false);
                setStatusEdit(data.statusCode);
              }
            }
          }
        }}
      >
        <ScaleAdminStep validationSchema={Yup.object(basicInfoSchema)}>
          <BasicInfoStep />
        </ScaleAdminStep>

        <ScaleAdminStep validationSchema={Yup.object(questionsStepSchema)}>
          <QuestionsStep />
        </ScaleAdminStep>
      </ScaleAdminStepper>

      <Modal
        isOpen={openPopup}
        onClose={() => handleClosePopup()}
        variant="confirm"
      >
        {loadingUpdate ? (
          <Spinner className_container="rolesForm" />
        ) : (
          <ModalContent
            close={() => handleClosePopup()}
            text={
              status
                ? "Formulario creado\nexitosamente"
                : statusEdit === 200
                ? "Formulario editado\nexitosamente"
                : "Error creando formulario,\ninténtalo de nuevo"
            }
            icon={status || statusEdit === 200 ? infoGreenIcon : infoRedIcon}
            btns={false}
            alt="Icono creación o edición de formulario"
          />
        )}
      </Modal>
    </>
  );
};
