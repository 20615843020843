import React from 'react'
import Banner from "../../atoms/Banner";
import styles from "./LoginWelcome.module.scss";

export const LoginWelcome = ({children}) => {
  return (
    <div className={styles.loginContainer}>
      {children}
      <p className={styles.copy}>
        Todos los derechos reservados © Fundación Santa Fe de Bogotá 2023
      </p>
      <Banner />
    </div>
  )
}
