import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext/AppContext";
import FilterInput from "../../molecules/FilterInput";
import ArrowDown from "../../assets/imgs/icons/arrowDownSort.svg";
import ArrowUp from "../../assets/imgs/icons/arrowUpSort.svg";

import styles from "./TableHeader.module.scss";

export default function TableHeader({label, column, handleGetFilter, filterValue}) {

  const {
    sort, setSort
  } = useContext(AppContext);

  const handleClick = () => {
    sort === "" ? setSort(`${column.id}:asc`) :
    sort.includes(`${column.id}:asc`) ? setSort(`${column.id}:desc`) :
    setSort("")
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <p
          style={!column.disableSortBy ? {cursor: "pointer"} : {cursor: "default"}}
          className={styles.headerLabel}
          onClick={!column.disableSortBy ? () => handleClick() : null}
        >
          {label}
          <span className={styles.headerSorted}>
            {!column.disableSortBy ?
              sort === `${column.id}:asc` ?
              <img
              src={ArrowDown}
              className={styles.arrowSort}
              alt="Flecha abajo"
              ></img> :
              sort === `${column.id}:desc` ?
              <img
              src={ArrowUp}
              className={styles.arrowSort}
              alt="Flecha arriba"
              ></img> :
              null
            : null}
          </span>
        </p>
        <div className={styles.headerInput}>
          {
            column.canFilter &&
            <FilterInput
              column={column}
              handleGetFilter={handleGetFilter}
              filterValue={filterValue}
            />
          }
        </div>
      </div>
    </>

  )
}