import styles from "./ToolboxTable.module.scss";

export default function ToolboxTable({children}) {

  return(
    <section className={styles.toolboxContainer}>
      {children.map((child, index) =>
        <div id={index}>{child}</div>
      )}
    </section>
  )
}