import React from "react";

import styles from "./Switch.module.scss";

export const Switch = ({ name, toggled, onChange }) => {
  return (
    <label className={styles.toggleSwitch}>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={toggled}
        className={styles.toggleSwitchCheckbox}
        data-testid="switch"
      />
      <span
        className={`${styles.toggleSwitchSwitch} ${toggled && styles.checked}`}
      ></span>
    </label>
  );
};
