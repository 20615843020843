import styles from "./PosasDescription.module.scss";

export default function PosasDescription() {
  return (
    <>
      <div className={styles.descriptionContainer}>
        <h2 className={styles.label_1}>Descripción</h2>
        <p className={styles.description}>
          La escala POSAS para el observador consta de seis parámetros
          (vascularización, pigmentación, grosor, relieve, elasticidad y
          superficie). Todos los parámetros se califican según una escala que va
          del 1 (‘piel normal’) al 10 (‘la peor cicatriz imaginable’) La suma de
          los seis parámetros constituye la puntuación total de la escala POSAS
          para el observador. En cada parámetro se agregan categorías. Además,
          se califica la opinión general según una escala que va del 1 al 10.
          Todos los parámetros se deben comparar preferiblemente con la piel
          normal de una ubicación anatómica equivalente.
        </p>
        <h3 className={styles.label_2}>
          Notas explicativas sobre los parámetros:
        </h3>
        <ul className={styles.listDescription}>
          <li>
            <span className={styles.subTitleList}>Vascularización:</span>{" "}
            presencia de vasos sanguíneos en el tejido cicatrizado, que se
            evalúa según la cantidad de enrojecimiento que ocurre por la
            cantidad de sangre que regresa después de presionar con una pieza de
            Plexiglass.
          </li>
          <li>
            <span className={styles.subTitleList}>Pigmentación:</span>{" "}
            coloración café o marrón de la cicatriz debido a un pigmento (la
            melanina). La pieza de Plexiglass se presiona con fuerza moderada
            contra la piel para eliminar el efecto de la vascularización.
          </li>
          <li>
            <span className={styles.subTitleList}>Grosor:</span> distancia media
            entre el borde subcuticular-dérmico y la superficie epidérmica de la
            cicatriz.
          </li>
          <li>
            <span className={styles.subTitleList}>Relieve:</span> magnitud de
            las irregularidades superficiales presentes (de preferencia en
            comparación con la piel normal adyacente).
          </li>
          <li>
            <span className={styles.subTitleList}>Elasticidad:</span>{" "}
            flexibilidad de la cicatriz probada al arrugar la cicatriz entre el
            pulgar y el índice.
          </li>
          <li>
            <span className={styles.subTitleList}>Superficie:</span> área de la
            cicatriz con relación al área original de la herida.
          </li>
        </ul>
      </div>
    </>
  );
}
