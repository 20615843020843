import { useState, useContext, useEffect } from "react";
import { RoutesContext } from "../context/RoutesContext/RoutesContext";

export const useCrumb = ({ location }) => {
  const { params } = useContext(RoutesContext);
  const [list, setList] = useState([]);

  const routesBreadCumbs = [
    {
      path: "/usuarios",
      crumbs: [{ name: "Usuarios", route: "usuarios" }],
    },
    {
      path: "/roles",
      crumbs: [{ name: "Roles", route: "roles" }],
    },
    {
      path: "/lineas",
      crumbs: [{ name: "Líneas de servicio", route: "lineas" }],
    },
    {
      path:
        params?.idLine === "nuevalinea"
          ? "/lineas/nuevalinea"
          : `/lineas/${params?.idLine}`,
      crumbs: [
        { name: "Líneas de servicio", route: "lineas" },
        {
          name: params?.idLine === "nuevalinea" ? "Nueva Línea" : "Editar línea",
          route:
            params?.idLine === "nuevalinea"
              ? "lineas/nuevalinea"
              : `/lineas/${params?.idLine}`,
        },
      ],
    },
    {
      path: "/formularios",
      crumbs: [{ name: "Formularios", route: "formularios" }],
    },
    {
      path: "/formularios/nuevoFormulario",
      crumbs: [
        { name: "Formularios", route: "formularios" },
        { name: "Nuevo", route: "formularios/nuevoFormulario" },
      ],
    },
    {
      path: params?.idScale && `/formularios/${params?.idScale}` ,
      crumbs: [
        { name: "Formularios", route: "formularios" },
        { name: "Editar", route: `formularios/${params?.idScale}` },
      ],
    },
    {
      path: params?.idScale && `/formularios/preview/nuevaEscala` ,
      crumbs: [
        { name: "Formularios", route: "formularios" },
        { name: "Nuevo", route: `formularios/nuevoFormulario` },
        { name: "Vista previa", route: `formularios/preview/nuevoFormulario` },
      ],
    },
    {
      path: params?.idScale && `/formularios/preview/${params?.idScale}` ,
      crumbs: [
        { name: "Formularios", route: "formularios" },
        { name: "Editar", route: `formularios/${params?.idScale}` },
        { name: "Vista previa", route: `formularios/preview/${params?.idScale}` },
      ],
    },
    {
      path: "/desenlaces/eventos",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/eventos" },
        { name: "Eventos", route: "desenlaces/eventos" },
      ],
    },
    {
      path: "/desenlaces/parahoy",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/parahoy" },
        { name: "Para hoy", route: "desenlaces/parahoy" },
      ],
    },
    {
      path:`/desenlaces/parahoy/diligenciamiento/${params?.idPlan}`,
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/parahoy" },
        { name: "Para hoy", route: "desenlaces/parahoy" },
        { name: "Diligenciamiento", route: `desenlaces/parahoy/diligenciamiento/${params?.idPlan}`},
      ],
    },
    {
      path: "/desenlaces/historico",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/historico" },
        { name: "Historico", route: "desenlaces/historico" },
      ],
    },
    {
      path:`/desenlaces/historico/diligenciamiento/${params?.idPlan}`,
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/historico" },
        { name: "Historico", route: "desenlaces/historico" },
        { name: "Diligenciamiento", route: `desenlaces/historico/diligenciamiento/${params?.idPlan}`},
      ],
    },
    {
      path: "/desenlaces/pacientes",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/pacientes" },
        { name: "Pacientes", route: "desenlaces/pacientes" },
      ],
    },
    {
      path: `/desenlaces/pacientes/detalle/${params?.idPatient}`,
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/pacientes" },
        { name: "Pacientes", route: "desenlaces/pacientes" },
        { name: "Ver Detalle", route: `desenlaces/pacientes/detalle/${params?.idPatient}` },
      ],
    },
    {
      path: `/desenlaces/pacientes/diligenciamiento/${params?.idPatient}/${params?.idPlan}`,
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/pacientes" },
        { name: "Pacientes", route: "desenlaces/pacientes" },
        { name: "Ver Detalle", route: `desenlaces/pacientes/detalle/${params?.idPatient}` },
        { name: "Respuestas", route: `desenlaces/pacientes/detalle/${params?.idPatient}/${params?.idPlan}` },
      ],
    },
    {
      path: "/desenlaces/resumen",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/resumen" },
        { name: "Tu resumen", route: "desenlaces/resumen" },
      ],
    },
    {
      path: "/desenlaces/reporte",
      crumbs: [
        { name: "Desenlaces", route: "desenlaces/reporte" },
        { name: "Reporte", route: "desenlaces/reporte" },
      ],
    },
    {
      path: "/contigo/pacientes",
      crumbs: [
        { name: "Contigo", route: "contigo/pacientes" },
        { name: "Pacientes", route: "contigo/pacientes" },
      ],
    },
    {
      path: "/contigo/pacientes/nuevoMonitoreo",
      crumbs: [
        { name: "Contigo", route: "contigo/pacientes" },
      { name: "Pacientes", route: "contigo/pacientes" },
        { name: "Nuevo Monitoreo", route: `contigo/pacientes/nuevoMonitoreo` },
      ],
    },
    {
      path: `/contigo/pacientes/detalle/${params?.idPatient}`,
      crumbs: [
        { name: "Contigo", route: "contigo/pacientes" },
        { name: "Pacientes", route: "contigo/pacientes" },
        { name: "Detalle", route: `contigo/pacientes/detalle/${params?.idPatient}` },
      ],
    },
    {
      path: `/contigo/pacientes/detalle/diligenciamiento/${params?.idPatient}/${params?.idPlan}`,
      crumbs: [
        { name: "Contigo", route: "contigo/pacientes" },
        { name: "Pacientes", route: "contigo/pacientes" },
        { name: "Detalle", route: `contigo/pacientes/detalle/${params?.idPatient}` },
        { name: "Diligenciamiento", route: `/contigo/pacientes/detalle/diligenciamiento/${params?.idPatient}/${params?.idPlan}` },
      ],
    },
    {
      path: `/contigo/pacientes/detalle/alerta/${params?.idPatient}/${params?.idAlert}`,
      crumbs: [
        { name: "Contigo", route: "contigo/pacientes" },
        { name: "Pacientes", route: "contigo/pacientes" },
        { name: "Detalle", route: `contigo/pacientes/detalle/${params?.idPatient}` },
        { name: "Alerta", route: `/contigo/pacientes/detalle/alerta/${params?.idPatient}/${params?.idAlert}` },
      ],
    },
    {
      path: "/contigo/reporte",
      crumbs: [
        { name: "Contigo", route: "contigo/reporte" },
        { name: "Reporte", route: "contigo/reporte" },
      ],
    },
   
  ];

  const getCrumbList = () => {
    let data = routesBreadCumbs.find(
      (crumb) => crumb.path === location.pathname
    );
    const crumbs = data ? data.crumbs : [];
    setList(crumbs);
  };

  useEffect(() => {
    getCrumbList();
  }, [location, params]);

  return [list, getCrumbList];
};
