import withAuth  from '../../HOC/withAuth';
import SummaryForm from "../../atoms/SummaryForm";
import background from "../../assets/imgs/download_summary.svg";
import styles from "./DownloadSummary.module.scss";

function DownloadSummary() {

  return (
    <>
      <div className={styles.container}>
        <p className={styles.helpText}>Selecciona los rangos de fecha y luego haz clic en el botón de descarga  y obtener el archivo en excel directamente en tu computador.</p>
        <div className={styles.formSection}>
          <SummaryForm />
        </div>
        <div className={styles.imgContainer}>
          <img className={styles.img} src={background} alt="image_background" />
        </div>
      </div>
    </>
  );
}

export default withAuth(DownloadSummary, '/desenlaces/resumen')