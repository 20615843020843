import ActionButton from "../../atoms/ActionButton";

import copy from "../../assets/imgs/icons/duplicateIconBlue.svg";
import trashIcon from "../../assets/imgs/icons/trashIconBlue.svg";
import styles from "./ActionButtonList.module.scss";

const ActionButtonList = (props) => {

  const { deleteOption, id, handleCopy } = props;

  return (
    <div className={styles.actionListContainer}>
      
      <ActionButton
        icon={copy}
        onClick={() => handleCopy(id)}
        disabled={props.disabled}
        text="Duplicar"
      />
      <ActionButton
        icon={trashIcon}
        onClick={() => deleteOption(id)}
        disabled={props.disabled}
        text="Borrar pregunta"
      />
    </div>
  );
};

export default ActionButtonList;
