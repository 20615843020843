import RadioIcon from "../../assets/imgs/icons/radbutton_selected_active.svg";
import SelectIcon from "../../assets/imgs/icons/select.svg";
import TextIcon from "../../assets/imgs/icons/Text.svg";
import DateIcon from "../../assets/imgs/icons/calendar.svg";
import Numbers from "../../assets/imgs/icons/Numbers.svg";
import Likert from "../../assets/imgs/icons/TodoList.svg";
import Speed from "../../assets/imgs/icons/Speed.svg";

import styles from "./ListOptionsButtons.module.scss";

export const options = [
  {
    id: 1,
    icon: RadioIcon,
    label: "Opción múltiple",
    value: 2,
    className: `${styles.optionButton}`,
    type:"Opción múltiple"
  },
  {
    id: 2,
    icon: SelectIcon,
    label: "Única opción",
    value: 3,
    className: `${styles.optionButton}`,
    type:"Única opción"
  },
  {
    id: 3,
    icon: TextIcon,
    label: "Texto abierto",
    value: 8,
    className: `${styles.optionButton}`,
  },
  {
    id: 4,
    icon: DateIcon,
    label: "Fecha",
    value: 4,
    className: `${styles.optionButton}`,
  },
  {
    id: 5,
    icon: Numbers,
    label: "Numérica",
    value: 5,
    className: `${styles.optionButton}`,
  },
  {
    id: 6,
    icon: Likert,
    label: "Likert",
    value: 6,
    className: `${styles.optionButton}`,
  },
  {
    id: 7,
    icon: Speed,
    label: "NPS",
    value: 12,
    className: `${styles.optionButton}`,
  },
];