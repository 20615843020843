import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import CanvaPatient from "../../atoms/CanvaPatient";
import CheckboxFSFB from "../../atoms/Checkbox";
import Eq5dInfo from "../../atoms/Eq5dInfo";
import LikertFSFB from "../../atoms/Likert";
import RadioButtonFSFB from "../../atoms/RadioButton";
import SelectOption from "../../atoms/SelectOption";
import TextArea from "../../atoms/TextArea";
import { AppContext } from "../../context/AppContext/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import FormikStepper from "../../molecules/FormikStepper";
import ThermometerCard from "../../molecules/ThermometerCard";
import NewSection from "../../organisms/NewSection";
import { saveScale } from "../../services/scalesManager";
import DateTimeOption from "../DateTimeOption";
import { default as style, default as styles } from "./ScaleForm.module.scss";
import NpsQuestion from "../../atoms/NpsQuestion";

export default function ScaleForm({ scale }) {
  const { step, setStep, userPlan, contEq5dTherm } = useContext(AppContext);
  const [questions] = useState(
    scale?.form.schema?.sort((a, b) => a.order - b.order) || []
  );
  const [arrayQuestions, setArrayQuestions] = useState([]);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const initialValues = {};

  const handleNext = () => {
    const value = step + 1;
    step < arrayQuestions.length - 1
      ? setStep(value)
      : step === arrayQuestions - 1 && navigate("/finaldiligence");
  };

  function establishYupSchema(question) {
    let validation = {};
    if (question?.is_required) {
      switch (question.type) {
        case 1:
          validation[question.id] = Yup.array()
            .min(1, "Debe seleccionar por lo menos una opción")
            .required("Olvidaste responder esta pregunta");
          break;

        case 5:
          validation[question.id] = Yup.number()
            .required("Olvidaste responder esta pregunta")
            .typeError(
              "Este campo debe ser diligenciado con números únicamente"
            );
          break;

        case 4:
          validation[`${question.id}`] = Yup.array()
            .required("Olvidaste responder esta pregunta")
            .min(2, "Olvidaste responder esta pregunta");
          break;

        case 10:
          validation[question.id] = Yup.array()
            .min(1, "Debes trazar por lo menos una línea")
            .required("Olvidaste responder esta pregunta");
          break;
        case 12: validation[question.id] = Yup.string().required("Olvidaste responder esta pregunta")
        break
        default:
          validation[question.id] = Yup.string().required(
            "Olvidaste responder esta pregunta"
          );
          break;
      }
    } else {
      if (question.type === 5) {
        validation[question.id] = Yup.number().typeError(
          "Este campo debe ser diligenciado con números únicamente"
        );
      }
    }
    return Yup.object().shape(validation);
  }

  function optionConverter(options) {
    const newArrayOption = options.map((item) => {
      return {
        value: item.id,
        label: item.detail,
      };
    });
    return newArrayOption;
  }

  const getQuestions = async () => {
    let totalQuestions = [];
    for (let q of questions) {
      if (q.question) {
        totalQuestions.push(q.question);
      } else if (q.section) {
        totalQuestions.push(q.section);
        for (let question of q.section.questions) {
          totalQuestions.push(question);
        }
      }
    }

    await setArrayQuestions(totalQuestions);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  if (arrayQuestions.length) {
    return (
      <div
        className={
          scale.id === 23 && width <= 480
            ? styles.scaleFormContainerEq5d
            : style.scaleFormContainer
        }
      >
        <FormikStepper
          initialValues={initialValues}
          onSubmit={async (values) => {
            let questions = [];
            for (let scaleAnsw in values) {
              questions.push({
                id: scaleAnsw,
                answers: values[scaleAnsw],
                remarks: "",
              });
            }

            const payload = {
              id_plan: userPlan,
              answer_by: "paciente",
              questions: questions,
              isFinished: true,
            };
            const data = await saveScale(payload);
            if (data.status === 201) {
              navigate(`/finaldiligence`);
            } else {
              alert("Error al guardar el formulario");
            }
          }}
        >
          {arrayQuestions?.map((question) => {
            const validateSchema = establishYupSchema(question);
            return (
              <div
                className={
                  scale?.id === 80 && question.type !== 10
                    ? styles.posasPatientDisplay
                    : scale?.id === 80 && question.type === 10
                    ? styles.canvaPatientDisplay
                    : scale.id === 23 && styles.eq5dPatientDisplay
                }
                validationschema={validateSchema}
                key={question.id}
              >
                {scale.form.id === 23 && <Eq5dInfo />}
                {question.type !== 11 && (
                  <h2
                    className={
                      scale.form.id === 80 && question.type !== 10
                        ? style.formTitlePosas
                        : style.formTitle
                    }
                  >
                    {question.detail}
                  </h2>
                )}
                {question.type === 1 ? (
                  <CheckboxFSFB
                    name={question.id}
                    options={optionConverter(question.options)}
                  />
                ) : question.type === 2 ? (
                  <RadioButtonFSFB
                    name={question.id}
                    options={optionConverter(question?.options)}
                    labelPlacement={scale.id === 80 ? "top" : "end"}
                    idQuestion={question.id}
                    className={
                      scale.id === 23
                        ? styles.optionRadioContainerEq5d
                        : scale.id === 80
                        ? styles.optionRadioContainerPosas
                        : styles.optionRadioContainer
                    }
                    containerStyle={styles.containerRadioScale}
                    touchedConditional={true}
                  />
                ) : question.type === 3 ? (
                  <div className={style.selectOption}>
                    <SelectOption name={question.id}>
                      <option value="">Seleccionar</option>
                      {question?.options.map(({ id, detail }) => (
                        <option value={id} key={id}>
                          {detail}
                        </option>
                      ))}
                    </SelectOption>
                  </div>
                ) : question.type === 4 ? (
                  <DateTimeOption
                    datePickerName={`${question.id}[0]`}
                    dateTimeName={`${question.id}[1]`}
                    name={question.id}
                  />
                ) : question.type === 5 ? (
                  <TextArea name={question.id} />
                ) : question.type === 6 ? (
                  <LikertFSFB
                    options={question.options}
                    name={question.id}
                    patientDesk="patientDesk"
                    position="patient"
                  />
                ) : question.type === 10 ? (
                  <CanvaPatient name={question.id} />
                ) : question.type === 11 &&
                  width <= 480 &&
                  contEq5dTherm === 6 ? (
                  <div className={styles.scaleFormContainer}>
                    <ul className={styles.uList}>
                      <li>
                        Nos gustaría saber lo buena o mala que es su salud HOY.
                      </li>
                      <li>
                        La escala está numerada de 0 a 100.
                        <br />
                        100 representa la <u>mejor</u> salud que se pueda
                        imaginar.
                        <br />0 representa la <u>peor</u> salud que se pueda
                        imaginar.
                      </li>
                      <li>
                        Por favor, haga clic en la escala para indicar cuál es
                        su
                        <br />
                        estado de salud HOY.
                      </li>
                    </ul>
                  </div>
                ) : question.type === 11 ? (
                  <ThermometerCard name={question.id} />
                ) : question.type === 8 ? (
                  <TextArea name={question.id} />
                ) : question.type === 12 ? (
                  
                  <div className={styles.npsContainer}>
                    <NpsQuestion
                      scale={scale}
                      name={question.id}
                      options={optionConverter(question?.options)}
                      ranges={question.ranges}
                    />
                  </div>
                ) : (
                  question.idSection && (
                    <NewSection title={question.name} handleNext={handleNext} />
                  )
                )}
              </div>
            );
          })}
        </FormikStepper>
      </div>
    );
  } else {
    return null;
  }
}
