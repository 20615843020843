import React, { useState } from 'react'
import checkIcon from "../../assets/imgs/icons/filters/check.svg";
import styles from "./FilterList.module.scss";

export default function FilterList({
  module,
  variant,
  options,
  setLabel,
  selectedOptions,
  setSelectedOptions,
}) {

  function filterByModule(allFilters, test) {
    const filteredFilters = variant === "filtersType" ? allFilters.filter((filter) => filter.module.includes(test)) : null;
    return filteredFilters;
  }
  const testValue = module;
  const filteredResults = filterByModule(options, testValue);

  let filter = variant === "filtersType" ? filteredResults : options;
  const handleOptionSelect = async(option) => {
    const validation = await selectedOptions.find(opt => opt.name === option.name)
    if (!validation) {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  const handleOptionUniqueSelect = (option) => {
    setSelectedOptions(option.value)
    setLabel(option.name)
  };
  const handleOptionCheckbox = (option) => {
    setSelectedOptions(option.value)
    setLabel(option.name)
  };

  return (
  <ul className={styles.dropdownList}>
    {filter.map((option, index) => {
      let isPresent = false;
      if (variant === "filtersType") {
        for (let i = 0; i < selectedOptions.length; i++) {
          if (selectedOptions[i].name === option.name) {
            isPresent = true;
            break;
          }
        }
      }
      return (
        <li
          key={option.name}
          onClick={() => variant === "filtersType"
            ? handleOptionSelect(option)
            : variant === "uniqueFilter" ? handleOptionUniqueSelect(option)
            : null
          }
        >
          <div className={styles.imgContainer}>
            <img src={option.img} alt="" />
          </div>
          {option.name}
          {isPresent ? <img src={checkIcon} alt="" /> : null}
          {variant === "uniqueFilter" && selectedOptions === option.value ? <img src={checkIcon} alt="" /> : null}
        </li>
      )
    })}
  </ul>
  )
}
