import { useState } from "react";

import { DashboardContext } from "./DashboardContext";

import completedResultsIcon from "../../assets/imgs/icons/completed_results.svg";
import deceasedPatientsIcon from "../../assets/imgs/icons/deceased_patients.svg";
import patientsMeasuredIcon from "../../assets/imgs/icons/patients_measured.svg";
import plannedOutcomesIcon from "../../assets/imgs/icons/planned_outcomes.svg";
import pendingPatientsIcon from "../../assets/imgs/icons/pending_patients.svg";
import totalPatientsIcon from "../../assets/imgs/icons/total_patients.svg";
import pendingScalesIcon from "../../assets/imgs/icons/pending_scales.svg";
import pendingAlertsIcon from "../../assets/imgs/icons/pending_alerts.svg";
import warninglertsIcon from "../../assets/imgs/icons/warning_alerts.svg";
import readmissionsIcon from "../../assets/imgs/icons/readmissions.svg";
import totaLinesIcon from "../../assets/imgs/icons/total_lines.svg";
import patientsIcon from "../../assets/imgs/icons/patients.svg";

export const DashboardProvider = ({ children }) => {
  const basicStatsPath = "/recu/stats";
  const desenlacesStatsPath = `${basicStatsPath}/desenlaces`;
  const contigoStatsPath = `${basicStatsPath}/contigo`;

  const [dataCards, setDataCards] = useState([
    {
      detail: "Lineas activas",
      icon: totaLinesIcon,
      type: "lines",
      path: basicStatsPath,
      backendField: "totalActiveServiceLines"
    },
    {
      detail: "Pacientes",
      icon: patientsIcon,
      type: "patients",
      path: basicStatsPath,
      backendField: "totalPatients"
    },
  ]);

  const [desenlacesDetailCards, setDesenlacesDetailCards] = useState([
    {
      detail: "Pacientes activos",
      icon: totalPatientsIcon,
      color: "primary",
      type: "activePatients",
      path: desenlacesStatsPath,
      backendField: "totalPatients",
    },
    {
      detail: "Desenlaces diligenciados",
      icon: completedResultsIcon,
      color: "primary",
      type: "filledScales",
      path: desenlacesStatsPath,
      backendField: "totalPlans",
    },
    {
      detail: "Desenlaces planeados",
      icon: plannedOutcomesIcon,
      color: "primary",
      type: "plannedScales",
      path: desenlacesStatsPath,
      backendField: "totalPlans",
    },
    {
      detail: "Pacientes fallecidos",
      icon: deceasedPatientsIcon,
      color: "alert",
      type: "deceasedPatients",
      path: desenlacesStatsPath,
      backendField: "totalPatients",
    },
    {
      detail: "Pacientes medidos",
      icon: patientsMeasuredIcon,
      color: "primary",
      type: "measuredPatients",
      path: desenlacesStatsPath,
      backendField: "totalPatients",
    },
    {
      detail: "Pacientes pendientes",
      icon: pendingPatientsIcon,
      color: "alert",
      type: "pendingPatients",
      path: desenlacesStatsPath,
      backendField: "totalPatients",
      timePeriod: "future",
    },
    {
      detail: "Desenlaces pendientes por medir",
      icon: pendingScalesIcon,
      color: "alert",
      type: "pendingScales",
      path: desenlacesStatsPath,
      backendField: "totalPlans",
      timePeriod: "future",
    },
  ]);

  const [contigoDetailCards, setContigoDetailCards] = useState([
    {
      detail: "Pacientes en monitoreo",
      icon: totalPatientsIcon,
      color: "primary",
      type: "trackingPatients",
      path: contigoStatsPath,
      backendField: "patientsTracking",
    },
    {
      detail: "Formularios diligenciados",
      icon: completedResultsIcon,
      color: "primary",
      type: "filledTraces",
      path: contigoStatsPath,
      backendField: "totalTracks",
    },
    {
      detail: "Monitoreos finalizados",
      icon: completedResultsIcon,
      color: "primary",
      type: "finishedTraces",
      path: contigoStatsPath,
      backendField: "totalTracks",
    },
    {
      detail: "Seguimiento alertas",
      icon: pendingAlertsIcon,
      color: "warning",
      type: "alertsMonitoring",
      path: contigoStatsPath,
      backendField: "totalAlertsTracked",
    },
    {
      detail: "Pacientes con alerta",
      icon: warninglertsIcon,
      color: "primary",
      type: "onWarningPatients",
      path: contigoStatsPath,
      backendField: "totalPatientsWithAlert",
    },
    {
      detail: "Reingresos",
      icon: readmissionsIcon,
      color: "alert",
      type: "readmissions",
      path: contigoStatsPath,
      backendField: "totalReadmissions",
    },
  ]);

  return (
    <DashboardContext.Provider
      value={{
        dataCards, setDataCards,
        desenlacesDetailCards, setDesenlacesDetailCards,
        contigoDetailCards, setContigoDetailCards
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
