import { useEffect, useContext } from "react";
import { useField, useFormikContext } from "formik";

import ErrorMessage from "../../atoms/ErrorMessage";
import { LabelInput } from "../../atoms/LabelInput";
import PillInput from "../../molecules/PillInput";
import { getSubModules } from "../../services/roles";
import { AppContext } from "../../context/AppContext/AppContext";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import styles from "./ModulesSelect.module.scss";

export const ModulesSelect = ({ ...props }) => {
  const [field, meta, helpers] = useField({ ...props });
  const { values, setFieldValue } = useFormikContext();
  const { listmodules, setListModules } = useContext(ScalesContext);
  const { modules } = useContext(AppContext);
  const { setValue } = helpers;

  const multiFilter = (array, filters) => {
    let filterKeys = Object.keys(filters);

    return array.filter((item) =>
      filterKeys.every((key) => filters[key].indexOf(item[key]) === -1)
    );
  };

  const filters = { key: [7, 8, 9, 12] };

  const fetchModules = async () => {
    const listModules = modules.map((module) => ({
      key: module.id,
      value: module.label,
    }));

    const arrayModules = multiFilter(listModules, filters);
    setListModules(arrayModules);
  };

  const deleteSubmodule = async (id) => {
    const response = await getSubModules(id);
    const listSubmodule = response[0].map((submodule) => submodule.id);
    let actualSubmodules =
      values?.submodules !== undefined ? values.submodules : [];

    let newSubmodules = [];
    for (let i = 0; i < actualSubmodules.length; i++) {
      let equal = false;
      for (let j = 0; (j < listSubmodule.length) & !equal; j++) {
        if (actualSubmodules[i] == listSubmodule[j]) equal = true;
      }
      if (!equal) newSubmodules.push(actualSubmodules[i]);
    }
    setFieldValue("submodules", newSubmodules);
  };

  const defaultValue = props.defaultValue;

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    !listmodules.length && fetchModules();
  }, [modules]);

  const handleDelete = async (key) => {
    setValue(field.value.filter((element) => element.key !== key));
    deleteSubmodule(key);
  };

  return (
    <div className={styles.modulesSelectContainer}>
      <LabelInput text="Módulos" />
      <div className={styles.modulesSelect}>
        <PillInput
          selected={field.value}
          options={listmodules}
          onClosePill={(key) => handleDelete(key)}
          onSelectOption={(value) => {
            setValue([...field.value, value]);
          }}
          disabled={props.disabled}
        />
      </div>
      {meta.touched && <ErrorMessage error={meta.error} />}
    </div>
  );
};
