import { useState, useEffect, useContext } from "react";
import styles from "./OtpTimer.module.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { AppContext } from "../../context/AppContext/AppContext";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { OTPChallenge } from "../../services/authentication";

export default function OtpTimer(props) {
  const { width } = useWindowDimensions();
  const { initialMinute = 1, initialSeconds = 59 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [resended, setResended] = useState(false);
  const [resendedError, setResendedError] = useState(false);
  const [charging, setCharging] = useState(false);
  const { otpChallenge, setOtpChallenge, destination } = useContext(AppContext);

  const sendChallenge = async () => {
    let result = false;
    if (charging) return;
    setCharging(true);
    try {
      const response = await OTPChallenge(otpChallenge.requestPayload,destination);

      // Request successfully
      if (response.ok) {
        setOtpChallenge({
          requestPayload: otpChallenge.requestPayload,
          data: response.body.data,
        });
        result = true;
      }
    } catch (error) {
      console.error(error);
    }
    setCharging(false);
    return result;
  };

  const handleResend = async () => {
    if (resended) return;
    setResended(true);
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
    const result = await sendChallenge();
    !result && setResendedError(true);
  };

  useEffect(() => {
    let timeout;
    if (resended) timeout = setTimeout(() => setResended(false), 3000);
    return () => clearTimeout(timeout);
  }, [resended]);

  useEffect(() => {
    let timeout;
    if (resendedError)
      timeout = setTimeout(() => setResendedError(false), 3000);
    return () => clearTimeout(timeout);
  }, [resendedError]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [seconds]);

  useEffect(() => {
    if (minutes > 0 && seconds === 0) {
      setMinutes(minutes - 1);
      setSeconds(59);
    }
  }, [seconds, minutes]);

  return (
    <>
      <div className={styles.container}>
        <div>
          0{minutes} : {seconds < 10 ? "0" + seconds : seconds}
        </div>
        <button
          onClick={handleResend}
          className={
            seconds === 0 && minutes === 0
              ? styles.buttonTimer
              : styles.buttonTimerOff
          }
        >
          Reenviar código
          {width <= 480 ? (
            <ArrowForwardIosRoundedIcon sx={{ fontSize: "2vh" }} />
          ) : null}
        </button>
      </div>
      {resended && !resendedError && (
        <div className={styles.confirmation}>El código fué reenviado</div>
      )}
      {resendedError && (
        <div className={styles.confirmationError}>
          Se presentó un error al hacer la solicitud. Comunícate con el
          administrador.
        </div>
      )}
    </>
  );
}
