import { LogoHeader } from "../../atoms/LogoHeader";
import { ContactForm } from "../../molecules/ContactForm";
import { HelpTemplate } from "../../templates/HelpTemplate";

export default function HelpView() {
  return (
    <HelpTemplate>
      <LogoHeader variant="light" />
      <ContactForm />
    </HelpTemplate>
  );
}
