import { useContext } from "react";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import styles from "./_.module.scss";

export default function NavCustomLink({
  isSelected,
  isMinor,
  children,
  onClick = () => {},
}) {
  const { dropdown } = useContext(AdminContext);
  return (
    <div
      className={`${styles.parent} ${!dropdown && styles.isLinkDropped}`}
      onClick={onClick}
    >
      <div
        className={`
        ${styles.card} ${styles.parent} 
        ${isSelected && styles.isSelected} 
        ${isMinor && styles.isMinor}
        ${dropdown && styles.cardDropdown}
        `}
      >
        {children}
      </div>
    </div>
  );
}
