import React, { useState } from "react";

import { toBase64 } from "../../custom/base64";

import { RadioButtons } from "../../atoms/RadioButtons";
import { UploadFile } from "../../atoms/UploadFile";

import styles from "./ContigoAddEquipment.module.scss";

const equipmentOptions = [
  { label: "Si", value: "true" },
  { label: "No", value: "false" },
];

export const ContigoAddEquipment = ({ modalType, equipmentValues, setEquipmentValues }) => {
  const [equipment, setEquipment] = useState(String(equipmentValues.equipment));
  const [observations, setObservations] = useState(equipmentValues.observations);

  const handleChangeEquipment = (evt) => {
    setEquipment(evt.target.value);
    setEquipmentValues({
      ...equipmentValues,
      equipment: evt.target.value === "true",
    });
  };

  const handleChangeObservations = (evt) => {
    setObservations(evt.target.value);
    setEquipmentValues({
      ...equipmentValues,
      observations: evt.target.value,
    });
  };

  const handleChangeFile = async (file) => {
    const base64File = await toBase64(file);
    const base64FileToSend = base64File.replace("data:application/pdf;base64,", "");
    setEquipmentValues({
      ...equipmentValues,
      file_material: base64FileToSend,
      file_name: file.name,
    });
  };

  return (
    <section className={`${styles.container} ${modalType ? styles.modalType : ""}`}>
      <div className={styles.equipmentSelector}>
        <span className={styles.detail}>
          Equipos/ Insumos
          <span className={styles.requiredField}>*</span>
        </span>
        <RadioButtons
          row
          name="equipment"
          currentValue={equipment}
          options={equipmentOptions}
          onChange={handleChangeEquipment}
        />
      </div>
      <div className={styles.observations}>
        <span className={styles.regularDetail}>Observaciones</span>
        <textarea
          name="equipmentObservations"
          className={styles.textArea}
          rows={modalType ? 2 : 4}
          onChange={handleChangeObservations}
          value={observations}
        />
      </div>
      <div className={styles.attachmentFile}>
        <span className={styles.detail}>Material educativo</span>
        <UploadFile
          name={equipmentValues.file_name}
          modalType={modalType}
          title={modalType ? "Cambiar adjunto" : "Adjuntar"}
          extension="pdf"
          type="application/pdf"
          maxSize={5e6}
          onChange={handleChangeFile}
        />
      </div>
    </section>
  )
};
