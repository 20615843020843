import LoginPatientsForm from "../../molecules/LoginPatientsForm";
import { LoginWelcome } from "../../templates/LoginWelcome";

import Logo from "../../assets/imgs/logoFSFB.svg";
import Doctor from "../../assets/imgs/doctor_login.png";
import styles from "./LoginPatients.module.scss";

export default function LoginPatients(props) {
  return (
    <LoginWelcome>
      <section className={styles.container}>
        <img src={Logo} alt="Logo Fundación SantaFe" className={styles.logo} />
        <div>
          <h1 className={styles.title}>
            Queremos saber cómo te encuentras, te damos la bienvenida a{" "}
            <b>Tu recuperación</b>
          </h1>
          <section className={styles.cardForm}>
            <LoginPatientsForm />
            <img src={Doctor} alt="imagen médico" className={styles.doctor} />
          </section>
        </div>
      </section>
    </LoginWelcome>
  );
}
