import axios from "axios";
import { apiInterceptor } from "./interceptors/jwt.interceptor";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const getLines = async (page, filters = "") => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/admin/service-line/getAll?page=${page}${filters}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getLineById = async (lineId) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/admin/service-line/getById/${lineId}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const createLine = async (data) => {
  try {
    const request = await axios.post(
      `${API_URL}/recovery/admin/service-line`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateLine = async (data, id) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/service-line/updateById/${id}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getList = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/recovery/admin/service-line/getList`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const deleteLine = async (data, id) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/service-line/deleteById`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getLineByModules = async (lines) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/admin/service-line/modules?ids=${lines}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const statusLine = async (data, email, status) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/service-line/activate?activate=${status}&email=${email}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getRelatedServiceLines = async (id) => {
  try {
    return apiInterceptor({
      method: "GET",
      endpoint: `/recu/service-lines?service_line=${id}`,
    });
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
