import styles from "./FooterText.module.scss";

export default function FooterText(props) {
  return (
    <div className={styles.left}>
      <p className={styles.sub1}>{props.sub1}</p>
      <p className={styles.sub2}>{props.sub2}</p>
    </div>
  );
}
