import { useContext } from "react";
import { format } from "date-fns";

import { ContigoContext } from "../context/ContigoContext/ContigoContext";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { FiltersContext } from "../context/FiltersContext/FiltersContext";

import { getReport } from "../services/Contigo/report";
import { offsetUser } from "../custom/offset";

export const useContigoReport = ({ dataReport = false }) => {

  const {
    name,
    document,
    actualPageReport,
    line,
    sort_report,
  } = useContext(ContigoContext);

  const {
    startDateReporteContigo,
    endDateReporteContigo,
    genereReporteContigo,
    ageReporteContigo,
    observationReporteContigo,
    monitoringReporteContigo
  } = useContext(FiltersContext);

  const { height, width } = useWindowDimensions();

  const fetchData = async (trackId) => {
    let params = {
      page: actualPageReport,
      offset: offsetUser(height, width),
      doc_number: document,
      name: name,
      service_line: line,
      start_date: startDateReporteContigo ? format(startDateReporteContigo, "yyyy-MM-dd") : "",
      end_date: endDateReporteContigo
        ? format(endDateReporteContigo, "yyyy-MM-dd")
        : "",
      genre: genereReporteContigo,
      legal_age: ageReporteContigo,
      remark_ids: observationReporteContigo.toString(),
      service_line_ids: monitoringReporteContigo.toString(),
      get_report: dataReport,
      sort: sort_report,
      track_ids: trackId.toString(),
    };

    const filters = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );

    const response = await getReport(filters);
    return response;
  };

  return { fetchData };
};
