import jwtDecode from "jwt-decode";
import { CognitoOAUTHLogout } from "../authentication";
import { apiInterceptor } from "../interceptors/jwt.interceptor";
const { REACT_APP_API_SERVER } = process.env;

export function isLoggedIn() {
  const token = localStorage.getItem("token");
  return !token || isTokenExpired(token) ? false : true;
}
export function isTokenExpired(token) {
  const decoded = jwtDecode(token);
  const expirationDate = decoded.exp * 1000;
  return Date.now() > expirationDate;
}

export const loginService = async (token) => {
  let response = await fetch(REACT_APP_API_SERVER + "/tr/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) throw new Error("Unauthorized");
  response = await response.json();
  localStorage.setItem("token", response.data[0].access_token);
  localStorage.setItem("refreshToken", response.data[0].refresh_token);
  return true;
};

export const logout = async () => {
  const response = await apiInterceptor({
    endpoint: "/tr/auth/logout",
    method: "POST",
  });
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  CognitoOAUTHLogout();
  return response;
};
