import * as Yup from "yup";
export const basicInfoSchema = {
  name: Yup.string().required("Olvidaste diligenciar este campo"),
  modules: Yup.array()
    .min(1, "Olvidaste agregar un módulo")
    .required("Este campo es requerido"),
  service_lines: Yup.array()
    .min(1, "Olvidaste agregar una línea de servicio")
    .required("Este campo es requerido"),
  description: Yup.string().required("Olvidaste diligenciar este campo"),
  time_complete: Yup.number()
    .required("Olvidaste diligenciar este campo")
    .typeError("Números únicamente"),
  bibliografy: Yup.string(),
  isDesenlacesSelect: Yup.boolean(),
  isContigoSelect: Yup.boolean(),
  age_range: Yup.string().when("isDesenlacesSelect", {
    is: true,
    then: Yup.string().required("Olvidaste diligenciar este campo"),
    otherwise: Yup.string().notRequired(),
  }),
  send_to_patient: Yup.boolean().when("isDesenlacesSelect", {
    is: true,
    then: Yup.boolean().required("Olvidaste diligenciar este campo"),
    otherwise: Yup.boolean().notRequired(),
  }),
  threshold: Yup.number().when("isContigoSelect", {
    is: true,
    then: Yup.number()
      .required("Olvidaste diligenciar este campo")
      .typeError("Números únicamente"),
    otherwise: Yup.number(),
  }),
};

export const questionsStepSchema = {
  schema:  Yup.array() 
    .required("Agregue una pregunta")
    .min(1, "Agregue una pregunta"),
};
