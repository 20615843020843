
import { useNavigate } from "react-router-dom";
import ImageSideDescription from "../../atoms/ImageSideDescription";
import { Button } from "../../atoms/Button/";
import docTriste from "../../assets/imgs/doctor_triste.png";
import styles from "./_.module.scss";

export default function ErrorTemplate({
  titleLabel,
  subtitleLabel,
  text = [],
  textButton,
}) {
  const navigate = useNavigate();
  return (
    <div className={styles.parent}>
      <div className={styles.content}>
        <ImageSideDescription
          src={docTriste}
          alt="Doctor triste"
          classesImage={styles.image}
        >
          <div className={styles.descriptionWrapper}>
            <h2 className={styles.title}>{titleLabel}</h2>
            <h4 className={styles.subtitle}>{subtitleLabel}</h4>
            {text.map((elm, i) => (
              <>
                {i !== 0 && <div className={styles.space}></div>}
                <div className={styles.text}>{elm}</div>
              </>
            ))}
            <div className={styles.text}>{}</div>
            <div className={styles.buttonContainer}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={false}
              onClick={()=> {
                navigate(-2)
              }}
            >
              Regresar
            </Button>

            </div>
          </div>
        </ImageSideDescription>
      </div>
    </div>
  );
}
