import React from "react";

import styles from "./RadioButtons.module.scss";

export const RadioButtons = ({ row, name, currentValue, options, onChange }) => (
  <section
    className={`
      ${styles.radioContainer}
      ${row ? styles.radioRow : styles.radioColumn}
    `}
  >
    {options.map(({ label, value }) => (
      <label className={styles.label} key={String(value)}>
        <input
          name={name}
          className={styles.radioBtn}
          type="radio"
          value={value}
          checked={currentValue === value}
          onChange={onChange}
        />
        {label}
        <span
          className={`
            ${styles.customRadioBtn__show}
            ${styles.customRadioBtn__showCheckbox}
          `}
        />
      </label>
    ))}
  </section >
);
