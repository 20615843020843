import React, {useState, useRef, useEffect} from 'react'
import { allFilters } from '../../molecules/FiltersOptions/filters';
import FiltersOptions from '../../molecules/FiltersOptions';
import FilterList from '../../molecules/FilterList';
import trashIconBlue from "../../assets/imgs/icons/trashIconBlue.svg";
import styles from "./Filters.module.scss";

export default function Filters({
  module,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  genere,
  setGenere,
  ageFilter,
  setAgeFilter,
  idOptions,
  setIdOptions,
  updateFilter,
  setUpdateFilter,
  alertFilter,
  setAlertFilter,
  observationFilter,
  setObservationFilter,
  monitoringFilter,
  setMonitoringFilter
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterIndex, setFilterIndex] = useState(null);

  const [genereLabel, setGenereLabel] = useState(null);
  const [ageLabel, setAgeLabel] = useState(null);
  const [updateLabel, setUpdateLabel] = useState(null);
  const [alertLabel, setAlertLabel] = useState(null);

  const [options, setOptions] = useState([]);
  const [reasonsOptions, setReasonsOptions] = useState([]);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setFilterIndex(-1)
  };

  const handleDeleteOptions = (option) => {
    const validation = selectedOptions.find(opt => opt.name === option.name)
  if (validation) {
    setSelectedOptions(selectedOptions.filter((item) => item.name !== option.name));
  }
  setFilterIndex(-1)

  switch (option.name) {
    case "Fecha Desde":
      setStartDate(null);
      break;
    case "Fecha Hasta":
      setEndDate(null);
      break;
    case "Género":
      setGenere(null);
      break;
    case "Edad":
      setAgeFilter(null);
      break;
    case "Líneas de servicio":
      setIdOptions([]);
      setOptions([]);
      break;
    case "Al día":
      setUpdateFilter(null);
      break;
    case "Alerta":
      setAlertFilter(null);
      break;
    case "Observación":
      setObservationFilter([]);
      setReasonsOptions([]);
      break;
    case "Tipo de monitoreo":
      setMonitoringFilter([]);
      setOptions([]);
      break;
    default:
      break;
  }
}

let getByClass = document.getElementsByClassName('MuiPickersPopper-root')
const filterContainerRef = useRef(null);

const handleClickOutside = (event) => {
  if (
    filterContainerRef.current &&
    !filterContainerRef.current.contains(event.target)
  ) {
    if (getByClass.length === 0) {
      setIsDropdownOpen(false);
      setFilterIndex(-1)
    }

  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);


  return (
  <>
    <div ref={filterContainerRef} id="filterContainer" className={styles.selectContainer}>
      <div
        className={styles.buttonOption}
        onClick={handleToggleDropdown}
      >
       + Filtrar por
      </div>
      {isDropdownOpen && (
        <div className={styles.dropdownContainer}>
          <FilterList
            module={module}
            variant="filtersType"
            options={allFilters}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      )}
      <ul className={styles.filtersOptions}>
        {selectedOptions.map((filter, index) => (
          <FiltersOptions
            // DATA FILTERS
            key={index}
            filter={filter}
            index={index}
            options={options}
            setOptions={setOptions}
            reasonsOptions={reasonsOptions}
            setReasonsOptions={setReasonsOptions}
            // SATATE DROPDOWN
            filterIndex={filterIndex}
            setFilterIndex={setFilterIndex}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            isDropdownOpenFilter={filterIndex === index}
            // GENRE FILTER
            setGenereLabel={setGenereLabel}
            genereLabel={genereLabel}
            setGenere={setGenere}
            genere={genere}
            // AGE FILTER
            setAgeLabel={setAgeLabel}
            ageLabel={ageLabel}
            setAgeFilter={setAgeFilter}
            ageFilter={ageFilter}
            // START DATE FILTER
            setStartDate={setStartDate}
            startDate={startDate}
            // END DATE FILTER
            setEndDate={setEndDate}
            endDate={endDate}
            // CATEGORY FILTER
            setIdOptions={setIdOptions}
            idOptions={idOptions}
            // UPDATE FILTER
            setUpdateLabel={setUpdateLabel}
            updateLabel={updateLabel}
            setUpdateFilter={setUpdateFilter}
            updateFilter={updateFilter}
            // ALERT FILTER
            setAlertLabel={setAlertLabel}
            alertLabel={alertLabel}
            setAlertFilter={setAlertFilter}
            alertFilter={alertFilter}
            // OBSERVATION FILTER
            observationFilter={observationFilter}
            setObservationFilter={setObservationFilter}
            // MONITORING FILTER
            monitoringFilter={monitoringFilter}
            setMonitoringFilter={setMonitoringFilter}
          >
          <div
            onClick={() => handleDeleteOptions(filter)}
            className={styles.clearFilter}
            >
              <img src={trashIconBlue} alt="" />
              <p>Eliminar filtro</p>
          </div>
          </FiltersOptions>
        ))}
      </ul>
    </div>
  </>
  )
}
