import React from 'react';

import UserScale  from "../../templates/UserScale"
import MainProfileHeader from "../../molecules/MainProfileHeader"
import { mockScale } from "./mock";

export default function PosasScale () {
  return (
    <UserScale
       header={<MainProfileHeader/>}
       scale = {mockScale}
    />
  )
};