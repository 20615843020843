import React from 'react';

import style from './ScaleTile.module.scss';
import estetoscopio from '../../assets/imgs/estetoscopio.svg';

export default function ScaleTitle(props) {
  return (
    <div className={style.scaleContainer}>
      <div className={style.icon}>
        <img src={estetoscopio} alt="Icono estetoscopio" />
      </div>
      <div className={style.title}>
      <h2>{props.title}</h2>
      </div>
    </div>

  );
};