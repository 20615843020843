import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import CancelButton from "../../atoms/CancelButton";
import NextButton from "../../atoms/NextButton";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";

import styles from "./ScaleAdminStepper.module.scss";

export default function ScaleAdminStepper({ children, ...props }) {
  const navigate = useNavigate();
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);

  const currentChild = childrenArray[step];
  const { editScale } = useContext(ScalesContext);

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  return (
    <Formik
      {...props}
      onSubmit={async (values, { setErrors }) => {
        if (isLastStep()) {
          await props.onSubmit(values, { setErrors });
        } else if (step === 0) {
          const serviceLines = values.service_lines;

          const periodicityErrors = [];
          for (let i in serviceLines) {
            let error = {};
            const frecyency_days = serviceLines[i].frequency_days;

            if (!Object.keys(frecyency_days).length) {
              error.frecyency_days = "Debes añadir una opción";
            }

            Object.keys(error).length
              ? periodicityErrors.push(error)
              : periodicityErrors.push(undefined);
          }

          setErrors({ frequency_days: periodicityErrors });

          const nextValidation = periodicityErrors.filter(
            (frecuency) => frecuency !== undefined
          );

          if (!nextValidation.length) {
            setStep((s) => s + 1);
          }
        } else {
          setStep((s) => s + 1);
        }
      }}
      validationSchema={currentChild?.props?.validationSchema}
    >
      {({ values, errors }) => (
        <Form className={styles.adminScaleContainer}>
          <div> {currentChild} </div>
          <div
            className={`${styles.buttonsContainer} ${
              step > 0 && styles.buttonsFlex
            }
            `}
          >
            <CancelButton
              text={!isLastStep() ? "Cancelar" : "Atrás"}
              onClick={() =>
                step > 0 ? setStep((s) => s - 1) : navigate("/formularios")
              }
              type="button"
            />

            {editScale.active && step === 1 ? null : (
              <NextButton
                text={!isLastStep() ? "Siguiente" : "Guardar"}
                type="submit"
                patientBtn="patientBtn"
                disabled={editScale.active && step === 1}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
