import { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as xlsx from "json-as-xlsx";
import * as moment from "moment-timezone";

import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { getTypeParameter } from "../../services/common/admin";
import LoginButton from "../../atoms/LoginButton";
import DatePickerSince from "../../atoms/DatePickerSince";
import DatePickerUntil from "../../atoms/DatePickerUntil";
import PillInput from "../../molecules/PillInput";
import styles from "./SummaryForm.module.scss";
import { AppContext } from "../../context/AppContext/AppContext";
import { getScaleByLine, getScaleReport } from "../../services/scalesManager";
import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import Spinner from "../../atoms/Spinner";

import icon from "../../assets/imgs/signo-de-exclamacion.png";
import downloadGreen from "../../assets/imgs/icons/download.svg";
import downloadRed from "../../assets/imgs/icons/downloadRed.svg";

export default function SummaryForm() {
  const { user } = useContext(AppContext);
  const [desenlaces, setDesenlaces] = useState([]);
  const [fieldDetected, setFieldDetected] = useState(null);
  const [haveData, setHaveData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState();
  const [optionDownload, setOptionDownload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [optionsInformant, setOptionsInformant] = useState([]);
  const [optionsLossInformation, setOptionsLossInformation] = useState([]);
  const [optionsInterview, setOptionsInterview] = useState([]);
  const [lines, setLines] = useState();

  useEffect(() => {
    async function fetchData() {
      const responseInformant = await getTypeParameter(2);
      setOptionsInformant(responseInformant.data);
      const responseLossInformation = await getTypeParameter(3);
      setOptionsLossInformation(responseLossInformation.data);
      const responseoptionsInterview = await getTypeParameter(4);
      setOptionsInterview(responseoptionsInterview.data);
      const res = await apiInterceptor({
        method: "GET",
        endpoint: `/tr/admin/lines?admin=true`,
      });
      setLines(res.data);
    }
    fetchData();
  }, []);

  const filtrado = (array) => {
    const newList = [];
    const listOpt = array || [];
    listOpt.map((item, index) => {
      const found = !newList.find((repeat, indexRepeat) => {
        return item.name === repeat.name && index !== indexRepeat;
      });
      if (found) newList.push(item);
    });
    return newList;
  };

  const options = filtrado(lines);

  const lineChangeHandler = async (value) => {
    if (value && fieldDetected !== value) {
      setFieldDetected(value);
      try {
        setDesenlaces([]);
        const response = await getScaleByLine(`${value}?user=${user.email}`);
        setDesenlaces(
          response.data?.data?.response?.map((des) => ({
            key: des.id,
            value: des.name,
          }))
        );
      } catch (error) {
        console.error("Error in Scales by service line request");
      }
    }
  };

  const validate = (valores) => {
    let errores = {};
    if (!valores.type && !valores.service_line) {
      errores.type = "Debe seleccionar una opción";
      errores.service_line = "La linea de servicio es obligatoria";
    }

    if (valores.service_line && !valores.scales) {
      errores.scales = "Es necesario definir las escalas para descargar.";
    }
    if (!valores.start_date | !valores.start_date.getTime)
      errores.start_date = "Es necesario establecer la fecha inicial.";
    if (!valores.end_date | !valores.start_date.getTime)
      errores.end_date = "Es necesario establecer la fecha final.";

    if (
      valores.start_date &&
      valores.end_date &&
      valores.start_date?.getTime() > valores.end_date?.getTime()
    ) {
      errores.end_date =
        "La fecha de inicio no puede ser mayor a la fecha final.";
    }

    return errores;
  };

  const downloadHandler = async (values, actions) => {
    const initalDate = values.start_date;
    const endDate = values.end_date;
    if (submitting) return;
    setSubmitting(true);
    values.start_date = moment
      .tz(values.start_date, "America/Bogota")
      .format("YYYY-MM-DD");
    values.end_date = moment
      .tz(values.end_date, "America/Bogota")
      .format("YYYY-MM-DD");
    values.user = user.email;
    values.scales = values?.scales?.map((scale) => scale.id || scale.key);

    try {
      const response = await apiInterceptor({
        method: "GET",
        endpoint: `/tr/desenlaces/report?service_line=${values.service_line}&scales=${values.scales}&start_date=${values.start_date}&end_date=${values.end_date}&user=${values.user}`,
      });

      if (response.statusCode === 200) {
        setOptionDownload("desenlaces");
        setLoading(false);
        setStatus(response.statusCode);
      } else {
        setOptionDownload("desenlaces");
        setLoading(false);
        setStatus(response.status);
      }

    } catch (error) {
      console.error("Error on query", error);
    }

    setSubmitting(false);
    actions.resetForm({
      values: {
        service_line: "",
        scales: [],
        start_date: initalDate,
        end_date: endDate,
        type: "",
      },
    });
    setDesenlaces([]);
  };

  const downloadeScoopeReport = async (values, actions) => {
    const initalDate = moment
      .tz(values.start_date, "America/Bogota")
      .format("YYYY-MM-DD");
    const endDate = moment
      .tz(values.end_date, "America/Bogota")
      .format("YYYY-MM-DD");

    let totalScoope = 0;
    if (submitting) return;
    try {
      const res = await apiInterceptor({
        method: "GET",
        endpoint: `/tr/desenlaces/scoope?startDate=${initalDate}&endDate=${endDate}&type=${values.type}`,
      });
      totalScoope = res.data.length;

      if (res.statusCode === 200) {
        setOptionDownload("scoope");
        setLoading(false);
        setStatus(res.statusCode);
        if (totalScoope) {
          xlsx(
            [
              {
                sheet: "ScoopeReport",
                columns: [
                  { label: "DOCUMENTO_PACIENTE", value: "doc_number" },
                  { label: "NOMBRE_PACIENTE", value: "patient_name" },
                  { label: "APELLIDO_PACIENTE", value: "patient_lastname" },
                  { label: "NOMBRE_ESCALA", value: "scale_name" },
                  { label: "FECHA", value: "date" },
                  { label: "TIPO", value: "type" },
                ],
                content: res.data,
              },
            ],
            {
              fileName: `Scoope_${values.start_date}_${values.end_date}`,
            }
          );
        }
      } else {
        setOptionDownload("scoope");
        setLoading(false);
        setStatus(res.statusCode);
      }
    } catch (error) {
      console.error("Error on query", error);
    }

    setSubmitting(false);
    if (totalScoope) {
      actions.resetForm({
        values: {
          service_line: "",
          scales: [],
          start_date: values.start_date,
          end_date: values.end_date,
          type: "",
        },
      });
      setDesenlaces([]);
    } else {
      setHaveData(true);
      actions.resetForm({
        values: {
          service_line: "",
          scales: [],
          start_date: values.start_date,
          end_date: values.end_date,
          type: "",
        },
      });
      setDesenlaces([]);
    }
  };

  return (
    <Formik
      initialValues={{
        service_line: "",
        scales: [],
        start_date: "",
        end_date: "",
        type: "",
      }}
      validate={validate}
      onSubmit={(values, actions) => {
        setLoading(true);
        setOpenPopup(true);

        if (values.type) {
          downloadeScoopeReport(values, actions);
        } else if (values.service_line) {
          downloadHandler(values, actions);
        }
      }}
    >
      {({ errors, setFieldValue, values }) => (
        <Form className={styles.form}>
          <section className={styles.formContainer}>
            <div className={styles.datePickers}>
              <div className={styles.labelRange}>
                <h3>Desde</h3>
                <DatePickerSince
                  preValue={null}
                  value={values.start_date}
                  maxDate={values.end_date}
                  changeValue={(value) => {
                    if (value) setFieldValue("start_date", value);
                  }}
                />
                <ErrorMessage
                  name="start_date"
                  component={() => (
                    <div className={styles.errorMessage}>
                      <img
                        src={icon}
                        alt="Icono de exclamación"
                        className={styles.exclamationIcon}
                      />
                      {errors.start_date}
                    </div>
                  )}
                />
              </div>
              <div className={styles.labelRange}>
                <h3>Hasta</h3>
                <DatePickerUntil
                  preValue={null}
                  minDate={values.start_date}
                  changeValue={(value) => {
                    if (value) setFieldValue("end_date", value);
                  }}
                />
                <ErrorMessage
                  name="end_date"
                  component={() => (
                    <div className={styles.errorMessage}>
                      <img
                        src={icon}
                        alt="Icono de exclamación"
                        className={styles.exclamationIcon}
                      />
                      {errors.end_date}
                    </div>
                  )}
                />
              </div>
            </div>
            <div className={styles.inputLineContainer}>
              <label htmlFor="type" className={styles.label}>
                Respuesta desde
              </label>
              <Field
                className={styles.loginInputs}
                as="select"
                id="type"
                name="type"
                onInput={(ev) => {
                  setFieldValue("type", []);
                  setFieldValue("type", ev.target.value);
                  setHaveData(false);
                }}
                disabled={values.service_line}
              >
                <option value="" className={styles.documentOptions}>
                  Seleccionar
                </option>
                <option value="mail">Correo electrónico</option>
                <option value="whatsapp">Whatsapp</option>
              </Field>
              <ErrorMessage
                name="type"
                component={() => (
                  <div className={styles.errorMessage}>
                    <img
                      src={icon}
                      alt="Icono de exclamación"
                      className={styles.exclamationIcon}
                    />
                    {errors.type}
                  </div>
                )}
              />
            </div>
            <div className={styles.inputLineContainer}>
              <label htmlFor="service_line" className={styles.label}>
                Línea de servicio
              </label>
              <Field
                className={styles.loginInputs}
                as="select"
                id="service_line"
                name="service_line"
                onInput={(ev) => {
                  setFieldValue("scales", []);
                  ev?.target?.value && lineChangeHandler(ev.target.value);
                  setFieldValue("service_line", ev.target.value);
                  setHaveData(false);
                }}
                disabled={values.type}
              >
                <option value="" className={styles.documentOptions}>
                  Seleccione una
                </option>
                {(options || []).map((sl) => (
                  <option value={sl.id} className={styles.documentOptions}>
                    {sl.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="service_line"
                component={() => (
                  <div className={styles.errorMessage}>
                    <img
                      src={icon}
                      alt="Icono de exclamación"
                      className={styles.exclamationIcon}
                    />
                    {errors.service_line}
                  </div>
                )}
              />
            </div>
            <div className={styles.inputScaleContainer}>
              <label htmlFor="scales" className={styles.label}>
                Desenlaces
              </label>
              <PillInput
                selected={values.scales}
                options={desenlaces}
                disabled={values.type}
                onClosePill={(key) =>
                  setFieldValue(
                    "scales",
                    values.scales.filter((elm) => elm.key !== key)
                  )
                }
                onSelectOption={(value) =>
                  setFieldValue("scales", [...values.scales, value])
                }
              />
              <ErrorMessage
                name="scales"
                component={() => (
                  <div className={styles.errorMessage}>
                    <img
                      src={icon}
                      alt="Icono de exclamación"
                      className={styles.exclamationIcon}
                    />
                    {errors.scales}
                  </div>
                )}
              />
            </div>
          </section>
          <div className={styles.buttonContainer}>
            {haveData && (
              <p className={styles.noData}>No hay resultados de tu busqueda</p>
            )}
            <LoginButton
              // disabled={isSubmitting}
              disabled={loading}
              text="Descargar"
              btn="summaryStyles"
            />
          </div>
          <Modal
            isOpen={openPopup}
            onClose={haveData ? setOpenPopup(false) : () => setOpenPopup(false)}
            variant="confirm"
          >
            {loading ? (
              <Spinner className_container="rolesForm" />
            ) : (
              <ModalContent
                close={() => setOpenPopup(false)}
                text={
                  status === 200
                    ? optionDownload === "desenlaces"
                      ? "Resumen generado"
                      : "Resumen descargado"
                    : "No se pudo descargar\nTu resumen, inténtalo\nde nuevo"
                }
                helpText={
                  status === 200 &&
                  optionDownload === "scoop" &&
                  "Revisa en la carpeta interna de tu\ncomputador"
                }
                btns={false}
                icon={status === 200 ? downloadGreen : downloadRed}
                alt="Icono descarga"
              />
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
