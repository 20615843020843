import React, { useContext } from "react";
import * as moment from 'moment-timezone';

import HabeasData from "../../atoms/HabeasData";
import { Button } from "../../atoms/Button";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import styles from "./UserBasicInfo.module.scss";

export const UserBasicInfo = ({setUpdate}) => {
  const { userInfo } = useContext(AdminContext);

  return (
    <div className={styles.container}>
      <section className={styles.nameContainer}>
        <h2 className={styles.patientName}>{userInfo.patient.name}</h2>
        <HabeasData policyAcceptance={userInfo.patient.policy_acceptance}/>
      </section>
      <div className={styles.data}>
        <div>
          <label className={styles.dataTitle}>Fecha ingreso</label>
          <p className={styles.dataInfo}>{userInfo.ingress_date? moment.tz(userInfo.ingress_date, 'America/Bogota').format("YYYY-MM-DD HH:mm:ss"):null}</p>
        </div>
        <div>
          <label className={styles.dataTitle}>Fecha egreso</label>
          <p className={styles.dataInfo}>{userInfo.eggress_date ? moment.tz(userInfo.eggress_date, 'America/Bogota').format("YYYY-MM-DD HH:mm:ss"):null}</p>
        </div>
        <div>
          <label className={styles.dataTitle}>Evento</label>
          <p className={styles.dataInfo}>{userInfo.event_number}</p>
        </div>
        <div>
          <label className={styles.dataTitle}>Línea</label>
          <p className={styles.dataInfo}>{userInfo.service_line.name}</p>
        </div>

        <div>
          <label className={styles.dataTitle}>Profesional</label>
          <p className={styles.dataInfo}>{userInfo.staff_name}</p>
        </div>

        <div>
          <label className={styles.dataTitle}>Documento</label>
          <p className={styles.dataInfo}>{userInfo.patient.doc_number}</p>
        </div>

        <div>
          <label className={styles.dataTitle}>Edad</label>
          <p className={styles.dataInfo}>{userInfo.patient.age} años</p>
        </div>
        <div>
          <label className={styles.dataTitle}>Género</label>
          <p className={styles.dataInfo}>
            {userInfo.patient.genre === "F" ? "Femenino" : "Masculino"}
          </p>
        </div>
        <div>
          <label className={styles.dataTitle}>Correo electrónico</label>
          <p className={styles.dataInfo}>
            {userInfo.patient.email.toLowerCase()}
          </p>
        </div>
        <div>
          <label className={styles.dataTitle}>Fijo</label>
          <p className={styles.dataInfo}>{userInfo.patient.phone}</p>
        </div>
        <div>
          <label className={styles.dataTitle}>Celular</label>
          <p className={styles.dataInfo}>{userInfo.patient.mobile}</p>
        </div>
      </div>
      <div className={styles.buttonContainer}>     
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={false}
        onClick={()=> setUpdate(false)}
      >
        Actualizar
      </Button>
      </div>
    </div>
  );
};
