import CancelButton from "../../atoms/CancelButton";
import NextButton from "../../atoms/NextButton";
import styles from "./ModalContent.module.scss";

const ModalContent = ({
  close,
  confirm,
  btns,
  text,
  icon,
  alt,
  helpText,
  helpTextCustom,
  updateTitleCustom,
  btnsContainerCustom,
  cancelButtonCustom,
  nextButtonCustom,
  textCancelCustom,
  textConfirmCustom,
  typeBtnCustom,
  form,
}) => {
  return (
    <div
      className={`${styles.updateConfirmationContainer} ${
        btns === false && styles.justifyContent
      }`}
    >
      <section className={styles.infoSection}>
        <img src={icon} alt={alt} className={styles.image}/>
        <h2
          className={`
            ${styles.updateTitle} 
            ${(helpText || btns === false) && styles.noMargin}
            ${updateTitleCustom && updateTitleCustom}
          `}
        >
          {text}
        </h2>
        {helpText && (
          <p className={`${styles.helpText} ${helpTextCustom && helpTextCustom}`}>
            {helpText}
          </p>
        )}
      </section>
      <section className={styles.btnSection}>
        {btns !== false && (
          <div
            className={`${styles.btnsContainer} ${
              btnsContainerCustom && btnsContainerCustom
            }`}
          >

            <CancelButton
              text={textCancelCustom ? textCancelCustom : "No"}
              onClick={() => close(false)}
              type="button"
              className={"modalConfirm"}
              cancelButtonCustom={cancelButtonCustom}
            />

            <NextButton
              text={textConfirmCustom ? textConfirmCustom : "Si"}
              onClick={() => confirm()}
              type={typeBtnCustom ? typeBtnCustom : "button"}
              className={"modalConfirm"}
              nextButtonCustom={nextButtonCustom}
              form={form}
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default ModalContent;
