import { useState, useContext, useEffect, useMemo } from "react";
import { format } from "date-fns";
import Popover from "@mui/material/Popover";
import { useNavigate } from "react-router-dom";
import * as moment from 'moment-timezone';


import { Pagination } from "../../atoms/Pagination";
import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import Table from "../../molecules/Table";
import DatePickerSince from "../../atoms/DatePickerSince";
import DatePickerUntil from "../../atoms/DatePickerUntil";
import DateRange from "../../atoms/DateRange";
import ArrowDown from "../../assets/imgs/icons/arrowDownSort.svg";
import ArrowUp from "../../assets/imgs/icons/arrowUpSort.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";

import FilterInput from "../../molecules/FilterInput";
import { ManagerContext } from "../../context/ManagerContext/ManagerContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { AppContext } from "../../context/AppContext/AppContext";
import { getHistoricalList } from "../../services/getHistorical";
import { setItem } from "../../services/localStorage";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { offsetUser } from "../../custom/offset";
import styles from "./HistoricalTable.module.scss";

function Sort(props) {
  const {
    setSort_historical,
    setNameSort_historical,
    nameSort_historical,
    setEventSort_historical,
    eventSort_historical,
    setLineSort_historical,
    lineSort_historical,
    setScaleSort_historical,
    scaleSort_historical,
  } = useContext(DesenlacesContext);
  const handleOnChange = async (e) => {
    let valueDesc = "desc";
    let valueAsc = "asc";
    if (props.name === "Paciente") {
      let param = "name:";
      if (nameSort_historical === "name:asc") {
        setNameSort_historical(param + valueDesc);
        setSort_historical(param + valueDesc);
      } else if (nameSort_historical === "name:desc") {
        setNameSort_historical(param + valueAsc);
        setSort_historical(param + valueAsc);
      }
    } else if (props.event === "Evento") {
      let param = "event_number:";
      if (eventSort_historical === "event_number:asc") {
        setEventSort_historical(param + valueDesc);
        setSort_historical(param + valueDesc);
      } else if (eventSort_historical === "event_number:desc") {
        setEventSort_historical(param + valueAsc);
        setSort_historical(param + valueAsc);
      }
    } else if (props.service_line === "Linea") {
      let param = "service_line:";
      if (lineSort_historical === "service_line:asc") {
        setLineSort_historical(param + valueDesc);
        setSort_historical(param + valueDesc);
      } else if (lineSort_historical === "service_line:desc") {
        setLineSort_historical(param + valueAsc);
        setSort_historical(param + valueAsc);
      }
    } else if (props.scale === "Desenlace") {
      let param = "scale:";
      if (scaleSort_historical === "scale:asc") {
        setScaleSort_historical(param + valueDesc);
        setSort_historical(param + valueDesc);
      } else if (scaleSort_historical === "scale:desc") {
        setScaleSort_historical(param + valueAsc);
        setSort_historical(param + valueAsc);
      }
    }
  };

  return (
    <span className={styles.headerSorted} onClick={handleOnChange}>
      {props.name === "Paciente"
        ? {
          ...(nameSort_historical === "name:asc" ? (
            <img
              src={ArrowDown}
              className={styles.arrowSort}
              alt="Flecha abajo"
            ></img>
          ) : (
            <img
              src={ArrowUp}
              className={styles.arrowSort}
              alt="Flecha arriba"
            ></img>
          )),
        }
        : props.event === "Evento"
          ? {
            ...(eventSort_historical === "event_number:asc" ? (
              <img
                src={ArrowDown}
                className={styles.arrowSort}
                alt="Flecha abajo"
              ></img>
            ) : (
              <img
                src={ArrowUp}
                className={styles.arrowSort}
                alt="Flecha arriba"
              ></img>
            )),
          }
          : props.service_line === "Linea"
            ? {
              ...(lineSort_historical === "service_line:asc" ? (
                <img
                  src={ArrowDown}
                  className={styles.arrowSort}
                  alt="Flecha abajo"
                ></img>
              ) : (
                <img
                  src={ArrowUp}
                  className={styles.arrowSort}
                  alt="Flecha arriba"
                ></img>
              )),
            }
            : {
              ...(scaleSort_historical === "scale:asc" ? (
                <img
                  src={ArrowDown}
                  className={styles.arrowSort}
                  alt="Flecha abajo"
                ></img>
              ) : (
                <img
                  src={ArrowUp}
                  className={styles.arrowSort}
                  alt="Flecha arriba"
                ></img>
              )),
            }}
    </span>
  );
}

export default function HistoricalTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  // const [isFinished, setIsFinished] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { height, width } = useWindowDimensions();

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  const { setLocation } = useContext(ManagerContext);
  const {
    setCustomePagination_his,
    actualPage_his,
    setActualPage_his,
    setCustomePagination_hisFin,
    actualPage_hisFin,
    setActualPage_hisFin,
    historicalList,
    setHistoricalList,
    scale,
    setScale,
    name,
    setName,
    document,
    setDocument,
    line,
    setLine,
    frequency,
    setFrequency,
    sort_historical,
    pagination_his,
    pagination_hisFin,
    isFinished, setIsFinished
  } = useContext(DesenlacesContext);

  const { user, setUserPlan, setModulesSelect } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateInfo, setDateInfo] = useState(null);
  const [dateInfoUntil, setDateInfoUntil] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const COLUMNS = [
    {
      Header: "Fecha",
      accessor: "date",
      Cell: ({ row }) =>
      <p> {moment.tz(row.values.date, 'America/Bogota').format("YYYY-MM-DD")}</p>,
      width: 12,
      tooltipType: "date"
    },
    {
      accessor: "patient",
      Header: ({ column }) => {
        return (
          <>
            <div className={styles.headerContainer}>
              <p className={styles.headerLabel}>
                Paciente
                <span id="Paciente" className={styles.headerSorted}>
                  {column.canFilter ? <Sort name="Paciente" /> : null}
                </span>
              </p>
              <div id="Paciente" className={styles.headerInput}>
                {
                  column.canFilter &&
                  <FilterInput 
                    column={column} 
                    handleGetFilter={setName}
                    filterValue={name}
                  /> 
                }
              </div>
            </div>
          </>
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleSelected(row.original)}
          >
            {row.original.patient}
          </p>
        );
      },
      width: 25,
    },
    {
      accessor: "doc_number",
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>Documento</p>
            <div id="Documento" className={styles.headerInput}>
              {
                column.canFilter &&
                <FilterInput 
                  column={column} 
                  handleGetFilter={setDocument}
                  filterValue={document}
                /> 
              }
            </div>
          </div>
        </>
      ),
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <>
            <div className={styles.headerContainer}>
              <p className={styles.headerLabel}>
                Evento
                <span id="Evento" className={styles.headerSorted}>
                  {column.canFilter ? <Sort event="Evento" /> : null}
                </span>
              </p>
            </div>
          </>
        );
      },
      accessor: "event_number",
      width: 8,
    },
    {
      accessor: "service_line",
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>
              Línea
              <span id="Linea" className={styles.headerSorted}>
                {column.canFilter ? <Sort service_line="Linea" /> : null}
              </span>
            </p>
            <div id="Linea" className={styles.headerInput}>
              {
                column.canFilter &&
                <FilterInput 
                  column={column} 
                  handleGetFilter={setLine}
                  filterValue={line}
                /> 
              }
            </div>
          </div>
        </>
      ),
      width: 17.5,
    },
    {
      accessor: "scale",
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>
              Desenlace
              <span id="Desenlace" className={styles.headerSorted}>
                {column.canFilter ? <Sort scale="Desenlace" /> : null}
              </span>
            </p>
            <div id="Desenlace" className={styles.headerInput}>
              {
                column.canFilter &&
                <FilterInput 
                  column={column} 
                  handleGetFilter={setScale}
                  filterValue={scale}
                /> 
              }
            </div>
          </div>
        </>
      ),
      width: 17.5,
    },
    {
      accessor: "frequency",
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>Periodo</p>
            <div id="Periodo" className={styles.headerInput}>
              {
                column.canFilter &&
                <FilterInput 
                  column={column} 
                  handleGetFilter={setFrequency}
                  filterValue={frequency}
                /> 
              }
            </div>
          </div>
        </>
      ),
      width: 5,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const handleGetHistoricalList = async () => {
    let params = {
      user: user.email,
      scale,
      name,
      doc_num: document,
      service_line: line,
      frequency,
      sort: sort_historical,
      date_from: dateInfo ? format(dateInfo, "yyyy-MM-dd") : "",
      date_until: dateInfoUntil ? format(dateInfoUntil, "yyyy-MM-dd") : "",
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    setIsLoading(true);
    const response = await getHistoricalList(
      offsetUser(height, width),
      isFinished === false ? actualPage_his : actualPage_hisFin,
      isFinished,
      route
    );

    const lastPage = await response.data.data.pagination.last_page;
    const actualPage_1 = await response.data.data.pagination.page;

    setHistoricalList(response.data.data.response);
    setCustomePagination_his({ page: actualPage_1, paginationTotal: lastPage });
    setCustomePagination_hisFin({
      page: actualPage_1,
      paginationTotal: lastPage,
    });
    if (response.status === 200) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setOpenErrorModal(true)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = async (selected) => {
    await setUserPlan(selected.id_plan);
    handleRedirect(selected);
  };

  const handleRedirect = (selected) => {
    setModulesSelect('desenlaces')
    setLocation("historical");
    setItem("module", "desenlaces")
    navigate(`/desenlaces/historico/diligenciamiento/${selected.id_plan}`);
  };

  const handleChange = async() => {
    await setActualPage_hisFin(1);
    await setActualPage_his(1);  
  };

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [scale, name, document, line, frequency]);

  useEffect(() => {
    if (isLoading) return;
    handleGetHistoricalList();
  }, [
    actualPage_his,
    actualPage_hisFin,
    sort_historical,
    dateInfo,
    dateInfoUntil,
    control,
    isFinished
    
  ]);

  useEffect(() => {
    if (
      (scale !== null && scale.length) ||
      name.length ||
      document.length ||
      line.length ||
      frequency.length > 0
    ) {
      setActualPage_his(1);
      setActualPage_hisFin(1);
    }
  }, [scale, name, document, line, frequency]);

  return (
    <>
      <div className={styles.option}>
        <div className={styles.dataLabelContainer}>
          <div className={styles.dateLabel}>Desde</div>
          <DatePickerSince
            preValue={dateInfo}
            changeValue={(value) => {
              setDateInfo(value);
            }}
            maxDate={dateInfoUntil}
            withCleaner={true}
          />
        </div>
        <div style={{ padding: "10px" }}></div>
        <div className={styles.dataLabelContainer}>
          <div className={styles.dateLabel}>Hasta</div>
          <DatePickerUntil
            preValue={dateInfoUntil}
            changeValue={(value) => {
              setDateInfoUntil(value);
            }}
            minDate={dateInfo}
            withCleaner={true}
          />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateRange changeValue={(value) => { }} />
        </Popover>
      </div>
      <div className={styles.tabsContainer}>
        <span
          className={`${styles.finalizedBtn} ${isFinished && styles.selectedBtn
            }`}
          onClick={isFinished === false ? async() => {
            setIsFinished(true)
            handleChange()
          }
            : null}
        >
          Finalizados
        </span>        
        <span
          className={`${styles.draftCopyBtn} ${!isFinished && styles.selectedBtn
            }`}
          onClick={isFinished === true ? async () =>{ 
            setIsFinished(false)
            handleChange()}
            : null}
        >
          Borradores
        </span>
      </div>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={historicalList}
        isFinished={isFinished}
        selected={handleSelected}
        getData={handleGetHistoricalList}
        offsetUser={offsetUser}
        actualPage={isFinished ? actualPage_hisFin : actualPage_his}
      />
      {isFinished ? (
        <Pagination
          actualPage={actualPage_hisFin}
          setPage={setActualPage_hisFin}
          infoPage={pagination_hisFin}
          className={styles.pagination}
        />
      ) :

        <Pagination
          actualPage={actualPage_his}
          setPage={setActualPage_his}
          infoPage={pagination_his}
          className={styles.pagination}
        />
      }

      <Modal
        isOpen={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        variant="confirm"
      >
        <ModalContent 
          close={() => setOpenErrorModal(false)} 
          text={"Error al traer los datos"}
          icon={personRed}
          btns={false}
          alt="Icono error de datos"
        />
      </Modal>
    </>
  );
}
