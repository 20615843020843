import React, { useContext, useEffect, useMemo, useState } from "react";
import * as moment from "moment-timezone";

import { Pagination } from "../../atoms/Pagination";
import { Switch } from "../../atoms/SwitchFSFB";
import TableHeader from "../../atoms/TableHeader";
import Table from "../../molecules/Table";
import { UsersContext } from "../../context/UsersContext/UsersContext";
import { offsetUser } from "../../custom/offset";
import { useSort } from "../../hooks/useSort";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import styles from "./UsersAdminTable.module.scss";

function randomizer() {
  return Math.floor(Math.random() * 100000);
}

export default function UsersAdminTable({
  newUserRender,
  isLoading,
  setIsLoading,
  renderControl,
  handleEditStatus,
  childData,
  handleSelected,
}) {
  const {
    userList,
    setUserList,
    rol_users,
    setRol_users,
    email_users,
    setEmail_users,
    updateBy_users,
    setUpdateBy_users,
    name_users,
    setName_users,
    sort_users,
    setSort_users,
    actualPage,
    setActualPage,
    pagination,
    setCustomePagination,
    setDiligenceInfo,
  } = useContext(UsersContext);

  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();

  const handleGetUsersList = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort_users,
      name: name_users,
      email: email_users,
      rol: rol_users,
      updated_by: updateBy_users,
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/users?${route}`,
    });
    const lastPage = res.pagination.last_page;
    const actualPage_1 = res.pagination.page;
    setUserList(res.data);
    setCustomePagination({ page: actualPage_1, paginationTotal: lastPage });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetUsersList();
  }, [actualPage, sort_users, control, newUserRender, renderControl]);

  // custom hook sort
  useSort(sort_users, setSort_users);

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Estado" column={column} />;
      },
      accessor: "switch",
      Cell: ({ row }) => (
        <>
          <div className={styles.switchContainer}>
            {row.original.active}
            <Switch
              toggled={row.original.active}
              onChange={() => {
                handleEditStatus(row.original);
              }}
              name={row.original.id}
            />
          </div>
        </>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setName_users}
            filterValue={name_users}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleSelected(row.original)}
          >
            {row.original.name}
          </p>
        );
      },
      accessor: "name",
      width: 25,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Correo"
            column={column}
            handleGetFilter={setEmail_users}
            filterValue={email_users}
          />
        );
      },
      accessor: "email",
      width: 20,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Rol"
            column={column}
            handleGetFilter={setRol_users}
            filterValue={rol_users}
          />
        );
      },
      accessor: "rol.name",
      width: 10,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Modificado por"
            column={column}
            handleGetFilter={setUpdateBy_users}
            filterValue={updateBy_users}
          />
        );
      },
      accessor: "updated_by",
      width: 20,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha modificado" column={column} />;
      },
      Cell: ({ row }) => (
        <p>
          {moment
            .tz(row.values.updated_at, "America/Bogota")
            .format("YYYY-MM-DD HH:mm")}
        </p>
      ),
      accessor: "updated_at",
      width: 15,
      textAlign: "right",
      disableFilters: true,
      tooltipType: "date"
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [rol_users, email_users, name_users, updateBy_users]);

  useEffect(() => {
    if (
      rol_users.length ||
      email_users.length ||
      name_users.length ||
      updateBy_users?.length > 0
    ) {
      setActualPage(1);
    }
  }, [rol_users, email_users, name_users, updateBy_users]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={userList}
        offsetUser={offsetUser}
        tooltipText="Habilita y asigna roles a las personas que gestionarán las funciones de la App."
        childData={childData}
        setValues={setDiligenceInfo}
      />
      <Pagination
        actualPage={actualPage}
        setPage={setActualPage}
        infoPage={pagination}
      />
    </>
  );
}
