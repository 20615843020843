import styles from "./ModuleTemplate.module.scss";

export default function ModuleTemplate({ children }) {
  return (
    <div className={styles.templateContainer}>
      {children.map((child, index) => {
        switch (child?.props?.name) {
          case "options":
            return (
              <section className={styles.optionsSection} key={index}>
                {child}
              </section>
            );
          case "Tollbox":
            return (
              <section className={styles.toolboxSection} key={index}>
                {child}
              </section>
            );
          case "filters":
            return (
              <section className={styles.filtersSection} key={index}>
                {child}
              </section>
            );
          case "Table":
            return (
              <section className={styles.tableSection} key={index}>
                {child}
              </section>
            );
          default:
            return null;
        }
      })}
    </div>
  );
}
