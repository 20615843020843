import React, { useState } from "react";

import { updateTrackConsent } from "../../services/Contigo/patient";

import { toBase64 } from "../../custom/base64";

import { UploadFile } from "../../atoms/UploadFile";
import Spinner from "../../atoms/Spinner";
import Modal from "../../atoms/Modal";

import GreenFlag from "../../assets/imgs/icons/GreenFlag.svg";
import RedFlag from "../../assets/imgs/icons/DeleteIcon.svg";

import styles from "./EquipmentInformation.module.scss";

const initialModalValues = {
  open: false,
  title: "",
  subtitle: "",
  img: "",
};

export const EquipmentInformation = ({ equipmentInfo, trackId }) => {
  const hasEquipment = !equipmentInfo.equipment ? "No" : "Si";
  const [, materialFile] = equipmentInfo.file_material?.split("/") || "";
  const [, consentFile] = equipmentInfo.file_consent?.split("/") || "";

  const [openModal, setOpenModal] = useState(initialModalValues);
  const [loading, setLoading] = useState(false);

  const [currentConsent, setCurrentConsent] = useState(consentFile);

  const materialFileUrl = equipmentInfo.file_material_url;
  const consentFileUrl = currentConsent ? equipmentInfo.file_consent_url : "";

  const uploadTitle = consentFile ? "Cambiar adjunto" : "Adjuntar";

  const handleChangeFile = async (file) => {
    setLoading(true);
    try {
      const base64File = await toBase64(file);
      const base64FileToSend = base64File.replace("data:application/pdf;base64,", "");
      const { data } = await updateTrackConsent({
        track_id: trackId,
        file_consent: base64FileToSend,
      });
      setCurrentConsent(data[0].file_consent_path);
      setOpenModal({
        open: true,
        img: GreenFlag,
        title: "Adjunto cargado"
      });
      return data[0].file_consent_url;
    } catch (err) {
      console.error(err);
      setOpenModal({
        open: true,
        img: RedFlag,
        title: "Tu adjunto no se cargó exitosamente"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={styles.equipmentContainer}>
      <section className={styles.equipmentSectionComment}>
        <span className={styles.fieldName}>Equipos/Insumos:</span>
        <span className={styles.fieldValue}>
          <strong>{hasEquipment}</strong>
          {equipmentInfo.observations && (
            <>
              <strong>, </strong>
              {equipmentInfo.observations}
            </>
          )}
        </span>
      </section>
      <section className={styles.equipmentSection}>
        <span className={styles.fieldName}>Material educativo:</span>
        <a
          id={`materialFile-${trackId}`}
          href={materialFileUrl}
          className={styles.fieldValue}
        >
          {materialFile}
        </a>
      </section>
      <section className={styles.equipmentSection}>
        <span className={styles.fieldName}>Consentimiento:</span>
        <UploadFile
          id={`consentFileAttach-${trackId}`}
          href={consentFileUrl}
          name={currentConsent}
          modalType
          title={uploadTitle}
          extension="pdf"
          type="application/pdf"
          maxSize={5e6}
          onError={() => setOpenModal({
            open: true,
            title: "Inténtalo de nuevo",
            subtitle: "Asegúrate de que tu archivo esté en formato PDF y no exceda las 5mb",
            img: RedFlag,
          })}
          onChange={handleChangeFile}
        />
      </section>

      <Modal
        variant="large"
        containerStyle={styles.modalContainer}
        isOpen={openModal.open}
        onClose={() => setOpenModal(initialModalValues)}
      >
        <section className={styles.modalContainer}>
          <img src={openModal.img} alt="Modal" />
          <h3 className={styles.modalTitle}>{openModal.title}</h3>
          <span className={styles.modalSubtitle}>{openModal.subtitle}</span>
        </section>
      </Modal>

      <Modal
        variant="large"
        containerStyle={styles.modalContainer}
        isOpen={loading}
        onClose={() => setLoading(false)}
      >
        <Spinner />
      </Modal>
    </section>
  )
};
