import { useContext, useEffect, useState } from "react";
import { useField } from "formik";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import Modal from "../../atoms/Modal";
import CardFSFB from "../../atoms/Card";
import { Button } from "../../atoms/Button";
import { PatientDetail } from "../PatientDetail";
import ErrorMessage from "../../atoms/ErrorMessage";
import { ContigoAddEquipment } from "../ContigoAddEquipment";
import { TitleAccordion } from "../../molecules/TitleAccordion";
import UserUpdateConfirmation from "../../atoms/UserUpdateConfirmation";

import styles from "./ListPatientSelected.module.scss";

const equipmentInitialValues = {
  equipment: true,
  file_material: "",
  observations: "",
  file_name: null,
};

export const ListPatientSelected = ({ ...props }) => {
  const { listSelectUsers, setListSelectUsers, setUserInfo, userInfo } = useContext(ContigoContext);
  const [isActive, setIsActive] = useState(true);
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const [equipmentValues, setEquipmentValues] = useState(equipmentInitialValues);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleDeleteUser = (id) => {
    setListSelectUsers(listSelectUsers.filter((user) => user.id_patient !== id));
  };

  useEffect(() => {
    setValue(listSelectUsers);
  }, [listSelectUsers]);

  const handleEdit = user => {
    setUserInfo(user);
    setEquipmentValues({
      equipment: user?.equipment,
      observations: user?.observations,
      file_material: user?.file_material,
      file_name: user?.file_name,
    })
    setOpenEditModal(true);
  };

  const handleSave = () => {
    const currentUserIndex = listSelectUsers.findIndex(user => user.id_patient === userInfo.id_patient);
    const currentUserValues = {
      ...userInfo,
      file_material: equipmentValues.file_material,
      file_name: equipmentValues.file_name,
      observations: equipmentValues.observations,
      equipment: equipmentValues.equipment,
    };

    const newSelectedUsers = [...listSelectUsers];
    newSelectedUsers[currentUserIndex] = currentUserValues;
    setListSelectUsers(newSelectedUsers);
    setOpenEditModal(false);
    setOpenSaveModal(true);
  };

  return (
    <CardFSFB>
      <section className={styles.cardContainer}>
        <TitleAccordion
          handleClick={() => setIsActive(!isActive)}
          isActive={isActive}
        >
          <h2 className={styles.titleCard}>1. Pacientes</h2>
        </TitleAccordion>
        {isActive ? (
          <div className={styles.listContainer}>
            {field.value.map((user) => (
              <div
                key={user.id_patient}
                className={styles.containerPill}
              >
                <div
                  className={styles.pillOption}
                  onClick={() => handleEdit(user)}
                >
                  <p className={styles.name}>
                    {user.names} {user.last_name} {user.mother_last_name}{" "}
                  </p>
                  <p className={styles.document}> {user.doc} </p>
                </div>
                <button
                  type="button"
                  className={styles.deleteButton}
                  onClick={() => handleDeleteUser(user.id_patient)}
                >
                  X
                </button>
              </div>
            ))}
            {meta.touched && <ErrorMessage error={meta.error} meta={meta} />}
          </div>
        ) : null}
      </section>

      <Modal
        isOpen={openEditModal}
        onClose={() => setOpenEditModal(false)}
        variant="large"
        containerStyle={styles.editContainer}
      >
        <>
          <PatientDetail canUpdate={false} />
          <ContigoAddEquipment modalType equipmentValues={equipmentValues} setEquipmentValues={setEquipmentValues} />
          <section className={styles.saveButton}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Guardar
            </Button>
          </section>
        </>
      </Modal>

      <Modal
        isOpen={openSaveModal}
        onClose={() => setOpenSaveModal(false)}
        variant="large"
        containerStyle={styles.saveContainer}
      >
        <UserUpdateConfirmation
          status={200}
          title="Datos guardados"
        />
      </Modal>
    </CardFSFB>
  );
};
