import { useState, useEffect } from "react";

function useSelect(childDataState, propertyNameMap) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const list = childDataState?.map((row) => {
      const mappedProperties = {};
      for (const [key, value] of Object.entries(propertyNameMap)) {
        mappedProperties[key] = row?.original?.[value];
      }
      return mappedProperties;
    });
    setSelectedOptions(list);
  }, [childDataState]);

  return selectedOptions;
}

export default useSelect;
