import { useState, useContext } from "react";
import ModuleTemplate from  "../../templates/ModuleTemplate";
import PatientsDesenlacesTable from "../../atoms/PatientsDesenlacesTable";
import Filters from "../Filters";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import withAuth  from '../../HOC/withAuth';


 function PatientsDesenlaces() {
  const {
    generePacientes, setGenerePacientes,
    agePacientes, setAgePacientes,
    updatePacientes, setUpdatePacientes
  } = useContext(FiltersContext);

  return (
    <>
      <ModuleTemplate>
        <Filters
          name="filters"
          module="pacientesDesenlaces"
          genere={generePacientes}
          setGenere={setGenerePacientes}
          ageFilter={agePacientes}
          setAgeFilter={setAgePacientes}
          updateFilter={updatePacientes}
          setUpdateFilter={setUpdatePacientes}
        />
        <PatientsDesenlacesTable
          name="Table"
        />
      </ModuleTemplate>
    </>
  );
}
export default withAuth(PatientsDesenlaces,'/desenlaces/pacientes')
