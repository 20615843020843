import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const saveScale = async (data) => {
  try {
    const request = await axios.post(
      `${API_URL}/tr/forms/patient/saveAnswers`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateScale = async (id, data) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/scale/updateScale/${id}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getScaleReport = async ({
  service_line,
  scales,
  start_date,
  end_date,
  user,
}) => {
  try {
    const request = await axios.get(`${API_URL}/recovery/scale/getReport`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      params: {
        service_line,
        scales: Array.isArray(scales) ? scales.join(",") : scales,
        start_date,
        end_date,
        user,
      },
    });
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getScaleByLine = async (service_line_id) => {
  try {
    const request = await axios.get(
      `${API_URL}/recovery/getScalesByLine/${service_line_id}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
