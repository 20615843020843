import React from 'react';
import styles from './NpsQuestionPreview.module.scss';

const NpsQuestionPreview = ({...props}) => {

  return (
    <div className={styles.npsContainer}>
      <section role="group" className={styles.npsGroup}>
        {props.options.map((value, index) => (
          <label key={index} className={styles.npsOption} >
            <input
              type="radio"
              value={value.detail}
              style={
                index <= 6 ? {borderColor: "#F24E68"}
                : index <= 8 ? {borderColor: "#FFD772"}
                : {borderColor: "#69CA63"}
                }
              disabled
            />
            <p className={styles.npsValue}>
              {value.detail}
            </p>
          </label>
        ))}
      </section>
      <section className={styles.rangesSection}>
        <p>{props.ranges[0].detail}</p>
        <p>{props.ranges[1].detail}</p>
      </section>
    </div>
  );
};

export default NpsQuestionPreview;