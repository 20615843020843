import React, { useState, useEffect ,useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as moment from 'moment-timezone';

import styles from "./EditNewUserForm.module.scss";
import NextButton from "../../atoms/NextButton";
import TextInput from "../../atoms/TextInput";
import SelectOption from "../../atoms/SelectOption";
import { getAllRol } from "../../services/roles";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { UsersContext } from "../../context/UsersContext/UsersContext";
import Spinner from "../../atoms/Spinner";

export default function EditNewUserForm({ setOpenEditUser, setError400, user, handleClose, setOpen, newUserRender, setNewUserRender }) {

  const { setEditStatus } = useContext(UsersContext)
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const response = await getAllRol();
      setRoles(response.response);
    }
    fetchData();
  }, []);


  return (
    <>
    {
      isLoading ? <Spinner className_container="rolesForm" /> :
        <Formik
          initialValues={{
            name: user.name ? user.name : "",
            email: user.email ? user.email.split('@')[0] : "",
            role: user?.rol?.id ? parseInt(user?.rol?.id) : ""
          }}
          onSubmit={async(values) => {
            const id = user.id
            const schemaCreate = {
              name: values.name,
              email: values.email+'@fsfb.org.co',
              rol: parseInt(values.role),
            };
            setIsLoading(true)
            const data = await apiInterceptor({
              method: "PUT",
              endpoint: `/tr/admin/users/${id}`,
              data: schemaCreate,
            });
            setEditStatus(data.statusCode)

            if (data.statusCode === 200) {
              setNewUserRender(!newUserRender);
              setOpen(true);
              setIsLoading(false)
              handleClose();
            } else {
              setOpen(true);
              setOpenEditUser(false)
              setIsLoading(false)
              // setError400(data.message);
              setError400("No se pudo completar la acción,\ninténtalo de nuevo");
            }

          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .matches(/^([A-Za-z_.\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,'Formato errado')
              .required("Campo obligatorio"),
            name: Yup.string().matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,'Formato errado').required("Campo obligatorio") ,
            role: Yup.string().required("Campo obligatorio"),
          })}
        >
          {() => (
            <Form className={styles.form}>
              <h2 className={styles.title}>Editar usuario</h2>
              <div className={styles.inputsContainer}>
                <div className={styles.inputOption}>
                  <TextInput
                    label="Correo electrónico"
                    name="email"
                    type="text"
                    className={styles.label}
                    classInput={styles.classInput}
                    customInputContainer={styles.customInputContainer}
                    placeholder="usuario"
                    isEmail={true}
                  />
                </div>
                <div className={styles.inputOption}>
                  <TextInput
                    label="Nombre"
                    name="name"
                    type="text"
                    className={styles.label}
                    classInput={styles.classInput}
                    customInputContainer={styles.customInputContainer}
                    placeholder="Nombre"
                  />
                </div>
                <div className={styles.inputOption}>
                  <SelectOption 
                    label="Rol" 
                    name="role"
                    className={styles.selectOptionContainer}
                    classNameL={styles.label}
                    classInputSelect={styles.classInputSelect}
                    errorMessageStyle={styles.errorMessageStyle}
                  >
                    <option value="" disabled selected>
                      Seleccionar
                    </option>
                    {roles.map((role) => (
                      <option
                        value={role.id}
                        className={styles.documentOptions}
                      >
                        {role.name}
                      </option>
                    ))}
                  </SelectOption>
                </div>
                <div className={`${styles.inputOption} ${styles.updatedBy}`}>
                  <div>
                  <label className={styles.updatedBylabel}>Última modificación</label>
                  <p className={styles.updatedByData}>{ moment.tz(user.updated_at, 'America/Bogota').format("YYYY-MM-DD HH:mm")} - Por: {user.updated_by}</p>
                  </div>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <NextButton type="submit" text="Aceptar" />
              </div>
            </Form>
          )}
        </Formik>
    }
    </>
  );
}
