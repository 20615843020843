import { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";

import { getSubModules, getModules } from "../../services/roles";
import PillInput from "../../molecules/PillInput";
import PillInputNew from "../../molecules/PillInputNew";
import ErrorMessage from "../../atoms/ErrorMessage";

export const RolModules = ({ setModule, edit, setTotalSubmodules, ...props }) => {
  const [field, meta, helpers] = useField({ ...props });
  const { values, setFieldValue } = useFormikContext();
  const { submodule, setSubmodule } = props;
  const [listmodules, setListModules] = useState();
  const { setValue } = helpers;

  const fetchModules = async () => {
    const response = await getModules();
    setListModules(
      response[0].map((module) => ({ key: module.id, value: module.label, submodules: module.submodules, }))
    );
    if (setTotalSubmodules) {
      setTotalSubmodules(
        response[0].map((module) => ({ key: module.id, value: module.label, submodules: module.submodules, }))
      );
    }
  };

  useEffect(() => {
    fetchModules();
  }, []);

  const list = listmodules?.map((element) => ({
    value: element.key,
    label: element.value,
    submodules: element.submodules,
  }));

const missingSubmodules = list?.filter((element) => !submodule.some((sub) => sub.value === element.value));

  return (
    <div className={props.className && props.className}>
      {
        list !== undefined ?
        <PillInputNew
        options={missingSubmodules}
        setSubmodule={setSubmodule}
        submodule={submodule}
        setValue={setValue}
        />
        : null
      }
      {meta.touched && <ErrorMessage error={meta.error} meta={meta}  />}
    </div>
  );
};
