export const optionList  = [
  {
    label: "Opción múltiple",
    value: 1,
  },
  {
    label: "Opción múltiple",
    value: 2,
  },
  {
    label: "Única opción",
    value: 3,
  },
  {
    label: "Texto abierto",
    value: 8,
  },
  {
    label: "Fecha",
    value: 4,
  },
  {
    label: "Numérica",
    value: 5,
  },
  {
    label: "Likert",
    value: 6,
  },
  {
    label: "NPS",
    value: 12,
  },

];