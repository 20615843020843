import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

import CanvasPreview from "../../atoms/CanvaPreview";
import NpsQuestionPreview from "../../atoms/NpsQuestionPreview";
import styles from "./QuestionOptionCard.module.scss";

const QuestionOptionCard = ({ question }) => {
  return (
    <div className={styles.cardQuestion}>
      <section className={styles.cardTitle}>
        <h3>{question.detail}</h3>
      </section>
      <section className={styles.cardOption}>
        {question.type === 2 ? (
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-form-control-label-placement"
                name={question.id}
              >
                {question.options !== undefined &&
                  question.options?.map((option, index) => (
                    <>
                      {!option.to_delete && (
                        <FormControlLabel
                          key={index}
                          value={option.order}
                          control={<Radio style={{ color: "#009DAC" }} />}
                          label={option.detail}
                        />
                      )}
                    </>
                  ))}
              </RadioGroup>
            </FormControl>
          </>
        ) : question.type === 1 ? (
          <>
            {question.options !== undefined &&
              question.options.map((option, index) => (
                <>
                  {!option.to_delete && (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox style={{ color: "#009DAC" }} />}
                      label={option.detail}
                    />
                  )}
                </>
              ))}
          </>
        ) : question.type === 3 ? (
          <>
            <select className={styles.selectContainer}>
              <option value="">Seleccionar</option>
              {question.options !== undefined &&
                question.options.map((option, index) => (
                  <>
                    {!option.to_delete && (
                      <option value={option.score} key={index}>
                        {option.detail}
                      </option>
                    )}
                  </>
                ))}
            </select>
          </>
        ) : question.type === 4 ? (
          <div className={styles.dateOption}>
            <TextField
              type="date"
              sx={{ width: 220 }}
              className={styles.datePickerInput}
            />
            <TextField
              type="time"
              sx={{ width: 120 }}
              className={styles.datePickerInput}
            />
          </div>
        ) : question.type === 5 ? (
          <textarea
            name={`${question.id}`}
            rows="4"
            cols="50"
            className={`${styles.textArea}`}
          />
        ) : question.type === 6 ? (
          <div>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name={question.id}
            >
              {question.options !== undefined &&
                question.options.map((option, index) => (
                  <>
                    {!option.to_delete && (
                      <FormControlLabel
                        labelPlacement="top"
                        key={index}
                        value={option.score}
                        control={<Radio style={{ color: "#009DAC" }} />}
                        label={option.detail}
                      />
                    )}
                  </>
                ))}
            </RadioGroup>
          </div>
        ) : question.type === 8 ? (
          <textarea
            name={`${question.id}`}
            rows="4"
            cols="50"
            className={`${styles.textArea}`}
          />
        ) : question.type === 10 ? (
          <CanvasPreview name={`${question.id}`} disabled={true} />
        ) : question.type === 11 ? (
            <div className={styles.thermoCard}>
              <ul className={styles.uList}>
                <li>Nos gustaría saber lo buena o mala que es su salud HOY.</li>
                <li>
                  La escala está numerada de 0 a 100.
                  <br />
                  100 representa la <u>mejor</u> salud que se pueda imaginar.
                  <br />0 representa la <u>peor</u> salud que se pueda imaginar.
                </li>
                <li>
                  Por favor, haga clic en la escala para indicar cuál es su
                  <br />
                  estado de salud HOY.
                </li>
              </ul>

              <div className={styles.thermoInfo}>
                <p className={styles.thermoTxt}>
                  La mejor salud que
                  <br />
                  se pueda imaginar
                </p>
                <div className={styles.termometro}>
                  <input
                    type="range"
                    id="range"
                    min="0"
                    max="100"
                    step="1"
                    className={styles.slider}
                    disabled
                  />
                </div>
                <p className={styles.thermoTxt}>
                  La peor salud que
                  <br />
                  se pueda imaginar
                </p>
              </div>
            </div>
          ) : (question.type === 12 && (
            <NpsQuestionPreview
              options={question.options}
              ranges={question.ranges}
            />
          ))
        }
        {/* <div className={styles.CardObservationOption}>
          <p className={styles.label}>Observación</p>
          {}
        </div> */}
      </section>
    </div>
  );
};

export default QuestionOptionCard;
