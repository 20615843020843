import React, {useEffect, useState} from 'react'
import * as moment from "moment-timezone";
import { getLineService } from "../../services/patients";
import { getTypeParameter } from "../../services/common/admin";
import { genreOptions, ageOptions, updateOptions, alertOptions } from './filters';
import DatePickerSince from "../../atoms/DatePickerSince";
import DatePickerUntil from "../../atoms/DatePickerUntil";
import FilterList from '../../molecules/FilterList';
import PillInput from "../../molecules/PillInput";
import styles from "./FiltersOptions.module.scss";

export default function FiltersOptions({
  filter,
  index,
  filterIndex,
  setFilterIndex,
  setIsDropdownOpen,
  isDropdownOpenFilter,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  genere,
  setGenere,
  ageFilter,
  setAgeFilter,
  genereLabel,
  setGenereLabel,
  ageLabel,
  setAgeLabel,
  setIdOptions,
  idOptions,
  setUpdateLabel,
  updateLabel,
  setUpdateFilter,
  updateFilter,
  setAlertLabel,
  alertLabel,
  setAlertFilter,
  alertFilter,
  observationFilter,
  setObservationFilter,
  monitoringFilter,
  setMonitoringFilter,
  options,
  setOptions,
  reasonsOptions,
  setReasonsOptions,
  children
}) {

  const [optionSelected, setOptionSelected] = useState("");

  useEffect(() => {
    setOptionSelected(
      filter.name === "Fecha Desde" ? startDate :
      filter.name === "Fecha Hasta" ? endDate :
      filter.name === "Género" ? genere :
      filter.name === "Edad" ? ageFilter :
      filter.name === "Líneas de servicio" ? idOptions :
      filter.name === "Al día" ? updateFilter :
      filter.name === "Alerta" ? alertFilter :
      filter.name === "Observación" ? observationFilter :
      filter.name === "Tipo de monitoreo" ? monitoringFilter : ""
    );
  }, [
    startDate,
    endDate,
    genere,
    ageFilter,
    idOptions,
    updateFilter,
    alertFilter,
    observationFilter,
    monitoringFilter
  ])

  const handleToggleDropdown = () => {
    setIsDropdownOpen(false)
    setFilterIndex(filterIndex === index ? -1 : index);
  };

  const [lines, setLines] = useState();
  const [reasons, setReasons] = useState();

  useEffect(() => {
    if (filter.name === "Líneas de servicio") {
      setIdOptions(options.map((id) => id.key))
    } else if (filter.name === "Tipo de monitoreo") {
      setMonitoringFilter(options.map((id) => id.key))
    } else if (filter.name === "Observación") {
      setObservationFilter(reasonsOptions.map((id) => id.key))
    }

  }, [options, reasonsOptions]);

  useEffect(() => {
    if (filter.name === "Líneas de servicio") {
      const getLines = async () => {
        const res = await getLineService("100");
        setLines(res);
      };
      getLines();
    } else if (filter.name === "Tipo de monitoreo") {
      const getLines = async () => {
        const res = await getLineService("101");
        setLines(res);
      };
      getLines();
    } else if (filter.name === "Observación") {
      const getReasons = async () => {
        const res = await getTypeParameter(8);
        setReasons(res.data);
      };
      getReasons()
    }
  }, [filter.name]);

  return (
  <>
    <div className={styles.selectContainer}>
      <div
        className={
          (optionSelected === "" || optionSelected === null || optionSelected.length === 0) ?
          styles.buttonOption
          : styles.buttonOptionBlue
        }
        onClick={handleToggleDropdown}
      >
        {(optionSelected === "" || optionSelected === null || optionSelected.length === 0) ?
        <>
          <div className={styles.imgContainer}>
            <img src={filter.img} alt="" />
          </div>
          <p>{filter.name}</p>
        </>
        :
        <>
          <div className={styles.imgContainer}>
            <img src={filter.imgBlue} alt="" />
          </div>
          <p className={styles.buttonOptionSelected}>
            {filter.name}:&nbsp;
            <span style={{fontFamily: "Gilroy-Regular"}}>
              {
                filter.name === "Fecha Desde" && startDate?.getFullYear().toString().length === 4 ? moment.tz(startDate, "America/Bogota").format("YYYY-MM-DD") :
                filter.name === "Fecha Hasta" && endDate?.getFullYear().toString().length === 4 ? moment.tz(endDate, "America/Bogota").format("YYYY-MM-DD") :
                filter.name === "Género" ? genereLabel :
                filter.name === "Edad" ? ageLabel :
                filter.name === "Líneas de servicio" ? `cant: ${optionSelected.length}` :
                filter.name === "Al día" ? updateLabel :
                filter.name === "Alerta" ? alertLabel :
                filter.name === "Tipo de monitoreo" ? `cant: ${optionSelected.length}` :
                filter.name === "Observación" ? `cant: ${optionSelected.length}` : ""
              }
            </span>
          </p>
        </>
        }

      </div>
      {isDropdownOpenFilter && (
        <div className={styles.dropdownContainer}>
          {filter.name === "Fecha Desde" ?
            <DatePickerSince
              preValue={startDate}
              changeValue={(value) => {
                setStartDate(value)
              }}
              maxDate={endDate}
              filterValue={startDate !== null && startDate}
            />
            : filter.name === "Fecha Hasta" ?
            <DatePickerUntil
              preValue={endDate}
              minDate={startDate}
              changeValue={(value) => {
                setEndDate(value)
              }}
              filterValue={endDate !== null && endDate}
            />
            : filter.name === "Género" ?
              <FilterList
                variant="uniqueFilter"
                options={genreOptions}
                setLabel={setGenereLabel}
                setSelectedOptions={setGenere}
                selectedOptions={genere}
              />
            : filter.name === "Edad" ?
            <FilterList
              variant="uniqueFilter"
              options={ageOptions}
              setLabel={setAgeLabel}
              setSelectedOptions={setAgeFilter}
              selectedOptions={ageFilter}
            />
            : filter.name === "Líneas de servicio" ?
            <PillInput
              selected={options}
              options={lines?.response[0]?.serviceLines?.map((line) => ({
                key: line.id,
                value: line.name,
              }))}
              onClosePill={(key) =>
                setOptions(options.filter((elm) => elm.key !== key))
              }
              onSelectOption={(value) => setOptions([...options, value])}
            />
            : filter.name === "Al día" ?
            <FilterList
              variant="uniqueFilter"
              options={updateOptions}
              setLabel={setUpdateLabel}
              setSelectedOptions={setUpdateFilter}
              selectedOptions={updateFilter}
            />
            : filter.name === "Alerta" ?
            <FilterList
              variant="uniqueFilter"
              options={alertOptions}
              setLabel={setAlertLabel}
              setSelectedOptions={setAlertFilter}
              selectedOptions={alertFilter}
            />
            : filter.name === "Tipo de monitoreo" ?
            <PillInput
              selected={options}
              options={lines?.response[0]?.serviceLines?.map((line) => ({
                key: line.id,
                value: line.name,
              }))}
              onClosePill={(key) =>
                setOptions(options.filter((elm) => elm.key !== key))
              }
              onSelectOption={(value) => setOptions([...options, value])}
            />
            : filter.name === "Observación" ?
            <PillInput
              selected={reasonsOptions}
              options={reasons?.map((reason) => ({
                key: reason.id,
                value: reason.value,
              }))}
              onClosePill={(key) =>
                setReasonsOptions(reasonsOptions.filter((elm) => elm.key !== key))
              }
              onSelectOption={(value) => setReasonsOptions([...reasonsOptions, value])}
            />
            : null
          }
          {children}
        </div>
      )}
    </div>
  </>
  )
}
