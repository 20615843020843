import { useContext } from "react";

import CardFSFB from "../../atoms/Card";
import Input from "../../atoms/Input";
import FileUpload from "../../atoms/FileUpload";
import { LabelInput } from "../../atoms/LabelInput";
import RadioButtonFSFB from "../../atoms/RadioButton";
import SelectOption from "../../atoms/SelectOption";
import { ModulesCreateScale } from "../ModulesCreateScale";
import { LineFrecuenctySelect } from "../LineFrecuenctySelect";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import styles from "./BasicInfoStep.module.scss";

const options = [
  { label: "Mayor de 18", value: "Mayor de edad" },
  { label: "Sin restricción", value: "Sin restricción" },
];

const send_to_patient = [
  { label: "Paciente", value: true },
  { label: "Médico-gestor", value: false },
];

const dateOrigin = [
  { label: "Ingreso", value: false},
  { label: "Egreso", value: true },
];


export const BasicInfoStep = () => {
  const { isDesenlacesSelect, isContigoSelect, editScale } = useContext(ScalesContext);

  return (
    <>
      {editScale?.active && (
        <CardFSFB className={styles.cardContainerWarning}>
          <div className={styles.warningAnnounce}>
            <label>No se puede actualizar la escala porque:</label>
            <ul>{editScale?.active && <li>La escala está activa</li>}</ul>
          </div>
        </CardFSFB>
      )}
      <section className={styles.formContainer}>
        <div className={styles.titleImageContainer}>
          <div className={styles.optionForm}>
            <LabelInput text="Nombre" />
            <div className={styles.input}>
              <Input
                name="name"
                type="text"
                placeholder="Ingresa el título de la escala"
                disabled={editScale?.active}
              />
            </div>
          </div>
          <div className={styles.imageOption}>
            <LabelInput text="Imagen" />
            <FileUpload
              name="image"
              disabled={editScale?.active}
              id="fileSelect"
            />
          </div>
        </div>
        <div className={styles.lineModule}>
          <ModulesCreateScale />
          <LineFrecuenctySelect />
        </div>

        <section>
          <section className={styles.twoColumn}>
            <div className={styles.optionForm}>
              <LabelInput text="Tiempo" />
              <Input
                name="time_complete"
                type="text"
                subLabel="minutos"
                disabled={editScale?.active}
              />
            </div>
            <div className={styles.optionForm}>
              <LabelInput text="Descripción" />
              <div className={styles.input}>
                <Input
                  name="description"
                  type="text"
                  placeholder="Ingresa la descripción de la escala "
                  disabled={editScale?.active}
                />
              </div>
            </div>
          </section>

          <section className={styles.twoColumn}>
            <div className={`${styles.optionForm} ${
                !isDesenlacesSelect && !isContigoSelect
                  ? styles.show
                  : isDesenlacesSelect
                  ? styles.show
                  : styles.disabled
              }`}>
              <LabelInput text="Edad" />
              <SelectOption name="age_range" disabled={editScale?.active}>
                <option value="" disabled selected>
                  Seleccionar
                </option>
                {options.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </SelectOption>
            </div>

            <div
              className={`${styles.optionForm} ${
                !isDesenlacesSelect && !isContigoSelect
                  ? styles.show
                  : isDesenlacesSelect
                  ? styles.show
                  : styles.disabled
              }`}
            >
              <LabelInput text="Bibliografía" />
              <div className={styles.input}>
                <Input
                  name="bibliografy"
                  type="text"
                  disabled={editScale?.active}
                />
              </div>
            </div>
          </section>

          <section 
            className={`${ styles.threeColum}`}>
            <div
              className={`${styles.optionDiligenceSendPatient} ${
                !isDesenlacesSelect && !isContigoSelect
                  ? styles.show
                  : isDesenlacesSelect
                  ? styles.show
                  : styles.disabled
              }`}
            >
              <LabelInput text="¿Quién diligencia?" />
                   
              <RadioButtonFSFB
                options={send_to_patient}
                name="send_to_patient"
                labelPlacement="end"
                disabled={editScale?.active}
              />
            </div>
            <div
              className={` $ ${
                !isDesenlacesSelect && !isContigoSelect
                  ? styles.show
                  : isContigoSelect
                  ? styles.show
                  : styles.disabled
              } ${styles.textCenter} ${styles.point}`}
            >
              <LabelInput text="Puntuación mínima" />
              <Input
                name="threshold"
                type="text"
                subLabel="puntos"
                disabled={editScale?.active}
              />
            </div>
          </section>
        </section>
      </section>
    </>
  );
};
