import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import RolesForm from "../../atoms/RolesForm";
import { ModalFormStepper } from "../../molecules/ModalFormStepper";
import { ModalStep } from "../../atoms/ModalStep";
import styles from "./EditRol.module.scss";

import { AppContext } from "../../context/AppContext/AppContext";
import { updateRol } from "../../services/roles";
import TextInput from "../../atoms/TextInput";
import { RolModules } from "../../molecules/RolModules";
import { SubmoduleList } from "../../molecules/SubmoduleList";
import { id } from "date-fns/locale";


const validateSchema = Yup.object({
  name: Yup.string()
    .required("Campo obligatorio"),
  newModules: Yup.array()
      .min(1, "Campo obligatorio")
});

export const EditRol = ({
  onClose,
  edit = true,
  setStatus,
  setIsLoading,
  setOpenCreateNotification,
  setError400,
  setNewRolRender,
  newRolRender,
  rolData,
}) => {
  const rolId = rolData.id
  const [uuid, setUuid] = useState();
  const [totalSubmodules, setTotalSubmodules] = useState([]);
  const [submodule, setSubmodule] = useState(rolData.modules.map((module) => {
    const { id, ...rest } = module;
    return { value: id, ...rest };
  }));
  const [submoduleEdit, setSubmoduleEdit] = useState();
  const [modules, setModule] = useState();
  const [idSubmodule, setIdSubmodule] = useState([]);
  const [idDelete, setIdDelete] = useState();
  const [arraySubmodule, setArraySubmodule] = useState([]);
  const uniqueArrayPrev = submodule.map(d =>{
    if(d.submodules){
      d.result = d.submodules.map(c => c.id);
    }
    return d.result;
  }).filter(d => d).flat()
  const uniqueArray = submodule.map(d =>{
    if(d.selectedOptions){
      d.result = d.selectedOptions.map(c => c.value);
    }
    return d.result;
  }).filter(d => d).flat()

  useEffect(() => {
    setArraySubmodule((prevArray) => [...prevArray, ...idSubmodule]);
  }, [idSubmodule]);

  function changeSelected (index, values) {
    submodule[index].selectedOptions = values;
  }

  const { user } = useContext(AppContext);

  function desenlacesValues () {
    let DesenlacesInitialValues = [];

    for (var i = 0; i < submodule.length; i++) {
      if (submodule[i].label === "Desenlaces") {
        DesenlacesInitialValues.push(submodule[i].result);
      }
    }
    return DesenlacesInitialValues[0];
  }

  function contigoValues () {
    let DesenlacesInitialValues = [];

    for (var i = 0; i < submodule.length; i++) {
      if (submodule[i].label === "Contigo") {
        DesenlacesInitialValues.push(submodule[i].result);
      }
    }
    return DesenlacesInitialValues[0];
  }

  const initialValuesCreate = {
    name: rolData.name,
    newModules: rolData.modules.map((item) => item.id),
    Desenlaces: desenlacesValues()?.length > 0 ? desenlacesValues() : [],
    Contigo: contigoValues()?.length > 0 ? contigoValues() : [],
  };

  const validateSchema2 = Yup.object().shape(
    submodule.reduce((fields, sub) => {
      if (sub.submodules && sub.submodules.length > 0) {
        const fieldName = sub.label;
        return {
          ...fields,
          [fieldName]: Yup.array().min(1, "Campo obligatorio"),
        };
      }
      return fields;
    }, {})
  );

  useEffect(() => {
    setSubmoduleEdit(submodule.map((id) => id.value))
  }, [submodule]);


  return (
    <ModalFormStepper
      onClose={onClose}
      initialValues={initialValuesCreate}
      onSubmit={async (values) => {
        const defaultData = {
          name: values.values.name,
          modules: submoduleEdit,
          sub_modules: uniqueArray,
          updated_by: user.email,
        };

        setIsLoading(true);
         const data = await updateRol(defaultData, rolId);
         setStatus(data.status);
         setOpenCreateNotification(true);
         onClose()
         setIsLoading(false);
         setNewRolRender(!newRolRender)
         if (data.status === 400) {
           setError400(data.data.data.response[0].message);
         }
      }}
    >
      <ModalStep validationSchema={validateSchema}>
        <h2 className={styles.title}>Editar rol</h2>
        <div className={styles.inputNameContainer}>
          <TextInput
            label="Nombre"
            name="name"
            type="text"
            className={styles.label}
            classInput={styles.classInput}
            customInputContainer={styles.customInputContainer}
            placeholder="Nombre del rol"
          />
        </div>
        <label htmlFor="newModules" className={styles.inputLabel}>Módulos</label>
        <RolModules
          name="newModules"
          setTotalSubmodules={setTotalSubmodules}
          setSubmodule={setSubmodule}
          setModule={setModule}
          submodule={submodule}
          className={styles.pillinput}
          edit={edit}
        />
      </ModalStep>
      {submodule?.some((sub) => sub?.submodules?.length > 0) &&
      <ModalStep validationSchema={validateSchema2}>
        <h2 className={styles.title}>Submódulos</h2>
        {submodule?.map((sub, index) => {
          return (
          <SubmoduleList
            index={index}
            changeSelected={changeSelected}
            listKey={setUuid}
            key={sub.value}
            item={sub}
            name={sub.label}
            listSubmodule={sub.submodules}
            setIdSubmodule={setIdSubmodule}
            setIdDelete={setIdDelete}
            totalSubmodules={totalSubmodules}
            edit={edit}
          />
          )})}
      </ModalStep>
      }
    </ModalFormStepper>
  );
};
