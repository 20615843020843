import styles from "./ActionsButton.module.scss";

const ActionButton = (props) => {
  const { icon, onClick, text } = props;

  return (
    <button
      type="button"
      className={styles.actionButton}
      onClick={onClick}
      disabled={props.disabled}
    >
      <img src={icon} alt="icono boton" />
      { text ?  
      <p>{ text }</p> : null }
    </button>
  );
};

export default ActionButton;
