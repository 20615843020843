import styles from "./BannerEspecial.module.scss"


export default function BannerEspecial() {
    return (
        <>
        <div className={styles.banner}>
            <span className={styles.bannerColor_1}></span>
            <span className={styles.bannerColor_2}></span>
            <span className={styles.bannerColor_3}></span>
            <span className={styles.bannerColor_4}></span>
            <span className={styles.bannerColor_5}></span>
        </div>
        </>
    );
}