import { useContext } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import { createNewMonitoring } from "../../services/Contigo/patient";

import { MonitoringList } from "../../molecules/MonitoringList";
import { ListPatientSelected } from "../../molecules/ListPatientSelected";

import styles from "./MonitoringForm.module.scss";

const validateSchema = Yup.object({
  patients: Yup.array().min(1, "Debes seleccionar mínimo un paciente"),
  service_line: Yup.number().required(
    "Debes seleccionar una línea de servicio"
  ),
});

export const MonitoringForm = () => {
  const {
    setListSelectUsers,
    setIsConfirmation,
    setConfirmResponse,
    setIsLoadingSend,
    selectedScales,
  } = useContext(ContigoContext);

  const initialValues = {
    patients: [],
    service_line: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validateSchema}
      onSubmit={async (values, { resetForm }) => {
        setIsLoadingSend(true);
        const payload = {
          service_line: values.service_line,
          doc_patients: values.patients.map((patient) => ({
            doc: patient.doc,
            tdoc: patient.tdoc.code,
            observations: patient.observations,
            equipment: patient.equipment,
            file_material: patient.file_material
          })),
          scaleIds: selectedScales.map((item) => item.id),
        };

        const response = await createNewMonitoring(payload);
        if (response.statusCode === 200) {
          setIsLoadingSend(false);
          setIsConfirmation(true);
          setConfirmResponse(response.data[0]);
          resetForm({
            patients: [],
            service_line: "",
          });
          setListSelectUsers([]);
        } else {
          console.error("error");
          setIsLoadingSend(false);
        }
      }}
    >
      {({values}) => (
        <Form>
          <ListPatientSelected name="patients" />
          <MonitoringList name="service_line" />
          <div className={styles.sendButtonContainer}>
            <button
              type="submit"
              className={styles.buttonStart}
            >
              Iniciar monitoreo
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
