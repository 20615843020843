import styles from "./ButtonOption.module.scss";

const ButtonOption = (props) => {
  const { icon, label, onClick, className, name, disabled, filtersStyle } =
    props;

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${className ? className : ""} ${styles.buttonOption}`}
      style={{
        fontSize: filtersStyle ? "10px" : "14px",
      }}
    >
      <img
        src={icon}
        alt={label}
        name={name}
        className={`
          ${styles[props.width]}
        `}
      />
      {label}
    </button>
  );
};

export default ButtonOption;
