import WarningIcon from "../../assets/imgs/icons/warningIcon.svg";

import styles from "./DeleteWarning.module.scss";

const DeleteWarning = ({valuesDelete, trackDelete, deleteRegister}) => {
  return (
    <section className={styles.warningContainer}>
      <img src={WarningIcon} alt="Icono de alerta" />
      {/* {trackDelete && <h2 className={styles.warningTitle}>Atención,</h2>} */}
      <p className={styles.warningSubtitleBlue}>
        {
          trackDelete ? 
          `Estás a punto de detener un monitoreo` :
          deleteRegister ?
          `Estás a punto de eliminar\n${deleteRegister} ${deleteRegister === 1 ? 'registro' : 'registros'}` :
          `Estás a punto de eliminar ${valuesDelete} ${valuesDelete === 1 ? 'evento' : 'eventos'}`
        }
      </p>
      <p className={styles.textContinue}>¿Deseas continuar?</p>
    </section>
  );
};

export default DeleteWarning;
