import React from "react";
import { useField } from "formik";

import styles from "./textArea.module.scss";
import ErrorMessage from "../ErrorMessage";

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label
        htmlFor={props.id || props.name}
        className={[
          props.variant === "white" ? styles.labelWhite : styles.label,
        ]}
      >
        {label}
      </label>
      <textarea
        name={props.name}
        rows={props.rows ? props.rows : "4"}
        cols={props.cols ? props.cols : "50"}
        className={`
          ${styles.textArea}
          ${props.disabled && styles.textAreaDisabled}
          ${props.type === 5 ? styles.numArea : null}
        `}
        {...field}
        disabled={props.disabled}
        placeholder={
          props.type === 5 ? "Escriba su respuesta, solo números" : null
        }
      />
      <ErrorMessage error={meta.error} meta={meta} />
    </>
  );
};

export default TextArea;
