import { useContext, useEffect, useState } from "react";
import AlertIcon from "../../assets/imgs/icons/alertIcon.svg";
import AlertInfo from "../../atoms/AlertInfo";
import ModalBase from "../../atoms/ModalBase";
import { AppContext } from "../../context/AppContext/AppContext";
import { logout } from "../../services/auth/auth.service";

export default function SessionLimiter() {
  const { sessionActive, keys, setKeys } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const duty = async () => {
      setLoaded(false);
      clearTimeout(timer);
      await new Promise((res) =>
        setTimer(
          setTimeout(() => {
            setLoaded(true);
          }, 2000)
        )
      );
    };
    duty();
    return () => clearTimeout(timer);
  }, [keys, sessionActive]);

  return (
    <ModalBase
      open={!sessionActive && keys?.jwt && loaded}
      handleClose={async () => {
        setKeys(null);
        await logout();
        window.location.href = `${window.location.origin}/managerlogin`;
      }}
    >
      <AlertInfo
        icon={AlertIcon}
        title="Atención"
        message="Oh oh.... La sesión ha caducado. Va a ser redirigido a el inicio de sesión."
      />
    </ModalBase>
  );
}
