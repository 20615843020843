import "./App.scss";

import { AdminProvider } from "./context/AdminContext/AdminProvider";
import { AppProvider } from "./context/AppContext/AppProvider";
import { FiltersProvider } from "./context/FiltersContext/FiltersProvider";
import { ContigoProvider } from "./context/ContigoContext/ContigoProvider";
import { ManagerProvider } from "./context/ManagerContext/ManagerProvider";
import { ScalesProvider } from "./context/ScalesContext/ScalesProvider";
import { LinesProvider } from "./context/LinesContext/LinesProvider";
import { UsersProvider } from "./context/UsersContext/UsersProvider";
import { RolesProvider } from "./context/RolesContext/RolesProvider";
import { DesenlacesProvider } from "./context/DesenlacesContext/DesenlacesProvider";
import { RoutesProvider } from "./context/RoutesContext/RoutesProvider";
import { TracingProvider } from "./context/TracingContext/TracingProvider";
import { DashboardProvider } from "./context/DashboardContext/DashboardProvider";
import SessionLimiter from "./molecules/SessionLimiter";

import AppRoutes from "./routes";

const providers = [
  AppProvider,
  FiltersProvider,
  AdminProvider,
  ScalesProvider,
  ManagerProvider,
  LinesProvider,
  UsersProvider,
  RolesProvider,
  ContigoProvider,
  DesenlacesProvider,
  RoutesProvider,
  TracingProvider,
  DashboardProvider,
];

function App() {
  const ProviderTree = providers.reduce(
    (accumulator, Provider) => <Provider>{accumulator}</Provider>,
    <>
      <SessionLimiter />
      <AppRoutes />
    </>
  );

  return ProviderTree;
}

export default App;
