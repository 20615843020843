import Error403 from "../../organisms/Error403";
import Error404 from "../../organisms/Error404";
import ErrorInconvenient from "../../organisms/ErrorInconvenient";
import ErrorInternet from "../../organisms/ErrorInternet";

export default function ErrorPages() {
  const errors = {
    403: <Error403 />,
    404: <Error404 />,
    inconvenient: <ErrorInconvenient />,
    internet: <ErrorInternet />,
  };
  const errorPath = window.location.pathname.split('/').filter(elm => !!elm);
  return errors[errorPath[errorPath.length - 1]] || <Error403 />;
}
