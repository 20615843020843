import { useContext } from "react";

import { AppContext } from "../../context/AppContext/AppContext";

import { HeaderDashboard } from "../../molecules/HeaderDashboard";
import { DetailDashboard } from "../../molecules/DetailDashboard";

import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  const { modules } = useContext(AppContext);

  const parentModules = modules.filter(mod => mod.submodules.length > 0);
  const modulesToFind = parentModules.map(mod => mod.id);

  return (
    <section className={styles.dashboard}>
      <HeaderDashboard />
      <section className={styles.detailContainer}>
        {modulesToFind.includes(100) && <DetailDashboard title="Desenlaces" />}
        {modulesToFind.includes(101) && <DetailDashboard title="Contigo" />}
      </section>
    </section>
  );
};

export default Dashboard;
