import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { getLineByModules } from "../../services/servicesLine";

import { FUTURE_PERIOD_OPTIONS, PAST_PERIOD_OPTIONS } from "./utils";

import { MessageContainer } from "../../atoms/MessageContainer";
import { Select } from "../../atoms/Select";
import Spinner from "../../atoms/Spinner";

import monitoringBlue from "../../assets/imgs/icons/filters/monitoringPrimaryBlue.svg";
import dateBlue from "../../assets/imgs/icons/filters/datePrimaryBlue.svg";
import searchFail from "../../assets/imgs/icons/searchFail.svg";

import styles from "./BarChart.module.scss";

const options = {
  ticks: {
    precision: 0,
  },
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      grid: {
        offset: true,
        display: false,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  width: 400,
  height: 300,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const graphDataConfig = {
  labels: [],
  datasets: [
    {
      backgroundColor: "#001DCB",
      borderColor: "#001DCB",
      borderRadius: 2,
      borderWidth: 1,
      data: [],
      barThickness: 15
    },
  ],
};

const TIME_FILTER_OPTIONS = {
  past: {
    defaultPeriod: "Última semana",
    selectOptions: PAST_PERIOD_OPTIONS,
  },
  future: {
    defaultPeriod: "Próxima semana",
    selectOptions: FUTURE_PERIOD_OPTIONS,
  },
};

export const BarChart = ({ titleChart, path, type, timeFilterType, module }) => {
  const [total, setTotal] = useState(0);
  const [graphData, setGraphData] = useState(graphDataConfig);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [serviceLines, setServiceLines] = useState("Todas");
  const [period, setPeriod] = useState(TIME_FILTER_OPTIONS?.[timeFilterType].defaultPeriod);

  const [validServiceLines, setValidServiceLines] = useState([{ label: "Todas", value: 0 }]);

  const getAllServiceLines = async () => {
    setError("");
    setLoading(true);
    try {
      const response = await getLineByModules(module);
      const validResponse = response.hasOwnProperty("response") ? response.response[0] : response.data[0];
      const serviceLines = validResponse.serviceLines;
      const serviceLinesToShow = serviceLines.map(sl => ({ label: sl.name, value: sl.id }));
      setValidServiceLines([...serviceLinesToShow, { label: "Todas", value: 0 }]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getGraphsDashboardData = async () => {
    setError("");
    setLoading(true);
    try {
      const periodIndex = TIME_FILTER_OPTIONS?.[timeFilterType].selectOptions.findIndex(opt => opt.label === period);

      const periodToFetch = TIME_FILTER_OPTIONS?.[timeFilterType].selectOptions[periodIndex].value;
      let serviceLinesToRequest = "";

      const serviceLinesRequested = [];
      const serviceLinesToFetch = serviceLines.split(",");
      serviceLinesToFetch.forEach(sl => {
        const serviceLineIndex = validServiceLines.findIndex(vsl => sl === vsl.label);
        const serviceLineId = validServiceLines[serviceLineIndex].value;
        serviceLinesRequested.push(serviceLineId);
      });

      if (serviceLinesRequested.includes(0)) {
        serviceLinesToRequest = "";
      } else {
        serviceLinesToRequest = `&service_lines=${serviceLinesRequested.join(",")}`;
      }

      const { data } = await apiInterceptor({
        method: "GET",
        endpoint: `${path}/graphs?type=${type}&period=${periodToFetch}${serviceLinesToRequest}`
      });

      if (data.total === 0) {
        setError(
          "Prueba con otra combinación en los filtros,\nla que seleccionaste no nos arrojó\ninformación para mostrarte"
        );
      } else {
        setTotal(data.total);
        let dataToPrint = graphDataConfig.datasets;
        dataToPrint[0].data = data.data;
        setGraphData({
          ...graphDataConfig,
          labels: data.labels,
          datasets: dataToPrint,
        });
      }
    } catch (err) {
      console.error(err);
      setError("Inténtalo de nuevo en un momento");
      setTotal(0);
      setGraphData({
        ...graphDataConfig,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllServiceLines();
  }, []);

  useEffect(() => {
    setServiceLines("Todas");
    setPeriod(TIME_FILTER_OPTIONS?.[timeFilterType].defaultPeriod);
  }, [titleChart])

  useEffect(() => {
    getGraphsDashboardData();
  }, [titleChart, period, serviceLines]);

  const handleChangePeriod = (val) => setPeriod(val);

  const handleChangeServiceLines = (val) => {
    if (serviceLines.includes(val)) {
      let newServiceLines =
        serviceLines
          .replace(val, "")
          .replace(",,", ",")
          .replace(/(^,|,$)/g, "");

      setServiceLines(newServiceLines || "Todas");
    } else {
      let newServiceLines = serviceLines.split(",");
      newServiceLines.push(val);
      setServiceLines(newServiceLines.join(","));
    }
  }

  return (
    <>
      <section className={styles.filters}>
        <Select
          multiple
          icon={monitoringBlue}
          placeholder="Línea"
          content={serviceLines}
          options={validServiceLines}
          onSelect={handleChangeServiceLines}
        />
        <Select
          icon={dateBlue}
          placeholder="Fecha"
          content={period}
          options={TIME_FILTER_OPTIONS?.[timeFilterType].selectOptions}
          onSelect={handleChangePeriod}
        />
      </section>
      <div className={styles.chartContainer}>
        {
          loading
            ? <Spinner className_container="rolesForm" />
            : error
              ? <MessageContainer img={searchFail} message={error} />
              : (
                <>
                  <section className={styles.header}>
                    <p className={styles.titleChart}>{titleChart}</p>
                    <span className={styles.total}>Total: {total}</span>
                  </section>
                  <Bar data={graphData} options={options} className={styles.barChart} />
                </>
              )
        }
      </div>
    </>
  );
}
