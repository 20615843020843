/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFilters, usePagination, useRowSelect, useTable } from "react-table";
import { useLocation } from "react-router-dom";
import * as moment from "moment-timezone";

// import {Tooltip} from "../../atoms/Tooltip"
import { Tooltip } from 'react-tooltip';
import tooltipIcon from "../../assets/imgs/icons/tooltip_blue.svg";
import Spinner from "../../atoms/Spinner";
import TooltipTable from "../../molecules/TooltipTable";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import styles from "./Table.module.scss";


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className={styles.customRadioCheckbox}>
        <input
          className={styles.checkBox}
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
        <span
          className={`${styles.customRadioCheckbox__show} ${styles.customRadioCheckbox__showCheckbox}`}
        ></span>
      </div>
    );
  }
);

const Table = ({ 
  isLoading, 
  tableData, 
  header, 
  setValues, 
  offsetUser, 
  childData,
  tooltipText,
}) => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const tooltipLocation = location.pathname;


  const data = tableData;
  const columns = useMemo(() => header, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: offsetUser(height, width) },
      manualFilters: true,
      autoResetRowState: false,
    },
    useFilters,
    usePagination,
    useRowSelect,
    (hooks) => {
      setValues &&
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          width: 5,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className={styles.checkAllBtn}>
              <TooltipTable tooltipLocation={tooltipLocation} tooltipText={tooltipText} customStyle={styles.selectedStyle} />
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className={styles.checkContainer}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );


  useEffect(() => {

    setValues &&
    setValues(selectedFlatRows[0]?.original);
    childData &&
    childData(selectedFlatRows)
  }, [selectedFlatRows]);

  // *--- table frame ---*
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [control, setControl] = useState(0);

  useEffect(() => {
    if (tableData.length !== 0 && control === 0) {
    const height = tableRef?.current?.offsetHeight;
    setTableHeight(height);
    setControl(1)
  }
  }, [isLoading]);
    // *--- table frame ---*

  return (
    <div style={{height: `${tableHeight}px`}} className={styles.tableContainer}>
      <table ref={tableRef} className={styles.table} {...getTableProps()}>
          {
            setValues?.length !== 1 && width > 1023.9 ?
            <TooltipTable tooltipLocation={tooltipLocation} tooltipText={tooltipText} />
            : null
          }
          <thead className={styles.thead}>
            {headerGroups.map((headerGroup, idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ width: `${column.width}%` }}
                    className={styles.header}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner className_container="tracingTable" />
            </div>
          ) : (
            <tbody {...getTableBodyProps()} className={`${styles.tbody}`}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={styles.row} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ textAlign: cell.column.textAlign }}
                          className={`${styles.cells}`}
                          key={index}
                        >
                        <div
                          className={`${styles.overflow}`}
                        >
                          <a
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              cell.value && typeof(cell.value) === 'object' ?
                              cell.value.map((module) => {
                                const propertyName = Object.keys(module)[1];
                                return module[propertyName];
                              })
                              .toString().replaceAll(",", ", "):
                              cell.value && cell.column.tooltipType === 'date' ?
                              moment.tz(cell.value, "America/Bogota").format("YYYY-MM-DD HH:mm") :
                              cell.value && cell.column.tooltipType === 'utc' ?
                              moment.utc(cell.value).format("YYYY-MM-DD HH:mm") :
                              cell.value && cell.column.tooltipType === 'expiration' && cell.column.tooltipFunction ? cell.column.tooltipFunction(cell.value) :
                              cell.value
                            }
                            data-tooltip-delay-show={500}
                          >
                            {cell.render("Cell")}
                          </a>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default Table;
