import React from 'react';
import { Navigate } from "react-router-dom";
import { getItem } from "../services/localStorage";

const withAuth = (WrappedComponent, authorizedRoute) => {
  return () => {
    const user = getItem("user");
    const authorizedRoutes = getAuthorizedRoutes( user);
    if (authorizedRoutes.includes(authorizedRoute)) {
      return <WrappedComponent />;
    } else {
      return <Navigate to="/error/403" />
    }
  };
};

const getAuthorizedRoutes = ( user) => {
  let routes = [];
  const modules = user?.modules || [];

  for (const route of modules) {
    if (route.submodules?.length) {
      for (const submodule of route.submodules) {
        routes.push(submodule);
      }
    } else {
      routes.push(route);
    }
  }

  return routes.map(route => route.path);
};

export default withAuth;
