import React, { useState, useEffect } from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./Pagination.module.scss";

export const Pagination = ({ actualPage, setPage, infoPage , className}) => {
  const [inputPage, setInputPage] = useState(actualPage);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  
  const totalPage = infoPage.paginationTotal === 0 ? 1 : infoPage.paginationTotal;

  const handlePreviousPage = () => {
    setLoading(true);
    setTimeout(() => {
      setPage(actualPage - 1);
    }, 500);
    setInputPage(actualPage - 1);
    setLoading(false);
  };

  const handleNextPage = () => {
    setLoading(true);
    setTimeout(() => {
      setPage(actualPage + 1);
    }, 500);
    setInputPage(actualPage + 1);
    setLoading(false);
  };


  useEffect(() => {
    const handlePage = setTimeout(() => {
      if (parseInt(inputPage) > totalPage) {
        setPage(totalPage);
      } else {
        if (parseInt(inputPage) >= 1) {
          if (Number.isInteger(parseInt(inputPage))) {
            setPage(parseInt(inputPage));
          } else if (isNaN(parseInt(inputPage))) {
            return;
          } else {
            setPage(1);
          }
        }
      }
    }, 500);
    return () => clearTimeout(handlePage);
  }, [inputPage]);

  useEffect(()=>{
    setInputPage(actualPage)
  },[actualPage])

  return (
    <>
      {!loading ? (
        <div className={`${styles.pagination} ${className ? className : null}`}>
          <button
            onClick={handlePreviousPage}
            disabled={actualPage <= 1}
            className={
              actualPage <= 1
                ? styles.previousPageDisabled
                : styles.previousPage
            }
          >
            <ArrowBackIosRoundedIcon className={styles.arrow} />
          </button>{" "}
          <div className={styles.infoPage}>
            <span className={styles.actualPage}>
              <input
                type="text"
                id="pagination"
                className={
                  width > 1439
                    ? styles.inputPagination
                    : styles.inputPaginationSmall
                }
                value={inputPage}
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete", "Enter"];
                  if (!allowedKeys.includes(e.key) && isNaN(parseInt(e.key))) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => 
                  setInputPage(e.target.value)
                }
              />
            </span>{" "}
            <span className={styles.tx}>de</span>{" "}
            <span
              className={
                width > 1439 ? styles.totalPages : styles.totalPagesSmall
              }
            >
              {totalPage}
            </span>
          </div>
          <button
            onClick={handleNextPage}
            disabled={actualPage >= totalPage}
            className={
              actualPage >= totalPage
                ? styles.nextPageDisabled
                : styles.nextPage
            }
          >
            <ArrowForwardIosRoundedIcon className={styles.arrow} />
          </button>
        </div>
      ) : null}
    </>
  );
};
