import { useState } from 'react';
import { RoutesContext } from "./RoutesContext";

export const RoutesProvider = ({ children }) => {
  const [ params, setParams ] = useState()

  return (
    <RoutesContext.Provider
      value={{
        params, setParams
      }}
    >
      {children}
    </RoutesContext.Provider>
  )
};

