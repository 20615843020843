import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { GetCurrentKeys } from "../../services/authentication";

export const ProtectedRoute = ({ children }) => {
  let navigate = useNavigate();
  const [loaded, SetLoaded] = useState(false);

  useEffect(() => {
    checkStatus().then(async (truthy) => {
      if (loaded) return;
      SetLoaded(true);
    });
  }, [loaded, navigate]);

  async function checkStatus() {
    return await GetCurrentKeys();
  }

  return (
    <>{!loaded ? <div>Cargando...</div> : children ? children : <Outlet />}</>
  );
};
