import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../atoms/Banner";
import LoginDescription from "../../atoms/LoginDescription";

export default function OnBoarding() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  });

  return (
    <>
      <LoginDescription
        img="onboarding"
        text1="Tu recuperación"
        text2="Fundación Santa Fe de Bogotá"
        text3="Eres lo más importante para nosotros."
      />
      {<Banner />}
    </>
  );
}
