import { useState, useContext } from "react";

import withAuth  from '../../HOC/withAuth';
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import ButtonOption from "../../atoms/ButtonOption";
import ModalContent from "../../atoms/ModalContent";
import AddNewUserForm from "../../atoms/AddNewUserForm";
import UsersAdminTable from "../../atoms/UsersAdminTable";
import EditNewUserForm from "../../atoms/EditNewUserForm";
import ToolboxTable from "../../atoms/ToolboxTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { UsersContext } from "../../context/UsersContext/UsersContext";
import useSelect from "../../hooks/useSelect";
import addIcon from "../../assets/imgs/icons/addIcon.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import check from "../../assets/imgs/icons/check.svg";
import xIcon from "../../assets/imgs/icons/xIcon.svg";
import styles from "./UsersAdmin.module.scss";

function UsersAdmin() {
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [openCreateNotification, setOpenCreateNotification] = useState(false);
  const [newUserRender, setNewUserRender] = useState(false);
  const [error400, setError400] = useState();
  const [openEditUser, setOpenEditUser] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [status, setStatus] = useState();
  const [renderControl, setRenderControl] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTrueModal, setOpenTrueModal] = useState(false);
  const [openFalseModal, setOpenFalseModal] = useState(false);
  const [statusDelete, setStatusDelete] = useState(null);
  const [statusActive, setStatusActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState();

  const { createStatus, editStatus } = useContext(UsersContext);

    // *--- Select ---*
    const [childDataState, setChildDataState] = useState();
    function childData(e) {
      setChildDataState(e)
    }
    const propertyNameMap = {
      id_user: 'id',
      name: 'name',
      email: 'email',
      status: 'active',
  };
  const selectOptions = useSelect(childDataState, propertyNameMap);
    // *--- Select ---*

  const handleDelete = async () => {
    setIsLoading(true);
    setAmount(selectOptions.length);
    const schemaCreate =
      selectOptions
      .filter((datos) => datos?.id_user)
      .map((datos) => datos?.id_user || "")
    const data = await apiInterceptor({
      method: "DELETE",
      endpoint: `/tr/admin/users`,
      data: schemaCreate,
    });
    setIsLoading(false);
    setStatusDelete(data.statusCode);
    setNewUserRender(!newUserRender);
  };

  const handleEditStatus = async (user) => {
    const id = user.id;
    const activate = !user.active;
    user.active = !user.active;
    const newStatus = [id]
    const data = await apiInterceptor({
      method: "PATCH",
      endpoint: `/tr/admin/users/status?activate=${activate}`,
      data: newStatus,
    });
    if (data.statusCode === 200) {
      setStatus(data.statusCode);
      setOpenCreateNotification(true);
    } else if (data.statusCode === 500) {
      setStatus(data.statusCode);
      // setError400(data.message);
      setError400("No es posible modificar tu usuario");
      setOpenCreateNotification(true);
    } else {
      alert("Error al actualizar el estado del usuario");
    }
  };

  const handleStatusTrue = async (status) => {
    setIsLoading(true);
    setAmount(selectOptions.length);
    const activate = status
    const newStatus =
      selectOptions
        .filter((datos) => datos?.id_user)
        .map((datos) => datos?.id_user || "")
    ;
    const data = await apiInterceptor({
      method: "PATCH",
      endpoint: `/tr/admin/users/status?activate=${activate}`,
      data: newStatus,
    });
    setIsLoading(false);
    setStatusActive(data.statusCode);
    setNewUserRender(!newUserRender);
  };

  const handleOpenDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setStatusDelete(null);
    setOpenDeleteModal(false);
    setAmount(null)
  };

  const handleOpenTrue = () => {
    setOpenTrueModal(true);
  };

  const handleCloseTrue = () => {
    setStatusActive(null);
    setOpenTrueModal(false);
    setAmount(null)
  };

  const handleOpenFalse = () => {
    setOpenFalseModal(true);
  };

  const handleCloseFalse = () => {
    setStatusActive(null);
    setOpenFalseModal(false);
    setAmount(null)
  };

  const handleOpenCreateUser = () => setOpenCreateUser(true);

  const handleCloseCreateUser = () => {
    setOpenCreateUser(false);
  };

  const handleOpenEditUser = (user) => {
    setEditUser(user);
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setOpenEditUser(false);
  };

  const handleCloseCreateUserNotification = () => {
    setOpenCreateNotification(false);
    setError400(null);
    status === 500 && setRenderControl(!renderControl);
    setStatus(null);
  };

  return (
    <>
      <ModuleTemplate>
        <div name="options">
          <ButtonOption
            icon={addIcon}
            label="Nuevo"
            onClick={handleOpenCreateUser}
          />
        </div>
        {selectOptions?.length > 0 &&
          <ToolboxTable name="Tollbox" >
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={handleOpenDelete}
            />
            <ButtonOption
              icon={check}
              label="Activar"
              onClick={handleOpenTrue}
            />
            <ButtonOption
              icon={xIcon}
              label="Desactivar"
              onClick={handleOpenFalse}
            />
          </ToolboxTable>
        }
        <UsersAdminTable
          name="Table"
          newUserRender={newUserRender}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          renderControl={renderControl}
          handleEditStatus={handleEditStatus}
          childData={childData}
          handleSelected={handleOpenEditUser}
        />
      </ModuleTemplate>

      <Modal
        isOpen={openCreateNotification}
        onClose={handleCloseCreateUserNotification}
        variant="large"
      >
        <ModalContent
          close={handleCloseCreateUserNotification}
          text={
            createStatus === 201 || editStatus === 200 || status === 200
              ? "Usuario guardado"
              : createStatus === 400 || editStatus === 400
              ? error400
              : status === 500
              ? error400
              : "No se pudo completar la acción,\ninténtalo de nuevo"
          }
          icon={
            createStatus === 201 || editStatus === 200 || status === 200
              ? personGreen
              : error400
              ? redFlag
              : personRed
          }
          btns={false}
          alt={
            createStatus === 201 || editStatus === 200 || status === 200
              ? "Icono usuario guardado"
              : "Icono usuario no guardado"
          }
        />
      </Modal>


      <Modal
        isOpen={
          openDeleteModal || 
          openTrueModal || 
          openFalseModal || 
          openCreateUser || 
          openEditUser
        }
        onClose={
          openDeleteModal ? handleCloseDelete : 
          openTrueModal ? handleCloseTrue : 
          openFalseModal ? handleCloseFalse :
          openCreateUser ? handleCloseCreateUser :
          openEditUser ? handleCloseEditUser :
          null
        }
        variant="large"
      >
        {isLoading ? 
          <Spinner className_container="rolesForm" />
          : 
            openDeleteModal ?
            <ModalContent
              close={handleCloseDelete}
              confirm={() => handleDelete()}
              text={
                statusDelete === 200
                  ? `${amount > 1 ? 'Usuarios borrados' : 'Usuario borrado'}`
                  : statusDelete === null
                  ? `Estás a punto de borrar\n${selectOptions.length} ${selectOptions.length > 1 ? 'usuarios' : 'usuario'}`
                  : "No es posible borrar"
              }
              updateTitleCustom={styles.updateTitleCustom}
              helpText={statusDelete === null && "¿Deseas continuar?"}
              helpTextCustom={styles.helpTextCustom}
              btns={statusDelete === null && true}
              btnsContainerCustom={styles.btnsContainerCustom}
              cancelButtonCustom={styles.cancelButtonCustom}
              nextButtonCustom={styles.nextButtonCustom}
              icon={
                statusDelete === 200 || statusDelete === null
                  ? personGreen
                  : redFlag
              }
              alt="Icono de usuario"
            />
            :
            openTrueModal ?
            <ModalContent
            close={handleCloseTrue}
            confirm={() => handleStatusTrue(true)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Usuarios activados' : 'Usuario activado'}`
                : statusActive === null
                ? `Estás a punto de activar\n${selectOptions.length} ${selectOptions.length > 1 ? 'usuarios' : 'usuario'}`
                : "No es posible activar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de usuario"
          />
          : 
            openFalseModal ?
            <ModalContent
            close={handleCloseFalse}
            confirm={() => handleStatusTrue(false)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Usuarios desactivados' : 'Usuario desactivado'}`
                : statusActive === null
                ? `Estás a punto de desactivar\n${selectOptions.length} ${selectOptions.length > 1 ? 'usuarios' : 'usuario'}`
                : "No es posible desactivar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de usuario"
          />
          :
          openCreateUser ?
          <AddNewUserForm
            handleClose={handleCloseCreateUser}
            showNotification={setOpenCreateNotification}
            setNewUserRender={setNewUserRender}
            newUserRender={newUserRender}
            setError400={setError400}
          />
          :
          openEditUser ?
          <EditNewUserForm
            handleClose={handleCloseEditUser}
            setNewUserRender={setNewUserRender}
            newUserRender={newUserRender}
            setError400={setError400}
            setOpenEditUser={setOpenEditUser}
            user={editUser}
            setOpen={setOpenCreateNotification}
          />
          : null
        }
      </Modal>
    </>
  );
}
export default withAuth(UsersAdmin, '/usuarios')