import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import CancelButton from "../../atoms/CancelButton";
import NextButton from "../../atoms/NextButton";
import Spinner from "../../atoms/Spinner";
import { AppContext } from "../../context/AppContext/AppContext";
import style from "./FormikStepper.module.scss";

export default function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const { step, setStep, setContEq5d, contEq5dTherm, setContEq5dTherm, plan } =
    useContext(AppContext);
  const currentChild = childrenArray[step];
  const conditionalNum_logoEq5d = currentChild.props.validationschema._nodes[0];
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  return (
    <>
      {isLoading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <Formik
          {...props}
          onSubmit={async (values) => {
            if (isLastStep()) {
              setIsLoading(true);
              await props.onSubmit(values);
              setIsLoading(false);
            } else {
              setStep((s) => s + 1);
            }
          }}
          validationSchema={
            currentChild?.props?.validationSchema ||
            currentChild?.props?.validationschema
          }
        >
          {({values}) => (
            <Form autoComplete="off">

              <div className={style.questionContainer}>{currentChild}</div>
              <div
                className={`${
                  plan.id === 23 && width <= 480
                    ? style.buttonOptionsEq5d
                    : style.buttonOptions
                } ${style[props.patientBtn]}`}
              >
                <CancelButton
                  text="Atrás"
                  onClick={() =>
                    (contEq5dTherm > 0 && setContEq5dTherm(contEq5dTherm - 1))(
                      step > 0 && setStep((s) => s - 1)
                    )(
                      conditionalNum_logoEq5d ===
                        ("2e5e09d9-510b-4bf6-88ac-5aa761dc0a21" ||
                          "a2b097f9-0324-4cf0-9de6-9548e716b7af" ||
                          "eb081641-e510-4e7f-9e81-57287fbdb90f" ||
                          "8671d3d6-d183-4f36-bb68-896f2bc0a021" ||
                          "12512d3c-126d-4313-8fc3-0ac2c2e6c26c" ||
                          "e8d94ea4-fe57-4e41-8285-e49c2af6a4a4") &&
                        setContEq5d(0)
                    )
                  }
                  type="button"
                  patientBtn="patientBtn"
                />
                <NextButton
                  text="Siguiente"
                  onClick={() => setContEq5dTherm(contEq5dTherm + 1)}
                  type={
                    conditionalNum_logoEq5d ===
                      ("89c4dce0-7c9e-4377-aef0-74120786b9d0" ||
                        "c6e2cda7-ed8d-401f-9160-9ee4daadfb08" ||
                        "eb53c208-de5f-4090-9efb-4c41097f6596" ||
                        "f31501fe-7b60-4fb4-bada-9978a5afab9a" ||
                        "f7bfe0a7-0a86-4c7a-a2eb-78baea4b04e2" ||
                        "46d4ce49-c8e9-4a7c-9f03-41015999231d") &&
                    contEq5dTherm === 6
                      ? "button"
                      : "submit"
                  }
                  patientBtn="patientBtn"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
