import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import styles from "./ModalBase.module.scss";
import CloseIcon from "../../assets/imgs/icons/close.svg"

const ModalBase = ({children, ...props}) => {
  const { open, handleClose } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <button className={styles.closeButton} onClick={handleClose}> 
        <img src={CloseIcon} alt="icono cerrar modal" />
      </button>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default ModalBase;
