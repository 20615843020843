import { CognitoUserPool } from "amazon-cognito-identity-js";
import kuid from "kuid";
import { getQueryString } from "../custom/getQueryString";
import { ApiRequester } from "./apiRequester";
import { loginService } from "./auth/auth.service";
import { getItem, setItem } from "./localStorage";

let jwt, refresh, auth_mode, username, id_token, loaded, session_date;

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

function setInitialKeys() {
  if (loaded) return;
  loaded = true;
  const keys = getItem("key");
  SetKeys(keys);
}

export const cognito = new CognitoUserPool(poolData);

function paramCreator() {
  return {
    object: {},
    append(key, value) {
      this.object = { ...this.object, [key]: value };
    },
    toString() {
      return Object.entries(this.object)
        .map((entry) => `${entry[0]}=${entry[1]}`)
        .join("&");
    },
  };
}

export function CognitoOAUTHChallenge() {
  const params = paramCreator();
  params.append("client_id", poolData.ClientId);
  params.append("response_type", "token"); // With code works
  params.append(
    "scope",
    "aws.cognito.signin.user.admin+email+openid+phone+profile"
  );
  params.append("redirect_uri", window.location.origin + "/authsuccess");
  params.append("state", kuid());
  params.append(
    "identity_provider",
    process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER
  );
  const url = `${
    process.env.REACT_APP_COGNITO_URL
  }/oauth2/authorize?${params.toString()}`;
  window.location.href = url;
}

export async function CognitoOAUTHCheckCode() {
  try {
    const params = getQueryString();
    const paramObject = Array.from(params).reduce(
      (prev, curr) => ({ ...prev, [curr[0]]: curr[1] }),
      {}
    );
    if (paramObject.access_token)
      return await loginService(paramObject.access_token);
  } catch (error) {
    console.error("Error in JWT token convertion", error);
  }
  return false;
}

export function CognitoOAUTHLogout() {
  const params = paramCreator();
  params.append("client_id", poolData.ClientId);
  params.append("logout_uri", window.location.origin + "/managerlogin");
  const url = `${
    process.env.REACT_APP_COGNITO_URL
  }/logout?${params.toString()}`;
  window.location.href = url;
}

export function OTPChallenge(patientInfo, destination) {
  setItem("credentials_tryied", patientInfo);
  return ApiRequester({
    url: `/auth/otp/challenge`,
    method: "POST",
    body: { ...patientInfo, destination },
  });
}

export function OTPSignin(credentials, omitCachedToken) {
  credentials = credentials || getItem("credentials_tryied");
  return ApiRequester({
    url: `/auth/otp/signin`,
    method: "POST",
    body: credentials,
  }).then((response) => {
    if (response.ok)
      SetKeys(
        response.body?.data
          ? { ...response.body.data, auth_mode: "paciente" }
          : response.body?.data,
        omitCachedToken
      );
    return response;
  });
}

export async function JWTRefresher(omitCachedToken) {
  const info = jwtInfo();
  const validityExp = (info?.payload.exp || 0) > Math.floor(Date.now() / 1000);
  if (refresh && !validityExp) {
    let err;
    try {
      if (auth_mode === "paciente")
        await ApiRequester({
          url: `/auth/jwt/refresh`,
          method: "POST",
          body: { refresh },
        })
          .then((response) => {
            if (response.ok) {
              SetKeys(
                { token: response.body?.data?.token, refresh, auth_mode },
                omitCachedToken
              );
              return true;
            }
            throw new Error("Token wont refreshed");
          })
          .catch((err) => {
            SetKeys(
              { token: null, refresh: null, auth_mode: null },
              omitCachedToken
            );
            throw err;
          });
    } catch (error) {
      err = error;
    }
    const refreshEvent = new CustomEvent("tokenRefreshed", { detail: refresh });
    window.dispatchEvent(refreshEvent);
    if (err) throw err;
  }
}

export function jwtInfo(externalJWT) {
  let info = null;
  try {
    const splitted = (externalJWT || jwt).split(".");
    const headers = JSON.parse(atob(splitted[0]));
    const payload = JSON.parse(atob(splitted[1]));
    info = { headers, payload };
  } catch (error) {}
  return info;
}

export function SetKeys(keys, omitCachedToken) {
  const time = keys?.session_date || Date.now();
  !omitCachedToken &&
    keys &&
    setItem("key", {
      ...keys,
      session_date: time,
    });
  jwt = keys?.token;
  refresh = keys?.refresh;
  auth_mode = keys?.auth_mode;
  username = keys?.username;
  id_token = keys?.id_token;
  session_date = time;
}

export async function GetCurrentKeys() {
  try {
    setInitialKeys();
    if (refresh) await JWTRefresher();
  } catch (error) {
    console.error("Error in refresh token", error);
  }
  return { jwt, refresh, auth_mode, id_token, username, session_date };
}
