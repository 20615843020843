import React from 'react'
import styles from './QuestionContainer.module.scss'

export default function QuestionContainer({children, label}) {
  return (
    <div className={styles.questionContainer}>
      {label && <label className={styles.title}>{label}</label>}
      {children}
    </div>
  )
}
