import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;
export const getplan = async (id) => {
  try {
    const res = await axios.get(`${API_URL}/tr/forms/patient/answers/${id}`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const sendScoope = async (data) => {
  try {
    const res = await axios.post(
      `${API_URL}/tr/desenlaces/scoope`,
      { data },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_APIGW_API_KEY,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
