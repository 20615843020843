import ReactDOM from "react-dom";
import { useCallback, useEffect, useRef } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import closeIcon from "../../assets/imgs/icons/close.svg";

import styles from "./Modal.module.scss";

export default function Modal({
  children,
  isOpen,
  onClose,
  variant,
  containerStyle,
  typeModal,
}) {
  const element =
    document.getElementById("modal") || document.createElement("div");
  const modalRef = useRef(null);
  const handleClickOutside = () => typeModal !== "filters" && onClose();

  useOnClickOutside(modalRef, handleClickOutside);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          onClose()
        }
      });
    }
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <div
          className={`
            ${styles.overlayBackground} 
            ${typeModal === "filters" && styles.overlayBackgroundFilters}
          `}
        >
          <div
            className={`${styles.modalContainer} ${
              variant === "large"
                ? styles.modalLarge
                : variant === "filters"
                ? styles.modalFilters
                : styles.XS
            } ${containerStyle}`}
            ref={modalRef}
          >
            {children}

            <button
              data-testid="close-modal-button"
              type="button"
              className={`${styles.closeButton} ${
                typeModal === "filters" && styles.closeButtonFilters
              }`}
              onClick={() => onClose()}
            >
              {typeModal === "filters" ? (
                <span className={styles.closeButtonIcon}>X</span>
              ) : (
                <img src={closeIcon} alt="cerrar modal" />
              )}
            </button>
          </div>
        </div>
      )}
    </>,
    element
  );
}
