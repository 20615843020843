import React from "react";
import styles from "./TextInputTable.module.scss";

const TextInputTable = (props) => {
  return (
    <>
      <input
        type={props.type || "text"}
        defaultValue={props.value}
        onChange={props.callback}
        onClick={props.onClick}
        placeholder={props.placeholder}
        className={styles.inputs}
        id="prueba_1"
      />
    </>
  );
};

export default TextInputTable;
