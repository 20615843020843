import PlusIcon from '../../assets/imgs/icons/addBlueButton.svg';

import styles from "./AddButtonOption.module.scss";

const AddButtonOption = (props) => {
  return (
    <button type="button" onClick={props.onClick} className={styles.addOption}>
       <img src={PlusIcon} alt="Icono Añadir" className={styles.addButton}/>
      Agregar Opción
    </button>
  )
}

export default AddButtonOption