import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import ModalP from "../../atoms/Modal";
import CardFSFB from "../../atoms/Card";
import Spinner from "../../atoms/Spinner";
import Eq5dInfo from "../../atoms/Eq5dInfo";
import EditHistory from "../../atoms/EditHistory";
import ModalContent from "../../atoms/ModalContent";
import ScaleDescription from "../../atoms/ScaleDescription";
import PosasDescription from "../../atoms/PosasDescription";
import AboutPatientForm from "../../atoms/AboutPatientForm";
import { QuestionList } from "../../molecules/QuestionList";
import { TitleAccordion } from "../../molecules/TitleAccordion";
import QuestionsCardFSFB from "../../molecules/QuestionsCardFSFB";
import { getItem } from "../../services/localStorage";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { AppContext } from "../../context/AppContext/AppContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import diligenceGreen from "../../assets/imgs/icons/infoGreenIcon.svg";
import diligenceBlue from "../../assets/imgs/icons/diligenceBlue.svg";
import styles from "./ManagerScale.module.scss";

export default function ManagerScale({ scale, edited }) {
  const navigate = useNavigate();
  const { reasonEdit, setReasonEdit } = useContext(AppContext);
  const { idPatientDetail } = useContext(DesenlacesContext);
  const [initialValues, setInitialValues] = useState({});
  const [answersLines, setAnswersLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDiligence, setIsDiligence] = useState(false);
  const [validation, setValidation] = useState(false);
  const [acceptProcessing, setAcceptProcessing] = useState(false);
  const [isEraser, setIsEraser] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [score, setScore] = useState();
  const [loadingForm, setLoadingForm] = useState(true);
  const [open, setOpen] = useState(false);
  const [isActiveEdit, setIsActiveEdit] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [patientEraser, setPatientEraser] = useState(false);
  const [Lquestions, setLQuestions] = useState([]);
  const [requierdFields, setRequierdFields] = useState({});
  const draftFields = {};
  const questions = scale?.form ? scale?.form?.schema : [];

  const closeModal = () => setOpen(false);

  const getValidationSchemas = async () => {
    const requiredFields = {};

    for (const q of questions) {
      let schema = {};

      if (q.question) {
        schema = generateValidationSchema(q.question);
        requiredFields[q.question.id] = schema;
      } else if (q.section) {
        for (const question of q.section.questions) {
          schema = generateValidationSchema(question);
          requiredFields[question.id] = schema;
        }
      }
    }
    await setRequierdFields(requiredFields);
  };

  const generateValidationSchema = (question) => {
    const schemaBase = Yup.string().required(
      "Olvidaste responder esta pregunta"
    );

    if (question?.is_required === true || question?.is_required === "true") {
      switch (question.type) {
        case 1:
        case 10:
          return Yup.array()
            .min(1, "Debe seleccionar por lo menos una opción")
            .required("Olvidaste responder esta pregunta");
        case 4:
          return Yup.array()
            .min(2, "Olvidaste responder esta pregunta")
            .required("Olvidaste responder esta pregunta");
        case 5:
          return Yup.number()
            .typeError(
              "Este campo debe ser diligenciado con números únicamente"
            )
            .required("Olvidaste responder esta pregunta");
        default:
          return schemaBase;
      }
    } else if (question.type === 5) {
      return Yup.number().typeError(
        "Este campo debe ser diligenciado con números únicamente"
      );
    } else {
      switch (question.type) {
        case 1:
        case 10:
          return Yup.array();
        case 4:
          return Yup.array();
        case 5:
          return Yup.number().typeError(
            "Este campo debe ser diligenciado con números únicamente"
          );
        default:
          return Yup.string();
      }
    }
  };

  questions?.map((scale) => {
    let schema = {};

    scale.type === 1
      ? (schema = Yup.array())
      : scale.type === 2
      ? (schema = Yup.array())
      : scale.type === 3
      ? (schema = Yup.array())
      : scale.type === 6
      ? (schema = Yup.array())
      : scale.type === 5
      ? (schema = Yup.number().typeError(
          "Este campo debe ser diligenciado con números únicamente"
        ))
      : scale.type === 4
      ? (schema = Yup.array())
      : scale.type === 10
      ? (schema = Yup.array())
      : (schema = Yup.string());

    return (draftFields[scale.id] = schema);
  });

  const openConfirmation = () => {
    setLoadingConfirmation(true);
  };

  const validationSchema = Yup.object({
    agree_medition: Yup.string().nullable().required("Campo obligatorio"),
    informant: Yup.number().required("Campo obligatorio"),
    loss_information: Yup.number().required("Campo obligatorio"),
    interview_type: Yup.number().required("Campo obligatorio"),
    answer_by: Yup.string().required("Campo obligatorio"),
    ...requierdFields,
  });

  const validationSchemaEdition = Yup.object({
    agree_medition: Yup.string().nullable(),
    informant: Yup.number(),
    loss_information: Yup.number(),
    interview_type: Yup.number(),
    answer_by: Yup.string(),
    ...draftFields,
  });

  const validationSchemaNull = Yup.object({});

  const extractQuestionValues = (question) => {
    const answers = question?.answers;
    switch (question.type) {
      case 2:
      case 3:
      case 6:
        return answers && answers?.length > 0 ? answers[0] : "";
      case 1:
        return answers && answers?.length > 0 ? answers : "";
      case 4:
        return answers && answers?.length > 0 ? answers[0]?.split(" ") : "";
      case 5:
        return answers && answers?.length > 0 ? parseInt(answers[0]) : "";
      case 8:
        return answers?.length > 0 ? answers[0] : "";
      case 11:
      case 12:
        return answers && answers?.length ? answers[0] : "";
      default:
        return answers ? answers[0] : "";
    }
  };

  const getInitialValues = () => {
    if (scale?.id) {
      const newValues = {};
      setLQuestions(scale?.form.schema?.sort((a, b) => a.order - b.order));
      const listQuestion = scale?.form?.schema;
      for (let q of listQuestion) {
        if (q.question) {
          newValues[q.question.id] = extractQuestionValues(q.question);

          if (q.question.remark) {
            newValues[`remark-${q.question.id}`] = q.question.remark;
          }
        } else if (q.section) {
          let listQ = q.section.questions;
          for (let question of listQ) {
            newValues[question.id] = extractQuestionValues(question);

            if (question.remark) {
              newValues[`remark-${question.id}`] = question.remark;
            }
          }
        }
      }

      newValues["agree_medition"] = scale?.form?.form_answer?.accept_scale
        ? "true"
        : !scale?.form?.form_answer?.accept_scale
        ? "false"
        : null;
      newValues["informant"] = scale?.form?.form_answer?.informant
        ? scale?.form?.form_answer?.informant
        : "";
      newValues["loss_information"] = scale?.form?.form_answer?.loss_information
        ? scale?.form?.form_answer.loss_information
        : "";
      newValues["interview_type"] = scale?.form?.form_answer?.interview_type
        ? scale?.form?.form_answer?.interview_type
        : "";
      newValues["answer_by"] = scale?.form?.form_answer?.answer_by
        ? scale?.form?.form_answer?.answer_by
        : scale?.form?.form_answer?.send_to_patient
        ? "gestor"
        : "";
      questions[0]?.question?.answers !== undefined &&
        setAnswersLines(questions[0]?.question?.answers[0]);

      setInitialValues(newValues);
      setLoadingForm(false);
    }
  };

  const handleSave = async (payload) => {
    if (scale?.form?.form_answer?.id) {
      setLoading(true);
      const id = scale?.form?.form_answer?.id;

      const data = await apiInterceptor({
        method: "PUT",
        endpoint: `/tr/forms/updateAnswers/${id}`,
        data: { ...payload, remarkId: reasonEdit ? reasonEdit : "" },
      });
      if (data.statusCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (data.statusCode === 400) {
        setLoading(false);
        alert("Error al actualizar el formulario");
      }
    } else {
      setLoading(true);

      const data = await apiInterceptor({
        method: "POST",
        endpoint: `/tr/forms/saveAnswers`,
        data: payload,
      });

      if (data.statusCode === 201) {
        setLoading(false);
        await setScore(data?.data[0]?.scoreScale ? data?.data[0]?.scoreScale : 0);
        openConfirmation();
        setReasonEdit(null);

        isEraser && navigate(-1);
      } else {
        setLoading(false);
        alert("Error al guardar el formulario");
        setReasonEdit(null);
        navigate(-1);
      }
    }
  };

  const validationPatient = patientEraser
    ? validationSchemaNull
    : acceptProcessing
    ? validationSchema
    : scale?.form?.is_finished
    ? validationSchema
    : validationSchemaNull;

  const validationManager = isDiligence
    ? validationSchemaNull
    : acceptProcessing
    ? validationSchema
    : validationSchemaEdition;

  useEffect(() => {
    getValidationSchemas();
    getInitialValues();
  }, [scale]);

  if (scale !== null) {
    return (
      <>
        {loadingForm ? null : (
          <Formik
            initialValues={initialValues}
            validationSchema={
              scale?.form?.send_to_patient
                ? validationPatient
                : validationManager
            }
            onSubmit={async (values) => {
              let questions = [];
              let remarks = [];
              for (let scaleAnsw in values) {
                if (scaleAnsw.includes("remark")) {
                  remarks.push({
                    id: scaleAnsw.substring(7),
                    value: values[scaleAnsw],
                  });
                } else if (
                  scaleAnsw !== "agree_medition" &&
                  scaleAnsw !== "informant" &&
                  scaleAnsw !== "loss_information" &&
                  scaleAnsw !== "interview_type" &&
                  scaleAnsw !== "answer_by" &&
                  scaleAnsw !== "name"
                ) {
                  questions.push({
                    id: scaleAnsw,
                    answers: values[scaleAnsw],
                    remarks: "",
                  });
                }
              }

              let questionRemark = [];

              for (let r in questions) {
                let findRemark = remarks.find(
                  (remark) => remark.id === questions[r].id
                );
                if (findRemark) {
                  questionRemark.push({
                    ...questions[r],
                    remarks: findRemark.value,
                  });
                } else {
                  questionRemark.push(questions[r]);
                }
              }

              let eraserPayload = {
                accept_scale: values.agree_medition === "true" ? true : false,
                informant: parseInt(values.informant),
                loss_information: parseInt(values.loss_information),
                interview_type: parseInt(values.interview_type),
                id_manager: getItem("user").id,
                id_plan: scale.id,
                answer_by: values.answer_by,
                questions: questionRemark,
                isFinished: false,
              };

              let payloadWithoutInfo = {
                id_plan: scale.id,
                accept_scale: values.agree_medition === "true" ? true : false,
                informant: values.informant ? parseInt(values.informant) : 16,
                loss_information: values.loss_information
                  ? parseInt(values.loss_information)
                  : 23,
                interview_type: values.interview_type
                  ? parseInt(values.interview_type)
                  : 26,
                isFinished: true,
                id_manager: getItem("user").id,
                answer_by: values.answer_by,
                questions: questionRemark,
              };

              let payload = {
                accept_scale: values.agree_medition === "true" ? true : false,
                informant: parseInt(values.informant),
                loss_information: parseInt(values.loss_information),
                interview_type: parseInt(values.interview_type),
                id_manager: getItem("user").id,
                id_plan: scale.id,
                answer_by: values.answer_by,
                questions: questionRemark,
                isFinished: validation ? true : false,
              };
              if (patientEraser) {
                handleSave(eraserPayload);
              } else if (isEraser) {
                handleSave(eraserPayload);
              } else if (isDiligence) {
                handleSave(payloadWithoutInfo);
              } else if (validation) {
                handleSave(payload);
              } else if (!isDiligence & !validation) {
                handleSave({
                  ...payloadWithoutInfo,
                  answer_by: values.answer_by,
                });
              }
            }}
          >
            {({ values, setErrors, errors }) => (
              <Form id="PruebaFormik">
                <div className={styles.formContainer}>
                  <CardFSFB className={styles.card}>
                    <TitleAccordion
                      handleClick={() => setIsActive(!isActive)}
                      isActive={isActive}
                      className={styles.arrowAccordion}
                    >
                      <h3 className={styles.titleAccordion}>
                        Sobre el paciente
                      </h3>
                    </TitleAccordion>
                    {isActive ? (
                      <AboutPatientForm
                        scale={scale}
                        acceptProcessing={acceptProcessing}
                        edited={edited}
                      />
                    ) : null}
                  </CardFSFB>

                  {scale?.form?.id &&
                    (scale?.form?.id === 23 || scale?.form?.id === 176) && (
                      <Eq5dInfo />
                    )}
                  <ScaleDescription acceptProcessing={acceptProcessing} />

                  {edited ? (
                    <>
                      <CardFSFB className={styles.card}>
                        <TitleAccordion
                          handleClick={() => setIsActiveEdit(!isActiveEdit)}
                          isActive={isActiveEdit}
                          className={styles.arrowAccordion}
                        >
                          <h3 className={styles.titleAccordion}>
                            Historial de edición
                          </h3>
                        </TitleAccordion>
                        {isActiveEdit && scale?.form?.edited.status ? (
                          <EditHistory record={scale?.form?.edited} />
                        ) : null}
                      </CardFSFB>
                    </>
                  ) : null}
                  {scale?.form?.id && scale?.form?.id === 79 && (
                    <PosasDescription />
                  )}
                  <div
                    className={styles.questionsContainer}
                    style={
                      !edited && scale?.form?.is_finished
                        ? { marginBottom: "50px" }
                        : null
                    }
                  >
                    {Lquestions?.map((item, index) => (
                      <div key={`q-${item.id}`}>
                        <QuestionsCardFSFB
                          item={item}
                          index={index}
                          answersLines={answersLines}
                          scale={scale}
                          edited={edited}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className={`${styles.buttonOptions}`}>
                  <QuestionList values={values} scale={scale.form} />
                  <div>
                    <h1 className={styles.scaleName}>{scale.form.name}</h1>
                    <p className={styles.patientName}>
                      {scale.patient_name} - {scale.doc_number}
                    </p>
                    <div
                      className={`${styles.buttons}  ${
                        scale?.form?.form_answer?.isFinished && edited === false
                          ? styles.hiddenButtons
                          : ""
                      }`}
                    >
                      <button
                        type="submit"
                        onClick={
                          edited
                            ? () =>
                                navigate(
                                  `/desenlaces/pacientes/detalle/${idPatientDetail}`
                                )
                            : () => {
                                scale?.form?.send_to_patient
                                  ? setPatientEraser(true)
                                  : setValidation(false);
                                setIsEraser(true);
                              }
                        }
                        className={`${styles.saveBtn} ${styles.btn} ${
                          loading && styles.disabledButton
                        }`}
                        disabled={loading}
                      >
                        {edited ? "Cancelar" : "Guardar borrador"}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setErrors({});
                          setOpen(true);
                        }}
                        className={`${styles.sendButton} ${styles.btn} ${
                          loading && styles.disabledButton
                        }`}
                        disabled={loading}
                      >
                        {edited ? "Guardar cambios" : "Finalizar"}
                      </button>
                    </div>
                  </div>
                </div>
                {Object.keys(errors).length ? closeModal() : null}
                <ModalP
                  isOpen={open}
                  onClose={
                    loadingConfirmation
                      ? () => navigate(-1)
                      : () => closeModal()
                  }
                  variant="confirm"
                >
                  {loading ? (
                    <Spinner className_container="rolesForm" />
                  ) : loadingConfirmation ? (
                    <ModalContent
                      close={() => {
                        navigate(-1);
                        setOpen(false);
                      }}
                      text={
                        score >= 0
                          ? "Escala finalizada\nResultado: " + score + " puntos"
                          : "Escala finalizada"
                      }
                      btns={false}
                      icon={diligenceGreen}
                      alt="Icono diligenciamiento"
                    />
                  ) : (
                    <ModalContent
                      typeBtnCustom="submit"
                      form="PruebaFormik"
                      close={() => closeModal()}
                      confirm={async () => {
                        if (!values["agree_medition"]) {
                          setIsDiligence(true);
                          setValidation(false);
                          setAcceptProcessing(false);
                        } else if (
                          (values.agree_medition === "false") |
                          false
                        ) {
                          setIsDiligence(false);
                          setValidation(false);
                          setAcceptProcessing(false);
                        } else if ((values.agree_medition === "true") | true) {
                          setIsDiligence(false);
                          setValidation(true);
                          setAcceptProcessing(true);
                        }
                      }}
                      text={"Atención"}
                      helpText={
                        "si das clic en Finalizar diligenciamiento\nno podrás volver a modificar\nlos campos de la escala\n\n¿Deseas finalizar?"
                      }
                      btns={true}
                      icon={diligenceBlue}
                      alt="Icono diligenciamiento"
                    />
                  )}
                </ModalP>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  } else {
    return null;
  }
}
