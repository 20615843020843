import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as moment from "moment-timezone";

import CardFSFB from "../../atoms/Card";
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { getPatientDetails } from "../../services/Contigo/patient";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import MessageIcon from "../../assets/imgs/icons/message.svg";
import ModalContent from "../../atoms/ModalContent";
import MoreIcon from "../../assets/imgs/icons/more.svg";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import styles from "./Comment.module.scss";

export const Comment = ({ trackInfo }) => {
  const params = useParams();
  const navigate = useNavigate();
  const parentRef = useRef(null);
  const { setDiligenceInfo } = useContext(DesenlacesContext);
  const [showComment, setShowComment] = useState(
    trackInfo.comment ? true : false
  );
  const [openOptions, setOpenOptions] = useState(false);
  const [initialValues, setInitialValues] = useState({
    comment: trackInfo.comment,
  });
  const [selectOption, setSelectOption] = useState();
  const [deleteOption, setDeleteOption] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [statusDelete, setStatusDelete] = useState(null);
  const [error400, setError400] = useState();
  const [loading, setLoading] = useState(false);

  const handleDeleteOComment = async () => {
    setLoading(true);
    setLoadingForm(true);
    const data = await apiInterceptor({
      method: "PUT",
      endpoint: `/tr/contigo/track/${deleteId}`,
      data: {
        comment: "",
      },
    });

    if (data.statusCode === 200) {
      await handlePatientDetail();
      await setInitialValues({ comment: "" });
      setStatusDelete(200);
      setDeleteId(null);
      setShowComment(false);
      setLoadingForm(false);
      setLoading(false);
    } else {
      setError400(data.message);
    }
  };
  const handleOpenOptions = async (id) => {
    setSelectOption(id);
    await setOpenOptions(true);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteOption(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        parentRef.current &&
        !parentRef.current.contains(event.target) &&
        openOptions
      )
        setOpenOptions(false);
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [openOptions]);

  const handleCloseModal = () => {
    setStatusDelete(null);
    setDeleteOption(false);
  };

  const handlePatientDetail = async () => {
    const response = await getPatientDetails(params.idPatient);

    if (response.response[0]) {
      setDiligenceInfo(response.response[0]);
    } else {
      navigate("/error/404");
    }
  };

  return (
    <>
      {loadingForm ? (
        <Spinner className_container="alertDel" />
      ) : (
        <CardFSFB className={styles.card}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setFieldValue }) => {
              setLoading(true);
              const id = trackInfo.track_id;
              const data = await apiInterceptor({
                method: "PUT",
                endpoint: `/tr/contigo/track/${id}`,
                data: {
                  comment: values.comment,
                },
              });

              if (data.statusCode === 200) {
                await handlePatientDetail();
                setInitialValues({ comment: values.comment });
                setFieldValue("comment", values.comment);
                setShowComment(true);
                setLoading(false);
              } else {
                setError400(data.message);
              }
            }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <p className={styles.activeCommentTitle}>Comentario:</p>{" "}
                {showComment ? (
                  <>
                    <div className={styles.listContainer}>
                      <div className={styles.comment}>
                        <p className={styles.activeTitle}>
                          {trackInfo?.user_comment} {"  "}
                          {trackInfo?.date_comment
                            ? moment(trackInfo?.date_comment)
                                .utc()
                                .format("YYYY-MM-DD")
                            : null}
                          {}
                        </p>
                        <p>{initialValues.comment}</p>
                      </div>

                      <button
                        type="button"
                        className={styles.moreButton}
                        onClick={() => {
                          handleOpenOptions(trackInfo.track_id);
                        }}
                      >
                        <img src={MoreIcon} alt="Icono opciones" />
                      </button>

                      {openOptions && selectOption === trackInfo.track_id ? (
                        <div
                          className={styles.optionsContainer}
                          ref={parentRef}
                        >
                          <button
                            className={styles.optionButton}
                            type="button"
                            onClick={() => {
                              setFieldValue("comment", initialValues.comment);
                              setShowComment(false);
                            }}
                          >
                            Editar
                          </button>
                          <button
                            type="button"
                            className={styles.optionButton}
                            onClick={() => handleDelete(trackInfo.track_id)}
                          >
                            Eliminar
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className={styles.commentContainer}>
                    {loading ? (
                      <Spinner className_container="alertDel" />
                    ) : (
                      <textarea
                        name="comment"
                        rows="2"
                        cols="50"
                        className={styles.textArea}
                        onChange={handleChange}
                        value={values.comment}
                        placeholder={"Escribe tu comentario aquí"}
                        disabled={loading}
                      />
                    )}
                    {values.comment?.length > 1 && (
                      <button
                        type="submit"
                        className={styles.sendButton}
                        disabled={loading}
                      >
                        <img src={MessageIcon} alt="icono Mensaje" />
                        Comentar
                      </button>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </CardFSFB>
      )}
      {deleteOption ? (
        <Modal isOpen={deleteOption} onClose={handleCloseModal} variant="large">
          {loading ? (
            <Spinner className_container="rolesForm" />
          ) : (
            <ModalContent
              close={() => handleCloseModal}
              confirm={handleDeleteOComment}
              text={
                statusDelete === 200
                  ? `Comentario eliminado`
                  : statusDelete === null
                  ? `¿Realmente quieres eliminar el comentario?`
                  : error400
              }
              updateTitleCustom={styles.updateTitleCustom}
              btns={statusDelete === null && true}
              icon={
                statusDelete === 200 || statusDelete === null
                  ? personGreen
                  : redFlag
              }
              alt="Icono de líneas"
            />
          )}
        </Modal>
      ) : null}
    </>
  );
};
