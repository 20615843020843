
import styles from './AddButton.module.scss';
import AddIcon from '../../assets/imgs/icons/AddButton.svg';

const AddButton = ({onClick, type}) => {
  return (
    <div className={styles.addButtonContainer} type="button">
      <img src={AddIcon} alt="Icono Añadir" onClick={onClick}  className={styles.addButton}/>
      <p>Agregar</p>
    </div>
  )
}

export default AddButton;