import React, { useState } from "react";

import File from "../../assets/imgs/icons/file.svg";

import styles from "./UploadFile.module.scss";

export const UploadFile = ({ id = "fileSelector", name = null, href = null, modalType, title, extension, type, maxSize, onChange, onError }) => {
  const [file, setFile] = useState(name);
  const [error, setError] = useState("");
  const [link, setLink] = useState(href);

  const handleChange = async ({ target: { files } }) => {
    setError("");
    setFile("");
    const { type: fileType, size, name: newName } = files?.[0];
    if (fileType && fileType !== type) {
      onError ? onError() : setError(`El archivo debe ser de extensión .${extension}`);
    } else if (maxSize && size > maxSize) {
      onError ? onError() : setError(`El archivo debe pesar máximo ${maxSize} bytes`);
    } else {
      setLink(null);
      const newLink = await onChange(files?.[0]);
      setFile(newName);
      newLink && setLink(newLink);
    }
  };

  return (
    <section className={styles.uploadIcon}>
      {modalType && file && !link && <span className={`${styles.file} ${modalType && styles.divider}`}>{file}</span>}
      {modalType && file && link && (
        <a
          href={link}
          className={`
            ${styles.file} 
            ${modalType && styles.divider}
          `}
        >
          {file}
        </a>
      )}
      <label className={styles.label} htmlFor={id}>
        <img src={File} alt="Upload" />
        {title}
      </label>
      <input
        id={id}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        accept={extension ? `.${extension}` : "*"}
      />
      {error && <span className={styles.error}>{error}</span>}
      {!modalType && file && <span className={styles.file}>{file}</span>}
    </section>
  )
};
