import React, { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import * as moment from "moment-timezone";

import { Pagination } from "../../atoms/Pagination";
import Table from "../../molecules/Table";
import { offsetUser } from "../../custom/offset";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { AppContext } from "../../context/AppContext/AppContext";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import { ManagerContext } from "../../context/ManagerContext/ManagerContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import TableHeader from "../../atoms/TableHeader";
import { setItem } from "../../services/localStorage";
import { useSort } from "../../hooks/useSort";
import styles from "./TodayTable.module.scss";

export default function TodayTable({ childData, tableRender }) {
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  const { setModulesSelect } = useContext(AppContext);
  const {
    startDateParaHoy,
    endDateParaHoy,
    genereParaHoy,
    ageParaHoy,
    idOptionsParaHoy,
  } = useContext(FiltersContext);
  const { setLocation } = useContext(ManagerContext);
  const {
    todayList,
    setTodayList,
    setCustomePagination_today,
    actualPage_today,
    setActualPage_today,
    setDiligenceInfo,
    scale_today,
    setScale_today,
    name_today,
    setName_today,
    document_today,
    setDocument_today,
    line_today,
    frequency_today,
    sort_today,
    setSort_today,
    pagination_today,
    setId,
  } = useContext(DesenlacesContext);

  const calculateTooltipExpiration = (date) => {
    const currentDate = moment();
    const datePlan = moment(date);
    const diff = datePlan.diff(currentDate, "days");

    if (diff !== 0) {
      return "Fuera de tiempo";
    }

    return "A tiempo";
  };

  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Paciente"
            column={column}
            handleGetFilter={setName_today}
            filterValue={name_today}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleRedirect(row.original)}
          >
            {row.original.patient.name}
          </p>
        );
      },
      accessor: "patient.name",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Documento"
            column={column}
            handleGetFilter={setDocument_today}
            filterValue={document_today}
          />
        );
      },
      accessor: "patient.doc_number",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Desenlace"
            column={column}
            handleGetFilter={setScale_today}
            filterValue={scale_today}
          />
        );
      },
      accessor: "scale.name",
      width: 25,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Periodo" column={column} />;
      },
      Cell: ({ row }) => (
        <section>
          <span className={styles.frequencyDays}>{row.original.frequency}</span>
          <span className={styles.frequencyPeriod}>
            {` dia${row.original.frequency > 1 ? "s" : ""}`}
          </span>
        </section>
      ),
      accessor: "frequency",
      width: 10,
      disableFilters: true,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Evento" column={column} />;
      },
      accessor: "event.event_number",
      width: 10,
      disableFilters: true,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha" column={column} />;
      },
      Cell: ({ row }) => (
        <p style={{ color: row.original.date_plan_color }}>
          {moment.utc(row.values.date_plan).format("YYYY-MM-DD")}
        </p>
      ),
      accessor: "date_plan",
      width: 10,
      disableFilters: true,
      textAlign: "center",
      tooltipType: "utc",
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Vencimiento" column={column} />;
      },
      Cell: ({ row }) => (
        <p style={{ color: row.original.date_plan_color }}>
          {calculateDateDifference(row.values.date_plan)}
        </p>
      ),
      id: "expiration",
      accessor: "date_plan",
      width: 20,
      disableFilters: true,
      textAlign: "right",
      tooltipType: "expiration",
      tooltipFunction: calculateTooltipExpiration,
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const calculateDateDifference = (date) => {
    const currentDate = moment();
    const datePlan = moment(date);
    const differenceInDays = currentDate.diff(datePlan, "days");

    if (differenceInDays > 0 && differenceInDays <= 30) {
      return `Hace ${differenceInDays} día${differenceInDays > 1 ? "s" : ""}`;
    }

    if (differenceInDays > 30 && differenceInDays <= 365) {
      const months = Math.floor(differenceInDays / 30);
      return `Hace ${months} mes${months > 1 ? "es" : ""}`;
    }

    if (differenceInDays > 365) {
      const years = Math.floor(differenceInDays / 365);
      return `Hace ${years} año${years > 1 ? "s" : ""}`;
    }

    return "Hoy";
  };

  const handleRedirect = (selected) => {
    setModulesSelect("desenlaces");
    setItem("module", "desenlaces");
    setLocation("today");
    setId(selected.id);
    navigate(`/desenlaces/parahoy/diligenciamiento/${selected.id}`);
  };

  const handleGetTodayList = async () => {
    let params = {
      page: actualPage_today,
      offset: offsetUser(height, width),
      sort: sort_today.replace("expiration", "date_plan"),
      name: name_today,
      doc_number: document_today,
      scale: scale_today,
      start_date: startDateParaHoy
        ? format(startDateParaHoy, "yyyy-MM-dd")
        : "",
      end_date: endDateParaHoy ? format(endDateParaHoy, "yyyy-MM-dd") : "",
      genre: genereParaHoy,
      legal_age: ageParaHoy,
      id_service_line: idOptionsParaHoy.toString(),
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/desenlaces/today?${route}`,
    });
    const lastPage = res.pagination.last_page;
    const actualPage_1 = res.pagination.page;
    setTodayList(res.data);
    setCustomePagination_today({
      page: actualPage_1,
      paginationTotal: lastPage,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetTodayList();
  }, [
    actualPage_today,
    sort_today,
    control,
    tableRender,
    startDateParaHoy,
    endDateParaHoy,
    genereParaHoy,
    ageParaHoy,
    idOptionsParaHoy.length > 0 && idOptionsParaHoy,
  ]);

  // custom hook sort
  useSort(sort_today, setSort_today);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [scale_today, name_today, document_today]);

  useEffect(() => {
    if (
      scale_today.length ||
      name_today.length ||
      document_today.length ||
      line_today.length ||
      frequency_today.length > 0
    ) {
      setActualPage_today(1);
    }
  }, [scale_today, name_today, document_today]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={todayList}
        setValues={setDiligenceInfo}
        getData={handleGetTodayList}
        offsetUser={offsetUser}
        tooltipText="Haz clic en la fila para iniciar el diligenciamiento. Para descartar selecciona los registros haciendo clic en el selector de la izquierda"
        childData={childData}
      />
      <Pagination
        actualPage={actualPage_today}
        setPage={setActualPage_today}
        infoPage={pagination_today}
      />
    </>
  );
}
