import { useState, useEffect } from "react";
import { useField } from "formik";

import styles from "./FileUpload.module.scss";
import File from "../../assets/imgs/icons/file.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";

const FileUpload = ({ ...props }) => {
  const [field, , helpers] = useField(props);
  const [selectedFile, setSelectedFile] = useState(null);
  
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
    return new Promise(async (resolve, reject) => {
      const $canvas = document.createElement("canvas");
      const imagen = new Image();
      imagen.onload = () => {
        let w = imagen.width,
          h = imagen.height;
        let factor = 1;
        if (w > 2000 || h > 2000) factor = 0.5;
        $canvas.width = w * factor;
        $canvas.height = h * factor;
        const ctx = $canvas.getContext("2d");
        ctx.drawImage(imagen, 0, 0, w * factor, h * factor);
        const result = $canvas.toDataURL("image/jpeg", porcentajeCalidad);
        resolve(result);
      };
      imagen.src = await toBase64(imagenComoArchivo);
    });
  };

  const handleChangeFile = async (event) => {
    const file = event.currentTarget.files[0];;
    const b64 = await comprimirImagen(file, 0.1);
    setSelectedFile(b64)
    return;
  };

  const handleImageClear = () => {
    helpers.setValue(null);
  };

  useEffect(() => {
    helpers.setValue(selectedFile);
  }, [selectedFile]);

    useEffect(() => {
    helpers.setValue(field.value);
  }, []);

  return (
    <>
    <label>
      <button
        className={styles.buttonUpload}
        type="button"
        onClick={() => document.getElementById(props.id).click()}
        disabled={props.disabled && props.disabled}
      >
        <img src={File} alt="File Upload" />
        <span>
          Adjuntar 
        </span>
      </button>

      <input
        type="file"
        {...props}
        onChange={handleChangeFile}
        style={{ display: "none" }}
        disabled={props.disabled && props.disabled}
      />

    </label>

      {field.value && (
        <div className={styles.previewContainer}>
          <div className={styles.previewImage}>
            <img src={field.value} alt="imagen selecconada" />
          </div>

          <img
            src={trashIcon}
            alt="icono borrar"
            className={styles.trashIcon}
            onClick={handleImageClear}
          />
        </div>
      )}
      </>
  );
};
export default FileUpload;
