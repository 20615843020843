import HeaderLogo from "../../atoms/HeaderLogo";
import styles from "./ProfileHeader.module.scss";

export default function ProfileHeader() {
  return (
    <>
      <div className={styles.container}>
        <HeaderLogo />
        <h1 className={styles.mainTitle}>Tu recuperación</h1>
      </div>
    </>
  );
}
