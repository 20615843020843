import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext/AppContext";

import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

import { transformNumber } from "../../custom/numberTransformations";

import styles from "./infoCard.module.scss";

const InfoCard = ({
  variant,
  color,
  icon,
  detail,
  path,
  type,
  modulesDependant = false,
  backendField = "",
  active,
  opacity,
  onClick
}) => {
  const { modules } = useContext(AppContext);
  const parentModules = modules.filter(mod => mod.submodules.length > 0);
  const modulesToFind = parentModules.map(mod => mod.id).join(",");

  const [total, setTotal] = useState(0);

  const getDashboardData = async () => {
    try {
      const { data } = await apiInterceptor({
        method: "GET",
        endpoint: `${path}?type=${type}${modulesDependant ? `&modules=${modulesToFind}` : ""}`,
      });

      setTotal(data[`${backendField}`]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!modulesToFind) return;
    if (type) getDashboardData();
  }, [type, modulesToFind]);

  return (
    <div
      className={`
      ${styles.cardContainer}
      ${variant === "medium" && styles.canHover}
      ${active && styles.active}
      ${opacity && styles.opacity}
    `}
      onClick={onClick}
    >
      <div className={`${styles.iconContainer} ${variant === "medium" && styles.smallIcon} ${styles[color]}`}>
        <img src={icon} alt="icono dashboard" />
      </div>
      <div className={`${styles.infoContainer} ${variant === "medium" && styles.smallInfo}`}>
        <p className={`${styles.numberDetail} ${variant === "medium" && styles.smallNumber}`}>
          {transformNumber(total)}
        </p>
        <p className={`${styles.detailText} ${variant === "medium" && styles.smallDetailText}`}>
          {detail}
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
