import React, { useEffect, useRef, useState } from "react";
import { Stage } from "react-konva";
import posasBg from "../../assets/imgs/posas.png";
import style from "./Canva.module.scss";

const CanvasPreview = ({ answersLines, scale, ...props }) => {
  const [tool] = useState("pen");
  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);

  const handleMouseDown = (e) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  useEffect(() => {
    if (answersLines !== undefined) {
      setLines(answersLines);
    }
  }, [answersLines]);

  return (
    <div className={style.canvaContainer}>
      {}

      <Stage
        width={475}
        height={320}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        style={{
          backgroundImage: `url(${posasBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          mouseZoomFactor: 1,
        }}
      >
        {}
      </Stage>
    </div>
  );
};

export default CanvasPreview;
