import { apiInterceptor } from "./interceptors/jwt.interceptor";
import { getItem, setItem } from "./localStorage";

let user, loaded;

function setUser(toSave) {
  user = toSave;
  setItem("user", toSave);
}

export function getUser() {
  if (!loaded) {
    loaded = true;
    setUser(getItem("user"));
  }
  return user;
}

export async function whoamiService() {
  const response = await apiInterceptor({
    endpoint: "/tr/admin/whoami",
    method: "GET",
  });
  setUser(response.data[0]);
  return { ...response, infoUser: user };
}
