import styles from './_.module.scss';

export default function ImageSideDescription({
  children,
  src,
  alt,
  stylesImage = {},
  classesImage = '',
}) {
  return (
    <div className={styles.parent}>
      <div className={styles.description}>{children}</div>
      <img src={src} alt={alt} style={stylesImage} className={`${styles.image} ${classesImage}`} />
    </div>
  );
}