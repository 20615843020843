import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function DatePickerUntil({
  preValue,
  changeValue,
  minDate,
  maxDate,
  withCleaner = false,
  filterValue,
  styleFilter,
}) {
  const [until, setUntil] = useState(filterValue ? filterValue : null);
  const location = useLocation();
  const { width } = useWindowDimensions();

  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: "100%",
            height: styleFilter === "modal" && "23px",
            marginLeft: styleFilter === "modal" && "10px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "11px !important",
            paddingRight: styleFilter === "modal" && "10px !important",
          },
          input: {
            marginLeft: styleFilter === "modal" && "5px",
            height: styleFilter === "modal" && "23px",
            width: "100% !important",
            // padding: "5px 1.5vh !important",
            padding:
              location.pathname === "/desenlaces/reporte" && width <= 960
                ? "0px !important"
                : styleFilter === "modal"
                ? "0px !important"
                : "5px 1.5vh !important",
            paddingRight: "0 !important",
            fontSize:
              location.pathname === "/desenlaces/reporte" && width <= 960
                ? "10px"
                : location.pathname === "/desenlaces/reporte" && width <= 1366
                ? "12px"
                : location.pathname === "/desenlaces/reporte" && width > 1366
                ? "16px"
                : styleFilter === "modal"
                ? "12px"
                : "14px",
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            position: "absolute",
            right: "0",
            paddingRight: styleFilter === "modal" ? "9px" : "1vh",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            color: "rgba(107, 187, 197, 1) !important",
            padding: "0 !important",
            marginRight: "0 !important",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            marginRight: "0 !important",
            fontSize: styleFilter === "modal" ? "1rem" : "16px",
          },
        },
      },
    },
  });

  useEffect(() => changeValue && changeValue(until), [until]);

  useEffect(() => {
    setUntil(preValue);
  }, [preValue]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DatePicker
            inputFormat="dd/MM/yyyy"
            components={{
              OpenPickerIcon: DateRangeOutlinedIcon,
            }}
            value={until}
            onChange={(newValue) => {
              newValue?.getFullYear()?.toString().length === 4 && setUntil(newValue);
            }}
            minDate={minDate}
            maxDate={maxDate}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder:
                    styleFilter === "modal" ? "DD MM AAAA" : "dd/mm/yyyy",
                }}
              />
            )}
          />
          {until && withCleaner ? (
            <i
              style={{
                color: "black",
                paddingLeft: 5,
                fontStyle: "normal",
                cursor: "pointer",
              }}
              onClick={() => setUntil(null)}
            >
              X
            </i>
          ) : null}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
