import logoWhite from "../../assets/imgs/logoFsfb_header_white.svg";
import styles from "./HeaderLogoWhite.module.scss";

export default function HeaderLogo() {
  return (
    <img
      src={logoWhite}
      alt="LogoFsfbWhite"
      className={styles.logoFsfbHeaderWhite}
    />
  );
}
