import React from "react";

import ArrowIcon from "../../assets/imgs/icons/arrow_accordion.svg";
import arrowAccordionWhite from "../../assets/imgs/icons/arrowAccordionWhite.svg";
import styles from "./TitleAccordion.module.scss";

export const TitleAccordion = ({
  children,
  isPatientDetail,
  handleClick,
  isActive,
  className, 
  sectionCardContainer,
  variant
}) => {

  return (
    <div 
      className={`
        ${styles.cardContainer} 
        ${sectionCardContainer && sectionCardContainer}`}
        onClick={variant === 'diligence' ? handleClick : null}
        style={variant === 'diligence' ? {cursor: 'pointer'}: null}
    >
      <>
        {children && children}
        <img
          src={isPatientDetail ? arrowAccordionWhite : ArrowIcon}
          alt="icono abrir acordion"
          onClick={variant !== 'diligence' ? handleClick : null}
          className={`
        ${isPatientDetail ? styles.iconAccordionPatient : styles.iconAccordion}
        ${!isActive && styles.rotateIcon}
        ${className && className}
      `}
        />
      </>
    </div>

  );
};
