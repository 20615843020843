import { useState, useEffect, useContext } from "react";
import { AppContext } from "../context/AppContext/AppContext";

export function useSort(component_sort, setComponent_sort) {
  const [isMounted, setIsMounted] = useState(false);

  const { sort, setSort } = useContext(AppContext);

  useEffect(() => {
    if (isMounted) {
      setComponent_sort(sort);
    } else {
      setIsMounted(true);
    }
  }, [sort, isMounted]);

  useEffect(() => {
    setSort(component_sort);
  }, []);

  return [component_sort, setComponent_sort];
}
