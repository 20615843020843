import React, { useContext } from "react";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import { searchPatient } from "../../services/Contigo/patient";

import { calculateAge } from "../../custom/calculateAge";

import UpdateData from "../UpdateData";
import HabeasData from "../../atoms/HabeasData";

import styles from "./PatientDetail.module.scss";

const docTypes = {
  2: "R.C",
  3: "T.I",
  4: "C.C",
  5: "C.E",
  7: "C.D",
  P: "P",
  S: "SC",
};

export const PatientDetail = ({ canUpdate = true }) => {
  const { userInfo, payloadSearch, setUserInfo, setIsError } =
    useContext(ContigoContext);

  const updateData = async () => {
    const response = await searchPatient(payloadSearch);
    if (response.response.length > 0) {
      setUserInfo(response.response[0]);
    } else {
      setIsError(true);
    }
  };

  const names = `${userInfo?.names} ${userInfo?.last_name} ${userInfo?.mother_last_name}`;

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <div className={styles.userInfoId}>
          <p className={styles.name}>
            {userInfo.names} {userInfo.last_name} {userInfo.mother_last_name}
          </p>
          <p className={styles.user_doc}>{docTypes[`${userInfo.tdoc.code}`] || userInfo.tdoc.detail}</p>
          <p className={styles.user_doc}>{userInfo.doc}</p>
        </div>
        {canUpdate && <div className={styles.updateData} style={{ display: userInfo?.id_patient ? "inline" : "block" }}>
          <UpdateData
            name={names}
            email={userInfo?.email}
            id={userInfo?.id_patient}
            mobile={userInfo?.mobile}
            phone={userInfo?.phone}
            updateInfo={updateData}
          />
        </div>}
      </section>
      <section className={styles.userInfoDetail}>
        <section className={styles.userInfoContainer}>
          <div className={styles.detail}>
            <p className={styles.title}>Edad:</p>
            <p className={styles.value}>{calculateAge(userInfo?.birtdate)} años</p>
          </div>
          <div className={styles.detail}>
            <p className={styles.title}>Genero:</p>
            <p className={styles.value}>{userInfo.gender === "F" ? "Femenino" : "Masculino"} </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.title}>Correo Electrónico:</p>
            <p className={styles.value}>{userInfo.email} </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.title}>Celular:</p>
            <p className={styles.value}>{userInfo.mobile} </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.title}>Fijo:</p>
            <p className={styles.value}>{userInfo.phone} </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.title}>Dirección:</p>
            <p className={styles.value}>{userInfo.address} </p>
          </div>
        </section>
        <HabeasData policyAcceptance={userInfo.accept_habeas === "S"} />
      </section>
    </div>
  );
}
