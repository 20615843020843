import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const getPatients = async (offset, page = 1, filters = "") => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/desenlaces/patients?offset=${offset}&page=${page}${filters}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getOne = async (id) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/desenlaces/patients/detail/${id}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const undeleteTracing = async (data) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/desenlaces/undelete`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
