import ErrorTemplate from "../../molecules/ErrorTemplate";

export default function ErrorInconvenient() {
  return (
    <ErrorTemplate
      titleLabel="¡Ups! Hay un inconveniente"
      subtitleLabel="Lo sentimos"
      text={["El servicio no responde en este momento, inténtalo más tarde."]}
    />
  );
}
