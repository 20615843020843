import logo from "../../assets/imgs/logoFsfb.png"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import icon from "../../assets/imgs/icon-check-diligence.png";
import CenterMessage from "../../atoms/CenterMessage";
import FooterDescription from "../../molecules/FooterDescription";
import ProfileHeaderWhite from '../../molecules/ProfileHeaderWhite';
import styles from "./FinalDiligence.module.scss";

export default function FinalDiligence() {
  const { width } = useWindowDimensions();

  return (
    <>
      <main className={styles.container}>
      <ProfileHeaderWhite />
        {width <= 480 ?
          <>
            <img src={logo} alt="Logo FSFB" className={styles.logoFsfb}></img>
            <img src={logo} alt="Logo FSFB" className={styles.logoFsfb_2}></img>
          </>
          :
          null
        }
        <section className={styles.subContainer}>
          <img className={styles.img} src={icon} alt="IconCheckDiligence" />
          <CenterMessage
            title2="El proceso ha finalizado con éxito,"
            subtitle="cuenta con nosotros"
          />
          <div className={styles.containerButton}>
            <div className={styles.nextButton}>Ya puedes cerrar esta ventana</div>
          </div>
        </section>
      <FooterDescription />
      </main>
    </>
  );
}
