import { useState, useContext } from "react";
import { FieldArray, useField } from "formik";

import CardFSFB from "../../atoms/Card";
import ErrorMessage from "../../atoms/ErrorMessage";
import NewSectionOption from "../../molecules/newSectionOption";
import CardSelectOption from "../../organisms/CardSelectOption";
import ListOptionButtons from "../../molecules/ListOptionsButtons";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import styles from "./AccordeonOptions.module.scss";

export const AccordeonOption = (props) => {
  const { option, index, name } = props;
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const { orderQuestions, setOrderQuestions } = useContext(ScalesContext);
  const [isAccordeonOpen, setIsAccordeonOpen] = useState(true);
 
  const handleDeleteSection = async (id) => {
    const copyQuestions = field.value;
    const newValue = {
      ...copyQuestions,
      to_delete: true,
    }
    setValue(newValue)
  };

  const handleCopyQuestion = (index, arrayHelpers) => {
    const copyQuestion = field.value.questions[index]
    arrayHelpers.push({
      ...copyQuestion,
      order: orderQuestions
    })
  };

  const handleSelectOption = (optionSelect, arrayHelpers) => {
    const order = orderQuestions;
    if (
      optionSelect === 2 ||
      optionSelect === 6 ||
      optionSelect === 3 ||
      optionSelect === 1
    ) {
      arrayHelpers.push({
        detail: "",
        type: optionSelect,
        section: option.section.id,
        options: [],
        is_required: false,
        order: order,
      });
    } else if (optionSelect === 4 || optionSelect === 5 || optionSelect === 8) {
      arrayHelpers.push({
        detail: "",
        type: optionSelect,
        section: option.section.id,
        is_required: false,
        order: order,
      });
    } else if (optionSelect === 12) {
      arrayHelpers.push({
        detail: "",
        type: optionSelect,
        options: [
          { detail: "0", score: "0", order: 1 },
          { detail: "1", score: "1", order: 2 },
          { detail: "2", score: "2", order: 3 },
          { detail: "3", score: "3", order: 4 },
          { detail: "4", score: "4", order: 5 },
          { detail: "5", score: "5", order: 6 },
          { detail: "6", score: "6", order: 7 },
          { detail: "7", score: "7", order: 8 },
          { detail: "8", score: "8", order: 9 },
          { detail: "9", score: "9", order: 10 },
          { detail: "10", score: "10", order: 11 },
        ],
        ranges: [],
        is_required: false,
        order: order,
      });
    }
    setOrderQuestions(order + 1);
  };

  const deleteQuestion = (index, arrayHelpers) => {
    const copyQuestion = field.value.questions[index];
    arrayHelpers.replace(index, {
      ...copyQuestion,
      to_delete: true,
    });
  };

  return (
    <CardFSFB className={styles.card}>
      <NewSectionOption
        deleteOption={handleDeleteSection}
        id={index}
        name={`${name}.name`}
        setIsAccordeonOpen={setIsAccordeonOpen}
        isAccordeonOpen={isAccordeonOpen}
      />

      <FieldArray
        name={`${name}.questions`}
        render={(arrayHelpers) => (
          <>
            {isAccordeonOpen ? (
              <CardFSFB>
                <div className={` ${styles.accordionDetails}`}>
                  {field?.value?.questions?.map((question, idx) => (
                    <>
                      {!question?.to_delete && (
                        <div
                          className={`${styles.cardOption} `}
                          key={`questions.${idx}-s.${question.section}`}
                        >
                          <CardSelectOption
                            type={question.type}
                            name={`${name}.questions.${idx}`}
                            id={idx}
                            deleteOption={(id) =>
                              deleteQuestion(id, arrayHelpers)
                            }
                            handleCopy={(id)=> handleCopyQuestion(id, arrayHelpers)}
                            idQuestion={question?.id}
                          />
                        </div>
                      )}
                    </>
                  ))}
                  {meta?.error?.questions === 'Debes Añadir 1 opción' && (
                    <ErrorMessage
                      error={meta?.error?.questions}
                    />
                  )}
                </div>

                <ListOptionButtons
                  handleSelectOption={(id) =>
                    handleSelectOption(id, arrayHelpers)
                  }
                />
              </CardFSFB>
            ) : null}
          </>
        )}
      />
    </CardFSFB>
  );
};
