// React 17.0.2
import Hotjar from "@hotjar/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

Hotjar.init(
  process.env.REACT_APP_HOTJAR_SITEID,
  process.env.REACT_APP_HOTJAR_VERSION
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
