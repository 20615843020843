
import { useField } from "formik";

import Input from "../../atoms/Input";
import ErrorMessage from "../../atoms/ErrorMessage";
import styles from "./NpsAdmin.module.scss";

const NpsAdmin = (props) => {
  const { name } = props;
  const [field, meta] = useField(props);

  return (
    <div className={styles.selectNewOption}>
      <>
            <div
              key={`${name}-${field.value.order}`}
              className={styles.optionContainer}
            >
              {!field.value.to_delete && (
                <>
                  <div className={styles.inputOption}>
                    <Input
                      name={`${name}.${"0"}.detail`}
                      className={styles.inputWhiteOption}
                      disabled={props.disabled && props.disabled}
                      placeholder="Nueva opción"
                    />
                    <p className={styles.npsRange}>Mínimo puntaje posible: 0</p>
                  </div>
                  <div className={styles.inputOption}>
                    <Input
                      name={`${name}.${"1"}.detail`}
                      className={styles.inputWhiteOption}
                      disabled={props.disabled && props.disabled}
                      placeholder="Nueva opción"
                    />
                    <p className={styles.npsRange}>Máximo puntaje posible: 10</p>
                  </div>
                </>

              )}
            </div>
        {meta.touched && <ErrorMessage error={meta.error} />}
      </>
    </div>

  );
};

export default NpsAdmin;