import { useContext, useState } from "react";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import { ContigoAddEquipment } from "../ContigoAddEquipment";
import { PatientDetail } from "../PatientDetail";
import AddButton from "../../atoms/AddButton";
import Spinner from "../../atoms/Spinner";

import ErrorIcon from "../../assets/imgs/icons/error_x.svg";

import styles from "./PatientAddInfo.module.scss";

const equipmentInitialValues = {
  equipment: true,
  file_material: "",
  observations: "",
  file_name: null,
};

export const PatientAddInfo = () => {
  const {
    userInfo,
    setUserInfo,
    showInfo,
    setShowInfo,
    setListSelectUsers,
    loading,
    listSelectUsers,
    isError,
    userExists,
    setUserExists,
  } = useContext(ContigoContext);
  const [equipmentValues, setEquipmentValues] = useState(equipmentInitialValues);

  const handleAdd = (user) => {
    const newUser = listSelectUsers.filter(
      (u) => u.id_patient === user.id_patient
    );

    if (!newUser.length) {
      setListSelectUsers(
        (prev) => [
          ...prev,
          {
            ...user,
            ...equipmentValues
          }
        ]
      );
      setUserInfo({});
      setShowInfo(false);
      setEquipmentValues(equipmentInitialValues);
    } else {
      setUserExists(true);
    }
  };

  return (
    <>
      {loading && <Spinner className_container="alertDel" />}
      {showInfo && Object.keys(userInfo).length > 0 ? (
        <>
          <div className={styles.container}>
            <PatientDetail />
            <ContigoAddEquipment
              equipmentValues={equipmentValues}
              setEquipmentValues={setEquipmentValues}
            />
            <div
              className={`${styles.buttonContainer} ${userExists && styles.errorUser
                }`}
            >
              {userExists ? (
                <div className={styles.errorMessageContainer}>
                  <img src={ErrorIcon} alt="Icono de error" />
                  <p className={styles.errorMessage}>
                    El paciente ya fue agregado previamente
                  </p>
                </div>
              ) : null}
              <AddButton onClick={() => handleAdd(userInfo)} type="button" />
            </div>
          </div>
        </>
      ) : isError ? (
        <div className={styles.errorMessageContainer}>
          <img src={ErrorIcon} alt="Icono de error" />
          <p className={styles.errorMessage}>
            No se encontró paciente asignado al número de documento
          </p>
        </div>
      ) : null}
    </>
  );
};
