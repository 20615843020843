import { useContext, useEffect, useState } from "react";

import clockDiligence from "../../assets/imgs/icons/clockDiligence.svg";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import startIcon from '../../assets/imgs/icons/startCircleIcon.svg';
import clockIcon from '../../assets/imgs/icons/clockCircleIcon.svg'
import styles from "./ScaleDescription.module.scss";


export default function ScaleDescription() {
  const { diligenceInfo } = useContext(DesenlacesContext);
  const data = diligenceInfo;
  const [score, setScore] = useState(null);

  useEffect(() => {
    let newScore = data?.form?.form_answer?.score;
    setScore(newScore);
  }, [data]);

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.formInfo}>
        <h2 className={styles.scaleName}>{data?.scale}</h2>
        <div className={styles.containerTime}>
          <img src={clockDiligence} alt="icono de reloj"></img>
          <p className={styles.timeText}>
            {data?.form?.time_complete} min
          </p>
        </div>
        <p className={styles.lineName}>{data?.service_line}</p>
      </div>
      <div className={score ? styles.contentOptions : styles.contentOptionScore}>
        {data?.form?.description && (
          <div className={ styles.containerDescription}>
            <img src={startIcon} alt="icono de estrella" style={{width: '35px'}}></img>
            <p className={styles.descriptionText}>
              {data?.form?.description}
            </p>
          </div>
        )}
         {score >= 0 ? (
          <h2 className={styles.scoreTitle}>Puntaje: {score} puntos</h2>
        ) : null}
        {data?.form?.bibliografy && (
          <div className={styles.containerDescription}>
            <img src={clockIcon} alt="icono de libro" style={{width: '35px'}}></img>
            <p className={styles.descriptionText}>
              {data?.form?.bibliografy}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
