import styles from "./CenterMessage.module.scss";

export default function CenterMessage(props) {
  return (
    <>
      <div className={styles.centerContainer}>
        <h1 className={styles.welcomeTitle}>
          {props.title}
          <span className={styles.centerUser}>{props.user}</span>
        </h1>
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.title2}>{props.title2}</h2>
        <p className={styles.subtitle}>{props.subtitle}</p>
        <p className={`${styles[props.otpClass]} ${styles.text1} `}>
          {props.text1}
        </p>
        <p className={styles.text2}>{props.text2}</p>
        <p className={styles.text3}>{props.text3}</p>
      </div>
    </>
  );
}
