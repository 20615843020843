import React, {useContext} from 'react'

import icon from "../../assets/imgs/icons/error_x.svg";
import styles from './ErrorMessage.module.scss';
import { AppContext } from "../../context/AppContext/AppContext";

const ErrorMessage = ({error, meta, ...props}) => {
  const { plan } = useContext(AppContext);
  
  return (
    <>
    {
       error && (
        <div 
          className={`
            ${plan.id === (28 || 29 || 30 || 31 || 32 || 33) ? styles.errorContainerEq5d : styles.errorContainer} 
            ${styles[props.errorResponsive]}
            ${props.containerCustom && props.containerCustom}
          `}>
          <span className={`${styles.errorMessage} ${styles[props.errorMessResp]} ${props.className && props.className}`}>
            <img
              src={icon}
              alt="Icono de exclamación"
              className={styles.exclamationIcon}
            />
            {error}
          </span>
        </div>
      )}
    </>
  )
}

export default ErrorMessage