import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import MenuLinks from "../../molecules/MenuLinks";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { AppContext } from "../../context/AppContext/AppContext";
import menuIcon from "../../assets/imgs/icons/menuIcon.svg";
import styles from "./_.module.scss";

export default function DesplegableMenu() {
  const navigate = useNavigate();
  const { dropdown, setDropdown } = useContext(AdminContext);
  const { user } = useContext(AppContext);

  function openCloseMenu() {
    setDropdown(!dropdown);
  }
  return (
    <nav className={`${styles.navbar}`}>
      <div className={styles.btnDropdownParent}>
        <button
          type="button"
          className={`${styles.btnDropdown} ${dropdown && styles.btnClosed}`}
          onClick={() => openCloseMenu()}
        >
          <img
            src={menuIcon}
            className={styles.menuIcon}
            alt="icono del menu"
          ></img>
        </button>
      </div>
      <div className={styles.navbarTitle}>
        <p className={` ${dropdown ? styles.title : styles.titleDropped}`} onClick={()=> navigate('/dashboard')} >
          Tu recuperación
        </p>
        <p
          className={` ${dropdown ? styles.versionTitle : styles.titleDropped}`}
        >
          {user.version}
        </p>
      </div>
      <div
        className={`${styles.linksZone} ${
          dropdown && styles.linksZoneDropdown
        }`}
      >
        <MenuLinks />
      </div>
    </nav>
  );
}
