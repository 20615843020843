import { useContext } from "react";
import { useField, useFormikContext } from "formik";

import { LabelInput } from "../../atoms/LabelInput";
import ErrorMessage from "../../atoms/ErrorMessage";
import PillInput from "../../molecules/PillInput";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import styles from "./_.module.scss";

export default function FrequencyInput({ ...props }) {
  const [field, meta, helpers] = useField({ ...props });
  const { frecuencyOptions, editScale } = useContext(ScalesContext);
  const { setValue } = helpers;

  const { errors } = useFormikContext();

  const addFrecuency = (index, value, option) => {
    const item = field.value[index];
    const listValues = field.value;
    const listFrecuency = [];
    let frecuencyDaysInitial;

    if (option === "income") {
      frecuencyDaysInitial = item.frequency_days.income_frecuency;
    } else if (option === "egress") {
      frecuencyDaysInitial = item.frequency_days.egress_frecuency;
    }

    for (let frecuency in frecuencyDaysInitial || []) {
      listFrecuency.push(frecuencyDaysInitial[frecuency]);
    }

    listFrecuency.push(value);
    const freqArray =
      option === "income" ? "income_frecuency" : "egress_frecuency";
    const changeItem = {
      line: item.line,
      frequency_days: {
        ...item.frequency_days,
        [freqArray]: listFrecuency,
      },
    };

    listValues[index] = changeItem;
    setValue(listValues);
  };

  const handleDelete = (index, key, option) => {
    const item = field.value[index];
    const listValues = field.value;
    const listFrecuency =
      option === "income"
        ? item.frequency_days.income_frecuency
        : item.frequency_days.egress_frecuency;
    const newValuesFrecuency = listFrecuency?.filter(
      (item) => item.key !== key
    );

    listValues[index].frequency_days[
      `${option === "income" ? "income_frecuency" : "egress_frecuency"}`
    ] = newValuesFrecuency;
    setValue(listValues);
  };

  const handleCustomSelect = (elm, index, option) => {
    if (/^\d+$/.test(elm.value)) {
      const existentElement = frecuencyOptions.find(
        (existent) => existent.key === elm.value
      );

      const listValues = field.value;
      if (option === "income") {
        listValues[index].frequency_days.income_frecuency.push(
          existentElement || { key: elm.value, value: `${elm.value} días` }
        );
      } else if (option === "egress") {
        listValues[index].frequency_days.egress_frecuency.push(
          existentElement || { key: elm.value, value: `${elm.value} días` }
        );
      }
    }
  };

   const error =
    errors?.frequency_days?.filter((err) => err !== undefined) || {};


  return (
    <div className={styles.periodicityOption}>
      <LabelInput text={`Periodicidad`} />
      <>
        {field?.value?.length &&
          field?.value?.map((service, index) => (
            <>
              {!service.to_delete && (
                <div
                  className={styles.periodicityListContainer}
                  key={service?.line?.key}
                >
                  <div className={styles.groupPeriodicity}>
                    <p className={styles.lineName}>{service?.line?.value} </p>
                    <p className={styles.dateOriginTitle}>Fecha de origen </p>
                    <div className={styles.periodicityList}>
                      <p className={styles.textFrecuency}>Ingreso</p>
                      <PillInput
                        selected={service.frequency_days.income_frecuency || []}
                        options={frecuencyOptions}
                        onClosePill={(key) =>
                          handleDelete(index, key, "income")
                        }
                        onSelectOption={(value) => {
                          addFrecuency(index, value, "income");
                        }}
                        disabled={editScale?.active}
                        onCustomElementCreation={(value) =>
                          handleCustomSelect(value, index, "income")
                        }
                      />
                    </div>
                    <div className={styles.periodicityList}>
                      <p className={styles.textFrecuency}>Egreso</p>
                      <PillInput
                        selected={service.frequency_days.egress_frecuency || []}
                        options={frecuencyOptions}
                        onClosePill={(key) =>
                          handleDelete(index, key, "egress")
                        }
                        onSelectOption={(value) => {
                          addFrecuency(index, value, "egress");
                        }}
                        disabled={editScale?.active}
                        onCustomElementCreation={(value) =>
                          handleCustomSelect(value, index, "egress")
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        <>
          {" "}
          {Object.keys(error).length ? (
            <ErrorMessage error="Olvidaste diligenciar alguna de las frecuencias" />
          ) : null}
        </>
      </>
    </div>
  );
}
