import React from "react";

import { SearchPatientForm } from "../../molecules/SearchPatientForm";
import { PatientAddInfo } from "../../molecules/PatientAddInfo";

import styles from "./SearchPatient.module.scss";

export const SearchPatient = () => (
  <main className={styles.mainContainer}>
    <section className={styles.searchContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.titleCard}>Buscador de pacientes</h2>
      </div>
      <SearchPatientForm />
    </section>
    <div className={styles.divider} />
    <section className={styles.patientInfo}>
      <PatientAddInfo />
    </section>
  </main >
);
