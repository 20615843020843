import styles from "./Spinner.module.scss";
import spinner from "../../assets/imgs/spinner.gif";

export default function Spinner(props) {
  return (
    <div className={`${styles.spinner} ${styles[props.className_container]}`}>
      <img
        src={spinner}
        alt="loading..."
        className={`${styles.spinner2} ${styles[props.className_img]}`}
      ></img>
    </div>
  );
}
