import React, { useContext, useEffect, useState } from "react";
import ProgressBar from "../../atoms/ProgressBar";
import ScaleTitle from "../../atoms/ScaleTitle";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProfileHeaderWhite from "../../molecules/ProfileHeaderWhite";
import ScaleForm from "../../molecules/ScaleForm";

import CancelButton from "../../atoms/CancelButton";
import Eq5dInfo from "../../atoms/Eq5dInfo";
import NextButton from "../../atoms/NextButton";
import { AppContext } from "../../context/AppContext/AppContext";
import styles from "./UserScale.module.scss";

export default function UserScale(props) {
  const { width } = useWindowDimensions();
  const { step, contEq5d, setContEq5d } = useContext(AppContext);
  const { scale } = props;
  const [questions] = useState(scale?.form?.schema || []);
  let totalQuestions = [];
  const [totalStep, setTotalStep] = useState(totalQuestions.length || 1);
  const normalise = (value) => ((value + 1) * 100) / (totalStep - 0);

  const getTotalSteps = async () => {
    for (let q of questions) {
      if (q.question) {
        totalQuestions.push(q);
      } else if (q.section) {
        totalQuestions.push(q.section);
        for (let question of q.section.questions) {
          totalQuestions.push(question);
        }
      }
    }
    await setTotalStep(totalQuestions.length || 1);
  };

  useEffect(() => getTotalSteps(), []);

  return (
    <>
      {width > 480 ? (
        <header className={styles.header}>{props.header}</header>
      ) : null}
      <main className={styles.main}>
        {width <= 480 ? <ProfileHeaderWhite /> : null}
        <section className={styles.mainContent}>
          <ProgressBar value={normalise(step)} />
          <ScaleTitle title={scale?.form?.name} />
          {contEq5d === 0 && scale?.form?.id === 23 ? (
            <div
              className={
                scale?.form?.id === 23 && width <= 480
                  ? styles.scaleFormContainerEq5d
                  : styles.scaleFormContainer
              }
            >
              <Eq5dInfo />
              <p className={styles.eq5dCol}>
                Colombia (Spanish)
                <br />
                Cuestionario de salud
                <br />
                Versión en español para Colombia
                <br />
                (Spanish version for Colombia)
              </p>
              <p className={styles.eq5dColPatient}>
                En las siguientes pantallas seleccione la afirmación que mejor
                describe su salud HOY.
              </p>
              <div className={`${styles.buttonOptions}`}>
                <CancelButton
                  text="Atrás"
                  onClick={() => contEq5d > 0 && setContEq5d(contEq5d - 1)}
                  type="button"
                  patientBtn="patientBtn"
                />
                <NextButton
                  text="Siguiente"
                  onClick={() => setContEq5d(contEq5d + 1)}
                  type="button"
                  patientBtn="patientBtn"
                />
              </div>
            </div>
          ) : (
            <ScaleForm scale={props.scale} />
          )}
        </section>
      </main>
    </>
  );
}
