import {Tooltip} from "../../atoms/Tooltip"
import tooltipIcon from "../../assets/imgs/icons/tooltip_blue.svg";
import tooltipWhite from "../../assets/imgs/icons/tooltip_white.svg";
import styles from "./TooltipTable.module.scss";


export default function TooltipTable({ tooltipLocation, color, trackNumber, tooltipText, location, customStyle }) {
  
  const title =
    tooltipLocation === "/desenlaces/historico"
      ? "Haz clic sobre la fila del registro del que deseas tener más información"
      : tooltipLocation === "/contigo/pacientes"
      ? "Haz clic sobre la fila correspondiente para ver detalle de paciente."
      : tooltipLocation === "/contigo/reporte"
      ? ""
      : color === 1 && location === "desenlaces"
      ? `Evento con escalas pendientes. Para diligenciar la escala o ver el detalle, haz clic sobre la fila correspondiente`
      : color === 2 && location === "desenlaces"
      ? "Evento sin pendientes. Para diligenciar la escala o ver el detalle, haz clic sobre la fila correspondiente"
      : color === 3 && location === "desenlaces"
      ? "Evento eliminado, disponible para restaurar, haz clic en el icono de la derecha."
      : color === 1
      ? "Monitoreo con escalas pendientes"
      : color === 2
      ? "Monitoreo finalizado"
      : color === 3
      ? "Monitoreo detenido"
      : trackNumber
      ? "Para ver el detalle, haz clic sobre la fila correspondiente"
      : tooltipText
      ? tooltipText
      : null;

  return (
    <thead
      className={`
      ${
        location === "desenlaces"
        ? styles.tooltipDesenlaces
        : location === "contigo"
        ? styles.tooltipContigo
        : trackNumber
        ? styles.tooltipTrack
        : tooltipLocation === "/desenlaces/pacientes" || "/contigo/pacientes"
        ? styles.tooltipPacientes
        : tooltipLocation === "/desenlaces/reporte"
        ? styles.tooltipPacientes
        : styles.tooltip
      }
      ${customStyle && customStyle}
      `}
    >
      <tr className={styles.trBackground}>
        <th>
          <Tooltip text={title} variant="dark" img={color ? tooltipWhite : tooltipIcon} />
        </th>
      </tr>
    </thead>
  );
}
