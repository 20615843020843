export const scaleDuplicator = (scale) => {
  const objectToSave = {
    age_range: scale.age_range,
    bibliografy: scale.bibliografy,
    description: scale.description,
    image: scale.image,
    modules: scale.modules,
    name: `${scale.name} Copia`,
    schema: scale.schema.map((option) => {
      if (option.question) {
        return {
          question: {
            detail: option.question.detail,
            is_required: option.question.is_required,
            type: option.question.type,
            order: option.question.order,
            ranges: option.question.ranges,
            options: option?.question?.options?.map((op) => ({
              detail: op.detail,
              score: op.score,
              order: op.order,
            })),
          },
        };
      } else {
        return {
          section: {
            name: option.section.name,
            questions: option?.section?.questions?.map((op) => ({
              detail: op.detail,
              is_required: op.is_required,
              type: op.type,
              order: op.order,
              ranges: op.ranges,
              options: op?.options?.map((item) => ({
                detail: item.detail,
                score: item.score,
                order: item.order,
              })),
            })),
          },
        };
      }
    }),
    send_to_patient: scale.send_to_patient,
    service_lines: scale.service_lines,
    threshold: scale.threshold ? parseInt(scale.threshold) : null,
    time_complete: scale.time_complete,
  };
  return objectToSave;
};
