import React, { useMemo, useState, useEffect, useContext } from "react";
import * as moment from "moment-timezone";

import { AdminContext } from "../../context/AdminContext/AdminContext";
import { TracingContext } from "../../context/TracingContext/TracingContext";

import useSelect from "../../hooks/useSelect";
import { offsetUser } from "../../custom/offset";

import Modal from "../../atoms/Modal";
import Table from "../../molecules/Table";
import TableHeader from "../../atoms/TableHeader";
import { Pagination } from "../../atoms/Pagination";
import { UserUpdateStepper } from "../../molecules/UserUpdateStepper";
import { ChangeServiceLineStepper } from "../../molecules/ChangeServiceLineStepper";

import styles from "./TracingTable.module.scss";

export default function TracingTable({ apply }) {
  const {
    setSelectTracingGroup,
    isLoading,
    actualPage,
    setActualPage,
    patientsList,
    pagination,
    name_tracing,
    setName_tracing,
    setDiligenceInfo,
    scale_tracing,
    setScale_tracing,
    document_tracing,
    setDocument_tracing,
  } = useContext(TracingContext);
  const { setUserInfo } = useContext(AdminContext);
  const [childDataState, setChildDataState] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLineServiceModal, setIsOpenLineServiceModal] = useState(false);

  const propertyNameMap = {
    eggress_date: "eggress_date",
    event_number: "event_number",
    id: "id",
    ingress_date: "ingress_date",
    patient: "patient",
    service_line: "service_line",
    staff_name: "staff_name",
  };

  const handleEditUser = async (user) => {
    await setUserInfo(user);
    setIsOpen(true);
  };

  const handleEditServiceLine = async (user) => {
    await setUserInfo(user);
    setIsOpenLineServiceModal(true);
  };

  const selectOptions = useSelect(childDataState, propertyNameMap);
  function childData(e) {
    setChildDataState(e);
  }

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Paciente"
            column={column}
            handleGetFilter={setName_tracing}
            filterValue={name_tracing}
          />
        );
      },
      accessor: "patient.name",
      Cell: ({ row }) => {
        return (
          <div
            className={styles.userRedirect}
            onClick={() => handleEditUser(row.original)}
          >
            {row.original.patient.name}
          </div>
        );
      },
      width: 30,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Documento"
            column={column}
            handleGetFilter={setDocument_tracing}
            filterValue={document_tracing}
          />
        );
      },
      accessor: "patient.doc_number",
      Cell: ({ row }) => {
        return <div>{row.original.patient.doc_number}</div>;
      },
      width: 10,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Linea"
            column={column}
            handleGetFilter={setScale_tracing}
            filterValue={scale_tracing}
          />
        );
      },
      accessor: "service_line.name",
      Cell: ({ row }) => {
        const hasToChangeStyle = row?.original?.service_line?.changed;
        return (
          <div
            className={styles.lineEdit}
            style={{
              color: hasToChangeStyle ? "#A1D23F" : "",
              fontWeight: hasToChangeStyle ? "600" : ""
            }}
            onClick={() => handleEditServiceLine(row.original)}
          >
            {row.original.service_line.name}
          </div>
        )
      },
      width: 30,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Evento" column={column} />;
      },
      accessor: "event_number",
      Cell: ({ row }) => {
        return <p>{row.original.event_number}</p>;
      },
      width: 5,
      textAlign: "center",
      disableFilters: true,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha" column={column} />;
      },
      accessor: "ingress_date",
      Cell: ({ row }) => {
        return (
          <p>
            {moment(row.original.ingress_date).utc()
              .format("YYYY-MM-DD HH:mm")}
          </p>
        );
      },
      width: 15,
      textAlign: "center",
      disableFilters: true,
      tooltipType: "date"
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    setSelectTracingGroup(selectOptions);
  }, [selectOptions]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={patientsList}
        offsetUser={offsetUser}
        tooltipText="Selecciona los registros que quieras gestionar (eliminar o iniciar proceso en desenlaces)"
        childData={childData}
        setValues={setDiligenceInfo}
      />
      <Pagination
        actualPage={actualPage}
        setPage={setActualPage}
        infoPage={pagination}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} variant="large">
        <UserUpdateStepper
          onClose={() => setIsOpen(false)}
          updateInfo={apply}
        />
      </Modal>

      <Modal
        variant="large"
        isOpen={isOpenLineServiceModal}
        onClose={() => setIsOpenLineServiceModal(false)}
      >
        <ChangeServiceLineStepper
          onClose={() => setIsOpenLineServiceModal(false)}
        />
      </Modal>
    </>
  );
}
