import { apiInterceptor } from "../interceptors/jwt.interceptor";

export const getReport = async (filters) => {
  const response = await apiInterceptor({
    method: 'GET',
    endpoint:`/tr/contigo/report?${filters}`,
  })

  return response;
};
