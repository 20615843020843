import { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import ErrorNotificator from "../../atoms/ErrorNotification";
import LoginButton from "../../atoms/LoginButton";
import { CognitoOAUTHChallenge } from "../../services/authentication";
import Logo from "../../assets/imgs/logoFSFB.svg";
import Woman from "../../assets/imgs/womanLogin.png";
import styles from "./ManagerLoginForm.module.scss";

export default function ManagerLoginForm() {
  const [error, setError] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => error && setError(null), 4000);
    return () => clearInterval(timeout);
  }, [error]);

  return (
    <div className={styles.container}>
      <img src={Logo} alt="Logo Fundación SantaFe" className={styles.logo} />
      <section>
        <h1 className={styles.title}>Bienvenido a</h1>
        <h1 className={styles.titleBold}>Tu recuperación</h1>
      </section>
      <Formik onSubmit={async () => CognitoOAUTHChallenge()}>
        <Form className={styles.form}>
          <div className={styles.loginForm}>
            <ErrorNotificator error={error} />
            <LoginButton text="Iniciar sesión" />
          </div>
        </Form>
      </Formik>
      <img src={Woman} alt="Doctora" className={styles.woman} />
    </div>
  );
}
