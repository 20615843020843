import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import styles from "./Diligence.module.scss";
import { ManagerContext } from "../../context/ManagerContext/ManagerContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import ManagerScale from "../../molecules/ManagerScale";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import { AppContext } from "../../context/AppContext/AppContext";
import PatientDataTable from "../../atoms/PatientDataTable";

export default function Diligence() {
  const params = useParams();
  const navigate = useNavigate();
  const { modules, modulesMixer } = useContext(AppContext);
  const { location } = useContext(ManagerContext);
  const { setDiligenceInfo, editedFinishScale, setEditedFinishScale } =  useContext(DesenlacesContext);
  const [scaleInfo, setScale] = useState({});

  const request = async () => {
    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/forms/answers/${params.idPlan}`
    })

    if(response.statusCode === 200){
      setScale(response.data[0]);
      setDiligenceInfo(response.data[0]);
    } else if (response.statusCode === 400 ){
      navigate("/error/404");
    }
  };

  useEffect(() => {
    request();
  }, [params.idPlan]);

  useEffect(() => {
    setTimeout(() => {
      const changeRoute = async () => {
        if (location === "today") {
          await modulesMixer(modules, "/desenlaces/parahoy");
        } else if (location === "historical") {
          await modulesMixer(modules, "/desenlaces/historico");
        } else {
          return;
        }
      };
      changeRoute();
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      setEditedFinishScale(false);
    };
  }, []);

  return (
    <>
      <section className={styles.tableSection}>
        {scaleInfo?.scale_form?.send_to_patient ? (
          <span className={styles.responsibleLabelContainer}>
            <p className={styles.responsibleLabel}>
              {scaleInfo?.scale_form?.send_to_patient && "Escala de paciente"}
            </p>
          </span>
        ) : null}
        <PatientDataTable styleType="patientsDetail" updateInfo={request} />
        <ManagerScale scale={scaleInfo} edited={editedFinishScale} />
      </section>
    </>
  );
}
