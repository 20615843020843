import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Spinner from "../../atoms/Spinner";
import { Button } from "../../atoms/Button/";
import { HelpLink } from "../../atoms/HelpLink";
import ErrorMessage from "../../atoms/ErrorMessage";
import { LogoHeader } from "../../atoms/LogoHeader";
import { LoginWelcome } from "../../templates/LoginWelcome";
import { CardOtp } from "../../molecules/CardOtp";
import { OTPChallenge } from "../../services/authentication";
import styles from "./LoginAuthOption.module.scss";
import { AppContext } from "../../context/AppContext/AppContext";

export const LoginAuthOption = () => {
  const { setOtpChallenge, userInfo, options, setDestination } = useContext(AppContext);
  const [customError, setCustomError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoginWelcome>
      <section className={styles.container}>
        <LogoHeader />
        <p className={styles.title}>
          Selecciona el medio por el cual deseas recibir tu código de
          autenticación
        </p>
        <CardOtp className={styles.cardForm}>
          <Formik
            initialValues={{
              optionSend: null,
            }}
            validationSchema={Yup.object({
              optionSend: Yup.string().required(
                "Por favor selecciona una de las opciones"
              ),
            })}
            onSubmit={async (values) => {
              setIsLoading(true);
              try {
                const payload = {
                  doc: {
                    type_code: options.find(
                      (opt) => opt.id === userInfo.tdoc.code
                    ).value,
                    doc_number: userInfo.doc,
                  },
                };
                const destination = [values.optionSend];
                await setDestination(destination)
                const response = await OTPChallenge(payload, destination);
                if (response.ok) {
                  setOtpChallenge({
                    requestPayload: payload,
                    data: response.body.data,
                  });
                  setIsLoading(false);
                  navigate("/otp");
                }
              } catch (error) {
                setCustomError(
                  "Los datos ingresados no están registrados, inténtalo nuevamente"
                );
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <p className={styles.optionTitle}>Código de verificación</p>
                <section className={`${styles.radioContainer} `}>
                  <div>
                    <label className={styles.label}>
                      <input
                        name="optionSend"
                        className={styles.radioBtn}
                        type="radio"
                        value="email"
                        checked={values.optionSend === "email"}
                        onChange={(e) =>
                          setFieldValue("optionSend", e.target.value)
                        }
                      />
                      <span
                        className={`${styles.customRadioBtn__show} ${styles.customRadioBtn__showCheckbox}`}
                      ></span>
                      Correo electrónico
                    </label>
                    <span className={styles.dataOption}>{userInfo.email}</span>
                  </div>
                  <div>
                    <label className={styles.label}>
                      <input
                        name="optionSend"
                        className={styles.radioBtn}
                        type="radio"
                        value="phone"
                        checked={values.optionSend === "phone"}
                        onChange={(e) =>
                          setFieldValue("optionSend", e.target.value)
                        }
                      />
                      <span
                        className={`${styles.customRadioBtn__show} ${styles.customRadioBtn__showCheckbox}`}
                      ></span>
                      Número celular
                    </label>
                    <span className={styles.dataOption}>{userInfo.mobile}</span>
                  </div>
                </section>

                <ErrorMessage error={customError} />
                <div className={styles.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    className={styles.buttonSend}
                    classNameL={styles.buttonL}
                  >
                    Continuar
                  </Button>

                  <HelpLink />
                </div>
              </Form>
            )}
   
          </Formik>
          {isLoading ? (
        <div className={styles.spinnerContainer}>
          <Spinner
            className_img="logPatientImg"
            className_container="logPatientCont"
          />
        </div>
      ) : null}
        </CardOtp>
      </section>
     
    </LoginWelcome>
  );
};
