import React from 'react';
import PropTypes from 'prop-types';

import styles from "./button.module.scss";


export const Button = ({ type, variant, color, onClick,disabled,children, ...props  }) => {
  return (
    <button
      type={type || 'text'}
      className={`${styles[variant]} ${styles[color]} ${styles.baseButton} 
        ${disabled && styles.disabled}
        ${props.className}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.object.isRequired,
  color: PropTypes.object.isRequired,
};