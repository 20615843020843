import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import { Field } from "formik";
import ModalContent from "../../atoms/ModalContent";
import Spinner from "../../atoms/Spinner";
import { ModalStepper } from "../../molecules/ModalStepper";
import { ModalStep } from "../../atoms/ModalStep";
import { getTypeParameter } from "../../services/common/admin";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import styles from "./EditAlert.module.scss";

const validateSchema = Yup.object({
  reason: Yup.string()
    .min(1, "Debe seleccionar una opción")
    .required("este campo es requerido"),
});

export const EditAlert = ({ onClose, setEdit }) => {
  const { setReasonEdit } = useContext(ContigoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [reasons, setReasons] = useState();


  useEffect(() => {
    const getReasons = async () => {
      const res = await getTypeParameter(11);
      setReasons(res.data);
      setIsLoading(false)
    };
    getReasons();
  }, []);

  return (
    <ModalStepper
      onClose={onClose}
      initialValues={{
        reason: "",
      }}
      onSubmit={async (values) => {
        await setReasonEdit(values.values.reason)
        setEdit(true)
        onClose();
      }}
    >
      <ModalStep>
        <ModalContent
          close={onClose}
          text="Estás a punto de editar un seguimiento"
          helpText="¿Deseas continuar?"
          icon={personGreen}
          btns={false}
          alt="Icono editar seguimiento"
        />
      </ModalStep>
      <ModalStep validationSchema={validateSchema}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <h2 className={styles.updateTitle}>Observaciones</h2>
            <div className={styles.inputContainer}>
              <p htmlFor="reason" className={styles.patientName}>
              Motivo por el cual editarás el seguimiento
              </p>
              <div className={styles.inputAndError}>
                <Field
                  className={styles.loginInputs}
                  as="select"
                  id="reason"
                  name="reason"
                >
                  <option value="" className={styles.documentOptions}>
                  Motivos de edición
                  </option>
                  {reasons?.map((option) => (
                    <option
                      value={option.id}
                      className={styles.documentOptions}
                    >
                      {option.value}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </>
        )}
      </ModalStep>
    </ModalStepper>
  );
};
