import * as moment from 'moment-timezone';
import styles from "./EditHistory.module.scss";

export default function EditHistory({record}) {
  return (
    <>
      <section className={styles.container}>
        <table>
          <thead>
            <tr>
              <th>Motivo</th>
              <th>Usuario</th>
              <th>Fecha</th>            
            </tr>
          </thead>
          <tbody>
          {record?.history?.map((item, index) => (
            <tr key={index}>
              <td>{item.remark}</td>
              <td>{item.updated_by}</td>
              <td>{moment.tz(item.updated_at, 'America/Bogota').format("YYYY-MM-DD HH:mm:ss")}</td>
            </tr>
          ))}          
          </tbody>
        </table>        
      </section>
    </>
  )
}