import { useState } from "react";
import { ScalesContext } from "./ScalesContext";

export const ScalesProvider = ({ children }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [hasResponses, setHasResponses] = useState(false);
  const [hasPlans, setHasPlans] = useState(false);
  const [editScale, setEditScale] = useState({});
  const [isEditEscale, setIsEditScale] = useState(false);
  const [scaleName, setScaleName] = useState();
  const [previewScale, setPreviewScale] = useState({});
  const [actualPage, setActualPage] = useState(1);
  const [pagination, setCustomePagination] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [scale_scale, setScale_scale] = useState("")
  const [line_scale, setLine_scale] = useState("")
  const [module_scale, setModule_scale] = useState("")
  const [sort, setSort] = useState("")
  const [scaleSort, setScaleSort] = useState("name:asc")
  const [lineSort, setLineSort] = useState("service_line:asc")
  const [scalesList, setScalesList] = useState([]);

  const [indexSection, setIndexSection] = useState();
  const [orderQuestions, setOrderQuestions] = useState(1);

  const [isDesenlacesSelect, setIsDesenlacesSelect] = useState(false);
  const [isContigoSelect, setIsContigoSelect] = useState(false);
  const [frecuencyOptions, setFrecuencyOptions] = useState([
    {
      value: "1 día",
      key: "1",
    },
    {
      value: "3 días",
      key: "3",
    },
    {
      value: "7 días",
      key: "7",
    },
    {
      value: "14 días",
      key: "14",
    },
    {
      value: "30 días",
      key: "30",
    },
    {
      value: "90 días",
      key: "90",
    },
    {
      value: "180 días",
      key: "180",
    },
    {
      value: "270 días",
      key: "270",
    },
    {
      value: "365 días",
      key: "365",
    },
    {
      value: "18 meses",
      key: "540",
    },
    {
      value: "2 años",
      key: "730",
    },
    {
      value: "5 años",
      key: "1825",
    },
    // {
    //   value: "intrahospitalario",
    //   key: "1",
    // },
  ]);
  const [listmodules, setListModules] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [selectGroup, setSelectGroup] = useState([]);


  return (
    <ScalesContext.Provider
      value={{
        isEditable,
        setIsEditable,
        editScale, setEditScale,
        isEditEscale, setIsEditScale,
        scaleName, setScaleName,
        previewScale, setPreviewScale,
        actualPage, setActualPage,
        pagination, setCustomePagination,
        scale_scale, setScale_scale,
        line_scale, setLine_scale,
        sort, setSort,
        scaleSort, setScaleSort,
        lineSort, setLineSort,
        scalesList, setScalesList,
        indexSection, setIndexSection,
        isDesenlacesSelect, setIsDesenlacesSelect,
        isContigoSelect, setIsContigoSelect,
        frecuencyOptions, setFrecuencyOptions,
        listmodules, setListModules,
        hasPlans, setHasPlans,
        optionsSelect, setOptionsSelect,
        selectGroup, setSelectGroup,
        module_scale, setModule_scale,
        orderQuestions, setOrderQuestions,
        hasResponses, setHasResponses
      }}
    >
      {children}
    </ScalesContext.Provider>
  );
};
