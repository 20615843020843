import React from 'react'
import { useField } from 'formik'
import PillInputNew from "../PillInputNew";
import ErrorMessage from "../../atoms/ErrorMessage";
import styles from "./ListModules.module.scss";

export default function ListModules({ options, setValues, defaultValue, ...props}) {
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  return (
    <>
    <div className={styles.listContainer}>
      <label htmlFor="module" className={styles.subTitle}> Módulos </label>
      <div className={styles.pillinputContainer}>
      <PillInputNew
        options={options}
        name={field.name}
        setValue={setValue}
        submodule={defaultValue}
        setSubmodule={setValues}
      />
      <div className={styles.errorContainer}>
        <ErrorMessage error={meta.error} meta={meta} />
      </div>

      </div>
    </div>
    </>

  )
}
