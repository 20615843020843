import { useEffect, useState, useContext } from "react";
import { useField } from "formik";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";

import { getScaleByLine } from "../../services/scalesManager";
import { getLineServiceNew } from "../../services/patients";

import CardFSFB from "../../atoms/Card";
import { ListScales } from "../ListScales";
import SelectOption from "../../atoms/SelectOption";
import { TitleAccordion } from "../../molecules/TitleAccordion";

import styles from "./MonitoringList.module.scss";

export const MonitoringList = ({ ...props }) => {
  const [field] = useField({ ...props });
  const { setSelectedScales } = useContext(ContigoContext);
  const [lineOptions, setLineOptions] = useState([]);

  const [isActive, setIsActive] = useState(true);

  const getLines = async () => {
    const response = await getLineServiceNew("101");
    setLineOptions(response.data[0].serviceLines);
  };

  const getForms = async (line) => {
    setSelectedScales([]);
    const response = await getScaleByLine(`${line}?send_to_patient=true`);
    const newData = response.data.data.response;
    setSelectedScales(newData);
  };

  useEffect(() => {
    getLines();
  }, []);

  useEffect(() => {
    field.value.length && getForms(field.value);
  }, [field.value]);

  return (
    <CardFSFB>
      <section className={styles.cardContainer}>
        <TitleAccordion
          handleClick={() => setIsActive(!isActive)}
          isActive={isActive}
        >
          <h2 className={styles.titleCard}> 2. Tipo de monitoreo </h2>
        </TitleAccordion>
        {isActive ? (
          <>
            <div className={styles.selectOption}>
              <SelectOption {...field}>
                <option value="" disabled selected>
                  Seleccionar
                </option>
                {lineOptions.map((line) => (
                  <option value={line.id} key={line.id}>
                    {line.name}
                  </option>
                ))}
              </SelectOption>
            </div>
            <ListScales />
          </>
        ) : null}
      </section>
    </CardFSFB>
  );
};
