import CheckboxFSFB from "../../atoms/Checkbox";
import SelectOption from "../../atoms/SelectOption";
import RadioButtonFSFB from "../../atoms/RadioButton";
import TextArea from "../../atoms/TextArea";
import Canvas from "../../atoms/Canva";
import DateTimeOption from "../DateTimeOption";
import ThermometerCard from "../../molecules/ThermometerCard";
import NpsQuestion from "../../atoms/NpsQuestion";
import QuestionContainer from "../../molecules/QuestionContainer";

import styles from "./ManagerScaleOptions.module.scss";

const QuestionType = {
  CHECKBOX: 1,
  RADIO_BUTTON: 2,
  SELECT: 3,
  DATE_TIME: 4,
  TEXT_AREA: 5,
  RADIO_BUTTON2: 6,
  TEXT_AREA2: 8,
  CANVAS: 10,
  THERMOMETER_CARD: 11,
  NPS_QUESTION: 12,
};

export const QuestionComponentMap = {
  [QuestionType.RADIO_BUTTON]: ({ question, options, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <RadioButtonFSFB
        idScale={scale?.scale_form?.id}
        name={question.id}
        options={options}
        labelPlacement={scale?.scale_form?.id === 80 ? "top" : "end"}
        className={
          scale?.scale_form?.id === 23 || scale?.scale_form?.id === 176
            ? styles.gridAuto
            : scale?.scale_form?.id !== 80
            ? styles.optionRadioContainer
            : null
        }
        type="diligence"
        disabled={scale?.form?.form_answer?.isFinished && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.CHECKBOX]: ({ question, options, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <CheckboxFSFB
        name={question.id}
        options={options}
        className={styles.optionsContainer}
        type="diligence"
        disabled={scale?.form?.form_answer?.isFinished && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.SELECT]: ({ question, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <div className={styles.selectContainer}>
        <SelectOption
          name={question.id}
          disabled={scale?.form?.form_answer?.isFinished  && !edited}
        >
          <option value="">Seleccionar</option>
          {question?.options.map(({ id, detail }) => (
            <option value={id} key={id}>
              {detail}
            </option>
          ))}
        </SelectOption>
      </div>
    </QuestionContainer>
  ),
  [QuestionType.TEXT_AREA]: ({ question, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <TextArea
        type={question.type}
        name={question.id}
        disabled={scale?.form?.form_answer?.isFinished  && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.DATE_TIME]: ({ question, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <DateTimeOption
        datePickerName={`${question.id}[0]`}
        dateTimeName={`${question.id}[1]`}
        name={question.id}
        labelStyle={styles.labelStyle}
        type="diligence"
        disabled={scale?.form?.form_answer?.isFinished  && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.RADIO_BUTTON2]: ({ question, options, scale, edited, id }) => (
    <QuestionContainer label={question?.detail}>
      <RadioButtonFSFB
        idQuestion={scale?.scale_form?.id}
        name={question.id}
        options={options}
        type="diligence"
        disabled={scale?.form?.form_answer?.isFinished  && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.CANVAS]: ({ question, scale, edited, answersLines }) => (
    <Canvas
      answersLines={answersLines}
      name={question.id}
      disabled={scale?.form?.form_answer?.isFinished  && !edited}
    />
  ),
  [QuestionType.THERMOMETER_CARD]: ({ question, scale, edited }) => (
    <ThermometerCard
      scale={scale}
      name={question.id}
      disabled={scale?.form?.form_answer?.isFinished  && !edited}
    />
  ),
  [QuestionType.NPS_QUESTION]: ({ question, options, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <NpsQuestion
        scale={scale}
        name={question.id}
        options={options}
        ranges={question.ranges}
        disabled={scale?.form?.form_answer?.isFinished  && !edited}
      />
    </QuestionContainer>
  ),
  [QuestionType.TEXT_AREA2]: ({ question, scale, edited }) => (
    <QuestionContainer label={question?.detail}>
      <TextArea
        name={question.id}
        disabled={scale?.form?.form_answer?.isFinished  && !edited}
      />
    </QuestionContainer>
  ),
};
