import React, { useMemo, useState, useContext, useEffect } from "react";
import * as moment from "moment-timezone";

import { Pagination } from "../../atoms/Pagination";
import { Switch } from "../../atoms/SwitchFSFB";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LinesContext } from "../../context/LinesContext/LinesContext";
import Table from "../../molecules/Table";
import {useSort} from '../../hooks/useSort';
import TableHeader from "../../atoms/TableHeader";
import { offsetUser } from "../../custom/offset";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import styles from "./ServiceLinesAdminTable.module.scss";

function randomizer() {
  return Math.floor(Math.random() * 100000);
}

export default function ServicesLinesTable({
  newLineRender,
  isLoading,
  setIsLoading,
  renderControl,
  handleEditStatus,
  childData,
  handleSelected,
}) {

  const {
    linesList,
    setLinesList,
    line_lines,
    setLine_lines,
    name_lines,
    setName_lines,
    module_lines,
    setModule_lines,
    sort_lines,
    setSort_lines,
    pagination,
    setCustomePagination,
    actualPage,
    setActualPage,
    updateBy_line,
    setUpdateBy_line,
    setDiligenceInfo
  } = useContext(LinesContext);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();

  const handleGetLinesList = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort_lines,
      name: line_lines,
      module: module_lines,
      leader: name_lines,
      updated_by: updateBy_line
    }
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/lines?${route}`,});
      const lastPage = res.pagination.last_page;
      const actualPage_1 = res.pagination.page;
      setLinesList(res.data);
      setCustomePagination({ page: actualPage_1, paginationTotal: lastPage });
      setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    handleGetLinesList();
  }, [actualPage, sort_lines, control, newLineRender, renderControl]);

  // custom hook sort
  useSort(sort_lines, setSort_lines)

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Estado" column={column} />;
      },
      accessor: "switch",
      Cell: ({ row }) => (
        <>
          <div className={styles.switchContainer}>
            {row.original.active}
            <Switch
              toggled={row.original.active}
              onChange={() => {
                handleEditStatus(row.original);
              }}
              name={row.original.id}
            />
          </div>
        </>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 5,
      textAlign: "center",
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setLine_lines}
            filterValue={line_lines}
          />
        );
      },
      Cell: ({ row }) => {
        return (
          <p
            className={styles.rowContainer}
            onClick={() => handleSelected(row.original)}
          >
            {row.original.name}
          </p>
        );
      },
      accessor: "name",
      width: 21.25,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Líder"
            column={column}
            handleGetFilter={setName_lines}
            filterValue={name_lines}
          />
        );
      },
      accessor: "leader.name" || "",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Módulo"
            column={column}
            handleGetFilter={setModule_lines}
            filterValue={module_lines}
          />
        );
      },
      accessor: "module",
      Cell: ({ row }) => (
        <>
          {row.original.module.length &&
            row.original?.module
              ?.map((module) => module.label)
              .toString()
              .replaceAll(",", ", ")}
        </>
      ),
      width: 20,
      disableSortBy: true,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Modificado por"
            column={column}
            handleGetFilter={setUpdateBy_line}
            filterValue={updateBy_line}
          />
        );
      },
      accessor: "updated_by",
      width: 17,
    },
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha modificado" column={column} />;
      },
      Cell: ({ row }) => (
        <p>
          {moment
            .tz(row.values.updated_at, "America/Bogota")
            .format("YYYY-MM-DD HH:mm")}
        </p>
      ),
      accessor: "updated_at",
      width: 20,
      disableFilters: true,
      textAlign: "right",
      tooltipType: "date"
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [name_lines, line_lines, module_lines, updateBy_line]);

  useEffect(() => {
    if (name_lines.length ||
      line_lines.length ||
      module_lines.length ||
      updateBy_line.length > 0) {
      setActualPage(1);
    }
  }, [name_lines, line_lines]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={linesList}
        offsetUser={offsetUser}
        tooltipText="Gestiona las líneas de servicio"
        childData={childData}
        setValues={setDiligenceInfo}
      />
      <Pagination
        actualPage={actualPage}
        setPage={setActualPage}
        infoPage={pagination}
      />
    </>
  );
}
