import {useState } from 'react';
import { Modal } from "@mui/material";

import NextButton from "../../atoms/NextButton";
import ProfileHeaderWhite from "../../molecules/ProfileHeaderWhite";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import doctor from "../../assets/imgs/newSection.svg";
import logo from "../../assets/imgs/logoFsfb.png"
import styles from "./NewSection.module.scss";

export default function NewSection(props) {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(true);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={styles.container}>
        {width <= 480 ?
          <img src={logo} alt="Logo FSFB" className={styles.logoFsfb}></img>
          :
          null
        }
        <ProfileHeaderWhite />
        <div className={styles.subContainer}>
          <div className={styles.docImgContainer}>
            <img src={doctor} alt="newSection" className={styles.docImg} />
          </div>
          <div className={styles.titleSection}>
            <h2 className={styles.title}>{props.title}</h2>
            <div className={styles.btnContainer}>
              <NextButton
                text="Continuar"
                type="button"
                onClick={() => {
                  props.handleNext();
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
