import { useField } from "formik";
import React from "react";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import styles from "./Thermometer.module.scss";

export default function Thermometer({ scale, disabled, ...props }) {
  const location = useLocation();
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;

  function handleInputChange(e) {
    let target = e.target;
    const val = target.value;
    setValue(val);
  }

  return (
    <div className={styles.thermoContainer}>
      <h2
        className={
          location.pathname === "/scale"
            ? styles.thermoLabelPatient
            : styles.thermoLabel
        }
      >
        SU SALUD HOY: {field.value}
      </h2>
      <div className={styles.thermoInfo}>
        <p className={styles.thermoTxt}>
          La mejor salud que
          <br />
          se pueda imaginar
        </p>
        <div
          className={
            location.pathname === "/scale"
              ? styles.termometroPat
              : styles.termometro
          }
        >
          <input
            value={field.value}
            type="range"
            id="range"
            min="0"
            max="100"
            step="1"
            onChange={handleInputChange}
            className={
              location.pathname === "/scale" ? styles.sliderPat : styles.slider
            }
            disabled={
                disabled
                ? disabled
                : location.pathname === "/formularios/nuevoFormulario"
                ? true
                : false
            }
          />
        </div>
        <p className={styles.thermoTxt}>
          La peor salud que
          <br />
          se pueda imaginar
        </p>
      </div>
      <ErrorMessage error={meta.error} meta={meta} />
    </div>
  );
}
