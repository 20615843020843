import { useState, useEffect, useContext } from "react";
import withAuth from "../../HOC/withAuth";
import useSelect from "../../hooks/useSelect";

import ToolboxTable from "../../atoms/ToolboxTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import { ContigoReportTable } from "../../molecules/ContigoReportTable";
import Filters from "../Filters";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import downloadIcon from "../../assets/imgs/icons/downloadIcon_ligthBlue.svg";
import Spinner from "../../atoms/Spinner";

import { handleDownload } from "../../molecules/DownloadReportButton/downloadHelper";
import ButtonOption from "../../atoms/ButtonOption";
import { useContigoReport } from "../../hooks/useContigoReport";
import { getTypeParameter } from "../../services/common/admin";

const ContigoReport = () => {
  const {
    startDateReporteContigo,
    setStartDateReporteContigo,
    endDateReporteContigo,
    setEndDateReporteContigo,
    genereReporteContigo,
    setGenereReporteContigo,
    ageReporteContigo,
    setAgeReporteContigo,
    observationReporteContigo,
    setObservationReporteContigo,
    monitoringReporteContigo,
    setMonitoringReporteContigo,
  } = useContext(FiltersContext);
  const { fetchData } = useContigoReport({ dataReport: true });
  const [optionsInformant, setOptionsInformant] = useState([]);
  const [optionsLossInformation, setOptionsLossInformation] = useState([]);
  const [optionsInterview, setOptionsInterview] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen ] = useState(false);
  const [isDownload, setIsDownLoad] = useState(false);
  const [isInfoEmpty, setIsInfoEmpty ] = useState(false);

  // *--- Select ---*
  const [childDataState, setChildDataState] = useState();
  function childData(e) {
    setChildDataState(e);
  }
  const propertyNameMap = {
    id_track: "id",
    name: "name",
    document: "doc_number",
  };
  const selectOptions = useSelect(childDataState, propertyNameMap);
  // *--- Select ---*

  useEffect(() => {
    async function fetchData() {
      const responseInformant = await getTypeParameter(2);
      setOptionsInformant(responseInformant.data);
      const responseLossInformation = await getTypeParameter(3);
      setOptionsLossInformation(responseLossInformation.data);
      const responseoptionsInterview = await getTypeParameter(4);
      setOptionsInterview(responseoptionsInterview.data);
    }
    fetchData();
  }, []);

  const handleDownloadReport = async () => {
    setIsDownLoad(false);
    setIsInfoEmpty(false);
    setIsLoading(true);
    setIsOpen(true);
    
    const trackId = selectOptions
      .filter((datos) => datos?.id_track)
      .map((datos) => datos?.id_track || "");
    const data = await fetchData(trackId);
    const downloadData = data.data.filter((item) => item.scales.length);
    if (downloadData.length) {
      handleDownload(
        setIsLoading,
        setIsDownLoad,
        downloadData,
        optionsInformant,
        optionsLossInformation,
        optionsInterview
      );
    } else {
      setIsInfoEmpty(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModuleTemplate>
        {selectOptions?.length > 0 && (
          <ToolboxTable name="Tollbox">
            {[
              <ButtonOption
                icon={downloadIcon}
                label="Descargar"
                onClick={handleDownloadReport}
              />,
            ]}
          </ToolboxTable>
        )}
        , ,
        <Filters
          name="filters"
          module="reporteContigo"
          startDate={startDateReporteContigo}
          setStartDate={setStartDateReporteContigo}
          endDate={endDateReporteContigo}
          setEndDate={setEndDateReporteContigo}
          genere={genereReporteContigo}
          setGenere={setGenereReporteContigo}
          ageFilter={ageReporteContigo}
          setAgeFilter={setAgeReporteContigo}
          observationFilter={observationReporteContigo}
          setObservationFilter={setObservationReporteContigo}
          monitoringFilter={monitoringReporteContigo}
          setMonitoringFilter={setMonitoringReporteContigo}
        />
        <ContigoReportTable name="Table" childData={childData} />
      </ModuleTemplate>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          variant="large"
        >
          {isLoading ? (
            <Spinner className_container="rolesForm" />
          ) : (
            <ModalContent
              close={() => setIsOpen(false)}
              btns={false}
              text={ isDownload ? "Resumen descargado": isInfoEmpty&& `No hay información para descargar`}
              helpText={isDownload ? `Revisa en la carpeta interna de tu computador`: ""}
              icon={downloadIcon}
              alt="Icono descarga resumen"
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default withAuth(ContigoReport, "/contigo/reporte");
