import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import styles from "./AddNewUserForm.module.scss";
import TextInput from "../../atoms/TextInput";
import SelectOption from "../../atoms/SelectOption";
import NextButton from "../../atoms/NextButton";
import { getAllRol } from "../../services/roles";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import Spinner from "../../atoms/Spinner";
import { UsersContext } from "../../context/UsersContext/UsersContext";

export default function AddNewUserForm({
  handleClose,
  showNotification,
  setNewUserRender,
  newUserRender,
  setError400
}) {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setCreateStatus } = useContext(UsersContext);

  useEffect(() => {
    async function fetchData() {
      const response = await getAllRol();
      setRoles(response.response);
    }
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <Formik
          initialValues={{}}
          onSubmit={async (values) => {
            const userEmail = values.email.trim();
            const schemaCreate = {
              name: values.name,
              email: userEmail + "@fsfb.org.co",
              rol: parseInt(values.role),
            };
            setIsLoading(true);
            const data = await apiInterceptor({
              method: "POST",
              endpoint: `/tr/admin/users`,
              data: schemaCreate,
            });
            setCreateStatus(data.statusCode);
            if (data.statusCode === 201) {
              setNewUserRender(!newUserRender);
              showNotification(true);
              setIsLoading(false);
              handleClose();
            } else if (data.statusCode === 400) {
              setError400(data.message);
              showNotification(true);
              setIsLoading(false);
              handleClose();
            } else {
              setIsLoading(false);
              setError400("No se pudo completar la acción,\ninténtalo de nuevo");
              showNotification(true);
            }
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .matches(
                /^([A-Za-z_.\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                "Formato errado"
              )
              .required("Campo obligatorio"),
            name: Yup.string()
              .matches(
                /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                "Formato errado"
              )
              .required("Campo obligatorio"),
            role: Yup.string().required("Campo obligatorio"),
          })}
        >
          {() => (
            <Form className={styles.form} autocomplete="off">
              <h2 className={styles.title}>Nuevo usuario</h2>
              <div className={styles.inputsContainer}>
                <div className={styles.inputOption}>
                  <TextInput
                    label="Correo electrónico"
                    name="email"
                    type="text"
                    className={styles.label}
                    classInput={styles.classInput}
                    customInputContainer={styles.customInputContainer}
                    placeholder="usuario"
                    isEmail={true}
                  />
                </div>
                <div className={styles.inputOption}>
                  <TextInput
                    label="Nombre"
                    name="name"
                    type="text"
                    className={styles.label}
                    classInput={styles.classInput}
                    customInputContainer={styles.customInputContainer}
                    placeholder="Nombre"
                  />
                </div>
                <div className={styles.inputOption}>
                  <SelectOption 
                    label="Rol" 
                    name="role"
                    className={styles.selectOptionContainer}
                    classNameL={styles.label}
                    classInputSelect={styles.classInputSelect}
                    errorMessageStyle={styles.errorMessageStyle}
                  >
                    <option value="" disabled selected>
                      Seleccionar
                    </option>
                    {roles.map((role) => (
                      <option
                        value={role.id}
                        className={styles.documentOptions}
                      >
                        {role.name}
                      </option>
                    ))}
                  </SelectOption>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <NextButton type="submit" text="Aceptar" />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
