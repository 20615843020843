
import { useField, FieldArray } from "formik";

import Input from "../../atoms/Input";
import ActionButton from "../../atoms/ActionButton";
import AddButtonOption from "../../atoms/AddButtonOption";
import ErrorMessage from "../../atoms/ErrorMessage";

import trashIcon from "../../assets/imgs/icons/trashIconBlue.svg";
import styles from "./SelectOptionAdmin.module.scss";

const SelectOptionAdmin = (props) => {
  const { name } = props;
  const [field, meta] = useField(props);

  const order = field?.value?.length ? field?.value?.length : 0;
  return (
    <div className={styles.selectNewOption}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {field.value &&
              field.value.length > 0 &&
              field.value.map((option, index) => (
                <div
                  key={`${name}-${option.order}`}
                  className={styles.optionContainer}
                >
                  {!option.to_delete && (
                    <div className={styles.inputOption}>
                      <Input
                        name={`${name}.${index}.detail`}
                        className={styles.inputWhiteOption}
                        disabled={props.disabled && props.disabled}
                        placeholder="Nueva opción"
                      />

                      <Input
                        name={`${name}.${index}.score`}
                        className={styles.inputWhiteOption}
                        disabled={props.disabled && props.disabled}
                        placeholder="Puntaje"
                      />

                      {!props.disabled && (
                        <ActionButton
                          icon={trashIcon}
                          onClick={() => {
                            arrayHelpers.replace(index, {
                              ...option,
                              to_delete: true,
                              detail: "delete",
                            });
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            {!props.disabled && (
              <AddButtonOption
                onClick={() => {
                  arrayHelpers.push({
                    detail: "",
                    score: null,
                    order: order + 1,
                  });
                }}
              />
            )}
            {meta.touched && <ErrorMessage error={meta.error} />}
           
          </>
        )}
      />
    </div>

  );
};

export default SelectOptionAdmin;
