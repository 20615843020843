import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const createRole = async (data) => {
  try {
    const required = await axios.post(`${API_URL}/recovery/admin/rol`, data, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return required;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getAllRol = async () => {
  try {
    const response = await axios.get(`${API_URL}/recovery/admin/rol/getAll`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getModules = async () => {
  try {
    const response = await axios.get(`${API_URL}/recovery/modules`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getSubModules = async (moduleId) => {
  try {
    const response = await axios.get(
      `${API_URL}/recovery/modules/submoduleByModule/${moduleId}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateRol = async (data, id) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/rol/updateById/${id}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const deleteRol = async (data, user) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/rol/deleteById?user=${user}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const rolState = async (data, email, activate) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/admin/rol/activate?email=${email}&activate=${activate}`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
