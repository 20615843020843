import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext/AppContext";

export function useUserModules(userInfo) {
  const { modules, toggleExpandableMenu, modulesMixer } =
    useContext(AppContext);
  const location = useLocation();

  useEffect(() => modulesMixer(modules, location.pathname), [location]);

  return { modules, toggleExpanded: toggleExpandableMenu };
}
