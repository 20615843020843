import React, { useContext } from "react";
import { useField, FieldArray } from "formik";

import { AccordeonOption } from "../AccordeonOption";
import { SelectOptionsList } from "../SelectOptionsList";
import CardSelectOption from "../../organisms/CardSelectOption";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";

import styles from "./CreateQuestions.module.scss";

export const CreateQuestions = (props) => {
  const { name } = props;
  const [field, meta] = useField(props);
  const { orderQuestions } = useContext(ScalesContext);

  const handleDeleteQuestion = (index, arrayHelpers) => {
    const copyQuestion = field.value[index];
    arrayHelpers.replace(index, {
      question: {
        ...copyQuestion.question,
        to_delete: true,
      },
      order: copyQuestion.order,
    });
  };

  const handleCopyQuestion = (index, arrayHelpers) => {
    const copyQuestion = field.value[index];
    if (copyQuestion.question) {
      const optionNew = {
        question: {
          ...copyQuestion.question,
          order: orderQuestions + 1,
        },
        order: orderQuestions + 1,
      };

      arrayHelpers.push(optionNew);
    }
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {field.value.map((option, idx) => (
            <>
              {option?.section === undefined ? (
                <>
                  {!option?.question?.to_delete && (
                    <div className={styles.cardOption} key={`schema.${idx}`}>
                      <CardSelectOption
                        type={option.question.type}
                        name={`schema.${idx}.question`}
                        id={idx}
                        deleteOption={(id) =>
                          handleDeleteQuestion(id, arrayHelpers)
                        }
                        handleCopy={(id) =>
                          handleCopyQuestion(id, arrayHelpers)
                        }
                        idQuestion={option.question?.id}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!option?.section.to_delete && (
                    <AccordeonOption
                      option={option}
                      name={`schema.${idx}.section`}
                      index={idx}
                      handleDeleteQuestion={handleDeleteQuestion}
                      handleCopyQuestion={handleCopyQuestion}
                    />
                  )}
                </>
              )}
            </>
          ))}

          {!props.disabled && (
            <SelectOptionsList
              pushOption={(option) => {
                arrayHelpers.push(option);
              }}
            />
          )}
        </>
      )}
    />
  );
};
