import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import CardFSFB from "../../atoms/Card";
import ErrorMessage from "../../atoms/ErrorMessage";
import { CreateQuestions } from "../../molecules/CreateQuestions";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import EyeIcon from "../../assets/imgs/icons/Eye.svg";
import styles from "./QuestionStep.module.scss";

export const QuestionsStep = () => {
  const navigate = useNavigate();
  const { values, errors } = useFormikContext();
  const {
    isEditable,
    setIndexSection,
    editScale,
    hasPlans,
    hasResponses,
    setPreviewScale,
  } = useContext(ScalesContext);

  useEffect(() => {
    return () => {
      setIndexSection();
    };
  }, []);

  return (
    <>
      <section className={styles.titleComponent}>
        <h2 className={styles.titleScale}>{values.name}</h2>
        <div className={styles.headerScale}>
          <button
            className={styles.viewScale}
            type="button"
            onClick={async () => {
              const id = editScale.id ? editScale.id : "nuevaEscala";
              await setPreviewScale({
                ...values,
                id: id,
              });

              navigate(`/formularios/preview/${id}`);
            }}
          >
            <img src={EyeIcon} alt="Icono de visualizar" />
            Vista previa
          </button>
        </div>
      </section>
      {!isEditable | hasPlans | hasResponses ? (
        <CardFSFB className={styles.cardContainerWarning}>
          <div className={styles.warningAnnounce}>
            <label>
              No se puede actualizar las preguntas de la escala porque:
            </label>
            <ul>
              {editScale?.active && <li>La escala está activa</li>}
              {editScale?.has_plans && (
                <li>Existen registros de planes registrados</li>
              )}
              {editScale?.has_responses && (
                <li>Ya se han respondido las preguntas asociadas</li>
              )}
            </ul>
          </div>
        </CardFSFB>
      ) : null}

      <CreateQuestions name="schema" disabled={!isEditable} />
      <br />
      {errors?.schema === "Agregue una pregunta" && (
        <ErrorMessage error={errors?.schema} />
      )}
    </>
  );
};
