import React, { useEffect } from "react";
import { useField, Field } from "formik";

import styles from "./CheckboxFSFB.module.scss";
import ErrorMessage from "../ErrorMessage";

const CheckboxFSFB = ({ options, ...props }) => {
  const [field, meta, helpers] = useField({ ...props, type: "checkbox" });
  const { setValue } = helpers;
  const defaultValue = props.defaultValue;

  useEffect(() => {
    defaultValue !== undefined && setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div
        className={`${props.className ? props.className : styles.checkboxList}`}
      > 
        {options.map((item) => (
          <label className={styles.checkboxOption} key={`${field.name}-${item.label}`}>
            <Field
              type="checkbox"
              name={field.name}
              value={item.value}
              className="checkBox"
            />
            {item.label}
          </label>
        ))}
       </div>
      <ErrorMessage error={meta.error} meta={meta} />
    </>
  );
};

export default CheckboxFSFB;
