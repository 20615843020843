import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { Button } from "../../atoms/Button/";
import { HelpLink } from "../../atoms/HelpLink";
import OtpTimer from "../../atoms/OtpTimer";
import { AppContext } from "../../context/AppContext/AppContext";
import { OTPSignin } from "../../services/authentication";
import styles from "./OtpPatientsForm.module.scss";

export default function OTPBox() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [error, setError] = useState(null);
  const [charging, setCharging] = useState(false);
  const { otpChallenge } = useContext(AppContext);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const OTPRequest = async () => {
    if (charging || !otp.join("")) return false;
    let result = false;
    setCharging(true);
    try {
      const response = await OTPSignin({
        id_patient: otpChallenge.data?.id_patient,
        password: otp.join(""),
      });

      // Request successfully
      if (response.ok) {
        // setOtpChallenge({requestPayload: otpChallenge.requestPayload, data: response.body.data});
        result = true;
      }
    } catch (error) {
      console.error(error);
    }
    setCharging(false);
    return result;
  };

  const handleLogin = async () => {
    const result = await OTPRequest();
    if (result) {
      navigate("/Welcome");
    } else setError("Código temporal incorrecto");
  };

  useEffect(() => {
    if (!otpChallenge) navigate("/login");
  }, [navigate, otpChallenge]);

  useEffect(() => {
    // const timeout = setTimeout(() => setError(null), 4000);
    // return () => clearTimeout(timeout);
  }, [error]);

  return (
    <div className={styles.container}>
      <div className={styles.otpInputs}>
        {otp?.map((data, index) => {
          return (
            <input
              className={`${styles.otpFields} ${
                error && styles.otpFieldsError
              }`}
              type="tel"
              name="otp"
              maxLength="1"
              key={index}
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onFocus={(e) => e.target.select()}
            />
          );
        })}
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <div className={styles.error}>
            <ErrorOutlineOutlinedIcon
              sx={{
                width: "1.62vh",
                height: "1.62vh",
                marginRight: "0.5vh",
              }}
            />
            <div>{error}</div>
          </div>
        </div>
      )}
      <p className={styles.informativeMessage}>
        ¡Recuerda! Recibirás tu código de
        <b> 6 dígitos</b>
        <br />
      </p>
      <OtpTimer />
      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={charging}
          className={styles.buttonSend}
          onClick={() => handleLogin()}
        >
          Continuar
        </Button>
      </div>
      <HelpLink/>
    </div>
  );
}
