import { useContext, useState, useEffect } from "react";
import * as moment from "moment-timezone";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import HabeasData from "../../atoms/HabeasData";
import Spinner from "../../atoms/Spinner";
import {Tooltip} from "../../atoms/Tooltip"
import styles from "./PatientDataTable.module.scss";
import UpdateData from "../../molecules/UpdateData";
import deceased from "../../assets/imgs/icons/deceased.svg";
import calendar from "../../assets/imgs/icons/calendarWhite.svg";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";

export default function PatientDataTable(props) {
  const { diligenceInfo } = useContext(DesenlacesContext);
  const [open, setOpen] = useState(false);
  const [age, setAge] = useState(null);


  const handleClose = () => {
    setOpen(false);
  };

  const calculateAge = (date) => {
    const todaydate = new Date();
    const birthdate = new Date(date);

    let edad = todaydate.getFullYear() - birthdate.getFullYear();
    return edad;
  };

  useEffect(() => {
    let edad = calculateAge(diligenceInfo?.birthdate);
    setAge(edad);
  }, [diligenceInfo]);

  return (
    <>
      <section
        className={`${styles.patientCard}`}
        style={{
          borderTopRightRadius: diligenceInfo?.patient_name && "16px",
          width: props.styleType === "patientsDetail" && "96%",
          height: props.styleType === "patientsDetail" && "auto",
        }}
      >
        {!diligenceInfo ? (
          <Spinner className_container="alertDel" />
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.nameContainer}>
                {diligenceInfo?.next_scales ? (
                  diligenceInfo?.next_scales?.length <= 0 ||
                  typeof diligenceInfo?.status === "boolean" ? null : (
                    <button
                      className={styles.scalesButton}
                      onClick={() => setOpen(true)}
                    >
                      <img
                        src={calendar}
                        className={styles.imgCalendar}
                        alt="calendar_icon"
                      />
                      Próximas escalas
                    </button>
                  )
                ) : null}
                <section className={styles.patientName}>
                  {diligenceInfo?.deceased_date ?
                    <Tooltip 
                    text={moment(diligenceInfo?.deceased_date).utc().format("YYYY-MM-DD")} 
                    variant="grey" 
                    img={deceased}
                    iconVariant="big"
                    />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                    :null}
                  <b className={styles.patientLabel}>
                    {diligenceInfo?.patient
                      ? diligenceInfo?.patient
                      : diligenceInfo?.patient_name}
                  </b>
                  <div className={styles.patientDocument}>
                    <p>
                      {diligenceInfo.doc_type_value
                        ? diligenceInfo?.doc_type_value
                        : diligenceInfo?.doc_type.detail}
                    </p>
                    <p>{diligenceInfo?.doc_number}</p>
                  </div>
                </section>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>Edad:</h3>
                  <p>
                    {diligenceInfo?.birthdate ? age : diligenceInfo?.age} años
                  </p>
                </div>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>Género:</h3>
                  <p>{diligenceInfo?.genre}</p>
                </div>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>
                    Correo electrónico:
                  </h3>
                  <p className={styles.lowerCaseOption}>
                    {diligenceInfo?.email}
                  </p>
                </div>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>Celular:</h3>
                  <p>{diligenceInfo?.mobile}</p>
                </div>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>Fijo:</h3>
                  <p>{diligenceInfo?.phone}</p>
                </div>
                <div className={styles.wideCell}>
                  <h3 className={styles.optionUserTitle}>Dirección:</h3>
                  <p className={styles.lowerCaseOption}>
                    {diligenceInfo?.address}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.updateDataContainer}>
              <UpdateData
                className={styles.btn}
                name={diligenceInfo?.patient}
                email={diligenceInfo?.email}
                id={diligenceInfo?.id_patient}
                mobile={diligenceInfo?.mobile}
                phone={diligenceInfo?.phone}
                diligenceInfo={diligenceInfo}
                updateInfo={props.updateInfo}
              />
              <HabeasData policyAcceptance={diligenceInfo?.policy_acceptance} />
            </div>
          </>
        )}
      </section>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {diligenceInfo?.next_scales?.map((scales) => (
            <p className={styles.infoNextScales}>
              {scales.date_plan.slice(0, 10)} - {scales.scale_name}
            </p>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
}
