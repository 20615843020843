import React, { useState } from "react";

import styles from "./Tooltip.module.scss";

export const Tooltip = ({ text, variant, img, customStyle, iconVariant }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <div
      className={`${styles.container}`}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <img src={img} alt="Icono tooltip" className={iconVariant=== "big" ? styles.iconBig: styles.icon} />
      {isOpen && text && (
        <div
          className={`
            ${styles.textContainer} 
            ${
              variant === "light"
                ? styles.light
                : variant === "dark"
                ? styles.dark
                : variant === "grey"
                ? styles.grey
                : styles.light
            }
            ${customStyle && customStyle} 
          `}
        >
          <p> {text} </p>
        </div>
      )}
    </div>
  );
};
