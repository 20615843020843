import { useState } from 'react';

import { RolesContext } from './RolesContext';

export const RolesProvider =({ children }) => {

  const [actualPage, setActualPage] = useState(1);
  const [pagination, setCustomePagination] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [rolesList,setRolesList] = useState([]);
  const [updateBy_roles, setUpdateBy_roles] = useState("")
  const [module_roles, setModule_roles] = useState("")
  const [name_roles, setName_roles] = useState("")
  const [sort_roles, setSort_roles] = useState("")
  const [createStatus, setCreateStatus] = useState()
  const [editStatus, setEditStatus] = useState()
  const [diligenceInfo, setDiligenceInfo] = useState(null)

  return(
    <RolesContext.Provider
      value={{
        actualPage, setActualPage,
        pagination, setCustomePagination,
        rolesList,setRolesList,
        updateBy_roles, setUpdateBy_roles,
        module_roles, setModule_roles,
        name_roles, setName_roles,
        sort_roles, setSort_roles,
        createStatus, setCreateStatus,
        editStatus, setEditStatus,
        diligenceInfo, setDiligenceInfo
      }}
    >
      {children}
    </RolesContext.Provider>
  )
}