import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";

import styles from "./DateTime.module.scss";
import icon from "../../assets/imgs/icons/timeMachine.svg"

const DateTime = ({ ...props }) => {
  const [field, meta] = useField({ ...props });

  return (
    <div className={styles.timeContainer}>
      <TextField
        type="time"
        sx={{ width: 120 }}
        InputLabelProps={{
          shrink: true,
        }}
        {...field}
        {...props}
        className={styles.datePickerInput}
      />
        <div className={props.disabled ? styles.iconDisabled : styles.icon}>
          <img src={icon} className={styles.img}></img>
        </div>
    </div>
  );
};

export default DateTime;
