import React, { useEffect, useContext, useState, useMemo} from "react";
import * as moment from 'moment-timezone';
import { format } from "date-fns";
import { FiltersContext } from "../../context/FiltersContext/FiltersContext";
import {useSort} from '../../hooks/useSort';
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import Table from "../../molecules/Table";
import { offsetUser } from "../../custom/offset";
import TableHeader from "../../atoms/TableHeader";

import { Pagination } from "../../atoms/Pagination";
import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const ContigoReportTable = ({childData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const { height, width } = useWindowDimensions();

  function randomizer() {
    return Math.floor(Math.random() * 100000);
  }

  const {
    startDateReporteContigo,
    endDateReporteContigo,
    genereReporteContigo,
    ageReporteContigo,
    observationReporteContigo,
    monitoringReporteContigo
  } = useContext(FiltersContext);

  const {
    listReport,
    setListReport,
    setPaginationReport,
    setActualPageReport,
    actualPageReport,
    paginationReport,
    name,
    setName,
    document,
    setDocument,
    line,
    sort_report,
    setSort_report,
    setDiligenceInfo
  } = useContext(ContigoContext);

  const COLUMNS = [
    {
      Header: ({ column }) => {
        return <TableHeader label="Fecha" column={column} />;
      },
      Cell: ({ row }) => {
        return (
          <p>
            {moment
              .tz(row.values.date, "America/Bogota")
              .format("YYYY-MM-DD")}
          </p>
        )
      },
      accessor: "date",
      width: 15,
      disableFilters: true,
      tooltipType: "date"
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Nombre"
            column={column}
            handleGetFilter={setName}
            filterValue={name}
          />
        );
      },
      accessor: "name",
      width: 30,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Documento"
            column={column}
            handleGetFilter={setDocument}
            filterValue={document}
          />
        );
      },
      accessor: "doc_number",
      width: 15,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Tipo"
            column={column}
          />
        );
      },
      accessor: "service_line",
      width: 15,
      disableFilters: true,
    },
    {
      Header: ({ column }) => {
        return (
          <TableHeader
            label="Observación"
            column={column}
          />
        );
      },
      accessor: "remark",
      width: 20,
      disableFilters: true,
      textAlign: "right",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const getData = async () => {
    let params = {
      page: actualPageReport,
      offset: offsetUser(height, width),
      doc_number: document,
      name: name,
      service_line: line,
      start_date: startDateReporteContigo ? format(startDateReporteContigo, "yyyy-MM-dd") : "",
      end_date: endDateReporteContigo
        ? format(endDateReporteContigo, "yyyy-MM-dd")
        : "",
      genre: genereReporteContigo,
      legal_age: ageReporteContigo,
      remark_ids: observationReporteContigo.toString(),
      service_line_ids: monitoringReporteContigo.toString(),
      get_report: false,
      sort: sort_report,
    };
    const filters = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/contigo/report?${filters}`,});
    const lastPage = response?.pagination?.last_page;
    const actualPage_1 = response?.pagination?.page;
    setListReport(response.data);
    setPaginationReport({ page: actualPage_1, paginationTotal: lastPage });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    getData();
  }, [
    control,
    sort_report,
    actualPageReport,
    monitoringReporteContigo.length > 0 && monitoringReporteContigo,
    observationReporteContigo.length > 0 && observationReporteContigo,
    ageReporteContigo,
    genereReporteContigo,
    endDateReporteContigo,
    startDateReporteContigo
  ]);

  // custom hook sort
  useSort(sort_report, setSort_report)

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [name, document, line]);

  useEffect(() => {
    if (
      name.length ||
      document.length ||
      line.length > 0
    ) {
      setActualPageReport(1);
    }
  }, [name, document, line]);

  return (
    <>
      <Table
        isLoading={isLoading}
        header={columns}
        tableData={listReport}
        getData={getData}
        offsetUser={offsetUser}
        childData={childData}
        setValues={setDiligenceInfo}
      />

      <Pagination
        actualPage={actualPageReport}
        setPage={setActualPageReport}
        infoPage={paginationReport}
      />
    </>
  );
};
