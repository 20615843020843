const { REACT_APP_API_SERVER } = process.env;

const apiInterceptor = async ({ method, endpoint, data }) => {
  const url = `${REACT_APP_API_SERVER}${endpoint}`;
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const accessToken = localStorage.getItem("token");
  if (accessToken) options.headers.Authorization = `Bearer ${accessToken}`;
  if (data) options.body = JSON.stringify(data);

  const response = await fetch(url, options);

  if (response.status !== 401) return response.json();

  const refreshResponse = await fetch(
    REACT_APP_API_SERVER + "/tr/auth/refresh",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("refreshToken"),
      },
    }
  );

  if (!refreshResponse.ok) throw new Error("No se pudo renovar el token");

  const newTokens = await refreshResponse.json();
  localStorage.setItem("token", newTokens.data[0].access_token);
  localStorage.setItem("refreshToken", newTokens.data[0].refresh_token);

  const retryOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return await fetch(url, retryOptions).then((res) => res.json());
};

export { apiInterceptor };
