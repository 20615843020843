import { useContext } from 'react';
import { useField, FieldArray } from "formik";

import Input from "../../atoms/Input";
import ActionButton from "../../atoms/ActionButton";
import ErrorMessage from "../../atoms/ErrorMessage";
import AddButtonOption from "../../atoms/AddButtonOption";
import trashIcon from "../../assets/imgs/icons/trashIconBlue.svg";
import checkBoxIcon from "../../assets/imgs/icons/checkbox_selected_active.svg";
import radioButtonIcon from "../../assets/imgs/icons/radbutton_selected.svg";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import styles from "./OptionCheckboxAdmin.module.scss";

const OptionCheckboxAdmin = (props) => {
  const { type, name } = props;
  const [field, meta] = useField(props);
  const order = field?.value?.length ? field?.value?.length : 0;
  const { isEditable, hasPlans, hasResponses } = useContext(ScalesContext);
  const disabled = isEditable &&  hasPlans &&  hasResponses;
  return (
    <div className={styles.checkboxNewOption}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {field.value &&
              field.value.length > 0 &&
              field.value.map((option, index) => (
                <div
                  key={`${name}-${option.order}`}
                  className={styles.optionContainer}
                >
                  {!option.to_delete && (
                    <div className={styles.inputOption}>
                      {type === 2 ? (
                        <img src={radioButtonIcon} alt="checkbox select icon" />
                      ) : type === 1 ? (
                        <img src={checkBoxIcon} alt="checkbox select icon" />
                      ) : (
                        type === 6 && (
                          <img
                            src={radioButtonIcon}
                            alt="checkbox select icon"
                          />
                        )
                      )}

                      <Input
                        name={`${name}.${index}.detail`}
                        className={styles.inputWhiteOption}
                        disabled={disabled}
                        placeholder="Nueva opción"
                      />

                      <Input
                        name={`${name}.${index}.score`}
                        className={styles.inputWhiteOption}
                        disabled={disabled}
                        placeholder="Puntaje"
                      />

                      {!disabled && (
                      <ActionButton
                        icon={trashIcon}
                        onClick={() => {
                          arrayHelpers.replace(index, {
                            ...option,
                            to_delete: true,

                            detail: "delete",
                          });
                        }}
                      />
                       )}
                    </div>
                  )}
                </div>
              ))}
             {!disabled && (
            <AddButtonOption
              onClick={() => {
                arrayHelpers.push({
                  detail: "",
                  score: null,
                  order: order + 1,
                });
              }}
            />
             )}
            {meta.touched && <ErrorMessage error={meta.error} />}
          </>
        )}
      />
    </div>
  );
};

export default OptionCheckboxAdmin;
