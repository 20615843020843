import React from "react";
import styles from "./styles.module.scss";
import ErrorIcon from "../../assets/imgs/icons/circuloError.svg";

export default function ErrorNotification({ error = null }) {
  return (
    <div className={styles.errorContainer}>
      {error && (
        <div className={styles.error}>
          <img src ={ErrorIcon} alt="icono de error" />
          <div>{error}</div>
        </div>
      )}
    </div>
  );
}
