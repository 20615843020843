import { useContext } from "react";
import { useField } from "formik";

import { LabelInput } from "../../atoms/LabelInput";

import { AdminContext } from "../../context/AdminContext/AdminContext";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import ErrorMessage from "../../atoms/ErrorMessage";
import PillInput from "../../molecules/PillInput";

import styles from "./LineSelect.module.scss";

export const LineSelect = ({ ...props }) => {
  const { serviceLine } = useContext(AdminContext);

  const { editScale } = useContext(ScalesContext);
  const [field, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;

  const lines = editScale?.service_lines ? editScale?.service_lines : [];

  const handleDelete = async (key) => {
    const copyLines = field.value;
    const newValues = [];

    for (let opt of copyLines) {
      if (opt.line.key === key) {
        const findScale = lines.find((line) => line.id === opt.line.key);
        if (findScale) {
          newValues.push({
            ...opt,
            to_delete: true,
            frequency_days: [0],
          });
        }
      } else {
        newValues.push(opt);
      }
    }
    setValue(newValues);
  };

  const handleAddLine = async (value) => {
    setValue([
      ...field.value,
      {
        line: value,
        frequency_days: [],
      },
    ]);
  };

  const linesWhitOutDeleteOption = field.value.length
    ? field.value.filter((line) => !line.to_delete)
    : [];
  const selectedLines = linesWhitOutDeleteOption.map((value) => value.line);

  // ARRAY DE LÍNEAS UNICAS
  function serviceLineUnique(arr) {
    let serviceLineUnique = {};

    arr.forEach(function (line) {
      let clave = line.key;

      if (!serviceLineUnique.hasOwnProperty(clave)) {
        serviceLineUnique[clave] = line;
      }
    });

    let res = Object.values(serviceLineUnique);
    return res;
  }

  return (
    <>
      <div className={styles.lineContainer}>
        <LabelInput text={`Líneas de servicio`} />
        <div>
          <PillInput
            selected={selectedLines}
            options={serviceLineUnique(serviceLine)}
            onClosePill={(key) => handleDelete(key)}
            onSelectOption={(value) => {
              handleAddLine(value);
            }}
            disabled={editScale?.active}
          />
        </div>
        {meta.touched && <ErrorMessage error={meta.error} />}
      </div>
    </>
  );
};
