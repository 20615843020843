import React, { useState } from "react";

import Spinner from "../../atoms/Spinner";
import ModalContent from "../../atoms/ModalContent";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import styles from "./ResendTrack.module.scss";

export const ResendTrack = ({ trackId, onClose }) => {
  const [statuSend, setStatusSend] = useState(null);
  const [error400, setError400] = useState();
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true);
    const data = await apiInterceptor({
      method: "POST",
      endpoint: `/tr/contigo/resend`,
      data: {
        id_track: trackId,
      },
    });

    if (data.statusCode === 201) {
      setStatusSend(data.statusCode);
    } else {
      setStatusSend(data.statusCode)
      setError400(data.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <ModalContent
          close={onClose}
          confirm={handleSend}
          text={
            statuSend === 201
              ? `Seguimiento reenviado`
              : statuSend === null
              ? `Estás a punto de reenviar el seguimiento`
              :  error400
          }
          updateTitleCustom={styles.updateTitleCustom}
          helpText={statuSend === null && "¿Deseas continuar?"}
          helpTextCustom={styles.helpTextCustom}
          btns={statuSend === null && true}
          icon={statuSend === 201 || statuSend === null ? personGreen : redFlag}
          alt="Icono de líneas"
        />
      )}
    </>
  );
};
