import { useState } from 'react';

import { LinesContext } from './LinesContext';

export const LinesProvider =({ children }) => {

  const [actualPage, setActualPage] = useState(1);
  const [pagination, setCustomePagination] = useState({
    page: 1,
    numberOfPages: 1,
  });
  const [name_lines, setName_lines] = useState("")
  const [line_lines, setLine_lines] = useState("")
  const [sort_lines, setSort_lines] = useState("")
  const [leaderSort_lines, setLeaderSort_lines] = useState("leader:asc")
  const [lineSort_lines, setLineSort_lines] = useState("name:asc")
  const [linesList, setLinesList] = useState([]);
  const [editLine, setEditLine] = useState([]);
  const [isEditLine, setIsEditLine] = useState(false);
  const [createStatus, setCreateStatus] = useState()
  const [editStatus, setEditStatus] = useState()
  const [updateBy_line, setUpdateBy_line] = useState("")
  const [diligenceInfo, setDiligenceInfo] = useState(null)
  const [module_lines, setModule_lines] = useState("")

  return(
    <LinesContext.Provider
      value={{
        actualPage, setActualPage,
        pagination, setCustomePagination,
        name_lines, setName_lines,
        line_lines, setLine_lines,
        sort_lines, setSort_lines,
        leaderSort_lines, setLeaderSort_lines,
        lineSort_lines, setLineSort_lines,
        linesList, setLinesList,
        editLine, setEditLine,
        isEditLine, setIsEditLine,
        createStatus, setCreateStatus,
        editStatus, setEditStatus,
        updateBy_line, setUpdateBy_line,
        diligenceInfo, setDiligenceInfo,
        module_lines, setModule_lines
      }}
    >
      {children}
    </LinesContext.Provider>
  )
}