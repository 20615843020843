import axios from "axios";
import { apiInterceptor } from "./interceptors/jwt.interceptor";

const API_URL = process.env.REACT_APP_API_DESENLACES;

// export const getPatients = async (offset, page = 1, filters = "") => {
//   try {
//     const res = await axios.get(
//       `${API_URL}/recovery/track?offset=${offset}&page=${page}${filters}`,
//       {
//         headers: {
//           "x-api-key": process.env.REACT_APP_API_KEY,
//         },
//       }
//     );
//     return res.data.data;
//   } catch (error) {
//     console.error(error);
//     return error.response;
//   }
// };

export const stopMonitoring = async (data) => {
  try {
    const request = await axios.put(
      `${API_URL}/recovery/track/batchDelete`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};


export const getLineService = async (id) => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/admin/service-line/modules?ids=${id}&active_forms=true`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getLineServiceNew = async (id) => {
  const response = await apiInterceptor({
    method: 'GET',
    endpoint:`/tr/admin/lines/modules?ids=${id}&active_forms=true`,
  })

  return response;
};
