import React from "react";
import { Link } from "react-router-dom";

import styles from "./Breadcumb.module.scss";
import separator from "../../assets/imgs/icons/separador.svg";

const Breadcrumb = (props) => {
  const isLast = (index) => {
    return index === props.crumbs.length - 1;
  };

  return (
    <div className={styles.listBreadcumb} style={props.style}>
      <ol className={styles.breadcumb}>
        {props.crumbs.map((crumb, i) => {
          const disabled = isLast(i);
          return (
            <li key={i} className={styles.breadcumbItem}>
              <Link
                to={`/${crumb.route}`}
                className={`${styles.buttonBreadCumb} ${
                  disabled && styles.disabled
                }`}
                disabled={disabled}
              >
                {crumb.name}
               
                  {!disabled && <img src={separator} alt="separador" className={styles.iconSeparator}/>}

              </Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Breadcrumb;
