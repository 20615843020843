import React, { useState, useEffect } from "react";
import RadioButtonFSFB from "../../atoms/RadioButton";
import SelectOption from "../../atoms/SelectOption";
import { getTypeParameter } from "../../services/common/admin";
import styles from "./AboutPatientForm.module.scss";

export const optionsAboutPatient = [
  { label: "Si", value: true },
  { label: "No", value: false },
];

export default function AboutPatientForm({ scale, acceptProcessing, edited }) {
  const [optionsInformant, setOptionsInformant] = useState([]);
  const [optionsLossInformation, setOptionsLossInformation] = useState([]);
  const [optionsInterview, setOptionsInterview] = useState([]);

  const options = [
    { label: "Gestor", value: "gestor" },
    { label: "Profesional de la salud", value: "profesional" },
  ];

  useEffect(() => {
    async function fetchData() {
      const responseInformant = await getTypeParameter(2);
      setOptionsInformant(responseInformant.data);
      const responseLossInformation = await getTypeParameter(3);
      setOptionsLossInformation(responseLossInformation.data);
      const responseoptionsInterview = await getTypeParameter(4);
      setOptionsInterview(responseoptionsInterview.data);
    }
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.questionOptions}  id="agree_medition">
        <label className={styles.label}>Acepta medición</label>
        <RadioButtonFSFB
          name="agree_medition"
          options={optionsAboutPatient}
          labelPlacement="end"
          disabled={scale?.form?.form_answer?.isFinished  && !edited}
        />
      </div>

      <div className={styles.questionOptions} id="loss_information">
        <label className={styles.label}>Pérdida de información</label>
        <SelectOption
          name="loss_information"
          disabled={scale?.form?.form_answer?.isFinished  && !edited}
          className={styles.selectOption}
          touchedConditional={acceptProcessing}
        >
          <option value="" disabled selected>
            Seleccionar
          </option>
          {optionsLossInformation.map(({ id, value }) => (
            <option value={id} key={id}>
              {value}
            </option>
          ))}
        </SelectOption>
      </div>
      <div className={styles.questionOptions}  id="informant">
        <label className={styles.label}>Informante</label>
        <SelectOption
          name="informant"
          disabled={scale?.form?.form_answer?.isFinished  && !edited}
          className={styles.selectOption}
          touchedConditional={acceptProcessing}
        >
          <option value="" disabled selected>
            Seleccionar
          </option>
          {optionsInformant.map(({ id, value }) => (
            <option value={id} key={id}>
              {value}
            </option>
          ))}
        </SelectOption>
      </div>
      <div className={styles.questionOptions} id="interview_type">
        <label className={styles.label}>Tipo de entrevista</label>
        <SelectOption
          name="interview_type"
          disabled={scale?.form?.form_answer?.isFinished  && !edited}
          className={styles.selectOption}
          touchedConditional={acceptProcessing}
        >
          <option value="" disabled selected>
            Seleccionar
          </option>
          {optionsInterview.map(({ id, value }) => (
            <option value={id} key={id}>
              {value}
            </option>
          ))}
        </SelectOption>
      </div>
      {/* {!scale?.scale_form?.send_to_patient ? ( */}
        <div className={styles.questionOptions} id="answer_by">
          <p className={styles.label}>Diligencia: </p>
          <RadioButtonFSFB
            name="answer_by" 
            options={options}
            labelPlacement="end"
            disabled={scale?.form?.form_answer?.isFinished  && !edited}
            touchedConditional={acceptProcessing}
          />
        </div>
      {/* ) : null} */}
    </div>
  );
}
