import { useContext, useEffect } from "react";

import withAuth  from '../../HOC/withAuth';
import { TitleModules } from "../../atoms/TitleModules";
import HistoricalTable from "../../atoms/HistoricalTable";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import calendarIcon_blue from "../../assets/imgs/icons/calendarIcon_blue.svg"
import styles from "./Historical.module.scss";

function Historical() {

  const {
    setScale
  } = useContext(DesenlacesContext);
   useEffect(()=> {
    setScale(null);
  },[])
  
  return (
    <>
     <TitleModules 
        icon={calendarIcon_blue}
        alt="Icono Historico"
        title="Histórico"
      />
      <section className={styles.tableSection}>
        <HistoricalTable />
      </section>
    </>
  );
}

export default withAuth(Historical, '/desenlaces/historico');