import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../atoms/Spinner";
import { AppContext } from "../../context/AppContext/AppContext";
import { logout } from "../../services/auth/auth.service";
import { CognitoOAUTHCheckCode } from "../../services/authentication";
import { getItem, removeItem } from "../../services/localStorage";
import { whoamiService } from "../../services/whoiam";
import styles from "./AuthSuccess.module.scss";

export default function AuthSuccess() {
  const { setUser, setKeys } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    setLoaded(true);
    CognitoOAUTHCheckCode()
      .then(async (result) => {
        if (result) {
          let whoami = await whoamiService();
          if (whoami.statusCode === 200 && whoami.infoUser) {
            setUser(whoami.infoUser);
            let destination = "/welcomeAdmin";
            const newDestination = getItem("ruta");
            if (newDestination) {
              destination = newDestination;
              removeItem("ruta");
            }
            navigate(destination);
            return;
          }
        }
        throw new Error("Invalid authentication");
      })
      .catch(async (err) => {
        console.error(err);
        try {
          setKeys(null);
          await logout();
        } catch (error) {}
        navigate("/managerlogin");
      });
  }, [loaded, navigate, setUser, setKeys]);

  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
}
