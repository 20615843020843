import styles from "./Card.module.scss";

const CardFSFB = ({ children, className, variant }) => {
  //variant : process | finished | paused | deceased

  return (
    <div className={` ${styles.cardContainer} ${className} ${styles[variant]}`}>
      {children}
    </div>
  );
};

export default CardFSFB;
