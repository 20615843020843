import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;

export const getTotal = async (offset, page = 1, filters = "") => {
  try {
    const res = await axios.get(
      `${API_URL}/recovery/admin/total?offset=${offset}&page=${page}${filters}`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
