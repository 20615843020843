import React from "react"
import { useTable } from "react-table";

import styles from "./PlansEliminated.module.scss"

export default function PlansEliminated({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data || [],
      }
    );

  return (
    <div className={styles.containerTable}>
      <table className={styles.table} {...getTableProps()}>
        <thead className={styles.thead}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ width: `${column.width}%` }}
                  className={
                    column.scaleNameWidth === true
                      ? styles.scaleNameWidth
                      : column.generalWidth === true
                        ? styles.generalWidth
                        : styles.header
                  }
                >
                  <div className={styles.headerName}>
                    <p className={styles.headerLabel}>
                      <div
                        className={styles.headerContainer}
                      >
                        {column.render("Header")}
                      </div>
                      <div>
                        {column.canFilter
                          ? column.render("Filter")
                          : null}
                      </div>
                    </p>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={styles.tbody}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={styles.row}
                style={{ width: `${row.width}%` }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: cell.column.textAlign }}
                      className={styles.cells}
                    >
                      <div className={styles.overflow}>
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
