import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import RolesForm from "../../atoms/RolesForm";
import { ModalFormStepper } from "../../molecules/ModalFormStepper";
import { ModalStep } from "../../atoms/ModalStep";
import styles from "./AddNewRol.module.scss";

import { AppContext } from "../../context/AppContext/AppContext";
import { createRole } from "../../services/roles";
import TextInput from "../../atoms/TextInput";
import { RolModules } from "../../molecules/RolModules";
import { SubmoduleList } from "../../molecules/SubmoduleList";
import { id } from "date-fns/locale";


const validateSchema = Yup.object({
  name: Yup.string()
    .required("Campo obligatorio"),
  newModules: Yup.array()
      .min(1, "Campo obligatorio")
});

// const message = Yup.array().min(1, "Campo obligatorio");

export const AddNewRol = ({
  onClose,
  edit = false,
  setStatus,
  setIsLoading,
  setOpenCreateNotification,
  setError400,
  setNewRolRender,
  newRolRender,
}) => {

  const [uuid, setUuid] = useState();
  const [rolesEditSelect, setRolesEditSelect] = useState([]);
  const [submodule, setSubmodule] = useState([]);
  const [modules, setModule] = useState();
  const [idSubmodule, setIdSubmodule] = useState([]);
  const [idDelete, setIdDelete] = useState();
  const [arraySubmodule, setArraySubmodule] = useState([]);
  const uniqueArray = submodule.map(d =>{
    if(d.selectedOptions){
      d.result = d.selectedOptions.map(c => c.value);
    }
    return d.result;
  }).filter(d => d).flat()

  useEffect(() => {
    setArraySubmodule((prevArray) => [...prevArray, ...idSubmodule]);
  }, [idSubmodule]);

  function changeSelected (index, values) {
    submodule[index].selectedOptions = values;
  }

  const { user } = useContext(AppContext);

  const initialValuesCreate = {
    name: "",
    newModules: [],
    Desenlaces: [],
    Contigo: [],
  };

  const validateSchema2 = Yup.object().shape({

    ...submodule.reduce((fields, sub) => {
      const fieldName = sub.label;
      return {
        ...fields,
        [fieldName]: Yup.array()
          .min(1, "Campo obligatorio")
      };
    }, {}),
  });


  return (
    <ModalFormStepper
      onClose={onClose}
      initialValues={initialValuesCreate}
      onSubmit={async (values) => {
        const defaultData = {
          name: values.values.name,
          modules: values.values.newModules.map((item) => item.value),
          sub_modules: uniqueArray,
          created_by: user.email,
        };

        setIsLoading(true);
        if (!edit) {
         const data = await createRole(defaultData);
         setStatus(data.status);
         setOpenCreateNotification(true);
         onClose()
         setIsLoading(false);
         setNewRolRender(!newRolRender)
         if (data.status === 400) {
           setError400(data.data.data.response[0].message);
         }
        }
      }}
    >
      <ModalStep validationSchema={validateSchema}>
        <h2 className={styles.title}>Nuevo rol</h2>
        <div className={styles.inputNameContainer}>
          <TextInput
            label="Nombre"
            name="name"
            type="text"
            className={styles.label}
            classInput={styles.classInput}
            customInputContainer={styles.customInputContainer}
            placeholder="Nombre del rol"
          />
        </div>
        <label htmlFor="newModules" className={styles.inputLabel}>Módulos</label>
        <RolModules
          name="newModules"
          defaultValue={rolesEditSelect}
          setSubmodule={setSubmodule}
          setModule={setModule}
          submodule={submodule}
          className={styles.pillinput}
        />
      </ModalStep>
      {submodule?.some((sub) => sub?.submodules?.length > 0) &&
      <ModalStep validationSchema={validateSchema2}>
        <h2 className={styles.title}>Submódulos</h2>
        {submodule?.map((sub, index) => {
          return (
          <SubmoduleList
            index={index}
            changeSelected={changeSelected}
            listKey={setUuid}
            key={sub.value}
            item={sub}
            name={sub.label}
            listSubmodule={sub.submodules}
            setIdSubmodule={setIdSubmodule}
            setIdDelete={setIdDelete}
          />
          )})}
      </ModalStep>
      }
    </ModalFormStepper>
  );
};
