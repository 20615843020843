// import objectJson from "./typical_whoami.json";
import NavCustomLink from "../../atoms/NavCustomLink";
import NavIcon from "../../atoms/NavIcon";
import styles from "./_.module.scss";
import { useContext } from "react";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { useUserModules } from "./_.hooks";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext/AppContext";

export default function MenuLinks() {
  const navigate = useNavigate();
  const { dropdown } = useContext(AdminContext);
  const { user } = useContext(AppContext);
  const { modules, toggleExpanded } = useUserModules(user);

  const elementClickHandler = (elm) => {
    if (elm?.path) navigate(elm.path);
    if (elm?.submodules?.length) toggleExpanded(elm.kuid);
  };

  const typicalComponent = (elm) => (
    <NavCustomLink
      isMinor={elm.level}
      isSelected={elm.isSelected}
      key={elm.kuid}
      onClick={() => elementClickHandler(elm)}
    >
      {elm?.icon && (
        <NavIcon
          isSelected={elm.isSelected}
          iconName={elm.icon}
        />
      )}
      <div
        className={`${styles.text} ${elm.isSelected && styles.selectedText} ${
          !dropdown && styles.isDropped
        }`}
      >
        {elm.label}
      </div>
      {elm?.submodules?.length ? (
        <svg 
          className={`
            ${styles.caret} 
            ${elm.isExpanded && styles.opened} 
            ${elm.isSelected && styles.isSelectedCaret}
          `}
          width="12" height="12" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.1319 3.13726L1 1" stroke={elm.isSelected ? "#001698" : "#FFFFFF"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.26862 1L3.13672 3.13726" stroke={elm.isSelected ? "#001698" : "#FFFFFF"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      ) : null}
    </NavCustomLink>
  );

  const evaluateChilds = (element) =>
    element?.submodules?.length
      ? element.submodules.map(
          (subEl) => subEl.is_active && typicalComponent(subEl)
        )
      : null;

  return modules.map((elm) => (
    <>
      {elm.is_active && typicalComponent(elm)}
      {elm.is_active && elm.isExpanded && evaluateChilds(elm)}
    </>
  ));
}
