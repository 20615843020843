import React from "react";
import { useField } from "formik";

import DatePicker from "../../atoms/DatePicker";
import DateTime from "../../atoms/DateTime";
import styles from "./DateTimeOption.module.scss";
import ErrorMessage from "../../atoms/ErrorMessage";

const DateTimeOption = ({
  datePickerName,
  dateTimeName,
  disabled,
  label,
  ...props
}) => {
  const [field, meta] = useField({ ...props });
  return (
    <div >
      <div className={styles.dateTimeContainer}>
        <DatePicker name={datePickerName} disabled={disabled} />
        <DateTime name={dateTimeName} disabled={disabled} />
      </div>
      <ErrorMessage error={meta.error} meta={meta} />
    </div>
  );
};
export default DateTimeOption;
