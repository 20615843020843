export const offsetUser = (height, width) => {
  let offsetUsers;

  if (height <= 1000 && width <= 1024) {
    offsetUsers = 10;
  } else if (height <= 1024 && width <= 1152) {
    offsetUsers = 10;
  } else if (height <= 1024 && width <= 1366) {
    offsetUsers = 8;
  } else if (height <= 1200 && width <= 1920) {
    offsetUsers = 7;
  } else if (height <= 1600 && width <= 2560) {
    offsetUsers = 5;
  } else if (height <= 1600 && width <= 2560) {
    offsetUsers = 5;
  } else if (height > 1600 && width > 2560) {
    offsetUsers = 5;
  }

  const heightDifference = height - 500;
  const extraOffset = Math.floor(heightDifference / 27);

  return offsetUsers + extraOffset;
};
