import styles from "./ConfirmContent.module.scss";

export const ConfirmContent = ({ text, icon, alt}) => {
  return (
    <div className={styles.confirmContainer}>
      <img src={icon} alt={alt} />
      <h2 className={styles.title}>
        {text}
      </h2>
    </div>
  )
};

