import ProfileHeaderWhite from "../../molecules/ProfileHeaderWhite";
import icon from "../../assets/imgs/icon-error-diligence.png";
import NextButton from "../../atoms/NextButton";
import CenterMessage from "../../atoms/CenterMessage";
import styles from "./ErrorDiligence.module.scss";
import FooterDescription from "../../molecules/FooterDescription";
import logo from "../../assets/imgs/logoFsfb.png"
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";

export default function ErrorDiligence() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  return (
    <>
      <main className={styles.container}>
        <ProfileHeaderWhite />
        {width <= 480 ?
          <>
            <img src={logo} alt="Logo FSFB" className={styles.logoFsfb}></img>
          </>
          :
          null
        }
        <section className={styles.subContainer}>
          <img src={icon} alt="IconErrorDiligence" />
          <CenterMessage
            title2="Ya has diligenciado la escala asignada,"
            text2="si necesitas asistencia ponte en contacto con nosotros"
          />
          <div className={styles.containerButton}>
            <NextButton 
              text="Salir"
              type="button"
              onClick={() => navigate("/")} />
          </div>
        </section>
        <FooterDescription />
      </main>
    </>
  );
}
