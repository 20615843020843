import { useContext, useMemo, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { format } from "date-fns";
import Table from "../../molecules/Table";
import { Pagination } from "../../atoms/Pagination";
import Modal from "../../atoms/Modal";
import ArrowDown from "../../assets/imgs/icons/arrowDownSort.svg";
import ArrowUp from "../../assets/imgs/icons/arrowUpSort.svg";
import downloadTable from "../../assets/imgs/icons/downloadTable.svg";
import DatePickerSince from "../../atoms/DatePickerSince";
import DatePickerUntil from "../../atoms/DatePickerUntil";
import DateRange from "../../atoms/DateRange";
import Spinner from "../../atoms/Spinner";
import FilterInput from "../../molecules/FilterInput";
import ModalContent from "../../atoms/ModalContent";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";
import downloadIcon from "../../assets/imgs/icons/download.svg";
import { jsonToExcel } from "../../custom/xmlsCreator";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getTotal } from "../../services/total";
import { offsetUser } from "../../custom/offset";
import styles from "./TotalTable.module.scss";

function randomizer() {
  return Math.floor(Math.random() * 100000);
}

export default function TotalTable() {

  function Sort(props) {
    const {
      setSort_total,
      setLineSort_total,
      lineSort_total,
      setScaleSort_total,
      scaleSort_total,
    } = useContext(DesenlacesContext);
    const handleOnChange = async (e) => {
      let valueDesc = "desc";
      let valueAsc = "asc";
      if (props.service_line === "Linea") {
        let param = "service_line:";
        if (lineSort_total === "service_line:asc") {
          setLineSort_total(param + valueDesc);
          setSort_total(param + valueDesc);
        } else if (lineSort_total === "service_line:desc") {
          setLineSort_total(param + valueAsc);
          setSort_total(param + valueAsc);
        }
      } else if (props.scale === "Evento") {
        let param = "scales_amount:";
        if (scaleSort_total === "scales_amount:asc") {
          setScaleSort_total(param + valueDesc);
          setSort_total(param + valueDesc);
        } else if (scaleSort_total === "scales_amount:desc") {
          setScaleSort_total(param + valueAsc);
          setSort_total(param + valueAsc);
        }
      }
    };

    return (
      <span className={styles.headerSorted} onClick={handleOnChange}>
        {props.service_line === "Linea"
          ? {
              ...(lineSort_total === "service_line:asc" ? (
                <img
                  src={ArrowDown}
                  className={styles.arrowSort}
                  alt="Flecha abajo"
                ></img>
              ) : (
                <img
                  src={ArrowUp}
                  className={styles.arrowSort}
                  alt="Flecha arriba"
                ></img>
              )),
            }
          : {
              ...(scaleSort_total === "scales_amount:asc" ? (
                <img
                  src={ArrowDown}
                  className={styles.arrowSort}
                  alt="Flecha abajo"
                ></img>
              ) : (
                <img
                  src={ArrowUp}
                  className={styles.arrowSort}
                  alt="Flecha arriba"
                ></img>
              )),
            }}
      </span>
    );
  }

  const { height, width } = useWindowDimensions();
  const { 
    totalList, 
    setTotalList, 
    line_total,
    setLine_total,
    sort_total, 
    actualPage_Report, 
    setActualPage_report, 
    pagination_report, 
    setCustomePagination_report 
  } = useContext(DesenlacesContext);

  // --Popover--
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateInfo, setDateInfo] = useState(null);
  const [dateInfoUntil, setDateInfoUntil] = useState(null);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [control, setControl] = useState(randomizer());
  const [timer, setTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    let params = {
      is_paginated: true,
      service_line: line_total,
      sort: sort_total,
      start_date: dateInfo ? format(dateInfo, "yyyy-MM-dd") : "",
      end_date: dateInfoUntil ? format(dateInfoUntil, "yyyy-MM-dd") : "",
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    setIsLoading(true);
    const res = await getTotal(offsetUser(height, width), actualPage_Report, route);

    const lastPage = res.pagination.last_page;
    const actualPage_1 = res.pagination.page;
    setTotalList(res.response);
    setCustomePagination_report({ page: actualPage_1, paginationTotal: lastPage });
    setIsLoading(false);
  };

  const handleDownload = async () => {
    setLoadingConfirmation(true);
    setLoadingDownload(true);
    let params = {
      is_paginated: false,
      start_date: dateInfo ? format(dateInfo, "yyyy-MM-dd") : "",
      end_date: dateInfoUntil ? format(dateInfoUntil, "yyyy-MM-dd") : "",
    };
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const download = await getTotal(
      offsetUser(height, width),
      actualPage_Report,
      route
    );

    await jsonToExcel({
      filename: "Totales",
      data: download?.response || [],
      columns: [
        { column: "LINEA", key: "service_line" },
        { column: "PACIENTES", key: "patients_amount" },
        { column: "ESCALAS", key: "scales_amount" },
      ],
    });
    setLoadingDownload(false);
  };

  useEffect(() => {
  setDateInfo(dateInfo)
  setDateInfoUntil(dateInfoUntil)
  },[dateInfo, dateInfoUntil]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const COLUMNS = [
    {
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>
              Línea de servicio
              <span id="Linea" className={styles.headerSorted}>
                {column.canFilter ? <Sort service_line="Linea" /> : null}
              </span>
            </p>
            <div id="Linea" className={styles.headerInput}>
              {
                column.canFilter &&
                <FilterInput 
                  column={column} 
                  handleGetFilter={setLine_total}
                  filterValue={line_total}
                /> 
              }
            </div>
          </div>
        </>
      ),
      accessor: "service_line",
      width: 60,
    },
    {
      Header: ({ column }) => (
        <>
          <div className={styles.headerContainer}>
            <p className={styles.headerLabel}>
              Desenlaces aplicados
              <span id="Evento" className={styles.headerSorted}>
                {column.canFilter ? <Sort scale="Evento" /> : null}
              </span>
            </p>
          </div>
        </>
      ),
      accessor: "scales_amount",
      width: 20,
      textAlign: 'center',
    },
    {
      Header: "Total pacientes",
      accessor: "patients_amount",
      disableFilters: true,
      width: 20,
      textAlign: 'center',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    const timerNow = setTimeout(() => setControl(randomizer()), 500);
    setTimer(timerNow);
    return () => {
      clearTimeout(timer);
    };
  }, [line_total]);

  useEffect(() => {
    if (isLoading) return;
    fetchData();
  }, [actualPage_Report, sort_total, dateInfo, dateInfoUntil, control]);

  useEffect(() => {
    if (line_total.length > 0) {
      setActualPage_report(1);
    }
  }, [line_total]);

  return (
    <>
      <div className={styles.options}>
        <div className={styles.dataLabelContainer}>
          <div className={styles.dateLabel}>Desde</div>
          <DatePickerSince
            preValue={null}
            changeValue={(value) => {
              setDateInfo(value);
            }}
            maxDate={dateInfoUntil}
            withCleaner={true}
          />
        </div>
        <div style={{ padding: "10px" }}></div>
        <div className={styles.dataLabelContainer}>
          <div className={styles.dateLabel}>Hasta</div>
          <DatePickerUntil
            preValue={null}
            changeValue={(value) => {
              setDateInfoUntil(value);
            }}
            minDate={dateInfo}
            withCleaner={true}
          />
        </div>
        <Button
          variant="outlined"
          onClick={handleDownload}
          sx={{
            border: "none",
            color: "rgba(0, 157, 172, 1)",
            fontSize: "2vh",
            fontFamily: "Gilroy-Regular",
            textTransform: "none",
            "&:hover": {
              border: "none",
            },
          }}
        >
          <img
            src={downloadTable}
            className={styles.icon}
            alt="Icono descarga"
          ></img>
          Descargar
        </Button>
        {}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateRange />
        </Popover>
      </div>

      <Table
        isLoading={isLoading}
        header={columns}
        tableData={totalList}
        getData={fetchData}
        offsetUser={offsetUser}
        tooltipText="Aquí encontrarás el histórico de densenlaces realizados y el número de pacientes medidos por línea de servicio. Recuerda filtrar antes de descargar tu reporte"
      />
      <Pagination
        actualPage={actualPage_Report}
        setPage={setActualPage_report}
        infoPage={pagination_report}
        className={styles.pagination}
      />

      {loadingConfirmation && (
        <Modal
          isOpen={loadingConfirmation}
          onClose={() => setLoadingConfirmation(false)}
          variant="confirm"
        >
          {loadingDownload ? (
            <Spinner className_container="rolesForm" />
          ) : (
            <ModalContent
            close={() => setLoadingDownload(false)} 
            btns={false}
            text={"Resumen descargado"}
            helpText={`Revisa en la carpeta interna de tu\ncomputador`}
            icon={downloadIcon}
            alt="Icono descarga resumen"
          />
          )}
        </Modal>
      )}
    </>
  );
}
