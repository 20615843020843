const API = process.env.REACT_APP_API_SERVER;

export async function ApiRequester({
  url,
  method = "GET",
  headers = {},
  body,
  query,
}) {
  const response = await fetch(
    `${API}${url}${
      typeof query === "string"
        ? query
        : query && typeof query === "object"
        ? "?" +
          Object.entries(query)
            .map(([key, value]) => key + "=" + value)
            .join("&")
        : ""
    }`,
    {
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: body ? JSON.stringify(body) : body,
    }
  );
  const toReturn = {
    status: response.status,
    ok: response.ok,
    body: await response.json(),
  };
  return toReturn;
}
