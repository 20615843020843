import { useContext, useEffect } from "react";

import { ContigoContext } from "../../context/ContigoContext/ContigoContext";
import { AppContext } from "../../context/AppContext/AppContext";

import withAuth from "../../HOC/withAuth";
import Spinner from "../../atoms/Spinner";
import { SearchPatient } from "../../molecules/SearchPatient";
import { MonitoringForm } from "../../molecules/MonitoringForm";
import { ConfirmationMonitoring } from "../../molecules/ConfirmationMonitoring";

import styles from "./NewMonitoring.module.scss";

const NewMonitoring = () => {
  const { isLoadingSend } = useContext(ContigoContext);
  const { modules, modulesMixer } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      const changeRoute = async () => {
        await modulesMixer(modules, "/contigo/pacientes");
      };
      changeRoute();
    }, 100);
  }, []);

  return (
    <div className={styles.monitoringContainer}>
      {isLoadingSend ? (
        <Spinner className_container="alertDel" />
      ) : (
        <>
          <SearchPatient />
          <MonitoringForm />
          <ConfirmationMonitoring />
        </>
      )}
    </div>
  );
};

export default withAuth(NewMonitoring, "/contigo/pacientes");
