import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/imgs/icons/logout_Blue.svg";
import { AppContext } from "../../context/AppContext/AppContext";
import { logout } from "../../services/auth/auth.service";
import styles from "./Logout.module.scss";

export default function Logout() {
  const navigate = useNavigate();
  const { setKeys } = useContext(AppContext);

  return (
    <div
      className={styles.container}
      onClick={async () => {
        setKeys(null);
        await logout();
        navigate("/managerlogin");
      }}
    >
      <img src={logo} alt="logo de salida" className={styles.logoutLogo} />
      <p className={styles.text}>Cerrar sesión</p>
    </div>
  );
}
