import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Field } from "formik";
import ModalContent from "../../atoms/ModalContent";
import Spinner from "../../atoms/Spinner";
import { ModalStepper } from "../../molecules/ModalStepper";
import { ModalStep } from "../../atoms/ModalStep";
import { getTypeParameter } from "../../services/common/admin";
import { AppContext } from "../../context/AppContext/AppContext";
import { DesenlacesContext } from "../../context/DesenlacesContext/DesenlacesContext";

import WarningIcon from "../../assets/imgs/icons/warningIcon.svg";
import styles from "./EditEvents.module.scss";

const validateSchema = Yup.object({
  reason: Yup.string()
    .min(1, "Debe seleccionar una opción")
    .required("este campo es requerido"),
});

export const EditEvents = ({ onClose, id_plan }) => {
  const { setReasonEdit } = useContext(AppContext);
  const {
    setEditedFinishScale,
  } = useContext(DesenlacesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [reasons, setReasons] = useState();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getReasons = async () => {
      const res = await getTypeParameter(12);
      setReasons(res.data);
      setIsLoading(false);
    };
    getReasons();
  }, []);

  return (
    <ModalStepper
      onClose={onClose}
      initialValues={{
        reason: "",
      }}
      onSubmit={async (values) => {
        await setEditedFinishScale(true);
        await setReasonEdit(values.values.reason);
        navigate(
          `/desenlaces/pacientes/diligenciamiento/${params.idPatient}/${id_plan}`
        );
        onClose();
      }}
      showCancelButton={true}
    >
      <ModalStep>
        <ModalContent
          close={onClose}
          text={`Estás a punto de editar una escala\ndiligenciada`}
          helpText="¿Deseas continuar?"
          icon={WarningIcon}
          btns={false}
          alt="Icono editar escala"
        />
      </ModalStep>
      <ModalStep validationSchema={validateSchema}>
        {isLoading ? (
          <Spinner />
        ) : (
          <section className={styles.OptionsContainer}>
            <h2 className={styles.updateTitle}>Observaciones</h2>
            <div className={styles.inputContainer}>
              <div className={styles.inputAndError}>
                <Field
                  className={styles.loginInputs}
                  as="select"
                  id="reason"
                  name="reason"
                >
                  <option value="" className={styles.documentOptions}>
                    Elige el motivo por el cual editarás la escala
                  </option>
                  {reasons?.map((option) => (
                    <option
                      value={option.id}
                      className={styles.documentOptions}
                    >
                      {option.value}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </section>
        )}
      </ModalStep>
    </ModalStepper>
  );
};
