import { useState, useContext } from "react";

import withAuth  from '../../HOC/withAuth';
import Modal from "../../atoms/Modal";
import Spinner from "../../atoms/Spinner";
import ButtonOption from "../../atoms/ButtonOption";
import ModalContent from "../../atoms/ModalContent";
import RolesAdminTable from "../../atoms/RolesAdminTable";
import EditNewUserForm from "../../atoms/EditNewUserForm";
import ToolboxTable from "../../atoms/ToolboxTable";
import ModuleTemplate from "../../templates/ModuleTemplate";
import { getItem } from "../../services/localStorage";
import { deleteRol,  rolState } from "../../services/roles";
import { AppContext } from "../../context/AppContext/AppContext";
import { RolesContext } from "../../context/RolesContext/RolesContext";
import useSelect from "../../hooks/useSelect";
import { AddNewRol } from "../../molecules/AddNewRol";
import { EditRol } from "../../molecules/EditRol";
import addIcon from "../../assets/imgs/icons/addIcon.svg";
import redFlag from "../../assets/imgs/icons/DeleteIcon.svg";
import personRed from "../../assets/imgs/icons/delete_rol.svg";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import check from "../../assets/imgs/icons/check.svg";
import xIcon from "../../assets/imgs/icons/xIcon.svg";
import styles from "./RolesAdmin.module.scss";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { offsetUser } from "../../custom/offset";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

function RolesAdmin() {
  const [openCreateRol, setOpenCreateRol] = useState(false);
  const [openCreateNotification, setOpenCreateNotification] = useState(false);
  const [newRolRender, setNewRolRender] = useState(false);
  const [error400, setError400] = useState();
  const [openEditRol, setOpenEditRol] = useState(false);
  const [editRol, setEditRol] = useState({});
  const [status, setStatus] = useState();
  const [renderControl, setRenderControl] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openTrueModal, setOpenTrueModal] = useState(false);
  const [openFalseModal, setOpenFalseModal] = useState(false);
  const [statusDelete, setStatusDelete] = useState(null);
  const [statusActive, setStatusActive] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState();

  const { user } = useContext(AppContext);
  const {setRolesList,setCustomePagination, actualPage, createStatus, editStatus, sort_roles, name_roles, module_roles,updateBy_roles } = useContext(RolesContext);

  const actualPage_1 = actualPage;

  const { height, width } = useWindowDimensions();

  const handleGetRolesList = async () => {
    let params = {
      page: actualPage,
      offset: offsetUser(height, width),
      sort: sort_roles,
      name: name_roles,
      module: module_roles,
      updated_by: updateBy_roles
    }
    const route = Object.entries(params).reduce(
      (prev, curr) => prev + (curr[1] ? `&${curr[0]}=${curr[1]}` : ""),
      ""
    );
    const res = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/rols?${route}`,});
      const lastPage = res.pagination.last_page;
      const actualPage_1 = res.pagination.page;
      setRolesList(res.data);
      setCustomePagination({ page: actualPage_1, paginationTotal: lastPage });
      setIsLoading(false);
  };
    // *--- Select ---*
    const [childDataState, setChildDataState] = useState();
    function childData(e) {
      setChildDataState(e)
    }
    const propertyNameMap = {
      id_rol: 'id',
      name: 'name',
      status: 'active',
  };
  const selectOptions = useSelect(childDataState, propertyNameMap);
    // *--- Select ---*

  const handleDelete = async (user) => {
    setIsLoading(true);
    setAmount(selectOptions.length);
    const schemaCreate = {
      roles_ids: selectOptions
      .filter((datos) => datos?.id_rol)
      .map((datos) => datos?.id_rol || ""),
    };
    const data = await deleteRol(schemaCreate, user);
    setIsLoading(false);
    setStatusDelete(data.status);
    setNewRolRender(!newRolRender);
  };

  const handleStatusTrue = async (status, user) => {
    if (user) {
      // setIsLoading(true);
      const id = user.id;
      const newStatus = {
        roles_ids: [id],
      };
      const activate = status
      const data = await rolState(newStatus, getItem("user").email, activate);
      if (data.status === 200) {
        setStatus(data.status);
        await handleGetRolesList();
        // setIsLoading(false);
        setOpenCreateNotification(true);
      } else if (data.status === 400) {
        setStatus(data.status);
        setError400(data.data.data.response[0].message);
        setOpenCreateNotification(true);
      } else {
        alert("Error al actualizar el estado del rol");
      }
    } else {
      setIsLoading(true);
      setAmount(selectOptions.length);
      const activate = status
      const newStatus = {
        roles_ids: selectOptions
        .filter((datos) => datos?.id_rol)
        .map((datos) => datos?.id_rol || ""),
      };
      const data = await rolState(newStatus, getItem("user").email, activate);
      setIsLoading(false);
      setStatusActive(data.status);
      setNewRolRender(!newRolRender);
    }

  };

  const handleOpenDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setStatusDelete(null);
    setOpenDeleteModal(false);
    setAmount(null)
  };

  const handleOpenTrue = () => {
    setOpenTrueModal(true);
  };

  const handleCloseTrue = () => {
    setStatusActive(null);
    setOpenTrueModal(false);
    setAmount(null)
  };

  const handleOpenFalse = () => {
    setOpenFalseModal(true);
  };

  const handleCloseFalse = () => {
    setStatusActive(null);
    setOpenFalseModal(false);
    setAmount(null)
  };

  const handleOpenCreateRol = () => {
    setOpenCreateRol(true)
  };

  const handleCloseCreateRol = () => {
    setOpenCreateRol(false);
  };

  const handleOpenEditRol = (user) => {
    setEditRol(user);
    setOpenEditRol(true);
  };

  const handleCloseEditRol = () => {
    setOpenEditRol(false);
  };

  const handleCloseCreateRolNotification = () => {
    setOpenCreateNotification(false);
    setError400(null);
    status === 400 && setRenderControl(!renderControl);
    setStatus(null);
  };

  return (
    <>
      <ModuleTemplate>
        <div className={styles.options} name="options">
          <ButtonOption
            icon={addIcon}
            label="Nuevo"
            onClick={handleOpenCreateRol}
          />
        </div>
        {selectOptions?.length > 0 &&
          <ToolboxTable name="Tollbox" >
            <ButtonOption
              icon={trashIcon}
              label="Borrar"
              onClick={handleOpenDelete}
            />
            <ButtonOption
              icon={check}
              label="Activar"
              onClick={handleOpenTrue}
            />
            <ButtonOption
              icon={xIcon}
              label="Desactivar"
              onClick={handleOpenFalse}
            />
          </ToolboxTable>
        }
        <RolesAdminTable
          name="Table"
          newRolRender={newRolRender}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          renderControl={renderControl}
          handleEditStatus={handleStatusTrue}
          childData={childData}
          handleSelected={handleOpenEditRol}
        />
      </ModuleTemplate>

      <Modal
        isOpen={openCreateNotification}
        onClose={handleCloseCreateRolNotification}
        variant="large"
      >
        <ModalContent
          close={handleCloseCreateRolNotification}
          text={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? "Rol guardado\ncon éxito"
              : createStatus === 400
              ? error400
              : status === 400
              ? error400
              : "No se pudo completar la acción,\ninténtalo de nuevo"
          }
          icon={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? personGreen
              : error400
              ? redFlag
              : personRed
          }
          btns={false}
          alt={
            createStatus === 201 || editStatus === 200 || status === 200 || status === 201
              ? "Icono rol guardado"
              : "Icono rol no guardado"
          }
        />
      </Modal>


      <Modal
        isOpen={
          openDeleteModal ||
          openTrueModal ||
          openFalseModal
        }
        onClose={
          openDeleteModal ? handleCloseDelete :
          openTrueModal ? handleCloseTrue :
          openFalseModal ? handleCloseFalse
          : null
        }
        variant="large"
      >
        {isLoading ?
          <Spinner className_container="rolesForm" />
          :
            openDeleteModal ?
            <ModalContent
              close={handleCloseDelete}
              confirm={() => handleDelete(user.email)}
              text={
                statusDelete === 200
                  ? `${amount > 1 ? 'Roles borrados' : 'Rol borrado'}`
                  : statusDelete === null
                  ? `Estás a punto de borrar\n${selectOptions.length} ${selectOptions.length > 1 ? 'roles' : 'rol'}`
                  : "No es posible borrar"
              }
              updateTitleCustom={styles.updateTitleCustom}
              helpText={statusDelete === null && "¿Deseas continuar?"}
              helpTextCustom={styles.helpTextCustom}
              btns={statusDelete === null && true}
              btnsContainerCustom={styles.btnsContainerCustom}
              cancelButtonCustom={styles.cancelButtonCustom}
              nextButtonCustom={styles.nextButtonCustom}
              icon={
                statusDelete === 200 || statusDelete === null
                  ? personGreen
                  : redFlag
              }
              alt="Icono de roles"
            />
            :
            openTrueModal ?
            <ModalContent
            close={handleCloseTrue}
            confirm={() => handleStatusTrue(true)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Roles activados' : 'rol activado'}`
                : statusActive === null
                ? `Estás a punto de activar\n${selectOptions.length} ${selectOptions.length > 1 ? 'roles' : 'rol'}`
                : "No es posible activar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de rol"
          />
          :
            openFalseModal ?
            <ModalContent
            close={handleCloseFalse}
            confirm={() => handleStatusTrue(false)}
            text={
              statusActive === 200
                ? `${amount > 1 ? 'Roles desactivados' : 'Rol desactivado'}`
                : statusActive === null
                ? `Estás a punto de desactivar\n${selectOptions.length} ${selectOptions.length > 1 ? 'roles' : 'rol'}`
                : "No es posible desactivar"
            }
            updateTitleCustom={styles.updateTitleCustom}
            helpText={statusActive === null && "¿Deseas continuar?"}
            helpTextCustom={styles.helpTextCustom}
            btns={statusActive === null && true}
            btnsContainerCustom={styles.btnsContainerCustom}
            cancelButtonCustom={styles.cancelButtonCustom}
            nextButtonCustom={styles.nextButtonCustom}
            icon={
              statusActive === 200 || statusActive === null
                ? personGreen
                : redFlag
            }
            alt="Icono de rol"
          />
          :
          openEditRol ?
          <EditNewUserForm
            actualPage_1={actualPage_1}
            user={editRol}
            handleClose={handleCloseEditRol}
            setOpen={setOpenCreateNotification}
            newUserRender={newRolRender}
            setNewUserRender={setNewRolRender}
          />
          : null
        }
      </Modal>

      <Modal
        isOpen={
          openCreateRol ||
          openEditRol
        }
        onClose={
          openCreateRol ? handleCloseCreateRol :
          openEditRol ? handleCloseEditRol :
          null
        }
        variant="large"
      >
        {isLoading ?
          <Spinner className_container="rolesForm" />
          :
          openCreateRol ?
        <AddNewRol
        edit={false}
        onClose={handleCloseCreateRol}
        setStatus={setStatus}
        setIsLoading={setIsLoading}
        setOpenCreateNotification={setOpenCreateNotification}
        setError400={setError400}
        setNewRolRender={setNewRolRender}
        newRolRender={newRolRender}
        />
        : openEditRol ?
        <EditRol
        edit={true}
        rolData={editRol}
        onClose={handleCloseEditRol}
        setStatus={setStatus}
        setIsLoading={setIsLoading}
        setOpenCreateNotification={setOpenCreateNotification}
        setError400={setError400}
        setNewRolRender={setNewRolRender}
        newRolRender={newRolRender}
        />
        : null
        }
      </Modal>
    </>
  );
}

export default withAuth(RolesAdmin, '/roles');