import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import TextInput from "../../atoms/TextInput";
import { RolModules } from "../../molecules/RolModules";
import NextButton from "../../atoms/NextButton";
import CancelButton from "../../atoms/CancelButton";
import SelectOption from "../../atoms/SelectOption";
import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import { SubmoduleList } from "../../molecules/SubmoduleList";
import { AppContext } from "../../context/AppContext/AppContext";
import { createRole, getAllRol, updateRol, deleteRol } from "../../services/roles";
import Spinner from "../../atoms/Spinner";

import personRed from "../../assets/imgs/icons/delete_rol.svg";
import personGreen from "../../assets/imgs/icons/userUpdate.svg";
import Trash from "../../assets/imgs/icons/trash_red.svg";
import styles from "./RolesForm.module.scss";

const createSchema = Yup.object({
  name: Yup.string().required("Este campo es requerido"),
});

const editSchema = Yup.object({
  rol_id: Yup.string().required("Este campo es requerido"),
});

const RolesForm = ({
  edit = false,
  setRolesSelect,
  submodule,
}) => {

  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const [roles, setRoles] = useState([]);
  const [selectValue, setSelectValue] = useState(0);
  // const [rolesSelect, setRolesSelect] = useState([]);
  const [rol, setRol] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  // const [submodule, setSubmodule] = useState([]);
  // const [submoduleDefault, setSubmoduleDefault] = useState([]);
  // const [rolesEditSelect, setRolesEditSelect] = useState([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setError] = useState(false);
  const [error400, setError400] = useState();
  

  // const initialValuesCreate = {
  //   name: "",
  //   newModules: [],
  // };
  // const initialValuesEdit = {
  //   rol_id: "",
  //   newModules: rolesEditSelect,
  // };

  const fetchData = async () => {
    const response = await getAllRol();
    setRoles(response.response);
  };

  const handleDeleteRole = () => {
    setIsOpenModal(true);
    const rolSelect = roles.find((item) => item.id === selectValue);
    setRol(rolSelect);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const response = await deleteRol(id, user.email);
    if (response.status === 200) {
      setLoading(false);
      setIsDeleted(true);
    } else if (response.status === 400) {
      setLoading(false);
      setError400(response.data.data.response[0].message);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    navigate("/usuarios");
  };

  const handleCloseErrorModal = () => {
    setIsOpenModal(false);
    setError(false);
    setError400(null)
  };

  useEffect(() => {
    edit && !roles.length && fetchData();
    
  }, [edit]);

  useEffect(() => {
    const updateSelectRoles = async () => {
      let arraySelectRoles = roles.filter((rol) => rol.id === selectValue);
      await setRolesSelect(arraySelectRoles);
    };
    updateSelectRoles();
  }, [selectValue]);

  // useEffect(() => {
  //   let rolesModule = rolesSelect?.map((roles) => roles.modules);
  //   const newModules = rolesModule[0]?.map((rol) => {
  //     return {
  //       key: rol.id,
  //       value: rol.label,
  //     };
  //   });

  //   const submodulesList = rolesModule[0]?.map((rol) => {
  //     return {
  //       id: rol.id,
  //       submodules: rol.sub_modules,
  //     };
  //   });

  //   const submodules = [];
  //   for (let sub in submodulesList) {
  //     submodules.push({
  //       [`submodules-${submodulesList[sub].id}`]: submodulesList[
  //         sub
  //       ].submodules.map((submodule) => {
  //         return {
  //           key: submodule.id,
  //           value: submodule.label,
  //         };
  //       }),
  //     });
  //   }

  //   setSubmoduleDefault(submodules);
  //   setRolesEditSelect(newModules);
  // }, [rolesSelect]);

  return (
    <>
      <Formik
        // initialValues={edit ? initialValuesEdit : initialValuesCreate}
        // onSubmit={async (values) => {
        //   const sumbodules = [];
        //   for (let answer in values) {
        //     if (
        //       answer !== "name" &&
        //       answer !== "newModules" &&
        //       answer !== "rol_id"
        //     ) {
        //       sumbodules.push(values[answer]);
        //     }
        //   }
        //   const defaultData = {
        //     name: values.name,
        //     modules: values.newModules.map((item) => item.key),
        //     sub_modules: sumbodules.flatMap((item) => {
        //       return item.map((opt) => opt.key);
        //     }),
        //     created_by: user.email,
        //   };

        //   if (!edit) {
        //     setIsOpenModal(true)
        //     setLoading(true);
        //     const data = await createRole(defaultData);
        //     if (data.status === 201) {
        //       setLoading(false);
        //       setIsSaved(true);
        //     } else if (data.status === 400) {
        //       setLoading(false);
        //       setError400(data.data.data.response[0].message)
        //     } else {
        //       console.error(data);
        //     }
        //   } else {
        //     const updateData = {
        //       modules: values.newModules.map((item) => item.key),
        //       sub_modules: sumbodules.flatMap((item) => {
        //         return item.map((opt) => opt.key);
        //       }),
        //       updated_by: user.email,
        //     };
        //     setIsOpenModal(true)
        //     setLoading(true);
        //     const data = await updateRol(updateData, values.rol_id);
        //     if (data.status === 200) {
        //       setLoading(false);
        //       setIsSaved(true);
        //     }
        //   }
        // }}
        validationSchema={edit ? editSchema : createSchema}
      >
        {({ values }) => (
          <Form className={styles.form}>
            {/* {values.rol_id !== "" && setSelectValue(parseInt(values.rol_id))} */}
            <div className={styles.loginForm}>
              <div className={styles.topContainer}>
                <div className={styles.nameContainer}>
                  {/* {edit ? (
                    <SelectOption label="Nombre" name="rol_id" defaultValue="">
                      <option value="" disabled selected>
                        Seleccionar
                      </option>
                      {roles.map((rol) => (
                        <option key={rol.id} value={rol.id}>
                          {rol.name}
                        </option>
                      ))}
                    </SelectOption>
                  ) : (
                    <TextInput
                      label="Nombre"
                      name="name"
                      type="text"
                      className={styles.loginLabel}
                      placeholder="Administrador"
                    />
                  )} */}
                </div>
                {edit && selectValue !== 0 && (
                  <button
                    type="button"
                    className={styles.deleteButton}
                    onClick={handleDeleteRole}
                  >
                    <img src={Trash} alt="icono Borrar" />
                    Eliminar rol
                  </button>
                )}
              </div>
              <p className={styles.loginLabel}>Módulos</p>
              {/* <RolModules
                name="newModules"
                defaultValue={rolesEditSelect}
                setSubmodule={setSubmodule}
                submodule={submodule}
              /> */}

              <p className={styles.loginLabel}>Submódulos</p>
              {/* {submodule &&
                submodule?.map((sub) => (
                  <SubmoduleList
                    key={sub.key}
                    item={sub}
                    name={`submodules-${sub.key}`}
                    defaultValue={submoduleDefault}
                  />
                ))} */}
            </div>
            <div className={styles.btnContainer}>
              <CancelButton
                text="Cancelar"
                type="button"
                onClick={() => setOpenCancelModal(true)}
              />
              <NextButton type="submit" text="Guardar" />
            </div>
            <Modal
              isOpen={isOpenModal}
              onClose={isDeleted ? handleCloseModal : handleCloseErrorModal}
              variant="confirm"
            >
              {
                loading ?
                <Spinner className_container="rolesForm" /> :
                <ModalContent 
                  close={handleCloseErrorModal} 
                  confirm={() => handleDelete(rol.id)} 
                  text={
                    isDeleted ? 'Rol eliminado\ncon éxito' : 
                    isSaved ? 'Rol guardado\ncon éxito' :  
                    error400 ? error400 :
                    isError ? 'No se pudo eliminar el rol,\ninténtalo de nuevo' :
                    `Estás a punto de\neliminar un rol`
                  }
                  helpText={isDeleted || isError || error400 || isSaved ? null : "¿Deseas continuar?"}
                  btns={isDeleted || isError || error400 || isSaved ? false : true}
                  icon={isDeleted || isSaved ? personGreen : personRed}
                  alt="Icono eliminación de rol"
                />                
              }
            </Modal>
            <Modal
              isOpen={openCancelModal}
              onClose={() => setOpenCancelModal(false)}
              variant="confirm"
            >
              <ModalContent 
                close={setOpenCancelModal} 
                confirm={handleCloseModal} 
                text={"Deseas salir\nsin guardar?"}
                icon={personRed}
                alt="Icono salida de creación o edición de roles"
              />
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RolesForm;
