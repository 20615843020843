import { apiInterceptor } from "../interceptors/jwt.interceptor";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_DESENLACES;
export async function getTypeParameter(id) {
  const response = await apiInterceptor({
    endpoint: `/tr/parameter/${id}`,
    method: "GET",
  });
 
return response
}

export async function getInfoPatient(data) {
  try {
    const request = await axios.post(
      `${API_URL}/patients/info`,
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return request;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export const sendForm = async (data) => {
  try {
   const result = await axios.post(
    `${API_URL}/tr/help/sendForm`,      
      data,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
