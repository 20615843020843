import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import withAuth from "../../HOC/withAuth";
import Spinner from "../../atoms/Spinner";
import { AdminStepper } from "../../molecules/AdminStepper";
import { AppContext } from "../../context/AppContext/AppContext";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import { getLineByModules } from "../../services/servicesLine";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

const CreateScale = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { modules, modulesMixer } = useContext(AppContext);
  const { serviceLine, setServiceLine } = useContext(AdminContext);
  const {
    editScale,
    setEditScale,
    frecuencyOptions,
    setHasPlans,
    setIsEditable,
    setIsEditScale,
    setOrderQuestions,
    setHasResponses,
  } = useContext(ScalesContext);
  const image = editScale?.image ? editScale?.image : null;
  const [initialValues, setInitialValues] = useState({
    name: editScale?.name ? editScale.name : "",
    description: editScale?.description ? editScale?.description : "",
    time_complete: editScale?.time_complete ? editScale?.time_complete : "",
    age_range: editScale?.age_range ? editScale?.age_range : "",
    bibliografy: editScale?.bibliografy ? editScale?.bibliografy : "",
    service_lines: [],
    questions: [],
    sections: [],
    image: image,
    modules: [],
    send_to_patient: "",
    originDate: "",
    isDesenlacesSelect: false,
    isContigoSelect: false,
    threshold: "",
    schema: [],
  });
  const [loading, setLoading] = useState(true);

  const questionSort = (a, b) => {
    return a.order - b.order;
  };

  const getListLines = async (ids) => {
    const response = await getLineByModules(ids);
    const list = response.response;
    const newList = [];
    for (let opt of list) {
      newList.push(opt.serviceLines);
    }
    const options = newList.flat(1);
    const lineOptions = options.map((option) => ({
      key: option.id,
      value: option.name,
    }));

    setServiceLine(lineOptions);
  };

  const getInitialData = async (id) => {
    const response = await apiInterceptor({
      method: "GET",
      endpoint: `/tr/admin/forms/${id}`,
    });

    if (response?.data?.[0]) {
      await setEditScale(response.data?.[0]);
    } else if (response?.statusCode === 400) {
      navigate("/error/404");
    }
  };

  const fetchData = async () => {
    const initialModules = [];

    const modulesSelect = editScale?.modules;

    for (let opt of modulesSelect) {
      const optionModule = modules.find((mod) => mod.id === opt);
      initialModules.push({
        key: optionModule.id,
        value: optionModule.label,
      });
    }

    const initialLineServices = [];

    const linesSelect = editScale?.service_lines;
    for (let service of linesSelect) {
      const optionLine = serviceLine.find((s) => s.key === service.id);
      const frecuencyIncome = service?.frequency_days?.ingress_date || [];
      const frecuencyEgress = service?.frequency_days?.egress_date || [];

      // const arrayFrecuency = [];
      const arrayIncomeFrecuency = [];
      const arrayEgressFrecuency = [];

      for (let opt in frecuencyIncome) {
        const findOpt = frecuencyOptions.find(
          (item) => item.key === frecuencyIncome[opt]
        );
        if (findOpt) {
          arrayIncomeFrecuency.push(findOpt);
        } else {
          arrayIncomeFrecuency.push({
            value: `${frecuencyIncome[opt]} días`,
            key: frecuencyIncome[opt],
          });
        }
      }

      for (let opt in frecuencyEgress) {
        const findOpt = frecuencyOptions.find(
          (item) => item.key === frecuencyEgress[opt]
        );
        if (findOpt) {
          arrayEgressFrecuency.push(findOpt);
        } else {
          arrayEgressFrecuency.push({
            value: `${frecuencyEgress[opt]} días`,
            key: frecuencyEgress[opt],
          });
        }
      }

      await initialLineServices.push({
        line: optionLine,
        frequency_days: {
          egress_frecuency: arrayEgressFrecuency ? arrayEgressFrecuency : [],
          income_frecuency: arrayIncomeFrecuency ? arrayIncomeFrecuency: [],
        },
      });
    }

    await editScale.schema.sort(questionSort);

    await setInitialValues({
      active: editScale?.active,
      created_by: editScale?.created_by,
      has_plans: editScale?.has_plans,
      has_responses: editScale?.has_responses,
      name: editScale?.name ? editScale.name : "",
      description: editScale?.description ? editScale?.description : "",
      time_complete: editScale?.time_complete ? editScale?.time_complete : "",
      age_range: editScale?.age_range ? editScale?.age_range : "",
      bibliografy: editScale?.bibliografy ? editScale?.bibliografy : "",
      service_lines: initialLineServices ? initialLineServices : [],
      image: image,
      modules: initialModules,
      send_to_patient: editScale?.send_to_patient,
      threshold: editScale?.threshold ? editScale?.threshold : "",
      isDesenlacesSelect: false,
      isContigoSelect: false,
      schema: editScale?.schema ? editScale?.schema : [],
    });

    setLoading(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (params.idScale) {
        await getListLines("100, 101");
        getInitialData(params.idScale);
      } else {
        setLoading(false);
      }
    };
    getData();

    return () => {
      setEditScale({});
      setOrderQuestions(1);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const changeRoute = async () => {
        await modulesMixer(modules, "/formularios");
      };
      changeRoute();
    }, 100);
  }, []);
  useEffect(() => {
    if (Object.keys(editScale).length) {
      fetchData();
    }
  }, [editScale]);

  useEffect(() => {
    const editable = !editScale?.active;
    const hasResponses = editScale?.has_responses;
    const plan = editScale?.has_plans ? editScale?.has_plans : false;
    setHasPlans(plan);
    setIsEditable(editable);
    setHasResponses(hasResponses);
    if (Object.keys(editScale).length) {
      setIsEditScale(true);
    } else {
      setIsEditScale(false);
    }
  }, [editScale]);

  return (
    <>
      {loading ? (
        <Spinner className_container="rolesForm" />
      ) : (
        <AdminStepper initialValues={initialValues} />
      )}
    </>
  );
};

export default withAuth(CreateScale, "/formularios");
