import styles from "./Eq5dInfo.module.scss";
import logo from "../../assets/imgs/eq5dLogo.png";
import { useLocation } from "react-router-dom";

export default function Eq5dInfo(props) {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/scale" ? (
        <>
          <p className={styles.scaleDescriptionPatient}>
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol
            Research Foundation
          </p>
          <div className={styles.eq5dContainer}>
            <img src={logo} className={styles.eq5dLogoPatient} alt="logo Eq5d" />
          </div>
        </>
      ) : (
        <div className={styles.descriptionContainer}>
          <p className={styles.scaleDescription}>
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol
            Research Foundation
          </p>
          <div className={styles.eq5dContainer}>
            <img src={logo} className={styles.eq5dLogo} alt="logo Eq5d" />
            <p className={styles.eq5dCol}>
              Colombia (Spanish)
              <br />
              Cuestionario de salud
              <br />
              Versión en español para Colombia
              <br />
              (Spanish version for Colombia)
            </p>
          </div>
        </div>
      )}
    </>
  );
}
