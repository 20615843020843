import { useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/logoFsfb.png"
import useWindowDimensions from "../../hooks/useWindowDimensions";

import CenterMessage from "../../atoms/CenterMessage";
import NextButton from "../../atoms/NextButton";
import FooterDescription from "../../molecules/FooterDescription";
import ProfileHeaderWhite from '../../molecules/ProfileHeaderWhite';
import heartIcon from '../../assets/imgs/icons/heart.svg';
import styles from "./PatientsNoLogin.module.scss";

export default function PatientsNoLogin() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <>
      <main className={styles.container}>
      <ProfileHeaderWhite />
        {width <= 480 ?
          <>
            <img src={logo} alt="Logo FSFB" className={styles.logoFsfb}></img>
            <img src={logo} alt="Logo FSFB" className={styles.logoFsfb_2}></img>
          </>
          :
          null
        }
        <section className={styles.subContainer}>
            <img src={heartIcon} alt="icono Corazón" />
          <CenterMessage
            otpClass="txtOtp"
            title2="Queremos brindarte"
            subtitle='la mejor atención, un profesional de la salud se pondrá en contacto'
            text1="contigo para continuar con el proceso"
          />
          <div className={styles.containerButton}>
            <NextButton
              height="noLogin"
              text="Aceptar"
              type="button"
              onClick={() => navigate("/")}
            />
          </div>
        </section>
      <FooterDescription />
      </main>
    </>
  );
}