import { useState, useEffect } from "react";

import TextArea from "../../atoms/TextArea";
import styles from "./ManagerScaleOptions.module.scss";
import { QuestionComponentMap } from "./QuestionMap";

const ManagerScaleOptions = ({
  answersLines,
  scale,
  question,
  edited,
  section,
}) => {
  const [showRemark, setShowRemark] = useState(false);

  const options = question.options
    ? question.options.map((option) => {
        return {
          value: option.id,
          label: option.detail,
        };
      })
    : null;

  useEffect(() => {
    if (question.remark) {
      setShowRemark(true);
    }
  }, [question?.remark]);

  const QuestionComponent = QuestionComponentMap[question.type];

  return (
    <div
      className={`${styles.managerScalecontainer} ${
        section && styles.withSection
      }`}
      key={question.id}
      id={question.id}
    >
      <div className={styles.managerAnswer}>
        {QuestionComponent({
          question,
          options,
          scale,
          edited,
          answersLines,
        })}
      </div>
      <p
        className={styles.titleRemark}
        onClick={() => setShowRemark(!showRemark)}
      >
        Observaciones
      </p>
      {showRemark ? (
        <div className={styles.containerRemark}>
          <TextArea
            name={`remark-${question.id}`}
            disabled={scale?.form?.form_answer?.isFinished  && !edited}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ManagerScaleOptions;
