import React from "react";
import { useLocation } from "react-router-dom";

import Thermometer from "../../atoms/Thermometer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import styles from "./ThermometerCard.module.scss";

export default function ThermometerCard({ scale, name, disabled }) {
  const location = useLocation();
  const { width } = useWindowDimensions();

  return (
    <div
      className={
        location.pathname === "/scale"
          ? styles.thermoCardPat
          : styles.thermoCard
      }
    >
      {width <= 480 && (
        <p className={styles.labelMobile}>
          Por favor toque en la escala para indicar cuál es su estado de salud
          HOY
        </p>
      )}
      <ul
        className={
          location.pathname === "/scale" ? styles.uListPat : styles.uList
        }
      >
        <li>Nos gustaría saber lo buena o mala que es su salud HOY.</li>
        <li>
          La escala está numerada de 0 a 100.
          <br />
          100 representa la <u>mejor</u> salud que se pueda imaginar.
          <br />0 representa la <u>peor</u> salud que se pueda imaginar.
        </li>
        <li>
          Por favor, haga clic en la escala para indicar cuál es su
          <br />
          estado de salud HOY.
        </li>
      </ul>
      <div>
        <Thermometer scale={scale} name={name} disabled={disabled} />
      </div>
    </div>
  );
}
