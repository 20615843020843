async function aesKey(keyAscii, ivAscii) {
  const encoder = new TextEncoder("ascii");
  const keyBytes = encoder.encode(keyAscii);
  const iv = encoder.encode(ivAscii);
  const key = await window.crypto.subtle.importKey(
    "raw",
    keyBytes,
    {
      name: "AES-CBC"
    },
    false,
    ["encrypt", "decrypt"]
  );
  return { key, iv };
}

export const patientScaleDecryptor = async (strings) => {
  const newStrings = decodeURIComponent(strings);
  const utf8 = new TextDecoder("utf8");
  let ivString = newStrings.substr(0, 16);
  let encodedString = newStrings.substr(16);
  const { key, iv } = await aesKey(process.env.REACT_APP_AES_KEY, ivString)
  const ascii = atob(encodedString);
  const asciiBytes = new Uint8Array(ascii.split("").map(c => c.charCodeAt(0)));
  const decrypted = await window.crypto.subtle.decrypt(
    { name: "AES-CBC", iv },
    key,
    asciiBytes,
  );
  const buff = new Uint8Array(decrypted);
  const decoded = utf8.decode(buff);
  return decoded;
};
