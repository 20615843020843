import { useState } from "react";

import CardFSFB from "../../atoms/Card";
import Modal from "../../atoms/Modal";
import { Button } from "../../atoms/Button";
import { EditAlert } from "../../molecules/EditAlert";
import { DeleteAlert } from "../../molecules/DeleteAlert";
import { AlertForm } from "../../molecules/AlertForm";
import { AlertHistory} from "../../molecules/AlertHistory";
import editIcon from "../../assets/imgs/icons/editIcon.svg";
import trashIcon from "../../assets/imgs/icons/trashIcon.svg";
import arrow from "../../assets/imgs/icons/arrowCircleGreen.svg";
import styles from "./AlertCard.module.scss";

export const AlertCard = ({ data, key, updateData}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [deleteAlert, setDeteleAlert] = useState(false);

  const handleUpdate = async() => {
    await updateData();
    setShowDetail(false);
  }

  return (
    <CardFSFB>
      {showDetail ? (
        <section className={styles.buttonOptions}>
          <img
            src={trashIcon}
            alt="icono borrar"
            className={styles.buttonIcon}
            onClick={() => setDeteleAlert(true)}
          />

          <img
            src={editIcon}
            alt="icono editar"
            className={styles.buttonIcon}
            onClick={() => setEdit(true)}
          />
          <img
            src={arrow}
            alt="icono cerrar detalle"
            className={styles.buttonIcon}
            onClick={() => {
              setShowDetail(false)
              setEdit(false)
              setIsEdited(false)
            }}
          />
        </section>
      ) : null}

      <section className={styles.questionsOption} key={key}>
        <div className={styles.dataContainer}>
          <h6>Pregunta</h6>
          <p>{data.answerQuestions.question}</p>
        </div>
        <div className={styles.dataContainer}>
          <h6>Respuesta</h6>
          <p>{data.answerQuestions.answer}</p>
        </div>
        {!showDetail && (
          <div className={styles.optionContainer}>
            {data.trace.status ? (
              <Button
                variant="contained"
                color="secondary"
                disabled={false}
                onClick={() => setShowDetail(true)}
              >
                Ver detalle
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                onClick={() => {
                  setIsEdited(true);
                  setShowDetail(true);
                }}
              >
                Iniciar seguimiento
              </Button>
            )}
          </div>
        )}
      </section>

      {showDetail && (
        <>
        <AlertForm edit={isEdited} data={data} setEdit={setIsEdited} updateData={updateData} />
        <AlertHistory data={data}/>
        </>
      )}

      <Modal isOpen={edit} onClose={() => setEdit(false)} variant="large">
        <EditAlert onClose={() => setEdit(false)} setEdit={setIsEdited} />
      </Modal>

      <Modal isOpen={deleteAlert} onClose={()=>setDeteleAlert(false)}>
        <DeleteAlert 
          onClose={()=>{
            setDeteleAlert(false)
          }}
          data={data}
          updateData={handleUpdate}
        />
      </Modal>  
    </CardFSFB>
  );
};
