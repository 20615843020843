import { useContext } from "react";

import Input from "../../atoms/Input";
import ActionButton from "../../atoms/ActionButton";
import { ScalesContext } from "../../context/ScalesContext/ScalesContext";
import DeleteIcon from "../../assets/imgs/icons/deleteWhite.svg";
import Arrow from "../../assets/imgs/icons/arrowUpCircleWhite.svg";
import styles from "./newSectionOption.module.scss";

const NewSectionOption = ({ ...props }) => {
  const {
    deleteOption,
    id,
    name,
    setIsAccordeonOpen,
    isAccordeonOpen,
    
  } = props;
  const { isEditable, hasPlans } = useContext(ScalesContext);
  return (
    <div className={styles.newSectionContainer}>
      <div className={styles.inputContainer}>
        <Input
          name={name}
          type="text"
          disabled={!isEditable | hasPlans}
          placeholder="Nueva sección"
          variant="white"
        />
      </div>
      <div className={styles.newSectionActionButtons}>
        <ActionButton
          icon={DeleteIcon}
          onClick={() => deleteOption(id )}
          disabled={!isEditable | hasPlans}
        />
        <ActionButton
          icon={Arrow}
          onClick={() => setIsAccordeonOpen(!isAccordeonOpen)}
          disabled={!isEditable | hasPlans}
        />
      </div>
    </div>
  );
};

export default NewSectionOption;
