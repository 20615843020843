import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/img_logo_fsfb.png";
import logoFsfb from "../../assets/imgs/logoFsfb.png";
import Banner from "../../atoms/Banner";
import { AppContext } from "../../context/AppContext/AppContext";
import { getQueryString } from "../../custom/getQueryString";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getPlanStatus } from "../../services/getTracing";
import { sendScoope } from "../../services/patientPlan";
import { setItem } from "../../services/localStorage";
import { patientScaleDecryptor } from "../../services/patientScaleDecryptor";
import { apiInterceptor } from "../../services/interceptors/jwt.interceptor";

import styles from "./Splash.module.scss";

export default function Splash(props) {
  const { setUserPlan, setPatientDocument } = useContext(AppContext);
  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();

  function launcher(plan) {
    setTimeout(async () => {
      if (location.pathname === "/managerlogin") {
        props.setControl(false);
      } else {
        try {
          if (plan) {
            const planStatus = await getPlanStatus(plan);
            if (planStatus?.response?.[0]?.isFinished) {
              navigate("/finaldiligence");
              return;
            }
          }
        } catch (error) {
          console.error(error);
        }
        navigate("/onboarding");
      }
    }, 500);
  }

  async function scoope(encode) {
    await sendScoope(encode);
  }

  useEffect(() => {
    localStorage.clear();
    const query = getQueryString();
    const plan = query.get("plan") || "";
    const encrypted = query.get("encrypted");

    if (encrypted) {
      patientScaleDecryptor(encrypted)
        .then((readed) => {
          const { plan, document } = JSON.parse(readed);
          setItem("plan", plan);
          setItem("document", document);
          setUserPlan(plan);
          setPatientDocument(document);
          launcher(plan);
          scoope(encrypted);
        })
        .catch(console.error);
    } else {
      setItem("plan", plan);
      setUserPlan(plan);
      launcher(plan);
    }
  }, []);

  return (
    <>
      <div className={styles.splashContainer}>
        <img src={logo} alt="Logo FSFB" />
        <h1 className={styles.label}>Más para vivir</h1>
      </div>
      {width > 480 ? <Banner /> : null}
      {width <= 480 ? (
        <img src={logoFsfb} alt="Logo FSFB" className={styles.logoFsfb}></img>
      ) : null}
    </>
  );
}
