import Modal from "../../atoms/Modal";
import ModalContent from "../../atoms/ModalContent";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/imgs/AvatarWelcome.png";
import heartWelcome from "../../assets/imgs/icons/heartRed.svg";
import Banner from "../../atoms/Banner";
import CancelButton from "../../atoms/CancelButton";
import CenterMessage from "../../atoms/CenterMessage";
import NextButton from "../../atoms/NextButton";
import { AppContext } from "../../context/AppContext/AppContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ProfileHeader from "../../molecules/ProfileHeader";
import ProfileHeaderWhite from "../../molecules/ProfileHeaderWhite";
import { getplan } from "../../services/patientPlan";
import styles from "./WelcomeView.module.scss";

export default function WelcomeView() {
  const { userPlan, plan, setPlan } = useContext(AppContext);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const handleNext = () => {
    if (Object.keys(plan)) {
      navigate("/scale");
    }
  };

  const getData = async () => {
    const response = await getplan(userPlan);
    if (response.statusCode === 200) {
      setPlan(response.data[0]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {width <= 480 ? <ProfileHeaderWhite /> : <ProfileHeader />}
      <main className={styles.container}>
        <section className={styles.welcomeImageContainer}>
          <img
            src={avatar}
            alt="AvatarWelcome"
            className={styles.welcomeImage}
          />
        </section>

        <div className={styles.message}>
          <CenterMessage
            title="Hola,  "
            user={plan?.patient?.name}
            text1="Queremos que tu recuperación avance pronto,"
            text2={`realiza la Escala ${plan?.form?.name}`}
          />
          {width > 480 && plan?.form?.description && (
            <>
              <h2 className={styles.labelDescription}>
                Descripción de la escala:
              </h2>
              <p className={styles.ScaleDescription}>
                {plan?.form?.description}
              </p>
            </>
          )}
        </div>
        <div className={styles.approximateTime}>
          <p>Tiempo aproximado: {plan?.form?.time_complete} minutos</p>
        </div>
        <section className={styles.containerButtons}>
          <CancelButton
            text="No deseo realizarla"
            onClick={() => setOpen(true)}
            type="button"
          />
          <NextButton text="Continuar" onClick={handleNext} type="button" />
        </section>
        <Banner />
      </main>

      <Modal isOpen={open} onClose={() => setOpen(false)} variant="confirm">
        <ModalContent
          close={() => setOpen(false)}
          confirm={() => navigate(`/login`)}
          btnsContainerCustom={styles.btnsContainerCustom}
          text={`¡Nos preocupamos\npor ti!`}
          updateTitleCustom={styles.updateTitleCustom}
          helpText={`¿Realmente quieres\ncancelar el proceso?`}
          btns={true}
          cancelButtonCustom={styles.cancelButtonCustom}
          nextButtonCustom={styles.nextButtonCustom}
          icon={heartWelcome}
          alt="Icono cancelación"
        />
      </Modal>
    </>
  );
}
