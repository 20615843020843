import ButtonOption from "../../atoms/ButtonOption";
import styles from "./ListOptionsButtons.module.scss";

import { options } from './Options';

const ListOptionButtons = ({ handleSelectOption}) => {
  return (
    <section className={styles.questionsContainerOption}>
      <p className={styles.addQuestion}>Agregar pregunta</p>

      <div className={styles.listOptionsButtons}>
        {options.map((item, idx) => (
          <ButtonOption
            icon={item.icon}
            label={item.label}
            key={idx}
            onClick={() => handleSelectOption(item.value)}
            className={item.className}
          />
        ))}
      </div>
    </section>
  );
};

export default ListOptionButtons;
