import React from "react";

import { HelpTemplate } from "../../templates/HelpTemplate";
import heartIcon from "../../assets/imgs/icons/heart.svg";
import styles from "./HelpNotification.module.scss";

export const HelpNotification = () => {
  return (
    <HelpTemplate>
      <div className={styles.notification}>
        <img src={heartIcon} alt="icono corazón" className={styles.iconHeart}/>
        <h1 className={styles.notificationTitle}>Queremos brindarte</h1>
        <p className={styles.notificationText}>
          la mejor atención, un profesional de la salud se pondrá en contacto
          contigo para continuar con el proceso, ya puedes cerrar esta ventana
        </p>
      </div>
    </HelpTemplate>
  );
};
