import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

import styles from "./ProgressBar.module.scss";

export default function ProgressBar({ value }) {
  return (
    <div className={styles.progressBarContainer}>
      <LinearProgress
        variant="determinate"
        value={value}
        className={styles.progressBar}
      />
    </div>
  );
}
