import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";

import { ModalFormStepper } from "../../molecules/ModalFormStepper";
import { ModalStep } from "../../atoms/ModalStep";
import styles from "./EditLine.module.scss";

import { AppContext } from "../../context/AppContext/AppContext";
import { UsersContext } from "../../context/UsersContext/UsersContext";
import {
  createLine,
  updateLine,
  getLineById,
} from "../../services/servicesLine";
import { getAllUsers } from "../../services/users";
import TextInput from "../../atoms/TextInput";
import SelectOption from "../../atoms/SelectOption";
import { SubmoduleList } from "../../molecules/SubmoduleList";
import { id } from "date-fns/locale";
import PillInputNew from "../PillInputNew";
import ErrorMessage from "../../atoms/ErrorMessage";


const validateSchema = Yup.object({
  name: Yup.string()
    .required("Campo obligatorio"),
  leader: Yup.string().required("Campo obligatorio"),
});

export const EditLine = ({
  onClose,
  edit = true,
  setStatus,
  setIsLoading,
  setOpenCreateNotification,
  setError400,
  setNewLineRender,
  newLineRender,
  lineData,
}) => {

  const initialModules = lineData.module.map((module) => {
    return {
      value: module.id,
      label: module.label,
    }
  });
  const initialManagers = lineData.managers.map((manager) => {
    return {
      value: manager.id,
      label: manager.name,
    }
  });

  const [lead, setLead] = useState([]);
  const [modulesPlan, setModulesPlan] = useState([]);
  const [valueModules, setValueModule] = useState(initialModules);
  const [valueManagers, setValueManagers] = useState(initialManagers);
  const [initialValues, setInitialValues] = useState({
    name: lineData.name,
    leader: lineData.leader ? lineData.leader.id: '',
    module: lineData.module.map((module) => module.id),
    managers: lineData.manager ?  lineData.managers.map((manager) => manager.id): [],
  });

  const { user, modules, modulesMixer } = useContext(AppContext);
  const { userList, setUserList } = useContext(UsersContext);





  useEffect(() => {
    async function fetchData() {
      const res = await getAllUsers();
      setLead(res.response);
      setUserList(
        res.response.map((user) => {
          return {
            value: user.id,
            label: user.name,
          };
        })
      );
    }
    fetchData();
  }, []);

  const multiFilter = (array, filters) => {
    let filterKeys = Object.keys(filters);

    return array.filter((item) =>
      filterKeys.every((key) => filters[key].indexOf(item[key]) === -1)
    );
  };

  useEffect(() => {
    const fetchModules = async () => {
      const listModules = modules.map((module) => ({
        key: module.id,
        value: module.label,
      }));
      const filters = { key: [7, 8, 9, 12] };
      const arrayModules = multiFilter(listModules, filters);
      const list = arrayModules?.map((element) => ({
        value: element.key,
        label: element.value,
      }));
      setModulesPlan(list);
    };
    fetchModules();
  }, []);

  const missingModules = modulesPlan?.filter((element) => !initialModules.some((sub) => sub.value === element.value));
  const missingManagers = userList?.filter((element) => !initialManagers.some((sub) => sub.value === element.value));

  return (
    <ModalFormStepper
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={async ({values}) => {
        const defaultData = {
          updated_by: user.email,
          name: values.name,
          leader: values.leader,
          managers: valueManagers.map(
            (manager) => manager.value
          ),
          module: valueModules.map((module) => module.value),
        };
        setIsLoading(true);
        if (edit) {
         const data = await updateLine(defaultData, lineData.id);
         setStatus(data.status);
         setOpenCreateNotification(true);
         onClose()
         setIsLoading(false);
         setNewLineRender(!newLineRender)
         if (data.status === 400) {
           setError400(data.data.data.response[0].message);
         }
        }
      }}
    >
      <ModalStep validationSchema={validateSchema}>
        <h2 className={styles.title}>Editar línea</h2>
        <div className={styles.inputNameContainer}>
          <TextInput
            label="Nombre"
            name="name"
            type="text"
            className={styles.label}
            classInput={styles.classInput}
            customInputContainer={styles.customInputContainer}
            placeholder="Nombre de línea"
          />
        </div>
        <div className={styles.inputSelectContainer}>
          <SelectOption
            label="Líder"
            name="leader"
            classNameL={styles.label}
            classInput={styles.classInput}
            errorMessageStyle={styles.errorMessageStyle}
            placeholder="Nombre de línea"
          >
          <option value="" disabled selected>
            Seleccionar
          </option>
          {lead.map((user) => (
            <option value={user.id} key={user.id}>
              {user.name}
            </option>
          ))}
          </SelectOption>
        </div>
      </ModalStep>
      <ModalStep >
        <h2 className={styles.title}>Editar línea</h2>
        <div className={styles.listContainer}>
          <label htmlFor="module" className={styles.subTitle}> Módulos </label>
          <div className={styles.pillinputContainer}>
          <PillInputNew
            options={missingModules}
            name="module"
            setValue={setValueModule}
            submodule={initialModules}
          />
          </div>
        </div>
        <div className={styles.listContainer}>
          <label htmlFor="selectedManager" className={styles.subTitle}> Responsables </label>
          <div className={styles.pillinputContainer}>
          <PillInputNew
            typeValue="string"
            options={missingManagers}
            name="selectedManager"
            setValue={setValueManagers}
            submodule={initialManagers}
          />
          </div>
        </div>
      </ModalStep>
    </ModalFormStepper>
  );
};
