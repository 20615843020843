import React, { useState } from "react";
import CardFSFB from "../../atoms/Card";
import { TitleAccordion } from "../../molecules/TitleAccordion";
import ManagerScaleOptions from "../MangerScaleOptions";
import styles from "./QuestionsCardFSFB.module.scss";

export default function QuestionsCardFSFB({
  item,
  index,
  answersLines,
  scale,
  edited,
}) {
  const [isActiveSection, setIsActiveSection] = useState(true);
  const [sectionIndex] = useState(index);

  const handleActiveSection = () => {
    setIsActiveSection(!isActiveSection);
  };

  return (
    <>
      {item.question ? (
        <ManagerScaleOptions
          answersLines={answersLines}
          scale={scale}
          question={item.question}
          edited={edited}
        />
      ) : (
        <CardFSFB className={styles.sectionCard}>
          <TitleAccordion
            handleClick={() => handleActiveSection()}
            isActive={isActiveSection}
            className={styles.arrowAccordion}
            isPatientDetail={true}
            sectionCardContainer={styles.sectionCardContainer}
            variant="diligence"
          >
            <h3 className={styles.titleSectionAccordion}>
              {item.section.name}
            </h3>
          </TitleAccordion>
          {isActiveSection && sectionIndex === index ? (
            <div className={styles.scaleOptionContainer}>
              {item.section.questions.map((question) => (
                <ManagerScaleOptions
                  answersLines={answersLines}
                  scale={scale}
                  question={question}
                  edited={edited}
                />
              ))}
            </div>
          ) : null}
        </CardFSFB>
      )}
    </>
  );
}
