import React from "react";
import styles from "./HelpTemplate.module.scss";
import FooterDescription from "../../molecules/FooterDescription";

export const HelpTemplate = ({ children }) => {
  return <div className={styles.containerHelp}>
    {children}
    <FooterDescription />
  </div>;
};
