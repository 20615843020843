import * as moment from 'moment-timezone';

import styles from "./AlertHistory.module.scss";

export const AlertHistory = ({data}) => {

  return (
    <div className={styles.tableContainer}>
       {data?.trace?.edited?.status ? 
        <section>
          <div className={styles.tableStyle}>
            <h6>Fecha modificación</h6>
            <h6>Motivo</h6>
            <h6>Responsable</h6>
          </div>
          {data.trace.edited.history.map((item, index) =>
            <div key ={`edited-${index}`} className={styles.tableStyle}>
              <p>{moment.tz(item.updated_at, 'America/Bogota').format("YYYY-MM-DD")}</p>
              <p>{item.remark}</p>
              <p>{item.updated_by}</p>

            </div>
          )} 
        </section>
        : null}
    </div>
  )
}
