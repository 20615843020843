import ErrorTemplate from "../../molecules/ErrorTemplate";

export default function Error403() {
  return (
    <ErrorTemplate
      titleLabel="¡Ups! Error 403"
      subtitleLabel="Acceso denegado"
      text={[
        "¡Lo sentimos!, pero no tienes permiso para ver este contenido.",
        "¿Buscamos otro mejor?",
      ]}
    />
  );
}
